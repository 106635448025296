import { useDispatch, useSelector } from "react-redux";
import Localization from "../../../../localization";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import constantValues from "../../../../misc/constantValues";
import useLogin from "../../../../hooks/User/useLogin";
import CircularProgress from "../../../../material-ui/CircularProgress/CircularProgress";
import { Link, useNavigate } from 'react-router-dom';
import SuccessBox from "../../Register/components/SuccessBox";
import useProfile from '../../../../hooks/User/useProfile';
import { logoutUser, loginUser } from "../../../../redux/state/userReducer";
import { showCookieDialog } from "../../../../redux/state/cookieReducer";
import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import GoogleButton from "../../../../misc/GoogleButton";

function LoginForm() {
    const cookieReducer = useSelector(state => state.cookieReducer);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const User = useSelector(state => state.userReducer);
    const { getProfile } = useProfile();
    const [showSuccessBox, setShowSuccessBox] = useState(false);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            display: 'flex',
            width: '100%',
            alignItems: 'flex-start',
            flexDirection: 'column',
        },
        inputContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        inputHelper: {
            color: theme.palette.text.disabled,
            fontSize: '11px'
        },
        input: {
            width: '100%',
            backgroundColor: theme.palette.mode === "light" ? 'white' : '#29263a'
        },
        inputRow: {
            width: desktopSize ? '80%' : '100%',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem'
        },
        buttonContainer: {
            width: '100%',
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        button: {
            textTransform: 'none',
            width: desktopSize ? '8rem' : '100%',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
        },
        errorContainer: {
            fontSize: '11px',
            height: '12px',
            color: theme.palette.error.main
        },
        link: {
            textDecoration: 'none',
            color: 'gray',
            fontSize: '12px',
        }
    }
    /*END: Styles */

    /*BEGIN: Form State */
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    /*BEGIN: Form State */

    /*BEGIN: Login State/Function */
    const { login, loading, validateForm, onGoogleLoginSuccess, onGoogleLoginError } = useLogin();
    const [errorList, setErrorList] = useState(null);

    const onGoogleLogin = () => {
        if (!cookieReducer?.accepted) {
            dispatch(showCookieDialog());
            return;
        }
    }
    const onLogin = () => {
        if (!cookieReducer?.accepted) {
            dispatch(showCookieDialog());
            return;
        }
        setErrorList(null);
        let errors = validateForm(email, password, 'sss');
        if (errors.length > 0) {
            setErrorList(errors);
        } else {
            dispatch(logoutUser());
            setTimeout(() => {
                login(
                    email,
                    password,
                    'sss'
                );
            }, 500)
        }
    }
    /*END: Login State/Function */

    useEffect(() => {
        let emailNotConfirmed = User?.accessToken &&
            User?.email &&
            !User?.emailConfirmed;

        let loggedIn = User?.accessToken && User?.email;

        if (loggedIn && !emailNotConfirmed) {
            navigate('/');
        }

    }, [User?.accessToken, User?.email])

    useEffect(() => {
        if (User?.accessToken) {
            const shouldShowBox = (profile) => {
                console.log(profile, 'profile')
                let emailNotConfirmed = !profile?.emailConfirmed;
                if (emailNotConfirmed) {
                    setShowSuccessBox(true);
                }
            }
            getProfile(shouldShowBox);
        }
    }, [User?.accessToken]);

    return (
        <form style={styles.root}>
            <div style={styles.inputRow}>
                <div style={styles.inputContainer}>
                    <div style={styles.inputHelper}>{TEXTS["email"]}</div>
                    <TextField
                        type='email'
                        size='small'
                        style={styles.input}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant='outlined'
                        error={errorList && errorList.find(x => x.field === "email")}
                    />
                    <div style={styles.errorContainer}>
                        {errorList &&
                            errorList.find(x => x.field === "email")?.message
                        }
                    </div>
                </div>
            </div>
            <div style={styles.inputRow}>
                <div style={styles.inputContainer}>
                    <div style={styles.inputHelper}>{TEXTS["password"]}</div>
                    <TextField
                        size='small'
                        style={styles.input}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant='outlined'
                        type={'password'}
                        error={errorList && errorList.find(x => x.field === "password")}
                    />
                    <div style={styles.errorContainer}>
                        {errorList &&
                            errorList.find(x => x.field === "password")?.message
                        }
                    </div>
                </div>
            </div>
            <Link style={styles.link} to={constantValues.FORGOT_PASSWORD_ROUTE}>
                Forgot password
            </Link>
            <SuccessBox
                route={constantValues.LOGIN_ROUTE}
                showSuccessBox={showSuccessBox}
            />
            <div style={styles.buttonContainer}>
                <Button
                    style={styles.button}
                    variant='contained'
                    onClick={onLogin}
                    disabled={loading}
                >
                    {TEXTS["login"]}
                    {loading && <CircularProgress />}
                </Button>
                <div>
                    Or
                </div>
                {cookieReducer?.accepted ?
                    <GoogleLogin
                        onSuccess={onGoogleLoginSuccess}
                        onError={onGoogleLoginError}
                    /> :
                    <GoogleButton onClick={onGoogleLogin} />
                }
            </div>
        </form>
    )
}

export default LoginForm