import constantValues from '../misc/constantValues';
import { useSelector, useDispatch } from 'react-redux';
import { showFailNotification } from '../redux/state/notificationReducer';
import { logoutUser } from '../redux/state/userReducer';
import { useNavigate } from 'react-router-dom';

const axios = require('axios').default;

function useRequest() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const User = useSelector(state => state.userReducer);
    const createRequest = (config) => {
        axios({
            method: config?.method || 'GET',
            baseURL: config?.baseUrl || constantValues.BASE_URL,
            url: config?.url,
            data: config?.data,
            params: config?.params,
            headers: {
                'authorization': `Bearer ${User?.accessToken}`,
                'Content-Type': config?.contentType || 'application/json'
            }
        }).then(response => {
            if (response.data?.isSuccess || config?.shouldNotCheckIsSuccess) {
                config?.onSuccess(response);
            } else {
                config?.onFail({ message: response.data?.message, error: response?.data?.error });
            }
        }).catch(error => {
            config?.onFail(error);
            if (User?.accessToken) {
                handleSessionErrors({
                    error,
                    dispatch,
                    showFailNotification,
                    logoutUser,
                    navigate
                });
            }
        }).finally(() => {
            if (config?.finally) {
                config?.finally();
            }
        });
    }

    return createRequest
}

function handleSessionErrors({ error, dispatch, showFailNotification, logoutUser, navigate }) {
    let response = error?.response?.data;
    if (response?.error === "Forbidden") {
        const reasons = ['SESSIONHIJACK', 'SESSIONNF', 'EMAILNOTVERIFIED', 'SESSIONOVERUSED'];

        const shouldLogout = response?.reasons?.includes(reasons[0]) ||
            response?.reasons?.includes(reasons[1]);

        // const shouldRedirectToVerify = response?.reasons?.includes(reasons[2]);

        const shouldRedirectToSessions = response?.reasons?.includes(reasons[3]);

        if (shouldLogout) {
            dispatch(logoutUser())
            setTimeout(() => dispatch(showFailNotification('Your session was Invalidated!')), 1000);
            return;
        }

        // if (shouldRedirectToVerify) {
        //     navigate(constantValues.VERIFY_EMAIL_ROUTE);
        //     return;
        // }

        if (shouldRedirectToSessions) {
            dispatch(showFailNotification('You have too many active sessions please delete some of your sessions in order to continue'))
            navigate(`${constantValues.PROFILE_ROUTE}?tab=4`)
        }
    }
    console.log(error.response.data, 'limit Error')
}

export default useRequest