import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import { userReducer } from "./state/userReducer";
import { themeReducer } from "./state/themeReducer";
import { localizationReducer } from "./state/localizationReducer";
import { notificationReducer } from "./state/notificationReducer";
import { timeframesReducer } from "./state/timeframesReducer";
import { signalsReducer } from "./state/signalsReducer";
import { limitReducer } from "./state/limitReducer";
import { termsOfServiceReducer } from "./state/termsOfServiceReducer";
import { trialReducer } from "./state/trialReducer";
import { cookieReducer } from "./state/cookieReducer";

import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
}

const reducers = combineReducers({
    userReducer,
    themeReducer,
    localizationReducer,
    notificationReducer,
    timeframesReducer,
    signalsReducer,
    limitReducer,
    termsOfServiceReducer,
    trialReducer,
    cookieReducer
})

const persistedReducers = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducers,
    middleware: [thunk]
});

export const persistor = persistStore(store)