import HelpIcon from '../../../material-ui/Icons/HelpIcon';
import constantValues from '../../../misc/constantValues';
import Localization from "../../../localization";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from "react-redux";
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import MakeStrategyDialog from './MakeStrategyDialog';
import { showLimitDialog } from '../../../redux/state/limitReducer';

function Header({ searchValue, setSearchValue, createStrategy, getStrategies, loading, result }) {
    const dispatch = useDispatch();
    const limitReducer = useSelector(state => state.limitReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            display: 'flex',
            width: mobileSize ? '96%' : '90%',
            justifyContent: 'flex-end',
            marginTop: '2rem'
        },
        container: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        helpIcon: {
            marginLeft: mobileSize ? '0.3rem' : '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
        },
        title: {
            display: 'flex',
            alignItems: 'center',
        },
        inputAdornment: {
            paddingRight: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : '',
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        searchInputRoot: {
            minWidth: mobileSize ? '200px' : desktopSize ? '600px' : '400px',
            marginLeft: desktopSize ? '2rem' : '',
            width: desktopSize ? '' : '100%',
            marginTop: desktopSize ? '' : '1.5rem'
        },
        muiOutlinedInputRoot: {
            paddingRight: 0,
            paddingLeft: 0,
            borderRadius: '8px',
            color: theme.palette.text.primary,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
        },
        startAdornmentRTL: {
            paddingRight: 0,
            paddingLeft: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            borderTopLeftRadius: '',
            borderBottomLeftRadius: '',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            marginRight: 0
        },
        endAdornmentLTR: {
            paddingRight: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        adornmentIconColor: {
            color: 'white'
        },
        button: {
            borderRadius: '8px',
            marginLeft: mobileSize ? '1rem' : '1.5rem',
            textTransform: 'none',
            padding: '0.6em 1em 0.6em 1em',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
        },
        makeStrategyIcon: {
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.4rem' : '',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.4rem'
        },
        titleHeader: {
            fontSize: mobileSize ? '1.2rem' : ''
        }
    }
    /*END: Styles */

    /*BEGIN: Application State */
    const [showDialog, setShowDialog] = useState(false);
    /*END: Application State */

    const onShowDialog = () => {
        let shouldShow = limitReducer?.strategyLeft > 0;
        if (!shouldShow) {
            dispatch(showLimitDialog(constantValues.OVERUSE_ENUMS.OVERUSE_CREATE_STRATEGY));
            return;
        }

        setShowDialog(true);
    }

    return (
        <section style={styles.root}>
            <MakeStrategyDialog
                createStrategy={createStrategy}
                getStrategies={getStrategies}
                loading={loading}
                result={result}
                open={showDialog}
                onClose={() => setShowDialog(false)}
            />
            <div style={styles.container}>
                {/*BEGIN: Title */}
                <div style={styles.title}>
                    <h2 style={styles.titleHeader}>{TEXTS["myStrategies"]}</h2>
                    <HelpIcon style={styles.helpIcon} />
                </div>
                {/*END: Title */}

                {/*BEGIN: Search Desktop */}
                {desktopSize &&
                    <OutlinedTextField
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={styles.searchInputRoot}
                        label={constantValues.IS_RTL_LANGUAGE(language) ? undefined : TEXTS["search"]}
                        placeholder={constantValues.IS_RTL_LANGUAGE(language) ? TEXTS["search"] : undefined}
                        size='small'
                        variant='outlined'
                        sx={{
                            "& .MuiOutlinedInput-root": styles.muiOutlinedInputRoot,
                            "& .MuiInputLabel-root": {
                                color: (theme) => theme.palette.text.disabled,
                            },
                        }}
                        InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                            {
                                startAdornment: (
                                    <InputAdornment
                                        position={"start"}
                                        sx={styles.startAdornmentRTL}
                                    >
                                        <SearchIcon style={styles.adornmentIconColor} />
                                    </InputAdornment>
                                )
                            } :
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"end"}
                                        sx={styles.endAdornmentLTR}
                                    >
                                        <SearchIcon style={styles.adornmentIconColor} />
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                }
                {/*END: Search Desktop*/}

                {/*BEGIN: Make Strategy */}
                <Button
                    onClick={onShowDialog}
                    sx={{ borderRadius: '8px', boxShadow: 0 }}
                    style={styles.button}
                    variant='contained'
                >
                    <AddIcon style={styles.makeStrategyIcon} />
                    {TEXTS["makeStrategy"]}
                </Button>
                {/*END: Make Strategy */}

                {/*BEGIN: Search */}
                {!desktopSize &&
                    <OutlinedTextField
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={styles.searchInputRoot}
                        label={constantValues.IS_RTL_LANGUAGE(language) ? undefined : TEXTS["search"]}
                        placeholder={constantValues.IS_RTL_LANGUAGE(language) ? TEXTS["search"] : undefined}
                        size='small'
                        variant='outlined'
                        sx={{
                            "& .MuiOutlinedInput-root": styles.muiOutlinedInputRoot,
                            "& .MuiInputLabel-root": {
                                color: (theme) => theme.palette.text.disabled,
                            },
                        }}
                        InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                            {
                                startAdornment: (
                                    <InputAdornment
                                        position={"start"}
                                        sx={styles.startAdornmentRTL}
                                    >
                                        <SearchIcon style={styles.adornmentIconColor} />
                                    </InputAdornment>
                                )
                            } :
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"end"}
                                        sx={styles.endAdornmentLTR}
                                    >
                                        <SearchIcon style={styles.adornmentIconColor} />
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                }
                {/*END: Search */}
            </div>
        </section>
    )
}

export default Header