import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

import MainConfig from './components/MainConfig';
import OtherParams from './components/OtherParams';
import Action from './components/Action';
import MarketOverAll from '../../../misc/MarketOverAll';
import constantValues from '../../../misc/constantValues';
import useBacktest from '../../../hooks/Backtest/useBacktest';

function BacktestSearch() {
    const { loading, createBacktest } = useBacktest();
    const [name, setName] = useState('');
    const [transaction, setTransaction] = useState('Buy');
    const [enterSignals, setEnterSignals] = useState([]);
    const [exitSignals, setExitSignals] = useState([]);
    const [executeCandlesEnter, setExecuteCandlesEnter] = useState(10);
    const [executeCandlesExit, setExecuteCandlesExit] = useState(10);
    const [profitLimit, setProfitLimit] = useState();
    const [lossLimit, setLossLimit] = useState();
    const [maxCandles, setMaxCandles] = useState(10);
    const [timeframe, setTimeframe] = useState('4h');
    const [fromDate, setFromDate] = useState(constantValues.fromXDaysAgo(180));
    const [toDate, setToDate] = useState(constantValues.fromXDaysAgo(0));

    /*BEGIN: Styles */
    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: '15px'
        },
    }
    /*BEGIN: Styles */

    const onCreateBacktest = () => {
        createBacktest(
            name,
            enterSignals,
            exitSignals,
            executeCandlesEnter,
            lossLimit,
            profitLimit,
            maxCandles,
            fromDate,
            toDate,
            timeframe,
            transaction
        )
    }
    return (
        <main style={styles.root}>
            {/* Market Overall */}
            <MarketOverAll />

            {/* Main Config */}
            <MainConfig
                name={name}
                setName={setName}
                transaction={transaction}
                setTransaction={setTransaction}
                enterSignals={enterSignals}
                setEnterSignals={setEnterSignals}
                exitSignals={exitSignals}
                setExitSignals={setExitSignals}
                executeCandlesEnter={executeCandlesEnter}
                setExecuteCandlesEnter={setExecuteCandlesEnter}
                executeCandlesExit={executeCandlesExit}
                setExecuteCandlesExit={setExecuteCandlesExit}
            />

            {/*Other Params */}
            <OtherParams
                profitLimit={profitLimit}
                setProfitLimit={setProfitLimit}
                lossLimit={lossLimit}
                setLossLimit={setLossLimit}
                maxCandles={maxCandles}
                setMaxCandles={setMaxCandles}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
            />

            {/*Action */}
            <Action submit={onCreateBacktest} loading={loading} />
        </main>
    )
}

export default BacktestSearch