import { useDispatch } from 'react-redux';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { setLimits } from '../../redux/state/limitReducer';

function useLimit() {
    const createRequest = useRequest();
    const dispatch = useDispatch();

    const getLimits = () => {
        const onSuccess = (response) => {
            dispatch(setLimits(response.data.data));
        }
        const onFail = (error) => {
            console.log(error, 'limitError')
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_LIMITS('CRYPTO'),
            onSuccess,
            onFail
        })
    }

    return {
        getLimits
    }
}

export default useLimit