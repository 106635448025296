const constantValues = {
    /*BEGIN: Common Styles */
    LAYOUT_WIDTH_LARGE: '93%',
    LAYOUT_WIDTH_SMALL: '92%',
    LAYOUT_WIDTH_NORMAL: '90%',

    BACKGROUND_COLOR_1: '#f6f1f9',
    BACKGROUND_COLOR_2: '#362050',

    ICON_COLOR_1: '#cdaed3',
    ICON_COLOR_2: '#484464',
    ICON_COLOR_3: '#a680ba',
    ICON_COLOR_4: '#f4e9f7',

    BORDER_COLOR_1: '#eee0f1',

    FONT_COLOR_1: '#78c9c0',
    PRIMARY_FONT_FAMILY: 'Poppins',
    /*END: Common Styles */

    /*BEGIN: Application Routes */
    LOGIN_ROUTE: '/auth/login',
    LOGIN_BY_QUERY_ROUTE: '/auth/login/query',
    REGISTER_ROUTE: '/auth/register',
    FORGOT_PASSWORD_ROUTE: '/auth/forgot',
    CHANGE_PASSWORD_ROUTE: '/recover',
    VERIFY_EMAIL_ROUTE: '/verify',
    SINGLE_SYMBOL_ROUTE: '/symbol/:name',
    STRATEGY_ROUTE: '/strategy',
    CHART_ROUTE: '/chart',
    DASHBOARD_ROUTE: '/dashboard',
    FAVORITE_COIN_ROUTE: '/favorite/coin',
    PROFILE_ROUTE: '/profile',
    TICKETS_OVERVIEW_ROUTE: '/tickets/overview',
    TICKET_DETAIL_ROUTE: '/tickets/detail/:id',
    BACKTEST_SEARCH_ROUTE: '/backtest/search',
    BACKTEST_RESULT_ROUTE: '/backtest/result/:id',
    BACKTEST_HISTORY_ROUTE: '/backtest/history',
    RUN_STRATEGY_ROUTE: '/strategy/:id',
    SEARCH_HISTORY_ROUTE: '/search/history',
    PLAN_ROUTE: '/plans',
    PAYMENT_ROUTE: '/payment/:id',

    COOPERATION_ROUTE: '/cooperation',
    CONTACT_ROUTE: '/contact',
    ABOUT_ROUTE: '/about',
    DELETE_ACCOUNT_ROUTE: '/account/delete',
    /*END: Application Routes */

    DEFAULT_CRYPTO_TIMEFRAME: '1h',
    MAX_FILE_SIZE_IN_KB: 200,

    IS_RTL_LANGUAGE: (language) => {
        const RTLZ = ["fa", "ar"];
        if (RTLZ.includes(language)) {
            return true
        }
        return false
    },

    parseTimeFrameFromNewAPI: (tf) => {
        switch (tf) {
            case 'D1':
                return '1D';
            case 'h1':
                return '1h';
            case 'h4':
                return '4h';
            case 'h6':
                return '6h';
            case 'h12':
                return '12h';
            case 'W1':
                return '1W';
            case 'm15':
                return '15m';
            case 'm30':
                return '30m';
            case 'm5':
                return '5m';
            default:
                return '1D'
        }
    },
    parseTimeFrameForNewAPI: (tf) => {
        switch (tf) {
            case '1D':
                return 'D1';
            case '1h':
                return 'h1';
            case '4h':
                return 'h4';
            case '6h':
                return 'h6';
            case '12h':
                return 'h12';
            case '1W':
                return 'W1';
            case '15m':
                return 'm15';
            case '30m':
                return 'm30';
            case '5m':
                return 'm5';
            default:
                return 'D1'
        }
    },

    shouldExcludeTimeframeInStrategy: ['5m'],

    OVERUSE_ENUMS: {
        OVERUSE_STRATEGY_SEARCH: 'OVERUSE_STRATEGY_SEARCH',
        OVERUSE_STRATEGY_EDIT: 'OVERUSE_STRATEGY_EDIT',
        OVERUSE_CREATE_BACKTEST: 'OVERUSE_CREATE_BACKTEST',
        OVERUSE_CREATE_STRATEGY: 'OVERUSE_CREATE_STRATEGY',
        OVERUSE_ADD_FAVORITE_SYMBOL: 'OVERUSE_ADD_FAVORITE_SYMBOL',
    },

    ACCOUNT_LIMIT_ENUMS: {
        INTERACT_BACKTEST: 'INTERACT_BACKTEST'
    },

    CANDLE_OPTIONS: [
        1,
        5,
        10,
        15,
        30,
        40
    ],
    BACKTEST_CANDLE_OPTIONS: [
        5,
        10,
        15,
        30,
        40
    ],
    DEFAULT_CANDLE_OPTION: 10,

    fromXDaysAgo: (x = 0) => {
        let curr = new Date();
        curr.setDate(curr.getDate() - x);
        let date = curr.toISOString().substring(0, 10);
        return date;
    },

    generateCoinMarketcapLink: (symbol) => `https://coinmarketcap.com/currencies/${symbol}`,
    generateTradingViewLink: (symbolAndCur) => `https://www.tradingview.com/symbols/${symbolAndCur}/?exchange=BINANCE`,
    generateBinanceLink: (symbol, cur) => `https://www.binance.com/en/trade/${symbol}_${cur}`,

    convertFileTimeToDateTime: (filetime) => {
        var s = String(filetime);
        var t = +s.substring(0, s.length - 4);
        var e = Date.UTC(1601, 0, 1);
        var dt = new Date(e + t);
        return dt;
    },
    timeFrameToInterval: (tf) => {
        switch (tf) {
            case '1D':
                return '1D';
            case '1h':
                return '60';
            case '4h':
                return '240';
            case '6h':
                return '360';
            case '12h':
                return '720';
            case '1W':
                return 'W';
            case '15m':
                return '15';
            case '30m':
                return '30';
            case '5m':
                return '5';
            default:
                return '1D'
        }
    },
    intervalToTimeFrame: (tf) => {
        switch (tf) {
            case '1D':
                return '1D';
            case '60':
                return '1h';
            case '240':
                return '4h';
            case '360':
                return '6h';
            case '720':
                return '12h';
            case '7D':
                return '1W';
            case '15':
                return '15m';
            case '30':
                return '30m';
            case '5':
                return '5m';
            default:
                return '1D'
        }
    },

    isSellOrBuySignal: (num) => Number(num) % 2 === 0 ? 'Sell' : 'Buy',

    disableBodyOverflow: () => {
        const body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'hidden';
    },

    convertDateToTableFormat: (date) => {
        const utcDate = new Date(date);
        if (isNaN(utcDate)) {
            return 'Invalid Date';
        }
        const localDateString = utcDate.toLocaleString();
        return localDateString;
    },
    TELEGRAM_LINK: 'https://t.me/allcantrade',
    LINKEDIN_LINK: 'https://www.linkedin.com/company/allcantrade/',
    YOUTUBE_LINK: 'https://www.youtube.com/@allcantrade',

    /*BEGIN: API */
    BASE_URL: 'https://api.allcan.trade/userservice/',
    CHART_DATA_FEED: 'https://api.allcan.trade/userservice/chart',

    REGISTER_USER: '/Account/Register',
    GET_PROFILE: '/User/Profile',
    GET_LIMITS: (market) => `/Limit/ByMarket/${market}`,
    UPDATE_PROFILE: '/User/Profile',
    DELETE_ACCOUNT: '/User/Delete',
    LOGIN_USER: '/Account/Login',
    INIT_PASSWORD_RECOVERY: '/Account/ForgetPassword',
    RECOVER_PASSWORD: '/Account/RecoverPassword',
    RESEND_VERIFY_EMAIL: '/User/ResendVerify',
    VERIFY_EMAIL: '/Account/Verify',
    GET_SESSIONS: '/Session',
    DELETE_SESSION: (id) => `/Session/kill/${id}`,
    GET_CURRENT_SUBSCRIPTION: (market) => `/Subscription/Current/${market}`,
    GET_SUBSCRIPTION_HISTORY: (market) => `/Subscription/History/${market}`,
    REFRESH_TOKEN: '/User/Refresh',
    REVOKE_TOKEN: (refreshToken) => `/User/Revoke?refreshToken=${refreshToken}`,
    CREATE_TICKET: '/Ticket',
    GET_TICKETS: '/Ticket',
    GET_PLANS: (market) => '/Plan/ByMarket/' + market,
    GET_PLAN_BY_ID: (id) => `/Plan/${id}`,
    GET_TIMEFRAMES: (market) => '/Signal/Timeframe/' + market,
    GET_SIGNALS: (market) => '/Signal/Signals/' + market,

    SEARCH_STRATEGY: (id) => `/Strategy/Search/${id}`,
    GET_STRATEGIES: (market, placement) => '/Strategy/ByMarket/' + market + '/' + placement,
    CREATE_STRATEGY: '/Strategy',
    EDIT_STRATEGY: (id) => `/Strategy/${id}`,
    DELETE_STRATEGY: (id) => `/Strategy/${id}`,
    GET_STRATEGY: (id) => `/Strategy/${id}`,

    GET_SYMBOL_INFO: (market, name) => `/Exchange/${market}/${name}`,
    GET_SYMBOL_INFO_GUEST: (market, name) => `/Exchange/Guest/${market}/${name}`,
    GET_SIGNAL_BY_PAIR_AND_TIMEFRAME: (market, base, quote, timeframe) =>
        `/Exchange/Signals/${market}/${base}/${quote}/${timeframe}`,
    GET_SIGNAL_BY_PAIR_AND_TIMEFRAME_GUEST: (market, base, quote, timeframe) =>
        `/Exchange/Guest/Signals/${market}/${base}/${quote}/${timeframe}`,
    TOGGLE_FAVORITE_SYMBOL: '/Exchange/Toggle',
    GET_FAVORITE_SYMBOLS: (market) => `/Exchange/Favorite/${market}`,
    GET_ALL_COINS: (market) => `/Exchange/${market}`,

    CREATE_TICKET_CONVERSATION: (id) => `/Ticket/${id}/Conversation`,
    CLOSE_TICKET: (id) => `/Ticket/${id}/Conversation/Close`,
    GET_TICKET_BY_ID: (id) => `/Ticket/${id}`,

    GET_CURRENT_BILLING_INFORMATION: '/BillingInfo',
    UPDATE_BILLING_INFORMATION: '/BillingInfo',
    CHECKOUT_PAYMENT: '/Payment/Checkout',
    PRE_CHECKOUT_PAYMENT: '/Payment/PreCheckout',

    GET_BROKERS: '/PairCategory/MyBrokers',
    UPDATE_BROKERS: '/PairCategory/MyBrokers',

    CREATE_BACKTEST: '/BackTest/Manual',
    GET_BACKTEST_HISTORY: '/BackTest/List',
    GET_BACKTEST_BY_ID: (id) => `/BackTest/Get/${id}`,

    DISCONNECT_MESSENGER: (platform) => `/User/Profile/Disconnect/${platform}`,

    SECONDARY_BASE_URL: 'https://api.allcan.trade/userservice',
    GET_SYMBOL_PRICE: (name) => '/symbol/ticker?symbol=' + name,
    GET_SYMBOL_CHART: (market, name, limit) =>
        `/symbol/price?market=${market}&name=${name}&limit=${limit}`,

    GET_BLOG_POSTS: '/blog/feed/json',
    BASE_BLOG_URL: 'https://allcan.trade',

    CONTACT_US: '/form/contactus',
    COOPERATION_WITH_US: '/form/cooperation',

    TERMS_OF_SERVICE_URL: 'https://allcan.trade/blog/index.php/term-of-service/',

    GOOGLE_LOGIN_CLIENT_ID: '814499949511-dccfp1goct3vat9m4nhpqhfqj701b626.apps.googleusercontent.com',
    GOOGLE_LOGIN_CALLBACK: 'https://api.allcan.trade/userservice/account/google/callback'

    /*END: API */

}

export default constantValues