import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import Localization from "../../../../localization";
import Grid from "@mui/material/Grid";
import OutlinedTextField from '../../../../material-ui/TextField/OutlinedTextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import constantValues from '../../../../misc/constantValues';
import useMediaQuery from '@mui/material/useMediaQuery';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

function OtherParams({
    profitLimit,
    setProfitLimit,
    lossLimit,
    setLossLimit,
    maxCandles,
    setMaxCandles,
    timeframe,
    setTimeframe,
    fromDate,
    setFromDate,
    toDate,
    setToDate
}) {
    const limitReducer = useSelector(state => state.limitReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    const timeframesReducer = useSelector(state => state.timeframesReducer);

    /*BEGIN: Styles */
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            display: 'flex',
            width: '100%',
            fontSize: mobileSize ? '12px' : '14px'
        },
        bigBoldText: {
            fontWeight: 'bold',
            fontSize: '17px',
            marginTop: '1.5rem'
        },
        helpText: {
            marginTop: '1rem'
        },
        inputContainer: {
            color: '#7d7d7d',
            display: 'flex',
            alignItems: 'center',
            gap: 5
        },
        timeframeInputContainer: {
            color: '#7d7d7d',
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            width: mobileSize ? '100%' : ''
        },
        inputs: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
            marginTop: '1.5rem',
            gap: mobileSize ? 20 : !desktopSize ? 20 : 10
        },
        profitLimitInput: {
            width: '80px',
        },
        candleInput: {
        },
        timeframeInput: {
            width: '87px'
        },
        borderRadius: {
            borderRadius: '8px',
            fontSize: mobileSize ? '12px' : '14px'
        },
        fromToDate: {
            textDecoration: 'underline',
            color: 'black',
            cursor: 'pointer'
        }
    }

    return (
        <section style={styles.root}>
            <Grid container justifyContent={'center'}>

                <Grid item xs={11}>
                    <div style={styles.bigBoldText}>
                        {TEXTS['otherParameters']}
                    </div>
                    <div style={styles.helpText}>
                        {TEXTS['backtestText1']}
                    </div>
                </Grid>

                <Grid item xs={11}>
                    <div style={styles.inputs}>
                        <div style={styles.inputContainer}>
                            <div>
                                {TEXTS['profitLimit']}:
                            </div>
                            <OutlinedTextField
                                type='number'
                                value={profitLimit}
                                onChange={(e) => setProfitLimit(e.target.value)}
                                size='small'
                                style={styles.profitLimitInput}
                                sx={{
                                    '& .MuiOutlinedInput-root': styles.borderRadius
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                        >
                                            %
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div style={styles.inputContainer}>
                            <div>
                                {TEXTS['lossLimit']}:
                            </div>
                            <OutlinedTextField
                                type='number'
                                value={lossLimit}
                                onChange={(e) => setLossLimit(e.target.value)}
                                size='small'
                                style={styles.profitLimitInput}
                                sx={{
                                    '& .MuiOutlinedInput-root': styles.borderRadius
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                        >
                                            %
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div style={styles.inputContainer}>
                            <div>
                                {TEXTS['maximumTradingTime']}:
                            </div>
                            <OutlinedTextField
                                value={maxCandles}
                                onChange={(e) => setMaxCandles(e.target.value)}
                                style={styles.candleInput}
                                size='small'
                                select
                                sx={{
                                    '& .MuiOutlinedInput-root': styles.borderRadius,
                                    "& .MuiOutlinedInput-input": {
                                        fontSize: mobileSize ? '12px' : '14px',
                                        color: theme.palette.mode === 'dark' ?
                                            constantValues.ICON_COLOR_1 : ''
                                    }
                                }}
                            >
                                {[...constantValues.BACKTEST_CANDLE_OPTIONS, 999].map(c =>
                                    <MenuItem key={c} value={c}>
                                        {
                                            c === 999 ? 'Unimportant' :
                                                c + ' ' + TEXTS['candles']
                                        }
                                    </MenuItem>
                                )}
                            </OutlinedTextField>
                        </div>
                        <div style={styles.timeframeInputContainer}>
                            <div>{TEXTS['timeframe']}:</div>
                            <OutlinedTextField
                                value={timeframe}
                                onChange={(e) => setTimeframe(e.target.value)}
                                style={styles.timeframeInput}
                                size='small'
                                select
                                sx={{
                                    '& .MuiOutlinedInput-root': styles.borderRadius
                                }}
                            >
                                {timeframesReducer.map(t =>
                                    !limitReducer?.timeFrames?.includes(t) ?
                                        <Tooltip
                                            title={
                                                'You must upgrade your plan in order to use this timeframe. Click to view plans'
                                            }
                                            arrow
                                        >
                                            <Link
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                                to={constantValues.PLAN_ROUTE}
                                            >
                                                <span>
                                                    <MenuItem
                                                        disabled
                                                        value={t}
                                                        key={t}
                                                    >
                                                        <div className="flex-row">
                                                            <LockOutlinedIcon fontSize='small' />
                                                            {t}
                                                        </div>
                                                    </MenuItem>
                                                </span>
                                            </Link>
                                        </Tooltip> :
                                        <MenuItem
                                            value={t}
                                            key={t}
                                        >
                                            {t}
                                        </MenuItem>
                                )}
                            </OutlinedTextField>
                        </div>
                        <div style={styles.inputContainer}>
                            <div>{TEXTS['from']}:</div>
                            {/* <div onClick={onInitiateFromDateChange} style={styles.fromToDate}>
                                2022/02/13
                            </div> */}
                            <OutlinedTextField
                                variant='standard'
                                type='date'
                                onChange={(e) => setFromDate(e.target.value)}
                                value={fromDate}
                                sx={{
                                    "& .MuiInput-input": {
                                        fontSize: mobileSize ? '12px' : '14px'
                                    }
                                }}
                            />
                        </div>
                        <div style={styles.inputContainer}>
                            <div>{TEXTS['untill']}:</div>
                            <OutlinedTextField
                                variant='standard'
                                type='date'
                                onChange={(e) => setToDate(e.target.value)}
                                value={toDate}
                                sx={{
                                    "& .MuiInput-input": {
                                        fontSize: mobileSize ? '12px' : '14px'
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </section>
    )
}

export default OtherParams