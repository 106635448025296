import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import constantValues from '../../misc/constantValues';
import { useTheme } from '@mui/material/styles';

function Pagination({ max, current, onNext, onPrevious }) {
    const theme = useTheme();
    const styles = {
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            color: theme.palette.mode === 'light' ? '#949494' : constantValues.ICON_COLOR_1,
            fontWeight: 'bold',
            fontSize: '13px'
        },
        icon: {
            color: theme.palette.mode === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            width: '1.5em',
            height: '1.5em'
        }
    }
    return (
        <div style={styles.root} dir='ltr'>
            <ArrowCircleLeftIcon
                style={styles.icon}
                onClick={current - 1 < 1 ? null : onPrevious}
            />
            <div>{current}/{max === 0 ? 1 : max}</div>
            <ArrowCircleRightIcon
                style={styles.icon}
                onClick={current + 1 > max ? null : onNext}
            />
        </div>
    )
}

export default Pagination