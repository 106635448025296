import { useSelector, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import React from 'react';

function ReduxGate({ children }) {
    const cookieReducer = useSelector(state => state.cookieReducer);

    return (
        <>
            <PersistGate loading={null} persistor={persistor}>
                {children}
            </PersistGate>
        </>
    )
}

export default ReduxGate;