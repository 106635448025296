import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import { useState } from 'react';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { loginUser } from '../../redux/state/userReducer';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import { useNavigate } from 'react-router-dom';
import useProfile from './useProfile';
import { initiateTrial } from '../../redux/state/trialReducer';
import ErrorParser from '../../misc/ErrorParser';
import { resetCookies } from "../../redux/state/cookieReducer";

function useRegister() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const createRequest = useRequest();
    const { getProfile } = useProfile();
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    const register = (
        FirstName,
        LastName,
        Email,
        Password,
        Referral,
        captcha,
        successCallback
    ) => {
        setLoading(true);

        const onSuccess = (response) => {
            dispatch(initiateTrial(Email));
            setLoading(false);
            setTimeout(() => dispatch(
                loginUser({
                    ...response.data.data,
                    firstName: FirstName,
                    lastName: LastName,
                    email: Email
                })), 200);
            setTimeout(() => dispatch(showSuccessNotification('Account Created!')), 250);
            getProfile();
            successCallback();
        }
        const onFail = (error) => {
            setLoading(false);

            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            data: { FirstName, LastName, Email, Password, Referral, captcha },
            url: constantValues.REGISTER_USER,
            method: 'POST',
            onSuccess,
            onFail
        })
    }

    const validateForm = (form) => {
        let errors = [];

        if (!form.termsOfService) {
            errors.push({
                field: 'termsOfService',
                message: 'You must accept the Terms Of Service in order to register'
            })
        }
        if (form.FirstName.length < 3) {
            errors.push({
                field: "firstName",
                message: "Name is too short"
            });
        }
        if (form.FirstName.length > 26) {
            errors.push({
                field: "firstName",
                message: "Name is too short"
            });
        }
        if (form.LastName.length < 3) {
            errors.push({
                field: "lastName",
                message: "Last name is too short"
            });
        }
        if (form.LastName.length > 26) {
            errors.push({
                field: "lastName",
                message: "Last name is too long"
            });
        }
        if (form.Password.length < 6) {
            errors.push({
                field: "password",
                message: "Password can't be less than 6 characters"
            })
        }
        if (!form.Password.match('^(?=.*[0-9])')) {
            errors.push({
                field: "password",
                message: "Password should contain atleast 1 number"
            })
        }
        if (!form.Password.match('^(?=.*[A-Z])')) {
            errors.push({
                field: "password",
                message: "Password should contain atleast 1 capital letter"
            })
        }

        if (!form.Password.match('^(?=.*[a-z])')) {
            errors.push({
                field: "password",
                message: "Password should contain atleast 1 lower case letter"
            })
        }
        if (form.Password !== form.PasswordConfirmation) {
            errors.push({
                field: "passwordConfirm",
                message: "Both password fields should be equal"
            })
        }

        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(form.Email)) {
            errors.push({
                field: "email",
                message: "Invalid Email"
            })
        }


        return errors
    }

    return {
        loading,
        validateForm,
        register,
        result
    }
}

export default useRegister