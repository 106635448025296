import { useTheme } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import constantValues from '../../../misc/constantValues';

const MOCK_DATA = [
    {
        signals: ["Price Crosses Up Kumo", "RSI Crosses up 30", "MACD Crosses Up Signal Line"],
        timeframe: "4 Hours",
        coins: "All Coins",
        signalDate: "Last 10 Candles"
    },
    {
        signals: ["Price Crosses Up Kumo", "RSI Crosses up 30", "MACD Crosses Up Signal Line"],
        timeframe: "4 Hours",
        coins: "All Coins",
        signalDate: "Last 10 Candles"
    },
    {
        signals: ["Price Crosses Up Kumo", "RSI Crosses up 30", "MACD Crosses Up Signal Line"],
        timeframe: "4 Hours",
        coins: "All Coins",
        signalDate: "Last 10 Candles"
    },
]

function Table() {
    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: mobileSize ? '100%' : '90%',
            marginTop: '2rem',
            paddingBottom: '2rem',
            overflowX: 'auto'
        },
        tableRoot: {
            width: '100%'
        },
        tableHeader: {
            border: 0,
            paddingBottom: '1.5em',
            fontWeight: 'normal',
            color: '#7a7a7a'
        },
        tableSignals: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
        },
        tableData: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }
    }
    return (
        <section style={styles.root}>
            <table style={styles.tableRoot}>
                <tr>
                    <th style={styles.tableHeader}>Signals</th>
                    <th style={styles.tableHeader}>Timeframe</th>
                    <th style={styles.tableHeader}>Coins</th>
                    <th style={styles.tableHeader}>Signal Date</th>
                    <th style={styles.tableHeader}>Run Again</th>
                </tr>
                {
                    MOCK_DATA.map((x, index) =>
                        <tr key={index}>
                            <td>
                                <div style={styles.tableSignals}>
                                    {x.signals.map((s, i) =>
                                        <div key={i}>
                                            {s}
                                        </div>
                                    )}
                                </div>
                            </td>
                            <td>
                                <div style={styles.tableData}>
                                    {x.timeframe}
                                </div>
                            </td>
                            <td>
                                <div style={styles.tableData}>
                                    {x.coins}
                                </div>
                            </td>
                            <td>
                                <div style={styles.tableData}>
                                    {x.signalDate}
                                </div>
                            </td>
                            <td>
                                <div style={styles.tableData}>
                                    <Button variant='contained'>
                                        <SettingsIcon />
                                    </Button>
                                </div>
                            </td>
                        </tr>)
                }
            </table>
        </section>
    )
}

export default Table