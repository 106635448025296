import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import constantValues from '../../misc/constantValues';

const OutlinedTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        fontFamily: constantValues.PRIMARY_FONT_FAMILY,
        '& fieldset': {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            borderColor: constantValues.ICON_COLOR_1
        },
        '&:hover fieldset': {
            borderColor: constantValues.ICON_COLOR_1,
        },
        '&.Mui-focused fieldset': {
            borderColor: constantValues.ICON_COLOR_1,
        },
    },
});

export default OutlinedTextField;