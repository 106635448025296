import Button from "@mui/material/Button";
import constantValues from "../../../misc/constantValues";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';
import CrownIcon from '../../../material-ui/Icons/CrownIcon';

function YourPlans({ subscription }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    const CONTAINER_GRADIENTS = [
        'linear-gradient(60deg, #ffa000 10%, #f9cc24 80%)',
        'linear-gradient(60deg, rgb(174, 174, 174) 10%, rgb(198, 198, 198) 80%)',
        'linear-gradient(60deg, rgb(240, 114, 89) 10%, rgb(264, 134, 120) 80%)'
    ];

    const calcContainerGradient = () => {
        if (subscription?.plan?.name === 'Gold') {
            return CONTAINER_GRADIENTS[0]
        }
        if (subscription?.plan?.name === 'Silver') {
            return CONTAINER_GRADIENTS[1]
        }
        if (subscription?.plan?.name === 'Bronze') {
            return CONTAINER_GRADIENTS[2]
        }

        return CONTAINER_GRADIENTS[0]
    }
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        noPlanContainer: {
            width: desktopSize || mobileSize ? '96%' : '80%',
            padding: desktopSize ? '2em' : '',
            paddingRight: desktopSize ? '3em' : '',
            paddingLeft: desktopSize ? '3em' : '',
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.mode === 'dark' ? '#484464' : '#f5f2f2',
            borderRadius: '13px',
            alignSelf: 'center'
        },
        noPlanText: {
            maxWidth: mobileSize ? '100%' : '60%',
            paddingTop: mobileSize ? '1em' : desktopSize ? '' : '2em',
            paddingBottom: mobileSize ? '1em' : desktopSize ? '' : '2em',
            marginLeft: !desktopSize && !mobileSize ? '1rem' : '0.6rem'
        },
        dangerText: {
            color: theme.palette.error.light
        },
        noPlanButton: {
            backgroundColor: '#f07258',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            textTransform: 'none',
            borderRadius: '8px',
            boxShadow: '0 5px 28px 0 rgba(240, 114, 89, 0.25)',
            fontSize: !desktopSize ? '10px' : '',
            marginRight: mobileSize ? '0.4rem' : desktopSize ? '' : '1rem',
            display: mobileSize ? 'none' : ''
        },
        header: {
            marginLeft: !desktopSize ? '0.4rem' : ''
        },
        container: {
            width: desktopSize || mobileSize ? '96%' : '80%',
            padding: desktopSize ? '2em' : '',
            paddingRight: desktopSize ? '3em' : '',
            paddingLeft: desktopSize ? '3em' : '',
            paddingTop: '1em',
            marginTop: '2rem',
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: calcContainerGradient(),
            color: 'white',
            borderRadius: '13px',
            alignItems: 'center',
            alignSelf: 'center'
        },
        planHeader: {
            width: '95%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        planSubscription: {
            marginTop: '1rem',
            paddingBottom: '1em',
            width: '100%'
        },
        upgradePlanButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            backgroundColor: 'white',
            color: '#fea302',
            borderRadius: '8px',
            boxShadow: '0 5px 28px 0 rgba(240, 114, 89, 0.25)'
        },
        cancelPlanButton: {
            color: 'white',
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            borderColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 5px 28px 0 rgba(240, 114, 89, 0.25)'
        }
    }
    return (
        <section style={styles.root}>
            <h2 style={styles.header}>{TEXTS['yourPlans']}</h2>
            {subscription?.plan?.name === 'Free' ?
                <div style={styles.noPlanContainer}>
                    <div style={styles.noPlanText}>
                        {TEXTS['noPlanAvailableText1']} <span style={styles.dangerText}>{TEXTS['noPlanAvailableText2']}</span> {TEXTS['noPlanAvailableText3']}
                    </div>
                    <Button variant='contained' style={styles.noPlanButton}>
                        {TEXTS['upgradeNow']}
                    </Button>
                </div> :
                <div style={styles.container}>
                    <div style={styles.planHeader}>
                        <div className="flex-row">
                            <CrownIcon />
                            <h1>{subscription?.plan?.name}</h1>
                        </div>
                        <div className="flex-row gap-1">
                            <div style={styles.planSubscription}>
                                {TEXTS['yourSubscriptionWillExpireOn']} <span className="bold">{new Date(subscription?.endDateTime).toLocaleDateString()}</span>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </section>
    )
}

export default YourPlans