import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function usePlan() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [loading, setLoading] = useState(false);
    const [didNotLoad, setDidNotLoad] = useState(false);
    const [plans, setPlans] = useState([]);
    const [plan, setPlan] = useState(null);
    const createRequest = useRequest();
    const dispatch = useDispatch();
    const getAllPlans = () => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoading(false);
            setPlans(response.data.data);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            url: constantValues.GET_PLANS('CRYPTO'),
            onSuccess,
            onFail
        })
    }

    const getPlanById = (id) => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoading(false);
            setPlan(response.data.data);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            url: constantValues.GET_PLAN_BY_ID(id),
            onSuccess,
            onFail
        })
    }

    return { loading, getAllPlans, plans, getPlanById, plan, didNotLoad }
}

export default usePlan