import { Grid } from "@mui/material"
import cooperation from '../../../media/cooperation.webp'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Image() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        padding: {
            width: '100%',
            paddingTop: '5em'
        },
        img: {
            width: !mobileSize ? '80%' : '100%',
            height: 'auto'
        }
    }
    return (
        <Grid item lg={5} sm={5} xs={11}>
            <div style={styles.container}>
                <div style={styles.padding} />
                <img src={cooperation} alt={'cooperate'} style={styles.img} />
            </div>
        </Grid>
    )
}

export default Image