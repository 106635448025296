import { useTheme } from '@mui/material/styles';

import MarketOverAll from '../../../misc/MarketOverAll';
import ResultDetails from './components/ResultDetails';
import ResultChart from './components/ResultChart';
import ResultMonthly from './components/ResultMonthly';
import ResultSummary from './components/ResultSummary';
import ResultOverAll from './components/ResultOverAll';

import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useBacktest from '../../../hooks/Backtest/useBacktest';
import MuiSkeleton from '../../../material-ui/Skeleton/MuiSkeleton';

function BacktestResult() {
    const { id } = useParams();
    const { getBacktestById, backtest, loading, didNotLoad } = useBacktest();
    /*BEGIN: Styles */
    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: '15px',
            paddingBottom: '2em'
        },
        content: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
    /*BEGIN: Styles */

    const getData = () => {
        getBacktestById(id);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <main style={styles.root}>
            {/*Market Overall */}
            <MarketOverAll />

            {
                (loading || didNotLoad) ?
                    <MuiSkeleton
                        variant='rectangular'
                        width='100%'
                        height={'600px'}
                        didNotLoad={didNotLoad}
                        reload={getData}
                    /> :
                    <div style={styles.content}>
                        {/*Search Details */}
                        <ResultDetails backtest={backtest} backtestName={id?.split(',')[1]} />

                        {/*Monthly Chart */}
                        <ResultChart backtest={backtest} />

                        {/*Monthly Statistic */}
                        <ResultMonthly backtest={backtest} />

                        {/*Summary */}
                        <ResultSummary backtest={backtest} />

                        {/*Backtest Overall */}
                        <ResultOverAll backtest={backtest} />
                    </div>
            }
        </main>
    )
}

export default BacktestResult