/* Action Types */
const UPDATE_TIMEFRAMES = "UPDATE_TIMEFRAMES";

/* Actions */
export function updateTimeframes(payload) {
    return {
        type: UPDATE_TIMEFRAMES,
        payload
    }
};

/* Reducer */
const initialState = ["5m", "15m", "30m", "1h", "4h", "6h", "12h", "1D", "1W"];
export function timeframesReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_TIMEFRAMES:
            return action.payload;
        default:
            return state;
    }
}