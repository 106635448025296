import jwt_decode from "jwt-decode";
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import useToken from '../hooks/User/useToken';

const isAuthenticated = (token) => {
    try {
        const { exp } = jwt_decode(token);
        if (Date.now() >= exp * 1000) {
            return false;
        }
    } catch (err) {
        return false;
    }
    return true;
}

function TokenController() {
    const User = useSelector(state => state.userReducer);
    const isAuth = useMemo(() => isAuthenticated(User?.accessToken), [User]);
    const { refreshToken } = useToken();

    useEffect(() => {
        if (User?.accessToken) {
            if (!isAuth) {
                refreshToken();
            }
        }
    }, []);

    return (<div style={{ display: 'none' }} />)
}

export default TokenController