import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from '../../../localization';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import constantValues from "../../../misc/constantValues";
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useSymbol from '../../../hooks/Exchange/useSymbol';
import useExchange from '../../../hooks/Exchange/useExchange';
import MuiSkeleton from '../../../material-ui/Skeleton/MuiSkeleton';
import CircularProgress from '../../../material-ui/CircularProgress/CircularProgress';
import { showLimitDialog } from '../../../redux/state/limitReducer';
import { useDispatch } from 'react-redux';


function AddCoinDialog({ open, onClose, favSymbols, getFavoriteSymbols }) {
    const dispatch = useDispatch();
    const limitReducer = useSelector(state => state.limitReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Application State */
    const {
        toggleFavSymbol,
        loadingToggleFavSymbol,
        didNotLoadToggleFavSymbol
    } = useSymbol();

    const {
        getAllCoins,
        coins,
        loading: loadingCoins,
        didNotLoad: didNotLoadCoins
    } = useExchange();

    const [searchValue, setSearchValue] = React.useState('');
    /*BEGIN: Application State */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1.5em',
            paddingBottom: '1.5em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '94%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        searchInputRoot: {
            width: '98%',
            marginTop: '1.5rem',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        deselectContainer: {
            width: '100%',
            display: 'flex'
        },
        deselectButton: {
            textTransform: 'none',
            marginTop: '1rem',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        listItem: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '1em',
            paddingBottom: '1em',
            cursor: 'pointer'
        },
        listContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            height: '300px',
            marginTop: '1rem'
        },
        coinShortName: {
            color: '#cccccc',
            marginLeft: '0.2rem',
            marginTop: '0.2rem',
            fontSize: '10px'
        },
        logo: {
            width: '24px',
            height: '24px',
            marginRight: '0.5rem'
        },
        checkIcon: {
            marginTop: '0.4rem',
            marginRight: '0.4rem'
        },
        coinName: {
            fontWeight: 'bold'
        },
        applyButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '8rem'
        },
        skeletonContainer: {
            marginTop: '1rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }
    }
    /*END: Styles */

    const onToggleSymbol = (currency, name) => {
        //If User is trying to remove:
        let isRemoving = favSymbols?.find(y => y.name === name);
        if (isRemoving) {
            toggleFavSymbol(currency, 'USDT');
            return;
        }
        //If there is limit
        let shouldShow = limitReducer?.favoriteCoinLeft - 1 >= 0;
        if (!shouldShow) {
            onClose();
            dispatch(showLimitDialog(constantValues.OVERUSE_ENUMS.OVERUSE_ADD_FAVORITE_SYMBOL));
            return;
        }
        //Add if no limits
        toggleFavSymbol(currency, 'USDT');
    }

    React.useEffect(() => {
        getAllCoins();
    }, []);

    React.useEffect(() => {
        if (!loadingToggleFavSymbol && !didNotLoadToggleFavSymbol) {
            getFavoriteSymbols();
        }
    }, [loadingToggleFavSymbol, didNotLoadToggleFavSymbol]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'xs'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>
                    {/*BEGIN: header */}
                    <div style={styles.header}>
                        <h3>{TEXTS["addCoin"]}</h3>
                        <IconButton onClick={onClose}>
                            <CancelIcon
                                style={
                                    themeReducer === "dark" ?
                                        { color: constantValues.ICON_COLOR_2 } : undefined}
                                color='disabled'
                            />
                        </IconButton>
                    </div>
                    {/*END: header */}

                    {/*BEGIN: Search */}
                    <OutlinedTextField
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={TEXTS["search"]}
                        style={styles.searchInputRoot}
                        size='small'
                        variant='outlined'
                    />
                    {/*END: Search */}

                    {/* <div style={styles.deselectContainer}>
                        <Button style={styles.deselectButton}>
                            {TEXTS['deselectAll']}
                        </Button>
                    </div> */}

                    {/*BEGIN: List */}
                    {
                        loadingCoins || didNotLoadCoins ?
                            <div style={styles.skeletonContainer}>
                                <MuiSkeleton
                                    variant='rectangular'
                                    width="100%"
                                    height="300px"
                                    didNotLoad={didNotLoadCoins}
                                    reload={getAllCoins}
                                />
                            </div> :
                            <div dir='ltr' style={styles.listContainer}>
                                {!loadingToggleFavSymbol && coins?.filter(
                                    x => x.name.toLowerCase().includes(searchValue.toLowerCase()) || x.currency.toLowerCase().includes(searchValue.toLowerCase())
                                ).map(d =>
                                    <div onClick={() => onToggleSymbol(d.currency, d.name)} style={styles.listItem}>
                                        <div className="flex-row">
                                            <img style={styles.logo} src={`/media/${d?.currency?.toLocaleLowerCase()}.svg`} alt={''} />
                                            <div style={styles.coinName}>{d?.name}</div>
                                            <div style={styles.coinShortName}>{d?.currency} USDT</div>
                                        </div>
                                        {favSymbols?.find(y => y.name === d.name) &&
                                            <CheckCircleIcon style={styles.checkIcon} color='primary' />
                                        }
                                    </div>
                                )}
                                {loadingToggleFavSymbol && <CircularProgress />}
                            </div>
                    }
                    {/*END: List */}

                    {/*BEGIN: Apply Button */}
                    {/* <Button style={styles.applyButton} variant='contained'>
                        {TEXTS["apply"]}
                    </Button> */}
                    {/*BEGIN: Apply Button */}
                </div>
            </div>
        </Dialog>
    )
}

export default AddCoinDialog