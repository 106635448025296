import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import constantValues from "../misc/constantValues";

function ThemeDirectionSetter() {
    const theme = useTheme();
    const language = useSelector(state => state.localizationReducer);

    //Set Direction
    if (constantValues.IS_RTL_LANGUAGE(language)) {
        document.getElementById("root").dir = 'rtl'
    } else {
        document.getElementById("root").dir = 'ltr'
    }

    //Set Background
    if (theme.palette.mode === 'dark') {
        document.getElementById("root").style.background = 'rgb(29, 26, 46)'
    } else {
        document.getElementById("root").style.background = 'white'
    }

    return null
}

export default ThemeDirectionSetter