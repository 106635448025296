import MuiSkeleton from '../../../../material-ui/Skeleton/MuiSkeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function Header({ loading, ticket, getTicketById }) {
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: desktopSize ? '' : 'space-between'
        },
        content: {
            marginTop: desktopSize ? '' : mobileSize ? '1rem' : '',
            display: 'flex',
            alignItems: 'center'
        },
        priority: (type) => {
            const commonStyles = {
                color: '#f07259',
                padding: '0.5em',
                paddingRight: '0.8em',
                paddingLeft: '0.8em',
                marginLeft: desktopSize ? '3rem' : '',
                borderRadius: '8px'
            }
            switch (type) {
                case "Urgent":
                    return {
                        ...commonStyles,
                        backgroundColor: '#fae3de',
                    };
                case "Medium":
                    return {
                        ...commonStyles,
                        backgroundColor: '#fae3de',
                    };
                case "Low":
                    return {
                        ...commonStyles,
                        backgroundColor: '#fae3de',
                    };
                default:
                    return {
                        ...commonStyles,
                        backgroundColor: '#fae3de',
                    }
            }
        },
        detail: {
            marginLeft: '1rem',
            color: '#898989'
        }
    }

    if (loading || !ticket) {
        return (
            <MuiSkeleton
                variant='rectangular'
                width={'100%'}
                height={'100px'}
                didNotLoad={!loading && !ticket}
                reload={getTicketById}
            />
        )
    }
    return (
        <section style={styles.root}>
            <h3>{ticket.title}</h3>
            <div style={styles.content}>
                <div style={styles.priority(ticket.priority)}>
                    {ticket.priority}
                </div>
                <div style={styles.detail}>
                    {ticket.category}
                </div>
                <div style={styles.detail}>
                    {ticket.status}
                </div>
            </div>
        </section>
    )
}

export default Header