import { Grid } from "@mui/material";
import about from "../../../media/about.webp";

function Image() {
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        img: {
            width: '80%',
            height: 'auto'
        },
        padding: {
            width: '100%',
            paddingTop: '3em'
        }
    }
    return (
        <Grid item xs={11} lg={5} md={11}>
            <div style={styles.container}>
                <div style={styles.padding} />
                <img src={about} alt={'abt'} style={styles.img} />
            </div>
        </Grid>
    )
}

export default Image