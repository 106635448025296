
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Grid
} from '@mui/material';
import { useEffect } from 'react';

import Header from './components/Header';
import Form from './components/Form';
import Image from './components/Image';
import Footer from './components/Footer';

function Contact() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.palette.text.primary,
            fontSize: mobileSize ? '13px' : '14px',
            overflowX: 'hidden',
            paddingBottom: '3em'
        },
        padding: {
            width: '100%',
            paddingTop: '2em'
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    },[])
    return (
        <main style={styles.root}>
            <div style={styles.padding} />
            <Grid container justifyContent={'center'}>
                <Grid item xs={11} lg={6} md={6}>
                    <Header />
                    <Form />
                    <Footer />
                </Grid>
                {!mobileSize &&
                    <Image />
                }
            </Grid>
        </main>
    )
}

export default Contact