import { Grid } from "@mui/material";

function Texts() {
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        text: {
            marginTop: '1rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        margin: {
            width: '100%',
            marginTop: '1rem'
        }
    }
    return (
        <Grid item xs={11} lg={5} md={11}>
            <div style={styles.container}>
                <h1>About ACT</h1>
                <div style={styles.text}>
                    <div style={styles.margin}>
                        ACT smart assistant service is the result of the knowledge and experience of a Software Developing experts and financial market analysts. The purpose of ACT is to increase the efficiency and effectiveness of trading of Cryptocurrency and forex markets.
                    </div>

                    <div style={styles.margin}>
                        ACT tools help a trader to monitor the entire market and make the best decisions using his trading strategies.Back test tool, along with other tools, evaluates the effectiveness of the determined strategy using previous data, and the trader can use it to measure the accuracy of his strategy.
                    </div>

                    <div style={styles.margin}>
                        In this service, the most important and efficient market analysis techniques are available to the trader and he can define his own trading strategies by determining his own decision parameters.
                    </div>

                    <div style={styles.margin}>
                        ACT monitors the market within the defined range based on the defined strategies and announces the items matching the strategy to the trader.
                    </div>

                    <div style={styles.margin}>
                        This mechanism allows the trader to spend his time on targeted and effective trading.
                    </div>

                    <div style={styles.margin}>
                        This service can be used by all traders with different skill levels. Professional traders can make more accurate and successful decisions by designing successful strategies, and novice traders can entrust many monitoring and analysis activities to the tool. ACT is the toolbox every trader needs.
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default Texts