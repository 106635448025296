import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import Localization from '../../../localization';
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import Button from '@mui/material/Button';
import constantValues from '../../../misc/constantValues';

function ProfileInvitation() {
    const User = useSelector(state => state.userReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '4em'
        },
        content: {
            width: mobileSize ? '93%' : '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'wrap',
            wordWrap: 'break-word'
        },
        numberOfInvitedRoot: {
            marginTop: '4rem',
            color: theme.palette.mode === "dark" ? '#b2b2b2' : '#848484',
            fontSize: '16px'
        },
        numberOfInvited: {
            fontWeight: 'bold',
            marginLeft: '1rem',
            marginRight: '1rem',
            color: theme.palette.mode === "dark" ? 'white' : 'black'
        },
        descriptionText: {
            marginTop: '2rem',
            wordWrap: 'break-word',
            textAlign: mobileSize ? 'center' : ''
        },
        descriptionTextSpan: {
            fontWeight: 'bold',
            marginLeft: '0.3rem',
            marginRight: '0.3rem'
        },
        inputContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: mobileSize ? 'flex-end' : 'space-between',
            width: '100%',
            marginTop: mobileSize ? '2rem' : '1rem',
            gap: mobileSize ? 5 : 20,
            flexWrap: 'wrap'
        },
        inputTitle: {
            color: theme.palette.mode === "dark" ? '#bcbcbc' : '#7c7c7c',
            width: mobileSize ? '100%' : '100px',
            whiteSpace: 'nowrap',
        },
        inputsMargin: {
            width: '100%',
            marginTop: mobileSize ? '' : '3rem'
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            width: mobileSize ? '16.5rem' : '',
        },
        inputRoot: {
            width: mobileSize ? '100%' : desktopSize ? '25rem' : '19rem',
        },
        inputParent: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: mobileSize ? 'column' : 'row'
        }
    }
    /*END: Styles */
    return (
        <section style={styles.root}>
            <div style={styles.content}>
                <div style={styles.numberOfInvitedRoot}>
                    {TEXTS["invited"]} <span style={styles.numberOfInvited}> {User.referCount} </span> {TEXTS["people"]}
                </div>
                <div style={styles.descriptionText}>
                    {TEXTS["invitationTextPart1"]}
                    <span style={styles.descriptionTextSpan}> {TEXTS["invitationTextPart2"]} </span>
                    {TEXTS["invitationTextPart3"]}
                    <span style={styles.descriptionTextSpan}> {TEXTS["invitationTextPart4"]} </span>
                    {TEXTS["invitationTextPart5"]}
                </div>
                <div style={styles.inputsMargin} />

                <div style={styles.inputContainer}>
                    <div style={styles.inputTitle}>{TEXTS["inviteLink"]}:</div>
                    <div style={styles.inputParent}>
                        <OutlinedTextField
                            style={styles.inputRoot}
                            size="small"
                            value={`https://allcan.trade/auth/register?inviationCode=${User.referral}`}
                        />
                    </div>
                    <Button
                        style={styles.button}
                        variant='contained'
                        onClick={() => navigator.clipboard.writeText(`https://allcan.trade/auth/register?inviationCode=${User.referral}`)}
                    >
                        {TEXTS["copyInviteLink"]}
                    </Button>
                </div>

                <div style={styles.inputContainer}>
                    <div style={styles.inputTitle}>{TEXTS["inviteCode"]}:</div>
                    <div style={styles.inputParent}>
                        <OutlinedTextField
                            style={styles.inputRoot}
                            size="small"
                            value={User.referral}
                        />
                    </div>
                    <Button
                        style={styles.button}
                        variant='contained'
                        onClick={() => navigator.clipboard.writeText(User.referral)}
                    >
                        {TEXTS["copyInviteCode"]}
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default ProfileInvitation