import { Grid } from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import allcan_trade from "../../../media/allcan-trade.svg";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import constantValues from "../../../misc/constantValues";
import { Link } from "react-router-dom";

function Footer() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            backgroundColor: '#300c5c',
            paddingTop: '4em',
            paddingBottom: '3em',
            color: 'white'
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            marginBottom: !desktopSize ? '1rem' : '',
            height: '100%   '
        },
        imgTitle: {
            width: '50%'
        },
        linkContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: desktopSize ? '' : 'center'
        },
        link: {
            marginTop: '1rem',
            color: 'white',
            textDecoration: 'none'
        },
        socialContainer: {
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            gap: 20
        }
    }
    return (
        <section id={'about'} style={styles.root}>
            <Grid container>
                <Grid item xs={12} lg={6} md={6}>
                    <div style={styles.titleContainer}>
                        <img style={styles.imgTitle} src={allcan_trade} alt={'allcan.trade'} />
                    </div>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                    <Grid container>
                        <Grid item lg={6} md={6} xs={6}>
                            <div style={styles.linkContainer}>
                                <Link style={styles.link} to={constantValues.ABOUT_ROUTE}>
                                    About ACT
                                </Link>
                                <Link style={styles.link} to={constantValues.CONTACT_ROUTE}>
                                    Contact ACT
                                </Link>
                                <a style={styles.link} href="#pricing">
                                    Pricing
                                </a>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} xs={6}>
                            <div style={styles.linkContainer}>
                                <a style={styles.link} href={constantValues.TERMS_OF_SERVICE_URL}>
                                    Terms of Service
                                </a>
                                <div style={styles.socialContainer}>
                                    <a target="_blank" rel="noreferrer" href={constantValues.TELEGRAM_LINK}>
                                        <TelegramIcon style={{ color: 'white' }} />
                                    </a>
                                    <a target="_blank" rel="noreferrer" href={constantValues.LINKEDIN_LINK}>
                                        <LinkedInIcon style={{ color: 'white' }} />
                                    </a>
                                    <a target="_blank" rel="noreferrer" href={constantValues.YOUTUBE_LINK}>
                                        <YouTubeIcon style={{ color: 'white' }} />
                                    </a>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}

export default Footer