import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Header from "./components/Header";
import Table from "./components/Table";
import { useEffect, useState } from 'react';
import useTicket from '../../../hooks/Ticket/useTicket';
import useMediaQuery from '@mui/material/useMediaQuery';

function TicketsOverview() {
    const [showDialog, setShowDialog] = useState(false);
    const {
        getTickets,
        loading,
        tickets,
        result,
        createTicket,
        resetResult,
        didNotLoad
    } = useTicket();
    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.mode === "dark" ? '#14121d' : '#f7f7f7',
            color: theme.palette.text.primary,
            fontSize: '15px',
            minHeight: desktopSize ? '83vh' : '89.5vh'
        },
        paper: {
            width: desktopSize ? '80%' : '100%',
            marginTop: '2rem',
            paddingTop: '2em',
            paddingBottom: '2em',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem',
            minHeight: '65vh'
        },
        content: {
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
        }
    }
    /*END: Styles */

    useEffect(() => {
        getTickets();
    }, []);

    useEffect(() => {
        if (result?.success) {
            getTickets();
            setShowDialog(false);
        }
    }, [result])

    return (
        <main style={styles.root}>
            <Paper style={styles.paper}>
                <div style={styles.content}>
                    {/*Header */}
                    <Header
                        showDialog={showDialog}
                        setShowDialog={setShowDialog}
                        loading={loading}
                        createTicket={createTicket}
                        result={result}
                        resetResult={resetResult}
                    />
                    {/*Table */}
                    <Table
                        tickets={tickets}
                        loading={loading}
                        didNotLoad={didNotLoad}
                        getTickets={getTickets}
                    />
                </div>
            </Paper>
        </main>
    )
}

export default TicketsOverview