import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import constantValues from '../../misc/constantValues';

const NakedTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            borderColor: (theme) => theme.palette.mode === "dark" ? 'green' : 'transparent',
            color: (theme) => theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : 'black'
        },
        '&:hover fieldset': {
            borderColor: (theme) => theme.palette.mode === "dark" ? 'green' : 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: (theme) => theme.palette.mode === "dark" ? 'green' : 'transparent',
        },
    },
});

export default NakedTextField;