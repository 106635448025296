import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function useContact() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const [loading, setLoading] = useState(false);

    const validateForm = (firstName, lastName, email, subject, message) => {
        if (firstName?.length < 2) {
            dispatch(showFailNotification('Please enter your first name correctly'));
            return false;
        }
        if (lastName?.length < 2) {
            dispatch(showFailNotification('Please enter your last name correctly'));
            return false;
        }
        if (!email?.match('^(?=.*[0-9])')) {
            dispatch(showFailNotification('Please enter a proper email'));
            return false;
        }
        if (subject?.length < 4) {
            dispatch(showFailNotification('Please enter your subject in detail'));
            return false;
        }
        if (message?.length < 5) {
            dispatch(showFailNotification('Your message is too short!'));
            return false;
        }

        return true;
    }

    const contactCompany = (firstName, lastName, email, subject, message, resetForm) => {
        const isValidForm = validateForm(firstName, lastName, email, subject, message);

        if (!isValidForm) {
            return;
        }

        setLoading(true);

        const onSuccess = (response) => {
            resetForm();
            setLoading(false);
            dispatch(showSuccessNotification('Your message was submited! We will get back at you as soon as possible'));
        }

        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'POST',
            url: constantValues.CONTACT_US,
            onFail,
            onSuccess,
            data: {
                firstName,
                lastName,
                email,
                subject,
                message
            }
        })
    }
    return {
        loading,
        contactCompany
    }
}

export default useContact