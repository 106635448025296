import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import constantValues from '../../misc/constantValues';

const FilledTextField = styled(TextField)({
    '& .MuiSelect-icon': {
        color: constantValues.ICON_COLOR_3
    },
    '& .MuiFilledInput-root': {
        backgroundColor: constantValues.ICON_COLOR_4,
        borderRadius: '5px'
    },
    '& .MuiFilledInput-root:after': {
        border: 0
    },
    '& .MuiFilledInput-root:before': {
        border: 0
    },
    '&:hover .MuiFilledInput-root:before': {
        border: 0
    },
    '& .MuiSelect-select': {
        paddingTop: 10,
        paddingBottom: 10
    }
});

export default FilledTextField;