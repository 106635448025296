import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/state/userReducer';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function useToken() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const createRequest = useRequest();
    const dispatch = useDispatch();
    const User = useSelector(state => state.userReducer);

    const revokeToken = (silent) => {
        setLoading(true);

        const onSuccess = (response) => {
            setLoading(false);
            if (!silent) {
                dispatch(showSuccessNotification('Logout Successful'));
            }
            dispatch(() => dispatch(loginUser(null)), 500);
            navigate(constantValues.LOGIN_ROUTE);
        }
        const onFail = (error) => {
            setLoading(false);
            if (error?.error === 'nf error') {
                if (!silent) {
                    dispatch(showSuccessNotification('Already logged out'));
                }
                dispatch(() => dispatch(loginUser(null)), 500);
            } else {
                dispatch(loginUser(null));
                console.log('Could not Sign out properly')
                // setTimeout(() => dispatch(showFailNotification('Could not Sign out properly')), 500)
            }
            navigate(constantValues.LOGIN_ROUTE);
        }
        createRequest({
            method: 'GET',
            url: constantValues.REVOKE_TOKEN(User.refreshToken),
            onSuccess,
            onFail
        })
    }

    const refreshToken = useCallback(() => {
        const onSuccess = (response) => {
            let newUser = { ...User, ...response.data.data };
            dispatch(loginUser(newUser))
        }

        const onFail = (error) => {
            //Show Error and logout
            dispatch(showFailNotification('Failed to refresh'));
            setTimeout(() => dispatch(loginUser(null)), 500)
        }

        createRequest({
            method: 'GET',
            params: { refreshToken: User?.refreshToken },
            url: constantValues.REFRESH_TOKEN,
            onSuccess,
            onFail
        })
    }, [User])

    return {
        refreshToken,
        loading,
        revokeToken
    }
}

export default useToken