import { Grid } from "@mui/material";
import advanced_technical_algorithm from "../../../media/advanced-technical-algorithm.svg";
import smart_strategy_backtest from "../../../media/smart-strategy-backtest.svg";
import smart_stock_hunting from "../../../media/smart-stock-hunting.svg";
import smart_signal_drawing from "../../../media/smart-signal-drawing.svg";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function GraphicSummary() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const items = [
        { text: 'Advanced Technical Algorithm', img: advanced_technical_algorithm },
        { text: 'Smart Strategy Backtest', img: smart_strategy_backtest },
        { text: 'Smart Stock Hunting', img: smart_stock_hunting },
        { text: 'Smart Signal Drawing', img: smart_signal_drawing }
    ]
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            marginBottom: mobileSize ? '3rem' : ''
        },
        img: {
            width: desktopSize ? '176px' : '140px',
            height: '154px',
        },
        text: {
            width: '130px',
            marginTop: '1rem',
        }
    }
    return (
        <Grid container>
            {
                items.map(i =>
                    <Grid key={i.text} item xs={12} lg={3} sm={3}>
                        <div style={styles.container}>
                            <img style={styles.img} src={i.img} alt={i.text} />
                            <div style={styles.text}>
                                {
                                    i.text.split(' ').map(t =>
                                        <div key={t}>
                                            {t}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </Grid>
                )
            }
        </Grid>
    )
}

export default GraphicSummary