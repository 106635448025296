/* Action Types */
const LOGIN_USER = "LOGIN_USER";
const LOGOUT_USER = "LOGOUT_USER";

/* Actions */
export function loginUser(data) {
    return {
        type: LOGIN_USER,
        payload: data
    }
};

export function logoutUser() {
    return {
        type: LOGOUT_USER
    }
}

/* Reducer */
const initialState = {}
export function userReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            return action.payload;
        case LOGOUT_USER:
            return null;
        default:
            return state;
    }
}