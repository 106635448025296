import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from '../localization';
import { useSelector, useDispatch } from 'react-redux';
import { hideLimitDialog } from '../redux/state/limitReducer';
import constantValues from './constantValues';
import { useNavigate } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BarChartIcon from '@mui/icons-material/BarChart';

function LimitIcon({ message, style }) {
    switch (message) {
        case constantValues.OVERUSE_ENUMS.OVERUSE_STRATEGY_SEARCH:
            return <SentimentVeryDissatisfiedIcon style={style} />
        case constantValues.OVERUSE_ENUMS.OVERUSE_STRATEGY_EDIT:
            return <SentimentVeryDissatisfiedIcon style={style} />
        case constantValues.OVERUSE_ENUMS.OVERUSE_CREATE_BACKTEST:
            return <SentimentVeryDissatisfiedIcon style={style} />
        case constantValues.OVERUSE_ENUMS.OVERUSE_CREATE_STRATEGY:
            return <SentimentVeryDissatisfiedIcon style={style} />
        case constantValues.OVERUSE_ENUMS.OVERUSE_ADD_FAVORITE_SYMBOL:
            return <SentimentVeryDissatisfiedIcon style={style} />
        case constantValues.ACCOUNT_LIMIT_ENUMS.INTERACT_BACKTEST:
            return <SentimentVeryDissatisfiedIcon style={style} />
        default:
            return <SentimentVeryDissatisfiedIcon style={style} />
    }
}

function LimitMessage({ message, style }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    switch (message) {
        case constantValues.ACCOUNT_LIMIT_ENUMS.INTERACT_BACKTEST:
            return (
                <div style={style}>
                    Sorry! This feature is locked for your plan. Please upgrade your plan in order to continue
                </div>
            )
        case constantValues.OVERUSE_ENUMS.OVERUSE_STRATEGY_SEARCH:
            return (
                <div style={style} >
                    You have searched too many strategies please Upgrade your plan in order to continue or wait 24 hours for your limit to reset.
                </div>
            )
        case constantValues.OVERUSE_ENUMS.OVERUSE_STRATEGY_EDIT:
            return (
                <div style={style} >
                    You have edited your strategies too many times. please Upgrade your plan in order to continue or wait 24 hours for your limit to reset.
                </div>
            )
        case constantValues.OVERUSE_ENUMS.OVERUSE_CREATE_BACKTEST:
            return (
                <div style={style} >
                    You have created too many backtests. please Upgrade your plan in order to continue or wait 24 hours for your limit to reset.
                </div>
            )
        case constantValues.OVERUSE_ENUMS.OVERUSE_CREATE_STRATEGY:
            return (
                <div style={style} >
                    You have reached your accounts limits. please Upgrade your plan in order to create more strategies
                </div>
            )
        case constantValues.OVERUSE_ENUMS.OVERUSE_ADD_FAVORITE_SYMBOL:
            return (
                <div style={style} >
                    You have reached your accounts limits. please Upgrade your plan in order to add more favorite symbols
                </div>
            )
        default:
            return (
                <div style={style} >
                    You have reached your accounts limits. please Upgrade your plan in order to continue.
                </div>
            )
    }
}

function LimitStatistics({ message, favoriteCoinUsed, favoriteCoin, strategyUsed, strategy }) {
    let shouldShow = (
        message === constantValues.OVERUSE_ENUMS.OVERUSE_CREATE_STRATEGY ||
        message === constantValues.OVERUSE_ENUMS.OVERUSE_ADD_FAVORITE_SYMBOL
    );

    if (!shouldShow) {
        return (null);
    }

    const styles = {
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginTop: '2rem'
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '49%',
            alignItems: 'center',
        },
        icon: {
            width: '5rem',
            height: '5rem',
            color: '#f07259'
        },
        text: {
            color: 'gray',
            marginTop: '0.4rem'
        },
        border: {
            width: 0,
            height: '100%',
            borderRight: '1px solid gray'
        },
        color: (curr, max) => ({
            color: curr <= max ? '#78c9c0' : '#f07259'
        })
    }

    return (
        <div style={styles.root}>
            <div style={styles.container}>
                <FavoriteIcon
                    style={{ ...styles.icon, ...styles.color(favoriteCoinUsed, favoriteCoin) }}
                />
                <div style={styles.color(favoriteCoinUsed, favoriteCoin)}>
                    Favorite Coins
                </div>
                <div style={{ ...styles.text, ...styles.color(favoriteCoinUsed, favoriteCoin) }}>
                    {favoriteCoinUsed}/{favoriteCoin}
                </div>
            </div>
            <div style={styles.border} />
            <div style={styles.container}>
                <BarChartIcon
                    style={{ ...styles.icon, ...styles.color(strategyUsed, strategy) }}
                />
                <div style={styles.color(strategyUsed, strategy)}>Strategy</div>
                <div style={{ ...styles.text, ...styles.color(strategyUsed, strategy) }}>
                    {strategyUsed}/{strategy}
                </div>
            </div>
        </div>
    )
}

function AppLimitsDialog() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const limitReducer = useSelector(state => state.limitReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1.5em',
            paddingBottom: '1.5em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            marginTop: '3rem'
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '7rem'
        },
        limitIcon: {
            width: '8rem',
            height: '8rem'
        },
        limitMessage: {
            marginTop: '1rem',
            fontWeight: 'bold',
            color: 'gray',
            textAlign: 'center'
        }
    }
    /*END: Styles */
    const closeDialog = () => {
        dispatch(hideLimitDialog());
    }

    const goToPlans = () => {
        navigate(constantValues.PLAN_ROUTE);
        closeDialog();
    }

    return (
        <Dialog
            onClose={closeDialog}
            open={limitReducer?.showDialog}
            maxWidth={'sm'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>
                    <LimitIcon style={styles.limitIcon} message={limitReducer?.message} />
                    <LimitMessage
                        style={styles.limitMessage}
                        message={limitReducer?.message}
                    />
                    <LimitStatistics
                        message={limitReducer?.message}
                        favoriteCoin={limitReducer?.favoriteCoin}
                        favoriteCoinUsed={limitReducer?.favoriteCoinUsed}
                        strategy={limitReducer?.strategy}
                        strategyUsed={limitReducer?.strategyUsed}
                    />
                    <div style={styles.actions}>
                        <Button onClick={closeDialog} style={styles.button} variant={'outlined'}>
                            Close
                        </Button>
                        <Button onClick={goToPlans} style={styles.button} variant={'contained'}>
                            Upgrade plan
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default AppLimitsDialog