import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from '../../../localization';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import constantValues from '../../../misc/constantValues';
import Button from '@mui/material/Button';
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import NakedTextField from '../../../material-ui/TextField/NakedTextField';
import MenuItem from '@mui/material/MenuItem';
import AntSwitch from '../../../material-ui/Switch/AntSwitch';
import { updateSignals } from '../../../redux/state/signalsReducer';
import CircularProgress from '../../../material-ui/CircularProgress/CircularProgress';
import { useEffect } from 'react';
import SignalSelector from '../../../misc/SignalSelector';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

function MakeStrategyDialog({
    open,
    onClose,
    isEditing,
    strategy,
    getStrategies,
    createStrategy,
    editStrategy,
    loading,
    result
}) {
    const limitReducer = useSelector(state => state.limitReducer);
    const dispatch = useDispatch();

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Application State */
    const signalsReducer = useSelector(state => state.signalsReducer);
    const [signals, setSignals] = React.useState([]);
    const [shownSignals, setShownSignals] = React.useState(signalsReducer?.data);
    const [searchValue, setSearchValue] = React.useState("");
    const [timeframe, setTimeframe] = React.useState(TEXTS["timeframe"]);
    const [selectedTimeframes, setSelectedTimeframes] = React.useState([]);
    const [notifications, setNotifications] = React.useState(TEXTS["notifications"]);
    const [strategyName, setStrategyName] = React.useState("");
    const [matchAll, setMatchAll] = React.useState(false);
    const timeframes = useSelector(state => state.timeframesReducer);
    const [anchorEl, setAnchorEl] = React.useState(null);
    /*END: Application State */

    /*BEGIN: UI Functions */
    const onAddTimeframe = (e, v) => {
        if (selectedTimeframes.includes(v)) {
            setSelectedTimeframes(prev => prev.filter(t => t !== v));
        } else {
            setSelectedTimeframes(prev => [...prev, v]);
        }
    }

    const onRemoveTimeframe = (v) => {
        setSelectedTimeframes(prev => prev.filter(x => x !== v));
    }

    const calcShownSignals = (sValue) => {
        setSearchValue(sValue);
        let searchString = sValue.toLowerCase();
        let result = signalsReducer
            .data
            .filter(x => {
                let section = TEXTS[`signal-${x.section}`]?.toLowerCase();
                let isSectionTitle = section?.includes(searchString);
                let isInSection = false;
                x.signals.every(s => {
                    let sig = TEXTS[`signal-${s}`]?.toLowerCase()
                    if (sig?.includes(searchString)) {
                        isInSection = true;
                        return false;
                    }
                    return true;
                })
                return isSectionTitle || isInSection;
            });

        setShownSignals(result);
    }

    const selectAllSignals = () => {
        let allSignals = [];
        signalsReducer.data.map(x => {
            x.signals.map(y => {
                allSignals.push(y);
            })
        });
        setSignals(allSignals);
    }


    const toggleColapse = (e, index) => {
        e.preventDefault();
        let result = [];
        shownSignals.map((x, i) => {
            if (i === index) {
                result.push({ ...x, showList: !x.showList });
            } else {
                result.push({ ...x, showList: false });
            }
        });
        setShownSignals(result);
    }

    const toggleSignal = (id) => {
        if (signals.includes(id)) {
            let result = signals.filter(x => x !== id);
            setSignals(result);
        } else {
            let result = [...signals, id];
            setSignals(result)
        }
    }

    const toggleSection = (section) => {
        let sectionSignal = signalsReducer.data.find(x => x.section === section).signals;
        let shouldToggleOff = sectionSignal.every(x => signals.includes(x));
        if (shouldToggleOff) {
            let result = signals.filter(x => !sectionSignal.includes(x));
            setSignals(result);
        } else {
            let filterPrevious = signals
                .filter(x => !sectionSignal.includes(x));
            let result = [...filterPrevious, ...sectionSignal];
            setSignals(result);
        }
    }

    const isSectionChecked = (section) => {
        let sectionSignal = signalsReducer.data.find(x => x.section === section).signals;
        let isChecked = sectionSignal.every(x => signals.includes(x));
        return isChecked
    }

    /*END: UI Functions */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1.5em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        innerContainer: {
            width: '94%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        header: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.mode === "dark" ? 'rgb(50,48,72)' : '#eeecf0',
            paddingTop: '1em',
            paddingBottom: '1em'
        },
        headerInner: {
            width: '94%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.mode === "dark" ? 'rgb(50,48,72)' : '#eeecf0',
        },
        headerTop: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        searchInputRoot: {
            width: '100%',
            marginTop: '1rem'
        },
        searchInput: {
            paddingRight: constantValues.IS_RTL_LANGUAGE(language) ? 1 : 0,
            paddingLeft: 0,
            borderRadius: '8px',
            color: theme.palette.text.primary,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        startAdornmentRTL: {
            paddingRight: 0,
            paddingLeft: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '',
            borderBottomRightRadius: '',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        endAdornmentLTR: {
            paddingRight: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        adornmentIconColor: {
            color: 'white'
        },
        searchInputContainer: {
            width: '100%',
        },
        selectorsContainer: {
            display: 'flex',
            width: '93%',
            alignItems: 'center',
            marginTop: '1rem',
            gap: 20
        },
        textButton: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            color: theme.palette.primary.main,
            textTransform: 'none',
            fontWeight: 500,
            padding: 0
        },
        signalsContainer: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            width: '100%',
            height: '250px',
        },
        divider: {
            width: '100%',
            borderBottom: '1px solid #e1e1e1',
            marginTop: '0.4rem',
            marginBottom: '0.4rem'
        },
        signal: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        signalHeader: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            cursor: 'pointer'
        },
        dialogActionsContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem'
        },
        saveChangesButton: {
            textTransform: 'none',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        cancelButton: {
            textTransform: 'none',
            backgroundColor: themeReducer === "light" ? '#ebebeb' :
                constantValues.ICON_COLOR_2,
            color: theme.palette.text.primary,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        signalList: {
            display: 'flex',
            flexDirection: 'column',
            width: '94%',
            fontSize: '14px'
        },
        signalListContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        helpIcon: {
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
            marginLeft: '0.4rem',
            width: '0.6em',
            height: '0.6em'
        },
        nakedTextFieldRoot: {
            backgroundColor: theme.palette.mode === "dark" ? 'rgb(72,68,91)' : 'white',
            color: theme.palette.mode === "dark" ? '#bebebe' : 'black',
            marginTop: '0.4rem',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        nakedTextField: {
            width: '100%'
        },
        strategyNameRoot: {
            width: '95%'
        },
        dialogOptions: {
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: '1rem',
            flexWrap: 'wrap'
        },
        outlinedTextFieldRoot: {
            minWidth: mobileSize ? '130px' : '160px',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            backgroundColor: theme.palette.mode === "dark" ? 'transparent' : 'white',
            borderRadius: '9px',
        },
        selectTextFieldRoot: {
            paddingRight: 0,
            borderRadius: '8px',
            paddingTop: '0.3em',
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
        },
        inputLabelRoot: {
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            fontWeight: 'normal',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        selectInputIcon: {
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
        },
        menuItem: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        matchContainer: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            marginLeft: desktopSize ? '1rem' : '1.5rem',
            marginTop: desktopSize ? '' : '1rem'
        },
        switchText: {
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1.5rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '1.5rem' : ''
        },
        timeframesContainer: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            minHeight: '50px',
            marginTop: '1rem',
            gap: 10
        },
        timeframe: {
            padding: '0.5em',
            border: '2px solid ' + theme.palette.primary.main,
            borderRadius: '28px',
            minWidth: '70px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        closeIcon: {
            color: 'gray',
            cursor: 'pointer'
        },
        timeframeInput: {
            display: 'flex',
            alignItems: 'center',
            gap: 30,
            border: '1px solid gray',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            borderRadius: '8px',
            paddingLeft: '0.5em',
            backgroundColor: 'inherit',
            color: theme.palette.mode === 'light' ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
            cursor: 'pointer'
        }
    }
    /*END: Styles */

    const makeStrategy = () => {
        createStrategy(
            strategyName,
            signals,
            selectedTimeframes,
            notifications === "Telegram" ? true : false,
            false,
            10,
            matchAll ? 'All' : 'Any',
            'Strategy'
        )
    }

    const updateStrategy = () => {
        editStrategy(
            strategy.id,
            strategyName,
            signals,
            selectedTimeframes,
            notifications === "Telegram" ? true : false,
            false,
            10,
            matchAll ? 'All' : 'Any',
            'Strategy'
        )
    }

    const resetAndClose = () => {
        onClose();
        setSelectedTimeframes([]);
        setSignals([]);
        setSearchValue("");
        setShownSignals(signalsReducer.data);
        setNotifications(TEXTS["notifications"]);
        setStrategyName("");
        setMatchAll(false);
    }

    React.useEffect(() => {
        if (result?.success) {
            if (result?.shouldFetchStrategies && !loading) {
                resetAndClose();
            }
        }
    }, [result]);


    useEffect(() => {
        if (isEditing) {
            setSelectedTimeframes(strategy.timeFrames);
            setSignals(strategy.items);
            setSearchValue("");
            setShownSignals(signalsReducer.data);
            setNotifications(strategy.sendSocialNotification ? 'Telegram' : 'Disabled');
            setStrategyName(strategy.name);
            setMatchAll(strategy.execute === 'All');
        }
    }, [isEditing, signalsReducer, strategy]);

    const onCloseDialog = () => {
        resetAndClose();
        let result = [];
        signalsReducer.data.map((s) => {
            result.push({ ...s, showList: false });
        });
        dispatch(updateSignals({ ...signalsReducer, data: result }));
        setShownSignals(result);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onCloseDialog()}
            maxWidth={'sm'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>
                    {/*BEGIN: header */}
                    <div style={styles.header}>
                        <div style={styles.headerInner}>
                            <div style={styles.headerTop}>
                                <h3>{TEXTS["makeStrategy"]}</h3>
                                <IconButton onClick={onClose}>
                                    <CancelIcon
                                        style={
                                            themeReducer === "dark" ?
                                                { color: constantValues.ICON_COLOR_2 } : undefined}
                                        color='disabled'
                                    />
                                </IconButton>
                            </div>
                            <div style={styles.strategyNameRoot}>
                                <NakedTextField
                                    value={strategyName}
                                    onChange={(e) => setStrategyName(e.target.value)}
                                    style={styles.nakedTextField}
                                    placeholder={TEXTS["strategyName"] + "..."}
                                    size='small'
                                    variant='outlined'
                                    sx={{
                                        "& .MuiOutlinedInput-root": styles.nakedTextFieldRoot,
                                        "& .MuiInputLabel-root": styles.inputLabelRoot,
                                        "& .MuiSelect-icon": styles.selectInputIcon
                                    }}
                                />
                            </div>
                            <div style={styles.dialogOptions}>
                                {/*BEGIN: Timeframe */}
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {
                                        timeframes?.filter(t =>
                                            !constantValues.shouldExcludeTimeframeInStrategy.includes(t)
                                        ).map(t =>
                                            !limitReducer?.timeFrames?.includes(t) ?
                                                <Tooltip
                                                    title={
                                                        'You must upgrade your plan in order to use this timeframe. Click to view plans'
                                                    }
                                                    arrow
                                                >
                                                    <Link
                                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                                        to={constantValues.PLAN_ROUTE}
                                                    >
                                                        <span>
                                                            <MenuItem
                                                                disabled
                                                                value={t}
                                                                key={t}
                                                            >
                                                                <div className="flex-row">
                                                                    <LockOutlinedIcon fontSize='small' />
                                                                    {t}
                                                                </div>
                                                            </MenuItem>
                                                        </span>
                                                    </Link>
                                                </Tooltip> :
                                                <MenuItem onClick={(e) => onAddTimeframe(e, t)} key={t} value={t}>
                                                    <div
                                                        style={{
                                                            width: '100px',
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <div>{t}</div>
                                                        {selectedTimeframes.includes(t) && <CheckCircleIcon color='primary' />}
                                                    </div>
                                                </MenuItem>
                                        )
                                    }
                                </Menu>
                                <div
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                    style={styles.timeframeInput}
                                >
                                    <div className="flex-row gap-small">
                                        <div>{TEXTS["timeframe"]}</div>
                                        <div style={{ minWidth: '30px' }}>
                                            ({selectedTimeframes?.length})
                                        </div>
                                    </div>
                                    <ArrowDropDownIcon />
                                </div>
                                {/*END: Timeframe */}

                                {/*BEGIN: Notifications */}
                                <NakedTextField
                                    value={notifications}
                                    onChange={(e) => setNotifications(e.target.value)}
                                    style={styles.outlinedTextFieldRoot}
                                    select
                                    size='small'
                                    variant='outlined'
                                    sx={{
                                        "& .MuiOutlinedInput-root": styles.selectTextFieldRoot,
                                        "& .MuiInputLabel-root": styles.inputLabelRoot,
                                        "& .MuiSelect-icon": styles.selectInputIcon
                                    }}
                                >
                                    <MenuItem disabled value={TEXTS["notifications"]}>
                                        <div style={{ fontFamily: constantValues.PRIMARY_FONT_FAMILY }}>
                                            {TEXTS["notifications"]}
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={"Telegram"}>
                                        Telegram
                                    </MenuItem>
                                    <MenuItem value={"Disabled"}>
                                        Disabled
                                    </MenuItem>
                                </NakedTextField>
                                {/*END: Notifications */}

                                {/*BEGIN: Match */}
                                <div style={styles.matchContainer}>
                                    <div style={styles.switchText}>{TEXTS["matchAll"]}</div>
                                    <AntSwitch
                                        checked={matchAll}
                                        onChange={(e) => setMatchAll(e.target.checked)}
                                    />
                                </div>
                                {/*END: Match */}
                            </div>
                            {/* <div style={styles.timeframesContainer}>
                                {
                                    selectedTimeframes.map(x =>
                                        <TimeFrameChip
                                            removable={true}
                                            onRemove={onRemoveTimeframe}
                                            timeframe={x}
                                        />
                                    )
                                }
                            </div> */}
                        </div>
                    </div>
                    {/*END: header */}
                    <div style={styles.innerContainer}>
                        {/*BEGIN: Search */}
                        <div style={styles.searchInputContainer}>
                            <OutlinedTextField
                                value={searchValue}
                                onChange={(e) => calcShownSignals(e.target.value)}
                                style={styles.searchInputRoot}
                                label={constantValues.IS_RTL_LANGUAGE(language) ? undefined : TEXTS["search"]}
                                placeholder={constantValues.IS_RTL_LANGUAGE(language) ? TEXTS["search"] : undefined}
                                size='small'
                                variant='outlined'
                                sx={{
                                    "& .MuiOutlinedInput-root": styles.searchInput,
                                    "& .MuiInputLabel-root": {
                                        color: (theme) => theme.palette.text.disabled,
                                    },
                                }}
                                InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                                    {
                                        endAdornment: (
                                            <InputAdornment
                                                position={"start"}
                                                sx={styles.startAdornmentRTL}
                                            >
                                                <SearchIcon style={styles.adornmentIconColor} />
                                            </InputAdornment>
                                        )
                                    } :
                                    {
                                        endAdornment: (
                                            <InputAdornment
                                                position={"end"}
                                                sx={styles.endAdornmentLTR}
                                            >
                                                <SearchIcon style={styles.adornmentIconColor} />
                                            </InputAdornment>
                                        )
                                    }
                                }
                            />
                        </div>
                        {/*END: Search */}

                        {/*BEGIN: Select/Deselect All Signals */}
                        <div style={styles.selectorsContainer}>
                            <Button onClick={() => setSignals([])} style={styles.textButton} variant='text'>
                                Deselect All
                            </Button>
                        </div>
                        {/*END: Select/Deselect All Signals */}

                        <div style={styles.divider} />

                        {/*BEGIN: Signals */}
                        <SignalSelector
                            shownSignals={shownSignals}
                            toggleColapse={toggleColapse}
                            toggleSection={toggleSection}
                            toggleSignal={toggleSignal}
                            signals={signals}
                        />
                        {/*END: Signals */}

                        {/*BEGIN: Dialog Actions */}
                        <div style={styles.dialogActionsContainer}>
                            <Button
                                onClick={onClose}
                                sx={{ boxShadow: 0 }}
                                style={styles.cancelButton}
                                variant='contained'
                            >
                                {TEXTS["cancel"]}
                            </Button>
                            <Button
                                onClick={isEditing ? updateStrategy : makeStrategy}
                                disabled={loading}
                                style={styles.saveChangesButton}
                                variant='contained'
                            >
                                {
                                    isEditing ? TEXTS["saveChanges"] : TEXTS["makeStrategy"]
                                }
                                {loading && <CircularProgress />}
                            </Button>
                        </div>
                        {/*END: Dialog Actions */}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default MakeStrategyDialog