import { Grid } from "@mui/material";


function Texts() {
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        subText: {
            fontSize: '15px',
            lineHeight: '30px',
            marginTop: '1rem'
        }
    }
    return (
        <Grid item lg={6} sm={6} xs={11}>
            <div style={styles.container}>
                <div>
                    <h1>Cooperation</h1>
                    <div style={styles.subText}>
                        BE OUR BUSINESS PARTNER
                        <br />
                        WOULD YOU LIKE TO BECOME OUR BUSINESS PARTNER
                        <br />
                        We Believe in Union Force, International Alliance and
                        <br />
                        All Business Partnership,
                        <br />
                        We are looking for expanding our business partner's
                        <br />
                        network all over the world,
                        <br />
                        Our business partners and agents are a vital part
                        <br />
                        and key member of our success, our success has
                        <br />
                        been supported through developing close and
                        <br />
                        lasting relation with our business partners and
                        <br />
                        agents.
                        <br />
                        TOGETHER WE CAN LIVE A BETTER TODAY AND BUILD A BETTER TOMORROW
                        <br />
                        Now it's your turn.
                        <br />
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default Texts