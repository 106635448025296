import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { loginUser } from '../../redux/state/userReducer';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function useMessenger() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const User = useSelector(state => state.userReducer);
    const [loading, setLoading] = useState(false);
    const createRequest = useRequest();
    const dispatch = useDispatch();

    const disconnectPlatform = (platform) => {
        //platform :  telegram || ..
        setLoading(true);

        const onSuccess = (response) => {
            setLoading(false);
            dispatch(showSuccessNotification('Disconnected'));
            let partialUser = {};
            switch (platform) {
                case 'telegram':
                    partialUser = { hasTelegram: false };
                    break;
                default:
                    console.log('platform not found')
                    break;
            }
            dispatch(loginUser({ ...User, ...partialUser }));
        }
        const onFail = (error) => {
            setLoading(false);

            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            url: constantValues.DISCONNECT_MESSENGER(platform),
            onSuccess,
            onFail
        })
    }

    return {
        disconnectPlatform,
        loading
    }
}

export default useMessenger