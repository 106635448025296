import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from '../localization';
import { useSelector, useDispatch } from 'react-redux';
import constantValues from './constantValues';
import { useEffect, useState } from 'react';
import { showInitiate, endTrial, fixState } from '../redux/state/trialReducer';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link } from 'react-router-dom';

function TrialDialog() {
    const dispatch = useDispatch();
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [showDialog, setShowDialog] = useState(false);
    const trialReducer = useSelector(state => state.trialReducer);
    const User = useSelector(state => state.userReducer);

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1.5em',
            paddingBottom: '1.5em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        icon: {
            width: '40%',
            height: 'auto',
            color: theme.palette.primary.main
        },
        header: {
            color: '#78c9c0'
        },
        subText: {
            marginTop: '0.4rem',
            color: 'gray'
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            marginTop: '3rem'
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '7rem'
        },
        importantText: {
            fontWeight: 'bold',
            color: '#78c9c0'
        }
    }
    /*END: Styles */

    const showInit = (currTrial) => {
        if(!currTrial){
            return;
        }
        if (!currTrial?.isShownInitiate) {
            setShowDialog(true);
            dispatch(showInitiate(User?.email));
        }
    }

    const endTrl = (currTrial) => {
        if (currTrial?.date) {
            let currDate = new Date();
            let hours = Math.abs(
                new Date(currTrial?.date).getTime() - currDate.getTime()
            ) / 3600000;
            let sevenDays = 168;
            if (hours >= sevenDays) {
                setShowDialog(true);
                dispatch(endTrial(User?.email))
            }
        }
    }

    useEffect(() => {
        //BEGIN: Fix State Deprication
        dispatch(fixState());
        //END:   Fix State Deprication
        const currTrial = trialReducer?.find(x => x.email === User?.email);

        if (User?.emailConfirmed && !currTrial?.isFinished) {
            showInit(currTrial);
            endTrl(currTrial);
        }

    }, [User?.emailConfirmed]);

    const onClose = () => {
        setShowDialog(false);
    }

    return (
        <Dialog
            open={showDialog}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>
                    {
                        trialReducer?.isFinished ?
                            <CalendarMonthIcon style={styles.icon} /> :
                            <CardGiftcardIcon style={styles.icon} />
                    }
                    {
                        trialReducer?.isFinished ?
                            <h3>
                                Your <span style={styles.header}>Free Trial</span> has ended!
                            </h3> :
                            <h3>
                                Welcome <span style={styles.header}>Gift!</span>
                            </h3>
                    }
                    {
                        trialReducer?.isFinished ?
                            <div style={styles.subText}>
                                If you enjoyed our services please consider taking a look at the plans we offer
                            </div> :
                            <div style={styles.subText}>
                                You have recieved <span style={styles.importantText}>7 Days</span> of free trial as your welcome gift
                            </div>
                    }
                    <div style={styles.actions}>
                        {trialReducer?.isFinished ?
                            <Link style={{ textDecoration: 'none' }} to={constantValues.PLAN_ROUTE}>
                                <Button
                                    onClick={onClose}
                                    style={styles.button}
                                    variant={'contained'}
                                >
                                    View Plans
                                </Button>
                            </Link>
                            :
                            <Button
                                onClick={onClose}
                                style={styles.button}
                                variant={'contained'}
                            >
                                {
                                    trialReducer?.isFinished ? 'View Plans' : 'Awesome'
                                }
                            </Button>
                        }
                        <Button
                            onClick={onClose}
                            style={styles.button}
                            variant={'outlined'}
                        >
                            Ok
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default TrialDialog