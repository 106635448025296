import constantValues from '../../../misc/constantValues';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StarIcon from '../../../material-ui/Icons/StarIcon';
import coin_marketcap_button from '../../../media/coin-marketcap-button.png';
import tradingview_button from '../../../media/tradingview-button.png';
import binance_button from '../../../media/binance-button.png';
import { useSelector } from "react-redux";
import Localization from "../../../localization";
import { SocketContext } from "../../../misc/SocketContextProvider";
import { useEffect } from 'react';
import useStrategy from '../../../hooks/Strategy/useStrategy';
import MuiSkeleton from '../../../material-ui/Skeleton/MuiSkeleton';
import { Link } from 'react-router-dom';
import useSymbol from '../../../hooks/Exchange/useSymbol';

function Table({ loading, didNotLoad, favSymbols, SocketFuncs, searchValue, getFavoriteSymbols }) {
    const { getCoinPrices, coins } = useStrategy();
    const {
        toggleFavSymbol,
    } = useSymbol();

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: mobileSize ? '100%' : '90%',
            marginTop: '2rem',
            paddingBottom: '2rem',
        },
        titleRoot: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        subText: {
            fontSize: '11px',
            width: '100%',
            textAlign: 'left',
            color: constantValues.ICON_COLOR_1,
        },
        titleText: {
            width: '180px',
            textAlign: 'left',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        },
        tableRoot: {
            width: '100%'
        },
        tableHeader: {
            border: 0,
            paddingBottom: '1.5em',
            fontWeight: 'normal',
            color: '#7a7a7a'
        },
        tableData: {
            textAlign: 'center',
            paddingLeft: '1.5em'
        },
        tableDataSignal: {
            display: 'flex',
            flexDirection: 'column',
            whiteSpace: 'nowrap'
        },
        notifOn: {
            color: theme.palette.warning.light,
            width: '1.5em',
            height: '1.5em'
        },
        notifOff: {
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_2 : '#e2e2e2',
            width: '1.5em',
            height: '1.5em'
        },
        editIcon: {
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1
        },
        dataContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            fontWeight: 'bold',
        },
        currentPriceData: {
            display: 'flex',
            width: '150px',
            justifyContent: 'center',
            fontWeight: 'bold',
        },
        settingButton: {
            borderRadius: '8px'
        },
        minWidthData: {
            minWidth: '70px'
        },
        shortName: {
            color: '#cccccc',
            fontSize: '10px',
            marginLeft: '0.2rem',
            marginTop: '0.18rem',
            whiteSpace: 'nowrap'
        },
        logo: {
            width: '32px',
            height: '32px',
            marginRight: '0.4rem'
        },
        starIconRoot: {
            marginTop: '0.18rem',
            marginLeft: '1rem'
        },
        moneyType: {
            color: '#cccccc',
            fontSize: '10px',
            marginLeft: '0.4rem',
            marginTop: '0.35rem'
        },
        positivePrice: {
            color: '#78c9c0',
            textAlign: 'center',
        },
        negativePrice: {
            color: '#f07259',
            textAlign: 'center'
        },
        brokerButton: {
            width: '8rem',
            height: '2rem',
            cursor: 'pointer'
        },
        symbolData: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap'
        }
    }
    /*END: Styles */

    useEffect(() => {
        if (favSymbols?.length > 0) {
            let symbols = favSymbols.map(x => x.currency + 'USDT');
            SocketFuncs.connectPrices(symbols);
            getCoinPrices(symbols);
            return () => {
                SocketFuncs.disconnectPrices(symbols);
            }
        }
    }, [favSymbols]);

    const calcPricePercent = (close, num) => {
        return ((((Number(close) / (num)) - 1)) * 100).toFixed(2)
    }

    if (loading || didNotLoad) {
        return (
            <div style={{ width: '100%', marginTop: '2rem' }}>
                <MuiSkeleton
                    variant='rectangular'
                    width={'100%'}
                    height={'400px'}
                    didNotLoad={didNotLoad}
                    reload={getFavoriteSymbols}
                />
            </div>
        )
    }

    const data = favSymbols?.filter(x => x.name.toLowerCase().includes(searchValue?.toLowerCase()));
    return (
        <section style={styles.root}>
            {data?.length > 0 ?
                <table style={styles.tableRoot}>
                    <tr>
                        <th style={styles.tableHeader}>{TEXTS['name']}</th>
                        <th style={styles.tableHeader}>{TEXTS['price']}</th>
                        <th style={styles.tableHeader}>7d %</th>
                        <th style={styles.tableHeader}>28d %</th>
                        <th style={styles.tableHeader}>Year %</th>
                        <th style={styles.tableHeader}></th>
                        <th style={styles.tableHeader}></th>
                        <th style={styles.tableHeader}></th>
                    </tr>
                    {data?.map((d, index) =>
                        <tr key={index}>
                            <td style={styles.tableData}>
                                <div dir='ltr' style={styles.symbolData}>
                                    <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                        to={constantValues.SINGLE_SYMBOL_ROUTE.replace(':name', d.currency + 'USDT')}
                                    >
                                        <div className="flex-row">
                                            <img src={`/media/${d?.currency?.toLocaleLowerCase()}.svg`} alt={d.currency} style={styles.logo} />
                                            <div>
                                                {d.name}
                                            </div>
                                            <div style={styles.shortName}>
                                                {d.currency} USDT
                                            </div>
                                        </div>
                                    </Link>
                                    <div style={styles.starIconRoot}>
                                        <StarIcon
                                            isFavorite={true}
                                            style={{ cursor: 'inherit' }}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div dir='ltr' style={styles.currentPriceData}>
                                    <div>
                                        {
                                            SocketFuncs?.result[d.currency + 'USDT']?.c &&
                                            parseFloat(SocketFuncs?.result[d.currency + 'USDT']?.c)
                                        }
                                        {
                                            !SocketFuncs?.result[d.currency + 'USDT']?.c &&
                                            parseFloat(coins[d.currency + 'USDT']?.Close)
                                        }
                                    </div>
                                    <div style={styles.moneyType}>
                                        USD
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div
                                    style={
                                        calcPricePercent(
                                            coins[d.currency + 'USDT']?.Close,
                                            coins[d.currency + 'USDT']?.Week
                                        ) > 0 ? styles.positivePrice : styles.negativePrice
                                    }
                                >
                                    {calcPricePercent(
                                        coins[d.currency + 'USDT']?.Close,
                                        coins[d.currency + 'USDT']?.Week
                                    )}%
                                </div>
                            </td>
                            <td>
                                <div
                                    style={
                                        calcPricePercent(
                                            coins[d.currency + 'USDT']?.Close,
                                            coins[d.currency + 'USDT']?.Month
                                        ) > 0 ? styles.positivePrice : styles.negativePrice
                                    }
                                >
                                    {calcPricePercent(
                                        coins[d.currency + 'USDT']?.Close,
                                        coins[d.currency + 'USDT']?.Month
                                    )}%
                                </div>
                            </td>
                            <td>
                                <div
                                    style={
                                        calcPricePercent(
                                            coins[d.currency + 'USDT']?.Close,
                                            coins[d.currency + 'USDT']?.Year
                                        ) > 0 ? styles.positivePrice : styles.negativePrice
                                    }
                                >
                                    {calcPricePercent(
                                        coins[d.currency + 'USDT']?.Close,
                                        coins[d.currency + 'USDT']?.Year
                                    )}%
                                </div>
                            </td>
                            <td>
                                <div style={styles.dataContainer}>
                                    <a
                                        href={constantValues.generateTradingViewLink(d.currency + 'USDT')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            style={styles.brokerButton}
                                            src={tradingview_button}
                                            alt='tradingview'
                                        />
                                    </a>
                                </div>
                            </td>
                            <td>
                                <div style={styles.dataContainer}>
                                    <a
                                        href={constantValues.generateBinanceLink(d.currency, 'USDT')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            style={styles.brokerButton}
                                            src={binance_button}
                                            alt='binance'
                                        />
                                    </a>
                                </div>
                            </td>
                            <td>
                                <div style={styles.dataContainer}>
                                    <a
                                        href={constantValues.generateCoinMarketcapLink(d.name)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            style={styles.brokerButton}
                                            src={coin_marketcap_button}
                                            alt='coin marketcap'
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    )}
                </table> :
                <div>
                    Please add a coin to see results
                </div>
            }
        </section>
    )
}

function WithContext({ loading, didNotLoad, favSymbols, searchValue, getFavoriteSymbols }) {
    return (
        <SocketContext.Consumer>
            {
                value =>
                    <Table
                        loading={loading}
                        didNotLoad={didNotLoad}
                        favSymbols={favSymbols}
                        SocketFuncs={value}
                        searchValue={searchValue}
                        getFavoriteSymbols={getFavoriteSymbols}
                    />
            }
        </SocketContext.Consumer>
    )
}

export default WithContext