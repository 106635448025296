import { Grid, TextField } from "@mui/material";
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Countries from '../../../localization/Countries';
import constantValues from "../../../misc/constantValues";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from "react";
import useCooperation from "../../../hooks/cooperation/useCooperation";
import CircularProgress from "../../../material-ui/CircularProgress/CircularProgress";

function Form() {
    const { requestCooperation, loading } = useCooperation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dialCode, setDialCode] = useState('+1');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [business, setBusiness] = useState('');
    const [profession, setProfession] = useState('');
    const [description, setDescription] = useState('');

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setCity('');
        setBusiness('');
        setProfession('');
        setDescription('');
        setCountry('');
    }

    const onSubmit = () => {
        requestCooperation(
            firstName,
            lastName,
            email,
            dialCode + phoneNumber,
            city,
            business,
            profession,
            description,
            country,
            resetForm
        )
    }

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
            fontSize: '14px',
            color: '#7c7c7c'
        },
        halfInputContainer: {
            width: mobileSize ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column'
        },
        halfContainer: {
            width: mobileSize ? '100%' : '50%',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
            gap: 10,
            color: '#7c7c7c',
            fontSize: '14px',
        },
        halfInput: {
            width: '100%'
        },
        input: {
            width: mobileSize ? '100%' : '50%'
        },
        inputTitle: {
            marginBottom: '0.4rem'
        },
        btn: {
            marginTop: '1rem',
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '8rem',
            borderRadius: '8px'
        }
    }
    return (
        <Grid item xs={11} lg={10} md={10}>

            <div style={styles.container}>
                <div style={styles.inputTitle}>{TEXTS['firstName']}</div>
                <OutlinedTextField
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style={styles.input}
                    size="small"
                />
            </div>

            <div style={styles.container}>
                <div style={styles.inputTitle}>{TEXTS['lastName']}</div>
                <OutlinedTextField
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    style={styles.input}
                    size="small"
                />
            </div>

            <div style={styles.container}>
                <div style={styles.inputTitle}>{TEXTS['email']}</div>
                <OutlinedTextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                    size="small"
                />
            </div>

            <div style={styles.container}>
                <div style={styles.inputTitle}>{TEXTS['phoneNumber']}</div>
                <OutlinedTextField
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    style={styles.input}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                variant='outlined'
                            >
                                <div className="flex-row ">
                                    <TextField
                                        value={dialCode}
                                        onChange={(e) => setDialCode(e.target.value)}
                                        select
                                        variant='standard'
                                        size='small'
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: 300
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        {Countries.map(x =>
                                            <MenuItem key={x.dialCode} value={x.dialCode}>
                                                {x.dialCode}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                    <div
                                        style={{
                                            height: '25px',
                                            width: '1px',
                                            borderRight: '1px solid gray',
                                            marginLeft: '0.4rem'
                                        }}
                                    />
                                </div>
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            <div style={styles.halfContainer}>
                <div style={styles.halfInputContainer}>
                    <div style={styles.inputTitle}>{TEXTS['country']}</div>
                    <OutlinedTextField
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        style={styles.halfInput}
                        size="small"
                    />
                </div>
                <div style={styles.halfInputContainer}>
                    <div style={styles.inputTitle}>{TEXTS['city']}</div>
                    <OutlinedTextField
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        style={styles.halfInput}
                        size="small"
                    />
                </div>
            </div>

            <div style={styles.halfContainer}>
                <div style={styles.halfInputContainer}>
                    <div style={styles.inputTitle}>Your business</div>
                    <OutlinedTextField
                        value={business}
                        onChange={(e) => setBusiness(e.target.value)}
                        style={styles.halfInput}
                        size="small"
                    />
                </div>
                <div style={styles.halfInputContainer}>
                    <div style={styles.inputTitle}>Your profession</div>
                    <OutlinedTextField
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        style={styles.halfInput}
                        size="small"
                    />
                </div>
            </div>

            <div style={styles.container}>
                <div style={styles.inputTitle}>
                    How you can grow ACT's business
                </div>
                <OutlinedTextField
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    minRows={4}
                    style={styles.input}
                    size="small"
                />
            </div>

            <Button
                disabled={loading}
                onClick={onSubmit}
                variant='contained'
                style={styles.btn}
            >
                {TEXTS['submit']}
                {loading && <CircularProgress />}
            </Button>
        </Grid>
    )
}

export default Form