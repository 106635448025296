import Dialog from '@mui/material/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from '../../../../localization';
import constantValues from '../../../../misc/constantValues';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import OutlinedTextField from '../../../../material-ui/TextField/OutlinedTextField';
import { useEffect, useState } from 'react';
import { showFailNotification } from '../../../../redux/state/notificationReducer';

function ChangePWDialog({
    open,
    onClose,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword
}) {
    const dispatch = useDispatch();
    const [cPassword, setCPassword] = useState('');
    const [nPassword, setNPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    const onDialogClose = () => {
        onClose();
    }

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1.5em',
            backgroundColor: theme.palette.background.default,
            fontSize: mobileSize ? '12px' : '14px'
        },
        container: {
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        header: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        inputsPadding: {
            width: '100%',
            paddingTop: '2em'
        },
        inputsPaddingBig: {
            width: '100%',
            paddingTop: '4em'
        },
        inputContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            marginTop: '1rem',
            gap: mobileSize ? 3 : ''
        },
        inputTitle: {
            color: theme.palette.mode === "dark" ? '#bbbbbb' : '#7c7c7c'
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            width: '150px',
            borderRadius: '8px'
        },
        outlinedInputRoot: {
            width: '100%',
            maxWidth: '60%'
        }
    }
    /*END: Styles */

    const applyChanges = () => {
        if (cPassword.length < 4) {
            dispatch(showFailNotification('Please enter your current password correctly'))
        } else if (nPassword.length < 6) {
            dispatch(showFailNotification('Your new password is too short!'))
        } else if (confirmPassword !== nPassword) {
            dispatch(showFailNotification('Password & Password confirmation do not match'))
        } else {
            setCurrentPassword(cPassword);
            setNewPassword(nPassword);
            onDialogClose();
        }
    }

    useEffect(() => {
        //Matching component local state to parents
        if (currentPassword !== undefined && newPassword !== undefined) {
            setCPassword(currentPassword);
            setNPassword(newPassword);
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            maxWidth={'xs'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>
                    <div style={styles.header}>
                        <h2>{TEXTS["changePassword"]}</h2>
                        <IconButton onClick={onClose}>
                            <CancelIcon
                                style={
                                    themeReducer === "dark" ?
                                        { color: constantValues.ICON_COLOR_2 } : undefined}
                                color='disabled'
                            />
                        </IconButton>
                    </div>

                    <div style={styles.inputsPadding} />

                    <div style={styles.inputContainer}>
                        <div style={styles.inputTitle}>
                            {TEXTS['currentPassword']}:
                        </div>
                        <OutlinedTextField
                            type='password'
                            value={cPassword}
                            onChange={(e) => setCPassword(e.target.value)}
                            size='small'
                            style={styles.outlinedInputRoot}
                        />
                    </div>

                    <div style={styles.inputContainer}>
                        <div style={styles.inputTitle}>
                            {TEXTS['newPassword']}:
                        </div>
                        <OutlinedTextField
                            type='password'
                            value={nPassword}
                            onChange={(e) => setNPassword(e.target.value)}
                            size='small'
                            style={styles.outlinedInputRoot}
                        />
                    </div>

                    <div style={styles.inputContainer}>
                        <div style={styles.inputTitle}>
                            {TEXTS['confirmPassword']}:
                        </div>
                        <OutlinedTextField
                            type='password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            size='small'
                            style={styles.outlinedInputRoot}
                        />
                    </div>

                    <div style={styles.inputsPaddingBig} />

                    <Button onClick={applyChanges} style={styles.button} variant='contained'>
                        {TEXTS['apply']}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default ChangePWDialog