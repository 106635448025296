import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { loginUser } from '../../redux/state/userReducer';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function useProfile() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const createRequest = useRequest();
    const dispatch = useDispatch();
    const User = useSelector(state => state.userReducer);
    const [loading, setLoading] = useState(false);

    const getProfile = (optionalCallback) => {
        if (User?.accessToken) {
            const onSuccess = (response) => {
                dispatch(loginUser({ ...User, ...response.data.data }));
                if (optionalCallback) {
                    optionalCallback(response.data.data);
                }
            }
            const onFail = (error) => {
                console.log('failed to fetch profile', error);
            }
            createRequest({
                url: constantValues.GET_PROFILE,
                method: 'GET',
                onSuccess,
                onFail
            })
        }
    }

    const updateProfile = (
        firstName,
        lastName,
        phone,
        gender,
        birthdate,
        location,
        profile,
        NeedPasswordChange,
        CurrentPassword,
        NewPassword
    ) => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            dispatch(showSuccessNotification('Profile Updated!'));
            setTimeout(() => getProfile(), 2000);
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'POST',
            url: constantValues.UPDATE_PROFILE,
            data: {
                firstName,
                lastName,
                phone,
                gender,
                birthdate,
                location,
                profile,
                NeedPasswordChange,
                CurrentPassword: CurrentPassword || '',
                NewPassword: NewPassword || '',
                theme: 'light', //TODO
                language: 'English' //TODO
            },
            onSuccess,
            onFail
        })
    }

    return {
        getProfile,
        loading,
        updateProfile
    }
}

export default useProfile