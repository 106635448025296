import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import Localization from '../../../localization';
import useSession from '../../../hooks/Session/useSession';
import MuiSkeleton from '../../../material-ui/Skeleton/MuiSkeleton';
import { Button } from '@mui/material';
import constantValues from '../../../misc/constantValues';
import CustomCircularProgress from '../../../material-ui/CircularProgress/CircularProgress';

function ProfileTopic() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    const {
        getSessions,
        loading,
        didNotLoad,
        data: sessions,
        loadingDelete,
        deleteSession
    } = useSession();
    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '4em',
            paddingTop: '3em'
        },
        content: {
            width: mobileSize ? '93%' : '80%',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            wordWrap: 'break-word',
            overflowX: 'auto'
        },
        subText: {
            marginTop: '2rem'
        },
        table: {
            width: '100%',
            marginTop: '2rem'
        },
        tableHeader: {
            border: 0,
            padding: 0,
            paddingBottom: '1em',
            fontWeight: 'normal',
            color: theme.palette.mode === 'dark' ? 'rgb(178, 178, 178)' : '#7a7a7a'
        },
        tableData: {
            textAlign: 'center',
            paddingTop: '1em',
            paddingBottom: '1em'
        },
        btn: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            color: 'white',
            boxShadow: 'none'
        },
        skeletonContainer: {
            width: '100%',
            marginTop: '2rem'
        }
    }
    /*END: Styles */

    const onDeleteSession = (id) => {
        deleteSession(id, getSessions)
    }

    useEffect(() => {
        getSessions();
    }, [])
    return (
        <section style={styles.root}>
            <div style={styles.content}>
                <h2>Session Management</h2>
                <div style={styles.subText}>
                    These are the devices you have logged in to your account with; In case you have logged in with a number of devices that exceeds your limit you can remove one or more of them in the table below
                </div>
                {
                    (loading || didNotLoad) ?
                        <div style={styles.skeletonContainer}>
                            <MuiSkeleton
                                variant={'rectangle'}
                                width='100%'
                                height='300px'
                                didNotLoad={didNotLoad}
                                reload={getSessions}
                            />
                        </div> :
                        <table style={styles.table}>
                            <tr>
                                {
                                    ['Device', 'IP', 'Last Activity', 'Action'].map(h =>
                                        <th style={styles.tableHeader} key={h}>
                                            {h}
                                        </th>
                                    )
                                }
                            </tr>
                            {sessions?.map(s =>
                                <tr key={s.id}>
                                    <td style={styles.tableData}>
                                        {s.isCurrent ? 'Your device' : s.device}
                                    </td>
                                    <td style={styles.tableData}>
                                        {s.ip}
                                    </td>
                                    <td style={styles.tableData}>
                                        {new Date(s.lastActive).toLocaleDateString()}
                                    </td>
                                    <td style={styles.tableData}>
                                        {!s.isCurrent &&
                                            <Button
                                                disabled={loadingDelete}
                                                onClick={() => onDeleteSession(s.id)}
                                                style={styles.btn}
                                                variant='contained'
                                            >
                                                Delete
                                                {loadingDelete && <CustomCircularProgress />}
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}
                        </table>
                }
            </div>
        </section>
    )
}

export default ProfileTopic