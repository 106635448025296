import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import constantValues from './constantValues';
import Localization from '../localization';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { useState, useEffect, useMemo } from "react";
import MenuItem from '@mui/material/MenuItem';
import FilledTextField from '../material-ui/TextField/FilledTextField';
import HelpIcon from '../material-ui/Icons/HelpIcon';
import coin_marketcap_icon from '../media/coin-marketcap-icon.jpg';
import binance_icon from '../media/binance-icon.png';
import tradingview_icon from '../media/tradingview-icon.png';
import TradingViewChart from './TradingViewChart';
import binance_button from '../media/binance-button.png';
import coin_marketcap_button from '../media/coin-marketcap-button.png';
import tradingview_button from '../media/tradingview-button.png';

function TradingViewChartDialog({
    open,
    onClose,
    symbol,
    interval,
    parentSignalCode,
    insCode,
    tableContent,
    signalCode,
    timeframe,
    similarSignals,

    setSelected
}) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Application State */
    const [signalType, setSignalType] = useState('');
    const [selectedSimilar, setSelectedSimilar] = useState(0);
    const [fullScreen, setFullScreen] = useState(false);
    /*END: Application State */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '0.7em',
            paddingBottom: '0.7em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '97%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header: {
            width: !desktopSize ? '95%' : '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap'
        },
        dialogVisibility: {
            display: 'flex',
            alignItems: 'center'
        },
        symbolManager: {
            display: 'flex',
            alignItems: 'center'
        },
        skipIconPrevious: {
            backgroundColor: constantValues.ICON_COLOR_3,
            padding: '0.1em',
            color: 'white',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            display: !desktopSize ? 'none' : ''
        },
        skipIcon: {
            backgroundColor: constantValues.ICON_COLOR_3,
            padding: '0.1em',
            color: 'white',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            marginLeft: '0.4rem',
            display: !desktopSize ? 'none' : ''
        },
        symbolLogo: {
            marginLeft: mobileSize ? '0.2rem' : '0.4rem',
        },
        symbolName: {
            marginLeft: mobileSize ? '0.2rem' : '0.4rem',
            fontSize: !desktopSize ? '10px' : '1rem'
        },
        symbolShortName: {
            marginLeft: '0.4rem',
            color: 'gray',
            fontSize: '8px'
        },
        subHeaderContainerLG: {
            display: !desktopSize ? 'none' : 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: '0.4rem',
            width: '100%'
        },
        subHeaderContainerSM: {
            display: !desktopSize ? 'flex' : 'none',
            marginTop: '1rem',
            width: '93%',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        helpIcon: {
            marginLeft: mobileSize ? '' : '0.4rem',
            marginTop: mobileSize ? '0.1rem' : '',
            width: mobileSize ? '1em' : '',
            height: mobileSize ? '1em' : ''
        },
        signalInfoContainer: {
            marginLeft: '1rem',
            display: 'flex',
            alignItems: 'center',
            fontSize: '13px',
            flexWrap: 'wrap'
        },
        warning: {
            color: theme.palette.warning.light
        },
        subText: {
            color: constantValues.ICON_COLOR_1,
            fontSize: !desktopSize ? '8px' : ''
        },
        infoContainer: {
            color: '#aeaeae',
            marginLeft: !desktopSize ? '' : '1.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: !desktopSize ? 'flex-end' : '',
            fontSize: !desktopSize ? '10px' : '',
            marginTop: !desktopSize ? '0.2rem' : ''
        },
        info: {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
        similarSignalManager: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: themeReducer === "light" ? constantValues.ICON_COLOR_4 : theme.palette.background.default,
            color: theme.palette.text.primary,
            marginLeft: '0.2rem',
            paddingBottom: 2,
            paddingTop: 2,
            borderRadius: '5px'
        },
        similarSignalSkipNextIcon: {
            color: constantValues.ICON_COLOR_3,
            marginLeft: '1rem'
        },
        similarSignalSkipPreviousIcon: {
            color: constantValues.ICON_COLOR_3,
            marginRight: '1rem'
        },
        exchangeLogo: {
            marginLeft: '1rem',
            width: '24px',
            height: '24px',
            borderRadius: '5px'
        },
        onlySM: {
            display: !desktopSize ? 'flex' : 'none'
        },
        timeframeDateSM: {
            gap: '0.5rem',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: mobileSize ? 'column' : 'row',
            alignSelf: mobileSize ? '' : 'flex-start',
        },
        sTypeColor: (num) => ({
            color: constantValues.isSellOrBuySignal(num) === 'Sell' ?
                theme.palette.warning.light : constantValues.FONT_COLOR_1,
            fontSize: '10px'
        })
    }
    /*BEGIN: Styles */

    const calcAvailableSignalTypes = () => {
        let selectedResult = tableContent?.result[insCode];
        let availableSignalTypes = tableContent?.items?.filter(x => {
            if (selectedResult && selectedResult[x]) {
                return true;
            }
            return false;
        });
        return availableSignalTypes;
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const allSignalTypes = useMemo(() => calcAvailableSignalTypes(), [insCode]);


    const onChangeSymbol = (nextOrPrevious) => {
        //BEGIN: Calculate insCode index
        let allInsCode = Object.keys(tableContent?.result);
        let currIndex = allInsCode.findIndex(x => x === insCode);
        let newIndex = nextOrPrevious === 'next' ?
            ((currIndex + 1) === (allInsCode.length) ? 0 : (currIndex + 1)) :
            ((currIndex - 1) < 0 ? (allInsCode.length - 1) : (currIndex - 1))
        //END: Calculate insCode index

        let newInsCode = allInsCode[newIndex];
        let newSymbol = tableContent?.result[newInsCode].Currency;
        let newSimilar = '';
        let newParentSignalCode = '';
        let newSignalCode = '';

        //Considering the first non-empty similar as base 
        let allPossibleSignals = tableContent?.items;
        allPossibleSignals?.every(s => {
            let similar = tableContent?.result[newInsCode][s];
            if (similar) {
                newSimilar = similar.split(',');
                newParentSignalCode = s[0] + s[1];
                newSignalCode = s;
                return false;
            }
            return true;
        });

        setSelected(prev => ({
            ...prev,
            insCode: newInsCode,
            similarSignals: newSimilar,
            symbol: newSymbol,
            parentSignalCode: newParentSignalCode,
            signalCode: newSignalCode
        }));
    }

    const onChangeSignal = (newType) => {
        let newSignalCode = newType;
        let newSimilarSignals = tableContent?.result[insCode][newType]?.split(',');
        let newParentSignalCode = newType[0] + newType[1];
        setSelected(prev => ({
            ...prev,
            signalCode: newSignalCode,
            similarSignals: newSimilarSignals,
            parentSignalCode: newParentSignalCode
        }))
    }

    const onChangeSimilar = (nextOrPrevious) => {
        let isNotValidNext = nextOrPrevious === 'next' &&
            (selectedSimilar + 1) >= similarSignals?.length
        let isNotValidPrevious = (nextOrPrevious === 'previous' && (selectedSimilar - 1) < 0);

        let isNotValid = isNotValidNext || isNotValidPrevious

        if (isNotValid) {
            return;
        }

        let newIndex = nextOrPrevious === 'next' ? (selectedSimilar + 1) : (selectedSimilar - 1);
        setSelectedSimilar(newIndex);
    }

    const logoAdrs = '/media/' + insCode?.replace('USDT', '').toLowerCase() + '.svg';
    const dateTime = similarSignals && new Date(similarSignals[selectedSimilar]?.split('|')[0]);
    const hash = similarSignals && similarSignals[selectedSimilar]?.split('|')[1];

    useEffect(() => {
        setSignalType(signalCode);
        setSelectedSimilar(0);
    }, [open, signalCode, insCode]);

    return (
        <Dialog
            PaperProps={
                mobileSize ? {
                    style: { margin: 0, maxWidth: '', width: 'calc(100% - 20px)' }
                } : {

                }
            }
            open={open}
            onClose={onClose}
            maxWidth={'xl'}
            fullScreen={fullScreen}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>

                    {/*BEGIN: header */}
                    <div style={styles.header}>
                        <div style={styles.symbolManager}>
                            <SkipPreviousIcon onClick={() => onChangeSymbol('previous')} style={styles.skipIconPrevious} />
                            <div style={styles.symbolManager}>
                                <img
                                    style={styles.symbolLogo}
                                    width="32px"
                                    height="32px"
                                    src={logoAdrs}
                                    alt='symbol logo'
                                />
                                <div style={styles.symbolManager}>
                                    <h4 style={styles.symbolName}>{symbol}</h4>
                                    <div style={styles.symbolShortName}>{insCode?.replace('USDT', '')}</div>
                                </div>
                            </div>
                            <SkipNextIcon onClick={() => onChangeSymbol('next')} style={styles.skipIcon} />
                        </div>
                        <div style={styles.dialogVisibility}>
                            {!mobileSize &&
                                <IconButton onClick={() => setFullScreen(prev => !prev)}>
                                    <FullscreenIcon color='primary' />
                                </IconButton>
                            }
                            <IconButton onClick={onClose}>
                                <CancelIcon
                                    style={
                                        themeReducer === "dark" ?
                                            { color: constantValues.ICON_COLOR_2 } : undefined}
                                    color='disabled'
                                />
                            </IconButton>
                        </div>
                    </div>
                    {/*END: header */}

                    {/*BEGIN: Subheader SM */}
                    <div style={styles.subHeaderContainerSM}>
                        <div className={"flex-column gap-small"}>
                            <div className="flex-row gap-small font-small white-space-no-wrap">
                                <div> {TEXTS[`signal-${signalType}`]}</div>
                                <HelpIcon signalCode={signalCode} style={styles.helpIcon} />
                            </div>
                            <div style={styles.subText}>
                                {TEXTS[`signal-${parentSignalCode}`]}/<span
                                    style={styles.sTypeColor(signalCode)}
                                >
                                    {constantValues.isSellOrBuySignal(signalCode)}
                                </span>
                            </div>
                        </div>
                        {(!mobileSize) &&
                            <div>
                                <div style={styles.infoContainer}>
                                    {TEXTS['timeframe']}: <span style={styles.info}> {timeframe}</span>
                                </div>
                            </div>
                        }
                        <div style={styles.timeframeDateSM}>
                            {(mobileSize) &&
                                <div style={styles.infoContainer}>
                                    {TEXTS['timeframe']}: <span style={styles.info}> {timeframe}</span>
                                </div>
                            }
                            <div style={styles.infoContainer}>
                                Signal Date: <span style={styles.info}> {dateTime && constantValues.convertDateToTableFormat(dateTime)}</span>
                            </div>
                        </div>
                    </div>

                    <div
                        style={styles.onlySM}
                        className={`flex-row width-100 ${mobileSize ? 'justify-center' : 'justify-end'} gap-1 mt-1 flex-wrap`}>
                        <a
                            href={constantValues.generateCoinMarketcapLink(symbol)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                style={{ width: '6rem', height: '2rem' }}
                                src={coin_marketcap_button}
                                alt='trd'
                            />
                        </a>
                        <a
                            href={constantValues.generateBinanceLink(
                                insCode.replace('USDT', ''), 'USDT'
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                style={{ width: '6rem', height: '2rem' }}
                                src={binance_button}
                                alt='trd'
                            />
                        </a>
                        <a
                            href={constantValues.generateTradingViewLink(
                                insCode
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                style={{ width: '6rem', height: '2rem' }}
                                src={tradingview_button}
                                alt='trd'
                            />
                        </a>
                    </div>

                    {/*END: Subheader SM */}

                    {/*BEGIN: Subheader LG*/}
                    <div style={styles.subHeaderContainerLG}>

                        {/*BEGIN: Signal Type */}
                        <FilledTextField
                            sx={
                                themeReducer === "dark" ?
                                    {
                                        '& .MuiFilledInput-root': { backgroundColor: theme.palette.background.paper }
                                    }
                                    : {
                                        '& .MuiFilledInput-root': { fontSize: '13px' }
                                    }
                            }
                            variant='filled'
                            value={signalType}
                            onChange={(e) => onChangeSignal(e.target.value)}
                            select
                        >
                            {
                                allSignalTypes?.map(i =>
                                    <MenuItem value={i}>
                                        {TEXTS[`signal-${i}`]}
                                    </MenuItem>
                                )
                            }
                        </FilledTextField>
                        {/*END: Signal Type */}

                        <HelpIcon signalCode={signalCode} style={styles.helpIcon} />

                        <div style={styles.signalInfoContainer}>

                            <div style={styles.subText}>
                                {TEXTS[`signal-${parentSignalCode}`]}/<span
                                    style={styles.sTypeColor(signalCode)}
                                >
                                    {constantValues.isSellOrBuySignal(signalCode)}
                                </span>
                            </div>

                            <div style={styles.infoContainer}>
                                {TEXTS['timeframe']}: <span style={styles.info}> {timeframe}</span>
                            </div>

                            <div style={styles.infoContainer}>
                                Signal Date: <span style={styles.info}> {dateTime && constantValues.convertDateToTableFormat(dateTime)}</span>
                            </div>

                            <div style={styles.infoContainer}>
                                <div>Other {TEXTS[`signal-${parentSignalCode}`]}:</div>
                                <div style={styles.similarSignalManager}>
                                    <SkipPreviousIcon
                                        onClick={() => onChangeSimilar('previous')}
                                        style={styles.similarSignalSkipPreviousIcon}
                                    />
                                    <div> {selectedSimilar + 1}/{similarSignals?.length} </div>
                                    <SkipNextIcon
                                        onClick={() => onChangeSimilar('next')}
                                        style={styles.similarSignalSkipNextIcon}
                                    />
                                </div>
                            </div>
                            <div style={styles.infoContainer}>
                                <a
                                    href={constantValues.generateCoinMarketcapLink(symbol)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        style={styles.exchangeLogo}
                                        src={coin_marketcap_icon}
                                        alt={'coin market cap'}
                                    />
                                </a>
                                <a
                                    href={constantValues.generateBinanceLink(
                                        insCode.replace('USDT', ''), 'USDT'
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        style={styles.exchangeLogo}
                                        src={binance_icon}
                                        alt={'binance'}
                                    />
                                </a>
                                <a
                                    href={constantValues.generateTradingViewLink(
                                        insCode
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        style={styles.exchangeLogo}
                                        src={tradingview_icon}
                                        alt={'Trading View'}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/*END: Subheader */}
                </div>
            </div>
            <div>
                <TradingViewChart
                    symbol={insCode}
                    interval={interval}
                    hash={hash}
                    selectedSimilar={selectedSimilar}
                    fullScreen={fullScreen}
                />
            </div>
        </Dialog>
    )
}

export default TradingViewChartDialog