import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from '../../../localization';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import CancelIcon from '@mui/icons-material/Cancel';
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import constantValues from "../../../misc/constantValues";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SignalSelector from '../../../misc/SignalSelector';
import { updateSignals } from '../../../redux/state/signalsReducer';
import CircularProgress from '../../../material-ui/CircularProgress/CircularProgress';

function AddStrategyDialog({
    resetPages,
    open,
    onClose,
    strategy,
    setStrategy,
    timeframe,
    matchAll,
    editStrategy,
    loading,
    result,
    searchStrategy,
    signalDate
}) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Application State */
    const signalsReducer = useSelector(state => state.signalsReducer);
    const [signals, setSignals] = React.useState([]);
    const [shownSignals, setShownSignals] = React.useState(signalsReducer?.data);
    const [searchValue, setSearchValue] = React.useState("");
    const dispatch = useDispatch();
    /*BEGIN: Application State */

    /*BEGIN: UI Functions */
    const calcShownSignals = (sValue) => {
        setSearchValue(sValue);
        let searchString = sValue.toLowerCase();
        let result = signalsReducer
            .data
            .filter(x => {
                let section = TEXTS[`signal-${x.section}`]?.toLowerCase();
                let isSectionTitle = section?.includes(searchString);
                let isInSection = false;
                x.signals.every(s => {
                    let sig = TEXTS[`signal-${s}`]?.toLowerCase()
                    if (sig?.includes(searchString)) {
                        isInSection = true;
                        return false;
                    }
                    return true;
                })
                return isSectionTitle || isInSection;
            });

        setShownSignals(result);
    }

    const toggleColapse = (e, index) => {
        e.preventDefault();
        let result = [];
        shownSignals.map((x, i) => {
            if (i === index) {
                result.push({ ...x, showList: !x.showList });
            } else {
                result.push({ ...x, showList: false });
            }
        });
        setShownSignals(result);
    }

    const toggleSection = (section) => {
        let sectionSignal = signalsReducer.data.find(x => x.section === section).signals;
        let shouldToggleOff = sectionSignal.every(x => signals.includes(x));
        if (shouldToggleOff) {
            let result = signals.filter(x => !sectionSignal.includes(x));
            setSignals(result);
        } else {
            let filterPrevious = signals
                .filter(x => !sectionSignal.includes(x));
            let result = [...filterPrevious, ...sectionSignal];
            setSignals(result);
        }
    }

    const toggleSignal = (id) => {
        if (signals.includes(id)) {
            let result = signals.filter(x => x !== id);
            setSignals(result);
        } else {
            let result = [...signals, id];
            setSignals(result)
        }
    }

    const selectAllSignals = () => {
        let allSignals = [];
        signalsReducer.data.map(x => {
            x.signals.map(y => {
                allSignals.push(y);
            })
        });
        setSignals(allSignals);
    }
    /*END: UI Functions */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1.5em',
            paddingBottom: '1.5em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '94%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        searchInputRoot: {
            width: '100%',
            marginTop: '2rem'
        },
        searchInput: {
            paddingRight: constantValues.IS_RTL_LANGUAGE(language) ? 1 : 0,
            paddingLeft: 0,
            borderRadius: '8px',
            color: theme.palette.text.primary,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        startAdornmentRTL: {
            paddingRight: 0,
            paddingLeft: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '',
            borderBottomRightRadius: '',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        endAdornmentLTR: {
            paddingRight: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        adornmentIconColor: {
            color: 'white'
        },
        searchInputContainer: {
            width: '93%',
        },
        selectorsContainer: {
            display: 'flex',
            width: '93%',
            alignItems: 'center',
            marginTop: '1rem',
            gap: 20
        },
        textButton: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            color: theme.palette.primary.main,
            textTransform: 'none',
            fontWeight: 500,
            padding: 0
        },
        signalsContainer: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            width: '100%',
            height: '250px',
        },
        divider: {
            width: '100%',
            borderBottom: '1px solid #e1e1e1',
            marginTop: '0.4rem',
            marginBottom: '0.4rem'
        },
        signal: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        signalHeader: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            cursor: 'pointer'
        },
        dialogActionsContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '2rem'
        },
        saveChangesButton: {
            textTransform: 'none',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        cancelButton: {
            textTransform: 'none',
            backgroundColor: themeReducer === "light" ? '#ebebeb' :
                constantValues.ICON_COLOR_2,
            color: theme.palette.text.primary,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        signalList: {
            display: 'flex',
            flexDirection: 'column',
            width: '94%',
            fontSize: '14px'
        },
        signalListContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        helpIcon: {
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
            marginLeft: '0.4rem',
            width: '0.6em',
            height: '0.6em'
        }
    }
    /*END: Styles */

    React.useEffect(() => {
        if (strategy) {
            //Updating Signals when strategy changes
            setSignals(strategy.items);
        }
    }, [strategy]);

    const onSaveStrategy = () => {
        editStrategy(
            strategy.id,
            strategy.name,
            signals,
            timeframe ? [timeframe] : [constantValues.DEFAULT_CRYPTO_TIMEFRAME],
            false,
            false,
            10,
            matchAll ? 'All' : 'Any',
            'Dashboard'
        )
    }

    React.useEffect(() => {
        if (result?.success && result?.shouldFetchStrategies) {
            onClose();
            resetPages();
            setStrategy(prev => ({ ...prev, id: result.strategy.id }))
            searchStrategy(
                result.strategy.id,
                matchAll ? 'All' : 'Any',
                signalDate || 30,
                timeframe ? timeframe : constantValues.DEFAULT_CRYPTO_TIMEFRAME,
                [],
                [],
                true,
                false,
                null,
                null
            )
        }
    }, [result])

    const onCloseDialog = () => {
        onClose();
        let result = [];
        signalsReducer.data.map((s) => {
            result.push({ ...s, showList: false });
        });
        dispatch(updateSignals({ ...signalsReducer, data: result }));
        setShownSignals(result);
    }

    return (
        <Dialog
            open={open}
            onClose={onCloseDialog}
            maxWidth={'sm'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>

                    {/*BEGIN: header */}
                    <div style={styles.header}>
                        <h3>{TEXTS["addStrategy"]}</h3>
                        <IconButton onClick={onClose}>
                            <CancelIcon
                                style={
                                    themeReducer === "dark" ?
                                        { color: constantValues.ICON_COLOR_2 } : undefined}
                                color='disabled'
                            />
                        </IconButton>
                    </div>
                    {/*END: header */}

                    {/*BEGIN: Search */}
                    <div style={styles.searchInputContainer}>
                        <OutlinedTextField
                            value={searchValue}
                            onChange={(e) => calcShownSignals(e.target.value)}
                            style={styles.searchInputRoot}
                            label={constantValues.IS_RTL_LANGUAGE(language) ? undefined : TEXTS["search"]}
                            placeholder={constantValues.IS_RTL_LANGUAGE(language) ? TEXTS["search"] : undefined}
                            size='small'
                            variant='outlined'
                            sx={{
                                "& .MuiOutlinedInput-root": styles.searchInput,
                                "& .MuiInputLabel-root": {
                                    color: (theme) => theme.palette.text.disabled,
                                },
                            }}
                            InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                                {
                                    endAdornment: (
                                        <InputAdornment
                                            position={"start"}
                                            sx={styles.startAdornmentRTL}
                                        >
                                            <SearchIcon style={styles.adornmentIconColor} />
                                        </InputAdornment>
                                    )
                                } :
                                {
                                    endAdornment: (
                                        <InputAdornment
                                            position={"end"}
                                            sx={styles.endAdornmentLTR}
                                        >
                                            <SearchIcon style={styles.adornmentIconColor} />
                                        </InputAdornment>
                                    )
                                }
                            }
                        />
                    </div>
                    {/*END: Search */}

                    {/*BEGIN: Select/Deselect All Signals */}
                    <div style={styles.selectorsContainer}>
                        <Button onClick={() => setSignals([])} style={styles.textButton} variant='text'>
                            Deselect All
                        </Button>
                    </div>
                    {/*END: Select/Deselect All Signals */}

                    <div style={styles.divider} />

                    {/*BEGIN: Signals */}
                    <SignalSelector
                        shownSignals={shownSignals}
                        toggleColapse={toggleColapse}
                        toggleSection={toggleSection}
                        toggleSignal={toggleSignal}
                        signals={signals}
                        height={'250px'}
                    />
                    {/*END: Signals */}

                    {/*BEGIN: Dialog Actions */}
                    <div style={styles.dialogActionsContainer}>
                        <Button
                            onClick={onClose}
                            sx={{ boxShadow: 0 }}
                            style={styles.cancelButton}
                            variant='contained'
                        >
                            {TEXTS["cancel"]}
                        </Button>
                        <Button
                            onClick={onSaveStrategy}
                            disabled={loading}
                            style={styles.saveChangesButton}
                            variant='contained'
                            sx={{ boxShadow: 0 }}
                        >
                            {TEXTS["saveChanges"]}
                            {loading && <CircularProgress />}
                        </Button>
                    </div>
                    {/*END: Dialog Actions */}
                </div>
            </div>
        </Dialog>
    )
}

export default AddStrategyDialog