import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import constantValues from '../../misc/constantValues';
import { useSelector } from 'react-redux';

function CustomCheckbox({ checked, onChange }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const styles = {
        root: {
            width: '11px',
            height: '11px',
            padding: 0,
            borderRadius: '3px',
            backgroundColor: '#e1e1e1',
            border: 'none',
            cursor: 'pointer',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.6rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.6rem' : ''
        },
        active: {
            backgroundColor: themeReducer === "light" ?
                theme.palette.primary.main : 'rgb(210,150,211)'
        }
    }
    const compStyle = checked ? Object.assign({}, styles.root, styles.active) : styles.root;
    /*END: Styles */

    return (
        <button
            onClick={onChange}
            style={compStyle}
        />
    )
}

export default CustomCheckbox