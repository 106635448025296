import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';
import { loginUser } from '../../redux/state/userReducer';
import { useNavigate } from 'react-router-dom';
import useProfile from './useProfile';

function useVerifyEmail() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const navigate = useNavigate();
    const { getProfile } = useProfile();
    const User = useSelector(state => state.userReducer);
    const [loadingResend, setLoadingResend] = useState(false);
    const [loadingVerify, setLoadingVerify] = useState(false);

    const resendVerify = () => {
        setLoadingResend(true);
        const onSuccess = (response) => {
            setLoadingResend(false);
            dispatch(showSuccessNotification('Email sent!'))
        }
        const onFail = (error) => {
            setLoadingResend(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'GET',
            data: { Email: User.email, Token: User.accessToken },
            url: constantValues.RESEND_VERIFY_EMAIL,
            onSuccess,
            onFail
        })
    }
    const verifyEmail = (Token, Email) => {
        setLoadingVerify(true);
        const onSuccess = (response) => {
            setLoadingVerify(false);
            dispatch(showSuccessNotification('Email Verified!'));
            dispatch(loginUser({ ...User, emailConfirmed: true }));
            navigate(constantValues.LOGIN_ROUTE);
        }
        const onFail = (error) => {
            setLoadingVerify(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;

            if(errMessage === "EmailAlreadyVerified"){
                dispatch(showSuccessNotification('Email Verified!'));
                dispatch(loginUser({ ...User, emailConfirmed: true }));
                navigate(constantValues.LOGIN_ROUTE);
                return;
            }
            
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'POST',
            data: { Email, Token },
            url: constantValues.VERIFY_EMAIL,
            onSuccess,
            onFail
        })
    }

    return {
        loadingResend,
        loadingVerify,
        resendVerify,
        verifyEmail
    }
}

export default useVerifyEmail