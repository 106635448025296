import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import CustomCheckbox from '../material-ui/Checkbox/CustomCheckbox';
import Localization from '../localization';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Collapse from '@mui/material/Collapse';
import HelpIcon from '../material-ui/Icons/HelpIcon';
import constantValues from './constantValues';
import useSubscription from '../hooks/Plan/useSubscription';
import { useEffect, useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

function SignalSelector({ shownSignals, toggleColapse, toggleSection, toggleSignal, signals, height }) {
    const theme = useTheme();
    const signalsReducer = useSelector(state => state.signalsReducer);
    const { getCurrentSubscription } = useSubscription();
    const [lockedSections, setLockedSections] = useState([]);
    const User = useSelector(state => state.userReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const styles = {
        signalsContainer: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            width: '100%',
            height: height || '160px',
        },
        divider: {
            width: '100%',
            borderBottom: '1px solid #e1e1e1',
            marginTop: '0.4rem',
            marginBottom: '0.4rem'
        },
        signal: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        signalHeader: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            cursor: 'pointer'
        },
        signalList: {
            display: 'flex',
            flexDirection: 'column',
            width: '94%',
            fontSize: '14px'
        },
        signalListContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        helpIcon: {
            marginLeft: '0.4rem',
            width: '0.9em',
            height: '0.9em'
        },
        sTypeColor: (num) => ({
            color: num % 2 === 0 ? theme.palette.warning.light : constantValues.FONT_COLOR_1,
            fontSize: '10px'
        })
    }
    /*END: Styles */

    const isSectionChecked = (section) => {
        let sectionSignal = signalsReducer.data.find(x => x.section === section).signals;
        let isChecked = sectionSignal.every(x => signals.includes(x));
        return isChecked
    }

    let isLocked = User?.plan?.name === 'free';

    useEffect(() => {
        getCurrentSubscription();
    }, []);

    useEffect(() => {
        if (isLocked) {
            let lockedList = [];
            signalsReducer?.data.map(x => {
                let isSectionLocked = false;

                x.signals.every(s => {
                    if (signalsReducer?.premiumSignals.includes(s)) {
                        isSectionLocked = true;
                        return false
                    }
                    return true;
                });

                if (isSectionLocked) {
                    lockedList.push(x.section);
                }
            });
            setLockedSections(lockedList);
        }
    }, [isLocked])

    return (
        <div
            className={themeReducer === "dark" ? "darkModeScroll" : ""}
            style={styles.signalsContainer}
        >
            {
                shownSignals?.map((s, i) =>
                    <div key={s.section} style={styles.signal}>
                        <div style={styles.signalHeader}>
                            <div
                                style={lockedSections.includes(s.section) ? { color: 'gray' } : {}}
                                className="flex-row"
                            >
                                <div onClick={() => toggleSection(s.section)}>
                                    {
                                        lockedSections?.includes(s.section) || s.signals.length === 0 ?
                                            <Tooltip title="This signal is only available to paid users. Click here to upgrade your plan" arrow>
                                                <Link style={{ color: 'gray' }} to={constantValues.PLAN_ROUTE}>
                                                    <LockOutlinedIcon />
                                                </Link>
                                            </Tooltip> :
                                            <CustomCheckbox
                                                checked={isSectionChecked(s.section)}
                                            />
                                    }
                                </div>
                                <div style={{ whiteSpace: "nowrap" }} onClick={(e) => toggleColapse(e, i)}>
                                    {TEXTS["signal-" + s.section] || `Unknown(${s.section})`}
                                </div>
                            </div>
                            <div
                                onClick={(e) => toggleColapse(e, i)}
                                style={{ display: 'flex', flexGrow: 1, width: '100%', height: '100%' }} />
                            {s.showList ?
                                <IconButton onClick={(e) => toggleColapse(e, i)}>
                                    <RemoveCircleOutlineIcon onClick={(e) => toggleColapse(e, i)} />
                                </IconButton> :
                                <IconButton onClick={(e) => toggleColapse(e, i)}>
                                    <AddCircleOutline onClick={(e) => toggleColapse(e, i)} />
                                </IconButton>
                            }
                        </div>
                        <Collapse in={s.showList}>
                            <div style={styles.signalListContainer}>
                                <div style={styles.signalList}>
                                    {s.signals?.map((l) =>
                                        !TEXTS["signal-" + l] ? null :
                                            <div
                                                key={l}
                                                onClick={
                                                    isLocked && signalsReducer?.premiumSignals?.includes(l) ?
                                                        undefined :
                                                        () => toggleSignal(l)
                                                }
                                                className="flex-row"
                                                style={
                                                    isLocked && signalsReducer?.premiumSignals?.includes(l) ?
                                                        { color: 'gray' } :
                                                        {}
                                                }
                                            >
                                                {
                                                    isLocked && signalsReducer?.premiumSignals?.includes(l) ?
                                                        <Tooltip title="This signal is only available to paid users. Click here to upgrade your plan" arrow>
                                                            <Link style={{ color: 'gray' }} to={constantValues.PLAN_ROUTE}>
                                                                <LockOutlinedIcon />
                                                            </Link>
                                                        </Tooltip> :
                                                        <CustomCheckbox checked={signals?.includes(l)} />
                                                }
                                                <div>
                                                    {TEXTS["signal-" + l] || `Unknown(${l})`}
                                                </div>
                                                <div style={styles.sTypeColor(l)}>
                                                    /{constantValues.isSellOrBuySignal(l)}
                                                </div>
                                                <HelpIcon signalCode={l} style={styles.helpIcon} />
                                            </div>
                                    )}
                                </div>
                            </div>
                        </Collapse>
                        {i !== shownSignals?.length - 1 &&
                            <div style={styles.divider} />
                        }
                    </div>
                )
            }
        </div>
    )
}

export default SignalSelector