import {
    Navigate
} from "react-router-dom";
import { useSelector } from 'react-redux';
import constantValues from '../../misc/constantValues';

export default function SecureRoute(props) {
    const User = useSelector(state => state.userReducer);
    const isLogged = User?.accessToken && User?.emailConfirmed;

    if (!isLogged) {
        return <Navigate to={constantValues.LOGIN_ROUTE} />
    }

    return props.children
}