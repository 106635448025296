import constantValues from '../../../misc/constantValues';
import { useTheme } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';
import MakeStrategyDialog from './MakeStrategyDialog';
import { useState } from 'react';
import MuiSkeleton from '../../../material-ui/Skeleton/MuiSkeleton';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../../../misc/ConfirmationDialog';

function Table({
    strategies,
    loading,
    didNotLoad,
    getStrategies,
    searchValue,
    createStrategy,
    editStrategy,
    deleteStrategyById,
    result
}) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: mobileSize ? '100%' : '90%',
            marginTop: '2rem',
            paddingBottom: '2rem',
            overflowX: 'auto',
        },
        titleRoot: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        subText: {
            fontSize: '11px',
            width: '100%',
            textAlign: 'left',
            color: constantValues.ICON_COLOR_1,
        },
        titleText: {
            width: '180px',
            textAlign: 'left',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        },
        tableRoot: {
            width: '100%',
        },
        tableHeader: {
            border: 0,
            paddingBottom: '1.5em',
            fontWeight: 'normal',
            color: '#7a7a7a'
        },
        tableData: {
            textAlign: 'center'
        },
        tableDataSignal: {
            display: 'flex',
            flexDirection: 'column',
            whiteSpace: 'nowrap',
        },
        notifOn: {
            color: theme.palette.warning.light,
            width: '1.5em',
            height: '1.5em'
        },
        notifOff: {
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_2 : '#e2e2e2',
            width: '1.5em',
            height: '1.5em'
        },
        editIcon: {
            color: theme.palette.mode === "dark" ?
                theme.palette.primary.main : constantValues.ICON_COLOR_1,
            cursor: 'pointer'
        },
        deleteIcon: {
            color: theme.palette.mode === "dark" ?
                theme.palette.primary.main : constantValues.ICON_COLOR_1,
            cursor: 'pointer',
            marginLeft: '0.4rem',
            marginRight: '0.4rem'
        },
        dataContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
        },
        settingButton: {
            borderRadius: '8px'
        },
        minWidthData: {
            minWidth: '70px'
        },
        timeframeData: {
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            gap: 10
        },
        tableBorder: {
            borderColor: '#d9d9d9'
        }
    }
    /*END: Styles */

    /*For strategy creation */
    const [showDialog, setShowDialog] = useState(false);
    const [strategy, setStrategy] = useState(0)
    /* */

    /*For deleting strategy */
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [strategyId, setStrategyId] = useState(0);
    /* */

    const initiateDelete = (id) => {
        setShowConfirmation(true);
        setStrategyId(id);
    }

    const deleteStrategy = () => {
        deleteStrategyById(strategyId);
        setShowConfirmation(false);
    }

    if (loading || didNotLoad) {
        return (
            <div style={{ width: '100%', marginTop: '1rem' }}>
                <MuiSkeleton
                    variant='rectangular'
                    width={'100%'}
                    height={'400px'}
                    didNotLoad={didNotLoad}
                    reload={() => getStrategies('Strategy')}
                />
            </div>
        )
    }

    const data = strategies?.filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase()));

    return (
        <section style={styles.root}>
            <MakeStrategyDialog
                createStrategy={createStrategy}
                editStrategy={editStrategy}
                loading={loading}
                result={result}
                open={showDialog}
                onClose={() => setShowDialog(false)}
                isEditing={showDialog}
                strategy={strategies && strategies[strategy]}
                getStrategies={getStrategies}
            />
            <ConfirmationDialog
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                submit={deleteStrategy}
            />
            {data?.length > 0 ?
                <table style={styles.tableRoot}>
                    <tr>
                        <th style={styles.tableHeader}>Name</th>
                        <th style={styles.tableHeader}>Timeframe</th>
                        <th style={styles.tableHeader}>Notification</th>
                        <th style={styles.tableHeader}>Signal</th>
                        <th style={styles.tableHeader}>Run</th>
                        <th style={styles.tableHeader}>Edit</th>
                    </tr>
                    {data?.map((d, index) =>
                        <tr key={index}>
                            <td style={{ ...styles.tableData, ...styles.tableBorder }}>
                                <h3>{d.name}</h3>
                            </td>
                            <td style={styles.tableBorder}>
                                <div style={{ ...styles.dataContainer, ...styles.timeframeData }}>
                                    {
                                        d?.timeFrames?.map(t =>
                                            <div>{t}</div>
                                        )
                                    }
                                </div>
                            </td>
                            <td style={styles.tableBorder}>
                                <div style={styles.dataContainer}>
                                    {d.sendSocialNotification ?
                                        <NotificationsIcon style={styles.notifOn} /> :
                                        <NotificationsOffIcon style={styles.notifOff} />
                                    }
                                </div>
                            </td>
                            <td style={styles.tableBorder}>
                                <div style={styles.tableDataSignal}>
                                    {d?.items?.map(s =>
                                        <div key={s}>
                                            {TEXTS[`signal-${s}`] || `Unknown(${s})`}
                                        </div>
                                    )}
                                </div>
                            </td>
                            <td style={styles.tableBorder}>
                                <div style={styles.dataContainer}>
                                    <Link to={constantValues.RUN_STRATEGY_ROUTE.replace(':id', d.id)}>
                                        <Button sx={{ boxShadow: 0 }} style={styles.settingButton} variant='contained'>
                                            <SettingsIcon />
                                        </Button>
                                    </Link>
                                </div>
                            </td>
                            <td style={styles.tableBorder}>
                                <div style={{ ...styles.dataContainer, ...styles.minWidthData }}>
                                    <EditIcon
                                        onClick={() => {
                                            setShowDialog(true);
                                            setStrategy(index);
                                        }}
                                        style={styles.editIcon}
                                    />
                                    <DeleteIcon
                                        style={styles.deleteIcon}
                                        onClick={() => initiateDelete(d.id)}
                                    />
                                </div>
                            </td>
                        </tr>
                    )}
                </table> :
                <div> No Strategy created yet. please make a strategy</div>
            }
        </section>
    )
}

export default Table