import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import { logoutUser } from '../../redux/state/userReducer';
import useRequest from "../../axios/useRequest";
import constantValues from "../../misc/constantValues";
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';

const useDeleteAccount = () => {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const [loading, setLoading] = useState(false);

    const deleteAccount = (Password) => {
        setLoading(true);
        const onSuccess = () => {
            setLoading(false);
            dispatch(logoutUser());
            setTimeout(() => {
                dispatch(showSuccessNotification("Account deleted successfully!"))
            }, 500)
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: "POST",
            url: constantValues.DELETE_ACCOUNT,
            data: { Password },
            onSuccess,
            onFail
        })
    }
    return {
        loading,
        deleteAccount
    }
}


export default useDeleteAccount