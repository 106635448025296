import { useSelector } from "react-redux";
import Localization from "../../../../localization";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import constantValues from "../../../../misc/constantValues";
import useRecovery from "../../../../hooks/User/useRecovery";
import CircularProgress from "../../../../material-ui/CircularProgress/CircularProgress";
import { Link } from 'react-router-dom';

function Form() {
    const { initiatePasswordRecovery, loading } = useRecovery();
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            display: 'flex',
            width: '100%',
            alignItems: 'flex-start',
            flexDirection: 'column',
        },
        inputContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        inputHelper: {
            color: theme.palette.text.disabled,
            fontSize: '11px'
        },
        input: {
            width: '100%',
            backgroundColor: theme.palette.mode === "light" ? 'white' : '#29263a'
        },
        inputRow: {
            width: desktopSize ? '80%' : '100%',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem'
        },
        buttonContainer: {
            width: '100%',
            marginTop: '2rem'
        },
        button: {
            textTransform: 'none',
            width: desktopSize ? '8rem' : '100%',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            borderRadius: '8px'
        },
        errorContainer: {
            fontSize: '11px',
            height: '12px',
            color: theme.palette.error.main
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.warning.light
        }
    }
    /*END: Styles */

    /*BEGIN: Form State */
    const [email, setEmail] = useState("");
    /*BEGIN: Form State */


    return (
        <main style={styles.root}>
            <div style={styles.inputRow}>
                <div style={styles.inputContainer}>
                    <div style={styles.inputHelper}>{TEXTS["email"]}</div>
                    <TextField
                        type='email'
                        size='small'
                        style={styles.input}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant='outlined'
                    />
                    <div style={styles.errorContainer}>

                    </div>
                </div>
            </div>
            <Link style={styles.link} to={constantValues.LOGIN_ROUTE}>{TEXTS['loginPage']}</Link>
            <div style={styles.buttonContainer}>
                <Button
                    onClick={() => initiatePasswordRecovery(email, 'sss')}
                    disabled={loading}
                    style={styles.button}
                    variant='contained'
                    sx={{ boxShadow: 0 }}
                >
                    {TEXTS["sendLink"]}
                    {loading && <CircularProgress />}
                </Button>
            </div>
        </main>
    )
}

export default Form