import { Grid } from "@mui/material";


function Header() {
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        subText: {
            fontSize: '15px',
            lineHeight: '30px',
            marginTop: '1rem'
        }
    }
    return (
        <Grid item xs={12} >
            <div style={styles.container}>
                <h1>
                    Contact Us
                </h1>
                <div style={styles.subText}>
                    For 24/7 support please use our Ticketing Center where the support team can answer your request in a few minutes.
                </div>
            </div>
        </Grid>
    )
}

export default Header