import how_act_works from '../../../media/how-it-works.svg';

function HowACTWorks() {
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        container: {
            width: '100%',
            marginTop: '2rem'
        },
        img: {
            width: '100%',
            height: 'auto'
        }
    }

    return (
        <section style={styles.root}>
            <h1 id={'howItWorks'}>How ACT works?</h1>
            <div style={styles.container}>
                <img src={how_act_works} alt={'how it works'} style={styles.img} />
            </div>
        </section>
    )
}

export default HowACTWorks