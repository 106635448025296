import { CircularProgress } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import useVerifyEmail from "../../hooks/User/useVerifyEmail";
import { useLocation, useNavigate } from 'react-router-dom';
import { showSuccessNotification } from "../../redux/state/notificationReducer";

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

function VerifyEmail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loadingVerify, verifyEmail } = useVerifyEmail();
    const query = useQuery();
    const User = useSelector(state => state.userReducer);
    const styles = {
        root: {
            width: '100%',
            height: '85vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        text: {
            marginTop: '1rem',
            color: 'gray'
        }
    }

    useEffect(() => {
        if (User?.accessToken && User?.emailConfirmed) {
            dispatch(showSuccessNotification('Email Verified!'));
            navigate('/');
        } else {
            let token = query.get('q');
            let email = query.get('email');
            verifyEmail(token, email);
        }
    }, [])
    return (
        <div style={styles.root}>
            <CircularProgress />
            <div style={styles.text}>
                Verifying your email..
            </div>
        </div>
    )
}

export default VerifyEmail