import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function useRecovery() {
    const navigate = useNavigate();
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const createRequest = useRequest();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const initiatePasswordRecovery = (email, captcha) => {
        //BEGIN: Validation
        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            dispatch(showFailNotification('Invalid Email!'));
            return;
        }
        //END: Validation

        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            dispatch(showSuccessNotification('Email sent! please check your mail'));
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'POST',
            data: { email, captcha },
            url: constantValues.INIT_PASSWORD_RECOVERY,
            onSuccess,
            onFail
        })
    }

    const recoverPassword = (Email, RecoveryToken, NewPassword, captcha) => {
        //BEGIN: Validation

        //END: Validation
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            dispatch(showSuccessNotification('Password changed successfully!'));
            navigate(constantValues.LOGIN_ROUTE);
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'POST',
            url: constantValues.RECOVER_PASSWORD,
            data: { Email, RecoveryToken, NewPassword, captcha },
            onSuccess,
            onFail
        })
    }
    return {
        initiatePasswordRecovery,
        recoverPassword,
        loading
    }
}

export default useRecovery