import constantValues from "../../../misc/constantValues";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';
import ShieldIcon from "@mui/icons-material/Shield";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

function PlanHistory({ subscriptionHistory }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'auto',
            paddingBottom: '2em'
        },
        header: {
            marginLeft: !desktopSize ? '0.4rem' : ''
        },
        circle: {
            width: '14px',
            height: '14px',
            borderRadius: '50%',
            backgroundColor: '#f07259',
            marginRight: '0.2rem'
        },
        headerColor: {
            color: theme.palette.mode === 'dark' ? '#cfcfcf' : '#7a7a7a',
            fontWeight: 'normal'
        },
        silverColor: {
            color: '#aeaeae'
        },
        goldColor: {
            color: '#f9cc24'
        }
    }
    const nameToIcon = (name) => {
        switch (name) {
            case 'Silver':
                return <ShieldIcon style={styles.silverColor} />;
            case 'Gold':
                return <EmojiEventsIcon style={styles.goldColor} />;
            case 'Bronze':
                return <div style={styles.circle} />;
            default:
                return <div style={styles.circle} />;
        }
    }

    const calcDuration = (start, end) => {
        let startDate = new Date(start);
        let endDate = new Date(end);
        let diff = endDate.getTime() - startDate.getTime();
        let daysBetween = diff / (1000 * 3600 * 24);
        let roundDaysBetween = Math.round(daysBetween);
        switch (roundDaysBetween) {
            case 30:
                return '1 Month';
            case 90:
                return '3 Months';
            case 180:
                return '6 Months';
            case 365:
                return '1 Year';
            default:
                return '1 Month';
        }
    }

    if (subscriptionHistory?.length === 0) {
        return (
            null
        )
    }
    return (
        <section style={styles.root}>
            <h2 style={styles.header}>{TEXTS['planHistory']}</h2>
            <table width="100%">
                <tr>
                    <th
                        style={styles.headerColor}
                        className="white-space-no-wrap no-border pb-1"
                    >
                        {TEXTS['plan']}
                    </th>
                    <th
                        style={styles.headerColor}
                        className="white-space-no-wrap no-border pb-1"
                    >
                        {TEXTS['duration']}
                    </th>
                    <th
                        style={styles.headerColor}
                        className="white-space-no-wrap no-border pb-1"
                    >
                        {TEXTS['startDate']}
                    </th>
                    <th
                        style={styles.headerColor}
                        className="white-space-no-wrap no-border pb-1"
                    >
                        {TEXTS['endDate']}
                    </th>
                </tr>
                {subscriptionHistory?.map(x =>
                    <tr>
                        <td>
                            <div className="flex-row justify-center gap-small bold">
                                {nameToIcon(x.plan.name)}
                                <div>{x.plan.name}</div>
                            </div>
                        </td>
                        <td className="bold text-align-center white-space-no-wrap">
                            {calcDuration(x.startDateTime, x.endDateTime)}
                        </td>
                        <td className="text-align-center white-space-no-wrap">
                            {new Date(x.startDateTime).toDateString()}
                        </td>
                        <td className="text-align-center white-space-no-wrap">
                            {new Date(x.endDateTime).toDateString()}
                        </td>
                    </tr>
                )}
            </table>
        </section>
    )
}

export default PlanHistory