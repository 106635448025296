import useCountdown from '../hooks/_countdown/useCountdown';

function CountdownTimer({ countdownDate }) {
    const [days, hours, minutes, seconds] = useCountdown(countdownDate);

    const styles = {
        display: 'flex',
        gap: '0.2rem',
        color: 'gray',
        fontSize: '12px'
    }

    if (days + hours + minutes + seconds > 0) {
        return (
            <div style={styles}>
                <div>{minutes > 0 && generateCountdownText('minute', minutes)}</div>
                <div>{minutes > 0 ? ' and ' : ''}</div>
                <div>{generateCountdownText('second', seconds)}</div>
            </div>
        )
    } else {
        return null
    }
}

function generateCountdownText(type, value) {
    switch (type) {
        case 'day':
            return value + (value > 1 ? ' Days ' : ' Day ');
        case 'hour':
            return value + (value > 1 ? ' Hours ' : ' Hour ');
        case 'minute':
            return value + (value > 1 ? ' Minutes ' : ' Minute ');
        case 'second':
            return value + (value > 1 ? ' Seconds ' : ' Second ');
        default:
            return 'Unknown'
    }
}

export default CountdownTimer