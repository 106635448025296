import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

const calculateMonthlyV2Table = (monthsV2) => {
    let result = [];
    let years = [];
    for (var key in monthsV2) {
        years.push(Number(key));
    }
    years.sort((a, b) => b - a);
    years.map(y => {
        result.push({ year: y, ...monthsV2[y] });
    });
    return result
}

const formatPercentNumber = (num) => {
    let result = parseFloat((Math.round(num * 100)).toFixed(2));
    if (result < 0) {
        return `-${Math.abs(result)}` + '%';
    }
    return (result) + '%'
}

function ResultMonthly({ backtest }) {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            marginTop: '2rem',
            alignItems: 'center',
            justifyContent: 'center',
            overflowX: 'auto'
        },
        title: {
            color: '#8f8f8f',
            padding: '1em',
            border: 0,
            textAlign: 'center'
        },
        titleRow: {
            borderBottom: '1px solid gray'
        },
        numColor: (num) => ({
            color: num > 0 ? '#78c9c0' : '#f07259'
        }),
    }
    let monthlyTableData = calculateMonthlyV2Table(backtest?.monthsV2);
    return (
        <section style={styles.root}>
            <Grid container justifyContent="center">
                <Grid item xs={11}>
                    <table dir='ltr' style={{ width: '100%' }}>
                        <tr style={styles.titleRow}>
                            {
                                [
                                    "Dec",
                                    "Nov",
                                    "Oct",
                                    "Sep",
                                    "Aug",
                                    "Jul",
                                    "Jun",
                                    "May",
                                    "Apr",
                                    "Mar",
                                    "Feb",
                                    "Jan",
                                    "Year"
                                ].map(x =>
                                    <th key={x} style={styles.title}>{x}</th>
                                )
                            }
                        </tr>

                        {monthlyTableData.map(x =>
                            <tr key={x.year}>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(y =>
                                    <td
                                        dir='ltr'
                                        key={y}
                                        style={{ ...styles.title, ...styles.numColor(x[y]) }}
                                    >
                                        {formatPercentNumber(x[y])}
                                    </td>
                                )}
                                <td
                                    dir='ltr'
                                    style={styles.title}
                                >
                                    {x.year}
                                </td>
                            </tr>
                        )}
                    </table>
                </Grid>
            </Grid>
        </section>
    )
}

export default ResultMonthly