import Grid from '@mui/material/Grid';
import BillingInformation from "./components/BillingInformation";
import PaymentMethod from "./components/PaymentMethod";
import OrderDetail from "./components/OrderDetail";
import { useEffect, useState } from "react";
import usePlan from "../../hooks/Plan/usePlan";
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Step1 from './components/Step1';

function Payment() {
    const [step, setStep] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('Paypal');
    const { id } = useParams();
    const {
        getPlanById,
        loading: loadingPlan,
        didNotLoad: didNotLoadPlan,
        plan
    } = usePlan();

    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.mode === 'light' ? '#f7f7f7' : '#14121d',
            paddingBottom: mobileSize ? '' : '1em',
            color: theme.palette.text.primary,
            minHeight: desktopSize ? '85.6vh' : '93vh'
        },
        content: {
            width: mobileSize ? '100%' : '85%',
            marginTop: '2rem',
        }
    }
    useEffect(() => {
        getPlanById(id);
    }, []);

    return (
        <main style={styles.root}>
            <div style={styles.content}>
                {
                    step === 0 &&
                    <Step1
                        step={step}
                        setStep={setStep}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                }
                {
                    step === 1 &&
                    <Grid container justifyContent='center'>
                        <Grid item lg={6} xs={11} md={6}>
                            <OrderDetail
                                step={step}
                                setStep={setStep}
                                paymentMethod={paymentMethod}
                                plan={plan}
                            />
                        </Grid>
                    </Grid>
                }
            </div>
        </main>
    )
}

export default Payment