import site_logo from "../media/allcantrade-site-logo.png";
import * as React from 'react';
import { Link, useLocation } from "react-router-dom";
import Localization from "../localization";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import constantValues from "./constantValues";
import Button from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useEffect } from "react";
import Popover from '@mui/material/Popover';
import AntSwitch from '../material-ui/Switch/AntSwitch';
import { changeTheme } from '../redux/state/themeReducer';
import useToken from '../hooks/User/useToken';
import LinearProgress from '../material-ui/LinearProgress/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';

function Header() {
    const { revokeToken, loading: loadingLogout } = useToken();
    const dispatch = useDispatch();
    const location = useLocation();
    const [showDrawer, setShowDrawer] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);

    /*Profile */
    const User = useSelector(state => state.userReducer);
    /* */

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    const ROUTES = User?.accessToken && User?.emailConfirmed ?
        [
            { name: TEXTS["dashboard"], link: constantValues.DASHBOARD_ROUTE },
            { name: TEXTS["strategy"], link: constantValues.STRATEGY_ROUTE },
            { name: TEXTS["backtest"], link: constantValues.BACKTEST_HISTORY_ROUTE },
            { name: TEXTS["favoriteSymbols"], link: constantValues.FAVORITE_COIN_ROUTE },
            { name: TEXTS["tickets"], link: constantValues.TICKETS_OVERVIEW_ROUTE },
            { name: TEXTS["blog"], link: 'https://allcan.trade/blog', type: 'external' },
            { name: TEXTS["plans"], link: constantValues.PLAN_ROUTE },
        ] :
        [
            { name: TEXTS["home"], link: '/', type: '' },
            { name: TEXTS["aboutCompany"], link: constantValues.ABOUT_ROUTE, type: '' },
            { name: TEXTS["companyUsers"], link: '/#features', type: 'id' },
            { name: TEXTS["howItWorks"], link: '/#howItWorks', type: 'id' },
            { name: TEXTS["atAGlance"], link: '/#atAGlance', type: 'id' },
            { name: TEXTS["pricing"], link: '/#pricing', type: 'id' },
            { name: TEXTS["contact"], link: constantValues.CONTACT_ROUTE, type: '' },
            { name: TEXTS["learning"], link: 'https://allcan.trade/blog', type: 'external' },
        ];
    const XS_ROUTES = User?.accessToken && User?.emailConfirmed ?
        [
            { name: TEXTS['profile'], link: constantValues.PROFILE_ROUTE },
            { name: TEXTS["dashboard"], link: constantValues.DASHBOARD_ROUTE },
            { name: TEXTS["strategy"], link: constantValues.STRATEGY_ROUTE },
            { name: TEXTS["backtest"], link: constantValues.BACKTEST_HISTORY_ROUTE },
            { name: TEXTS["favoriteSymbols"], link: constantValues.FAVORITE_COIN_ROUTE },
            { name: TEXTS["tickets"], link: constantValues.TICKETS_OVERVIEW_ROUTE },
            { name: TEXTS["blog"], link: 'https://allcan.trade/blog', type: 'external' },
            { name: TEXTS["plans"], link: constantValues.PLAN_ROUTE },
        ] :
        [
            { name: TEXTS["home"], link: '/', type: '' },
            { name: TEXTS["aboutCompany"], link: constantValues.ABOUT_ROUTE, type: '' },
            { name: TEXTS["companyUsers"], link: '/#features', type: 'id' },
            { name: TEXTS["howItWorks"], link: '/#howItWorks', type: 'id' },
            { name: TEXTS["atAGlance"], link: '/#atAGlance', type: 'id' },
            { name: TEXTS["pricing"], link: '/#pricing', type: 'id' },
            { name: TEXTS["contact"], link: constantValues.CONTACT_ROUTE, type: '' },
            { name: TEXTS["learning"], link: 'https://allcan.trade/blog', type: 'external' },
        ];
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const tabletSize = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const toggleTheme = () => {
        let newTheme = theme.palette.mode === 'dark' ? 'light' : 'dark';
        dispatch(changeTheme(newTheme));
    }
    const styles = ({
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1em',
            paddingBottom: '1em',
            backgroundColor: theme.palette.background.default,
            fontSize: mobileSize ? '12px' : '15px'
        },
        content: {
            width: desktopSize ? constantValues.LAYOUT_WIDTH_LARGE :
                mobileSize ? constantValues.LAYOUT_WIDTH_SMALL :
                    constantValues.LAYOUT_WIDTH_NORMAL,
            display: 'flex',
            alignItems: 'center',
            justifyContent: tabletSize ? 'center' : ''
        },
        logo: {
            width: '150px',
            height: 'auto',
        },
        nav: {
            display: desktopSize ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1
        },
        navLink: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            fontWeight: 600,
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1.3rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1.3rem' : '',
        },
        drawerNavLink: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            fontWeight: 'bold',
        },
        companyName: {
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            color: theme.palette.text.secondary,
            fontWeight: 900,
        },
        auth: {
            display: desktopSize ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1.5rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1.5rem' : '',
        },
        button: {
            textTransform: 'none',
            fontSize: '15px',
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            marginRight: '0.4rem',
            width: tabletSize ? '6rem' : desktopSize ? '130px' : '5rem',
        },
        primaryButton: {
            textTransform: 'none',
            fontSize: '15px',
            width: tabletSize ? '6rem' : '130px',
            borderRadius: '8px',
            fontWeight: 'bold',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
        },
        mobileIconsContainer: {
            display: desktopSize ? 'none' : 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            height: '100%',
            alignItems: 'center'
        },
        menuIcon: {
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            color: theme.palette.mode === 'light' ? '#010101' : 'white'
        },
        belIcon: {
            color: '#a680ba'
        },
        btnLink: {
            textDecoration: 'none'
        },
        profFirstLetter: {
            width: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#a680ba',
            borderRadius: '8px',
            color: 'white',
            marginRight: mobileSize ? '0.4rem' : ''
        },
        profPic: {
            width: '32px',
            heigt: '32px',
            backgroundColor: '#a680ba',
            marginRight: mobileSize ? '0.4rem' : '',
            borderRadius: '50%'
        },
        profFirstNameContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: mobileSize ? 3 : 10,
        },
        profFirstName: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '13px'
        },
        hi: {
            color: '#a680ba'
        },
        belIconDesktop: {
            color: '#a680ba',
            marginRight: '0.4rem'
        },
        expandMoreIcon: {
            color: '#a680ba',
            marginRight: '0.4rem',
            marginLeft: '0.4rem'
        },
        profLink: {
            textDecoration: 'none',
            color: 'inherit'
        },
        personIconLink: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            marginRight: '0.4rem',
            color: '#a680ba'
        },
        popover: {
            width: anchorEl?.offsetWidth,
            pointerEvents: 'all',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '9px',
            paddingTop: '1em',
            paddingBottom: '1em',
            border: `solid 1px ${theme.palette.mode === 'light' ? '#dcdcdc' : '#484464'}`,
            fontSize: mobileSize ? '12px' : '14px',
            color: theme.palette.mode === 'light' ? '#828283' : '#d2d2d2',
            backgroundColor: theme.palette.mode === 'dark' ? '#1d1a2e' : ''
        },
        popoverContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '80%'
        },
        popoverItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        popoverDivider: {
            marginTop: '0.35rem',
            marginBottom: '0.35rem'
        },
        signOutColor: {
            color: theme.palette.mode === 'light' ? '#ba8080' : '#f07a7a'
        },
        signOutBtn: {
            color: 'inherit',
            textTransform: 'none',
            backgroundColor: 'transparent',
            padding: 0,
            marginLeft: (mobileSize) ? '1rem' : '',
            fontWeight: mobileSize ? 'bold' : '',
            fontSize: mobileSize ? '16px' : '',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            justifyContent: constantValues.IS_RTL_LANGUAGE(language) ? 'flex-start' : ''
        },
        signOutPrg: { width: '30px', marginLeft: '0.4rem' },
        closeIconRoot: {
            width: '95%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem'
        },
        profContainerXS: {
            paddingLeft: '1rem',
            marginTop: '1rem',
        }
    });
    /*END: Styles */

    /*BEGIN: render util functions */
    const handlePopoverOpen = (event) => {
        if (desktopSize) {
            setAnchorEl(event.currentTarget);
        }
    }
    const handlePopoverClose = () => {
        setAnchorEl(null);
    }
    /*END: render util functions */

    const handleScroll = () => {
        const href = window.location.href;
        const hrefArr = href.split('/');
        const route = hrefArr[hrefArr.length - 1];
        if (route[0] === '#') {
            const element = window.document.getElementById(route.replace('#',''));
            setTimeout(() => element?.scrollIntoView({ behavior: 'smooth' }), 100);
        }
    }
    useEffect(() => {
        handleScroll();
        setShowDrawer(false);
        handlePopoverClose();
    }, [location]);

    if (location.pathname === constantValues.CHART_ROUTE + '/') {
        return (null)
    }

    const authenticated = Boolean(User?.accessToken && User?.emailConfirmed);
    const profileFetched = Boolean(User?.firstName);

    return (
        <header onMouseLeave={handlePopoverClose} style={styles.root}>
            <Popover
                keepMounted
                open={menuOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                sx={{
                    pointerEvents: 'none',
                }}
                PaperProps={{ style: { borderRadius: '9px' } }}
                disableRestoreFocus
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                container={menuOpen ? anchorEl.parentNode : undefined}
            >
                <div
                    style={styles.popover}
                >
                    <div style={styles.popoverContent}>
                        <Link style={styles.profLink} to={constantValues.PROFILE_ROUTE}>
                            <div style={styles.popoverItem}>
                                {TEXTS['profile']}
                            </div>
                        </Link>
                        <Divider style={styles.popoverDivider} />
                        <div style={{ ...styles.popoverItem, ...styles.signOutColor }}>
                            <Button
                                style={styles.signOutBtn}
                                disabled={loadingLogout}
                                onClick={() => revokeToken()}
                            >
                                {TEXTS['signOut']}
                                {loadingLogout && <LinearProgress style={styles.signOutPrg} />}
                            </Button>
                        </div>
                        <Divider style={styles.popoverDivider} />
                        <div style={styles.popoverItem}>
                            <div>
                                {TEXTS['darkMode']}
                            </div>
                            <AntSwitch checked={theme.palette.mode === 'dark'} onChange={toggleTheme} />
                        </div>
                    </div>
                </div>
            </Popover>
            <div style={styles.content}>
                <Link to={'/'}>
                    <img src={site_logo} style={styles.logo} alt="Site Logo" />
                </Link>
                {/* <h3 style={styles.companyName}>{TEXTS["companyName"]}</h3> */}
                <nav style={styles.nav}>
                    {ROUTES.map(r =>
                        r?.type === 'id' || r?.type === 'external' ?
                            <a
                                target={r.type === 'external' ? '_blank' : ''}
                                rel={"noreferrer"}
                                style={styles.navLink}
                                href={r.link}
                            >
                                {r.name}
                            </a> :
                            <Link key={r.name} style={styles.navLink} to={r.link}>
                                {r.name}
                            </Link>
                    )}
                </nav>
                {!authenticated ?
                    <div style={styles.auth}>
                        <Link style={styles.btnLink} to={constantValues.LOGIN_ROUTE}>
                            <Button
                                style={styles.primaryButton}
                                variant={'contained'}
                            >
                                {TEXTS["login"]}
                            </Button>
                        </Link>
                        {/* <Link style={styles.btnLink} to={constantValues.REGISTER_ROUTE}>
                            <Button
                                style={styles.primaryButton}
                                variant={'contained'}
                            >
                                {TEXTS["register"]}
                            </Button>
                        </Link> */}
                    </div> :
                    <div style={styles.auth}>
                        <div onMouseEnter={handlePopoverOpen} className="flex-row">
                            {/* <NotificationsIcon style={styles.belIconDesktop} fontSize='large' /> */}
                            <div style={{ width: '1rem' }} />
                            <div className="flex-row">
                                <div style={styles.profFirstNameContainer}>
                                    <div style={styles.hi}>
                                        {TEXTS["hi"]},
                                    </div>
                                    <div style={styles.profFirstName}>
                                        {User?.firstName?.slice(0, 6)}
                                        {User?.firstName?.length > 5 ? '..' : ''}
                                    </div>
                                </div>
                                <ExpandMoreIcon style={styles.expandMoreIcon} fontSize='small' />
                                {
                                    User?.profile ?
                                        <img style={styles.profPic} alt='prof' src={User.profile} /> :
                                        <div style={styles.profFirstLetter}>
                                            {User?.firstName && User?.firstName[0]}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div style={styles.mobileIconsContainer}>
                    {/* <Link style={styles.personIconLink} to={constantValues.PROFILE_ROUTE}>
                            <PersonIcon fontSize='large' />
                        </Link> */}
                    {/* <NotificationsIcon style={styles.belIcon} fontSize='large' /> */}
                    <MenuIcon
                        onClick={() => setShowDrawer(true)}
                        style={styles.menuIcon}
                        fontSize='large'
                    />
                    <Drawer
                        anchor={constantValues.IS_RTL_LANGUAGE(language) ? 'left' : 'right'}
                        open={showDrawer}
                        onClose={() => setShowDrawer(false)}
                        PaperProps={{ style: { width: '80%' } }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        variant='temporary'
                    >
                        <div style={styles.closeIconRoot}>
                            <CloseIcon onClick={() => setShowDrawer(false)} fontSize="small" />
                        </div>
                        {!authenticated &&
                            <div className="flex-row justify-center mt-1 flex-wrap">
                                <Link style={styles.btnLink} to={constantValues.LOGIN_ROUTE}>
                                    <Button
                                        style={styles.primaryButton}
                                        variant={'contained'}
                                    >
                                        {TEXTS["login"]}
                                    </Button>
                                </Link>
                                {/* <Link style={styles.btnLink} to={constantValues.REGISTER_ROUTE}>
                                    <Button
                                        style={styles.primaryButton}
                                        variant={'contained'}
                                    >
                                        {TEXTS["register"]}
                                    </Button>
                                </Link> */}
                            </div>
                        }
                        {profileFetched &&
                            <div style={styles.profContainerXS} className="flex-row">
                                {
                                    User?.profile ?
                                        <img style={styles.profPic} alt='prof' src={User.profile} /> :
                                        <div style={styles.profFirstLetter}>
                                            {User?.firstName && User?.firstName[0]}
                                        </div>
                                }
                                <div style={styles.profFirstNameContainer}>
                                    <div style={styles.hi}>
                                        {TEXTS["hi"]},
                                    </div>
                                    <div style={styles.profFirstName}>
                                        {User?.firstName?.slice(0, 6)}
                                        {User?.firstName?.length > 5 ? '..' : ''}
                                    </div>
                                </div>
                            </div>
                        }
                        <List>
                            <Divider style={{ marginTop: '1rem' }} />
                            {XS_ROUTES.map((r, i) =>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {r.type === 'id' || r.type === 'external' ?
                                        <a
                                            href={r.link}
                                            target={r.type === 'external' ? '_blank' : ''}
                                            rel={"noreferrer"}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <ListItem>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        fontWeight: 'bold',
                                                        fontFamily: constantValues.PRIMARY_FONT_FAMILY,
                                                        color: theme.palette.text.primary,
                                                        textDecoration: 'none'
                                                    }}
                                                    primary={r.name}
                                                />
                                            </ListItem>
                                        </a> :
                                        <Link style={styles.drawerNavLink} key={r.name} to={r.link}>
                                            <ListItem>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        fontWeight: 'bold',
                                                        fontFamily: constantValues.PRIMARY_FONT_FAMILY
                                                    }}
                                                    primary={r.name}
                                                />
                                            </ListItem>
                                        </Link>
                                    }
                                    <Divider />
                                </div>
                            )}
                            <ListItem
                                secondaryAction={
                                    <AntSwitch
                                        size="large"
                                        checked={theme.palette.mode === 'dark'}
                                        onChange={toggleTheme}
                                    />
                                }
                            >
                                <ListItemText
                                    primaryTypographyProps={{
                                        fontWeight: 'bold',
                                        fontFamily: constantValues.PRIMARY_FONT_FAMILY
                                    }}
                                    primary={TEXTS['darkMode']}
                                />
                            </ListItem>
                            <Divider />
                        </List>
                        {authenticated &&
                            <div style={{ ...styles.popoverItem, ...styles.signOutColor }}>
                                <Button
                                    style={styles.signOutBtn}
                                    disabled={loadingLogout}
                                    onClick={() => revokeToken()}
                                >
                                    {TEXTS['signOut']}
                                    {loadingLogout && <LinearProgress style={styles.signOutPrg} />}
                                </Button>
                            </div>
                        }
                    </Drawer>
                </div>
            </div>
        </header>
    )
}

export default Header