import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import MarketOverAll from "../../misc/MarketOverAll";
import TradingViewChart from "../../misc/TradingViewChart";

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

function Chart() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));

    const query = useQuery();
    let symbol = query.get('symbol');
    let timeframe = query.get('timeframe');
    let hash = query.get('hash');

    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
    }

    return (
        <main style={styles.root}>
            <TradingViewChart
                symbol={symbol}
                interval={timeframe}
                hash={hash}
                height={'100vh'}
            />
        </main>
    )
}

export default Chart