import Grid from '@mui/material/Grid';
import constantValues from '../../../../misc/constantValues';
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import Localization from "../../../../localization";
import HelpIcon from '../../../../material-ui/Icons/HelpIcon';
import btc_logo from '../../../../media/btc-logo.png';
import useMediaQuery from '@mui/material/useMediaQuery';

const formatPercentNumber = (num) => {
    let result = parseFloat((Math.round(num * 100)).toFixed(2));
    if (result < 0) {
        return `-${Math.abs(result)}` + '%';
    }
    return (result) + '%'
}

function ResultSummary({ backtest }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            display: 'flex',
            width: '100%'
        },
        smallBoldText: {
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            marginTop: '2rem',
            fontSize: mobileSize ? '12px' : '14px'
        },
        helpIcon: {
            marginLeft: '0.4rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
        },
        tableRoot: {
            width: '100%',
            marginTop: '2rem',
        },
        tableContainer: {
            width: '100%',
            overflowX: 'auto'
        },
        tableHeader: {
            border: 0,
            padding: '1em',
            color: '#7a7a7a',
            whiteSpace: 'nowrap'
        },
        tableData: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold'
        },
        coinLogo: {
            width: '32px',
            height: '32px',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.4rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.4rem' : ''
        },
        coinShortName: {
            color: '#cccccc',
            fontSize: '12px',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.2rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '0.2rem' : '',
            marginTop: '0.2rem'
        },
        numColor: (num) => ({
            color: num > 0 ? '#78c9c0' : '#f07259'
        })
    };
    /*END: Styles */
    return (
        <section style={styles.root}>
            <Grid container justifyContent="center">
                <Grid item xs={11}>
                    <div style={styles.smallBoldText}>
                        <div>{TEXTS['summaryOfResults']}:</div>
                        <HelpIcon style={styles.helpIcon} />
                    </div>
                </Grid>
                <Grid item xs={11}>
                    <div style={styles.tableContainer}>
                        <table style={styles.tableRoot}>
                            <tr>
                                <th style={styles.tableHeader}>{TEXTS['coin']}</th>
                                <th style={styles.tableHeader}>{TEXTS['number']}</th>
                                <th style={styles.tableHeader}>{TEXTS['successRate']}</th>
                                <th style={styles.tableHeader}>{TEXTS['startDate']}</th>
                                <th style={styles.tableHeader}>{TEXTS['endDate']}</th>
                                <th style={styles.tableHeader}>{TEXTS['profitAndLoss']}</th>
                            </tr>
                            {backtest?.perSymbols?.map(x =>
                                <tr>
                                    <td>
                                        <div style={styles.tableData}>
                                            <img
                                                style={styles.coinLogo}
                                                src={`/media/${x.symbolName.replace('USDT', '').toLowerCase()}.svg`}
                                                alt={x.symbolName}
                                            />
                                            <div>
                                                {x.symbolName.replace('USDT', '')}
                                            </div>
                                            <div style={styles.coinShortName}>
                                                {'USDT'}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div style={styles.tableData}>
                                            {x.count}
                                        </div>
                                    </td>
                                    <td>
                                        <div dir='ltr' style={{ ...styles.tableData, ...styles.numColor(x.successPercent) }}>
                                            {formatPercentNumber(x.successPercent)}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={styles.tableData}>
                                            {new Date(x.startDateTime).toLocaleDateString()}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={styles.tableData}>
                                            {new Date(x.endDateTime).toLocaleDateString()}
                                        </div>
                                    </td>
                                    <td>
                                        <div dir='ltr' style={{ ...styles.tableData, ...styles.numColor(x.gainLoss) }}>
                                            {formatPercentNumber(x.gainLoss)}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </table>
                    </div>
                </Grid>
            </Grid>
        </section>
    )
}

export default ResultSummary