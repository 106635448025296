/* Action Types */
const CHANGE_THEME = "CHANGE_THEME";

/* Actions */
export function changeTheme(data){
    return {
        type: CHANGE_THEME,
        payload: data
    }
}

/* Reducer: light || dark */
const initialState = "light";
export function themeReducer(state = initialState, action){
    switch(action.type){
        case CHANGE_THEME:
            return action.payload;
        default:
            return state;    
    }
}