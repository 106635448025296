import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { loginUser } from '../../redux/state/userReducer';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function useLogin() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const createRequest = useRequest();
    const dispatch = useDispatch();

    const validateForm = (username, password, captcha) => {
        let errors = [];
        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(username)) {
            errors.push({
                field: 'email',
                message: 'Invalid email'
            })
        }
        if (password.length < 6) {
            errors.push({
                field: 'password',
                message: 'Please write your password completely'
            })
        }

        return errors;
    }

    const login = (username, password, captcha) => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            dispatch(loginUser(response.data.data));
            // dispatch(showSuccessNotification("Login Successful!"))
            // navigate('/');
        }
        const onFail = (error) => {
            setLoading(false);

            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            url: constantValues.LOGIN_USER,
            data: { username, password, captcha },
            method: 'POST',
            onSuccess,
            onFail
        })
    }

    const onGoogleLoginSuccess = (response) => {
        let accessToken = response.credential;

        const formData = new FormData();
        formData.append("credential", accessToken);
        formData.append("g_csrf_token", '_');

        fetch(constantValues.GOOGLE_LOGIN_CALLBACK, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                if (response.isSuccess) {
                    dispatch(loginUser(response.data));
                } else {
                    dispatch(showFailNotification("Auth Failed To Exchange Token"));
                }
            })
            .catch(error => {
                dispatch(showFailNotification("Auth Failed To Exchange Token"));
            })
    }

    const onGoogleLoginError = (error) => {
        console.log(error)
        dispatch(showFailNotification("Google Auth Failed"));
    }

    return { login, loading, validateForm, onGoogleLoginSuccess, onGoogleLoginError }
}

export default useLogin