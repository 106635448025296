import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from '../../../../localization';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import constantValues from '../../../../misc/constantValues';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import OutlinedTextField from '../../../../material-ui/TextField/OutlinedTextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '../../../../material-ui/CircularProgress/CircularProgress';
import { showFailNotification } from '../../../../redux/state/notificationReducer';

function NewTicketDialog({ open, onClose, loading, createTicket, resetResult, result }) {
    const dispatch = useDispatch();
    /*BEGIN: Application State */
    const [category, setCategory] = React.useState(0);
    const [title, setTitle] = React.useState("");
    const [priority, setPriority] = React.useState(0);
    const [text, setText] = React.useState("");
    const [attachments, setAttachments] = React.useState([]);
    const attachButtonRef = React.useRef(null);
    const priorities = [
        "Urgent",
        "High",
        "Medium",
        "Low"
    ];
    const categories = [
        "Technical",
        "Financial"
    ];
    const onCreateTicket = () => {
        createTicket(
            categories[category],
            priorities[priority],
            title,
            text,
            attachments
        )
    }
    const onRemoveAttachment = (index) => {
        let result = attachments.filter((x, i) => i !== index);
        setAttachments(result);
    }
    const onChangeAttachments = ({ target }) => {
        let file = target.files[0];
        let reader = new FileReader();
        reader.onload = function () {
            if ((file.size / 1024) > constantValues.MAX_FILE_SIZE_IN_KB) {
                dispatch(showFailNotification(
                    `File size can't be more than ${constantValues.MAX_FILE_SIZE_IN_KB} KB`
                ))
                return;
            }
            let newAttach = {
                FileName: file.name,
                ContentType: file.type,
                Content: reader.result.split(',')[1]
            }
            setAttachments(prev => [...prev, newAttach])
        }
        reader.readAsDataURL(file);
    }
    const onInitiateAttachment = () => {
        attachButtonRef.current.click();
    }
    /*END: Application State */

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        header: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '0.4rem'
        },
        inputsRoot: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        inputContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            gap: 20,
            marginTop: '1rem'
        },
        inputTitle: {
            color: theme.palette.mode === "dark" ? '#bbbbbb' : '#7c7c7c'
        },
        outlinedInputRoot: {
            width: '100%',
            maxWidth: '70%'
        },
        attachmentsContainer: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            marginTop: '1rem',
            gap: 20
        },
        addAttachmentButton: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            textTransform: 'none',
            backgroundColor: theme.palette.mode === "light" ? constantValues.ICON_COLOR_1 : '#736e93'
        },
        attachedItemButton: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            textTransform: 'none',
            padding: '0.6em',
            fontSize: '13px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px',
            backgroundColor: theme.palette.mode === "light" ? '#e6e6e6' : '#484464',
            color: theme.palette.mode === "light" ? '#848484' : '#a6a6a6'
        },
        addAttachButtonIcon: {
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.2rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.2rem' : ''
        },
        attachedItemButtonIcon: {
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.2rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.2rem' : '',
            cursor: 'pointer'
        },
        primaryButtonContainer: {
            width: '100%',
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        primaryButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '10rem'
        }
    }
    /*END: Styles */
    const resetInputs = () => {
        setCategory(0);
        setTitle('');
        setPriority(0);
        setText('');
        setAttachments([]);
    }
    const onDialogClose = () => {
        onClose();
        resetInputs();
    }

    React.useEffect(() => {
        if (result?.success) {
            resetInputs();
            resetResult();
        }
    }, [result]);

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            maxWidth={'xs'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>
                    <div style={styles.header}>
                        <h2>{TEXTS["newTicket"]}</h2>
                        <IconButton onClick={onClose}>
                            <CancelIcon
                                style={
                                    themeReducer === "dark" ?
                                        { color: constantValues.ICON_COLOR_2 } : undefined}
                                color='disabled'
                            />
                        </IconButton>
                    </div>

                    <div style={styles.inputsRoot}>

                        {/*BEGIN: Category */}
                        <div style={styles.inputContainer}>
                            <div style={styles.inputTitle}>
                                {TEXTS["category"]}:
                            </div>
                            <OutlinedTextField
                                onChange={(e) => setCategory(e.target.value)}
                                value={category}
                                style={styles.outlinedInputRoot}
                                size='small'
                                select
                                placeholder={TEXTS["category"]}
                            >
                                {categories.map((c, index) =>
                                    <MenuItem key={c} value={index}>
                                        {c}
                                    </MenuItem>
                                )}
                            </OutlinedTextField>
                        </div>
                        {/*END: Category */}

                        {/*BEGIN: Title */}
                        <div style={styles.inputContainer}>
                            <div style={styles.inputTitle}>
                                {TEXTS["title"]}:
                            </div>
                            <OutlinedTextField
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                style={styles.outlinedInputRoot}
                                size='small'
                                placeholder={TEXTS["title"]}
                            />
                        </div>
                        {/*END: Title */}

                        {/*BEGIN: Priority */}
                        <div style={styles.inputContainer}>
                            <div style={styles.inputTitle}>
                                {TEXTS["priority"]}:
                            </div>
                            <OutlinedTextField
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                                style={styles.outlinedInputRoot}
                                size='small'
                                select
                                placeholder={TEXTS["priority"]}
                            >
                                {priorities.map((p, index) =>
                                    <MenuItem key={p} value={index}>
                                        {p}
                                    </MenuItem>
                                )}
                            </OutlinedTextField>
                        </div>
                        {/*END: Priority */}

                        {/*BEGIN: Text */}
                        <div style={styles.inputContainer}>
                            <div style={styles.inputTitle}>
                                {TEXTS["text"]}:
                            </div>
                            <OutlinedTextField
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                style={styles.outlinedInputRoot}
                                placeholder={TEXTS["text"]}
                                multiline
                                rows={6}
                            />
                        </div>
                        {/*END: Text */}

                        {/*BEGIN: Attachments */}
                        <div style={styles.attachmentsContainer}>
                            <Button onClick={onInitiateAttachment} variant='contained' style={styles.addAttachmentButton}>
                                <AddIcon style={styles.addAttachButtonIcon} />
                                {TEXTS["attach"]}
                                <input
                                    ref={attachButtonRef}
                                    hidden
                                    type='file'
                                    multiple
                                    accept={'image/*'}
                                    onChange={onChangeAttachments}
                                />
                            </Button>
                            {
                                attachments.map((a, index) =>
                                    <div variant='contained' style={styles.attachedItemButton}>
                                        <CloseIcon
                                            onClick={() => onRemoveAttachment(index)}
                                            fontSize='small'
                                            style={styles.attachedItemButtonIcon}
                                        />
                                        {a.FileName}
                                    </div>
                                )
                            }
                        </div>
                        {/*END: Attachments */}

                        <div style={styles.primaryButtonContainer}>
                            <Button
                                onClick={onCreateTicket}
                                disabled={loading}
                                variant='contained'
                                style={styles.primaryButton}
                            >
                                {TEXTS["send"]}
                                {loading && <CircularProgress />}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default NewTicketDialog