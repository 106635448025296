import { useTheme } from '@mui/material/styles';
import MarketOverAll from '../../misc/MarketOverAll';
import Header from './components/Header';
import Table from './components/Table';
import useStrategy from '../../hooks/Strategy/useStrategy';
import { useEffect, useState } from 'react';

function Strategy() {
    const {
        getStrategies,
        loading,
        strategies,
        didNotLoad,
        createStrategy,
        editStrategy,
        result,
        deleteStrategyById
    } = useStrategy();
    const [searchValue, setSearchValue] = useState('');
    /*BEGIN: Styles */
    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: '15px',
            minHeight: '88.4vh'
        }
    }
    /*END: Styles */

    useEffect(() => {
        getStrategies('Strategy');
    }, []);

    useEffect(() => {
        if (result?.success) {
            if (result?.shouldFetchStrategies && !loading) {
                getStrategies('Strategy');
            }
        }
    }, [result]);

    return (
        <main style={styles.root}>
            {/*Overall market data*/}
            <MarketOverAll />
            {/*Header */}
            <Header
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                getStrategies={getStrategies}
                createStrategy={createStrategy}
                result={result}
                strategies={strategies}
                loading={loading}
            />
            {/*Table */}
            <Table
                createStrategy={createStrategy}
                editStrategy={editStrategy}
                result={result}
                searchValue={searchValue}
                getStrategies={getStrategies}
                strategies={strategies}
                loading={loading}
                didNotLoad={didNotLoad}
                deleteStrategyById={deleteStrategyById}
            />
        </main>
    )
}

export default Strategy