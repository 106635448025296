import React from 'react';
import ReactDOM from 'react-dom/client';
import "./fonts/Poppins-Regular.ttf";
import './index.css';
import reportWebVitals from './reportWebVitals';
import ApplicationRouter from "./router";
import Theme from "./material-ui/Theme";
import ReduxGate from './redux/ReduxGate';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import constantValues from './misc/constantValues';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={constantValues.GOOGLE_LOGIN_CLIENT_ID}>
      <Provider store={store}>
        <ReduxGate>
          <Theme>
            <ApplicationRouter />
          </Theme>
        </ReduxGate>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
