import { useTheme } from '@mui/material/styles';
import MarketOverAll from '../../misc/MarketOverAll';
import CoinDetails from './components/CoinDetails';
import CoinChart from './components/CoinChart';
import CoinTable from './components/CoinTable';
import { useEffect, useState } from 'react';
import constantValues from '../../misc/constantValues';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";

function Coin() {
    const User = useSelector(state => state.userReducer);
    let { name } = useParams();
    const [timeframe, setTimeframe] = useState(
        User?.accessToken ? constantValues.DEFAULT_CRYPTO_TIMEFRAME : '1W'
    );
    /*BEGIN: Styles */
    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: '15px'
        }
    }
    /*END: Styles */

    const parsedName = name.replace('USDT', '');

    return (
        <main style={styles.root}>
            <Helmet>
                <title>{parsedName} signals | allcan.trade</title>
                <meta name="keywords" content={`${parsedName} free signal, ${parsedName} price, ${parsedName} chart`} />
            </Helmet>
            {/*Overall market data*/}
            <MarketOverAll />
            {/*Coin Details */}
            <CoinDetails />
            {/*Chart */}
            <CoinChart timeframe={timeframe} symbol={name} />
            {/*Table */}
            <CoinTable timeframe={timeframe} setTimeframe={setTimeframe} />
        </main>
    )
}

export default Coin