import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from '../localization';
import { useSelector } from 'react-redux';
import constantValues from './constantValues';
import WarningIcon from "../media/warning.svg";

function ConfirmationDialog({ open, onClose, submit }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1.5em',
            paddingBottom: '1.5em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            marginTop: '2rem'
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '7rem'
        },
        warningIcon: {
            color: 'yellow'
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: 10,
            fontSize: '17px',
            fontWeight: 'bold'
        },
        text: {
            width: '100%',
            marginTop: '1rem',
            fontSize: '13px'
        },
        cancelButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '7rem',
            backgroundColor: '#ebebeb',
            color: 'black',
            border: 'none'
        }
    }
    /*END: Styles */
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'xs'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>
                    <div style={styles.title}>
                        <img src={WarningIcon} alt={'warning'} style={styles.warningIcon} />
                        <div>
                            Are you sure?
                        </div>
                    </div>
                    <div style={styles.text}>
                        Are you sure you want to delete this strategy?
                    </div>
                    <div style={styles.actions}>
                        <Button
                            onClick={onClose}
                            style={styles.cancelButton}
                            variant='outlined'
                        >
                            No, Cancel
                        </Button>
                        <Button
                            onClick={submit}
                            style={styles.button}
                            variant='contained'
                        >
                            Yes, Delete
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ConfirmationDialog