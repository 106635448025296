import Grid from '@mui/material/Grid';
import easy_to_use from "../../../media/easy-to-use.webp";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function EasyToUse() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%'
        },
        imgContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: !desktopSize ? '1rem' : ''
        },
        subText: {
            color: '#808080'
        },
        margin: {
            width: '100%',
            marginTop: '1.5rem'
        },
        img: {
            width: '70%',
            height: 'auto'
        }
    }
    return (
        <Grid container>
            <Grid item xs={12} lg={6} sm={6}>
                <div style={styles.container}>
                    <h1>
                        ACT is easy to use
                    </h1>
                    <div style={styles.margin} />
                    <div style={styles.subText}>
                        ACT recognizes the trends and patterns. It draws the guidelines automatically based on the most popular technical algorithms. So you have all you need for trading. Just choose your best trade.
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} lg={6} sm={6}>
                <div style={styles.imgContainer}>
                    <img style={styles.img} src={easy_to_use} alt='easy to use' />
                </div>
            </Grid>
        </Grid>
    )
}

export default EasyToUse