function ErrorParser(error) {
    let responseErrorList = error?.response?.data?.errors;
    let responseErrorMessage = error?.response?.data?.message;

    if (responseErrorMessage?.length > 0) {

        return responseErrorMessage;
        
    } else if (responseErrorList) {
        let keys = Object.keys(responseErrorList);
        let k1 = keys[0];
        // dispatch(showFailNotification(responseErrorList[k1]));
        return responseErrorList[k1];
    } else if (error?.message?.length > 0 && typeof error?.message === 'string') {
        return error.message
    } else {
        return 'Connection Error'
    }
}

export default ErrorParser