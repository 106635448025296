import * as React from 'react';
import star_icon from './disable-Icon awesome-star.png';
import star_icon_filled from './Icon-awesome-star.png'

function StarIcon({ isFavorite, style, onClick }) {
    return (
        <img
            src={isFavorite ? star_icon_filled : star_icon}
            alt='isFavorite'
            style={{ cursor: 'pointer', ...style }}
            onClick={onClick}
        />
    )
}

export default StarIcon