import OutlinedTextField from '../../../../material-ui/TextField/OutlinedTextField';
import Button from '@mui/material/Button';
import constantValues from '../../../../misc/constantValues';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Localization from '../../../../localization';
import CircularProgress from '../../../../material-ui/CircularProgress/CircularProgress';
import { showFailNotification } from '../../../../redux/state/notificationReducer';

function Actions({ getTicketById, createConversation, loading, result }) {
    const dispatch = useDispatch();
    const { id } = useParams();
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [text, setText] = useState('');
    const [attachments, setAttachments] = useState([]);
    const attachButtonRef = useRef(null);
    /*BEGIN: Styles */
    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
            minHeight: '200px'
        },
        textfieldRoot: {
            width: '100%',
        },
        actions: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '1rem'
        },
        sendButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '7rem'
        },
        attachButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            backgroundColor: theme.palette.mode === "light" ? constantValues.ICON_COLOR_1 : '',
            marginRight: '1rem'
        },
        attachmentsContainer: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            marginTop: '1rem',
            gap: 20
        },
        addAttachmentButton: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            textTransform: 'none',
            backgroundColor: theme.palette.mode === "light" ? constantValues.ICON_COLOR_1 : '#736e93'
        },
        addAttachButtonIcon: {
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.2rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.2rem' : ''
        },
        attachedItemButton: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            textTransform: 'none',
            padding: '0.6em',
            fontSize: '13px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px',
            backgroundColor: theme.palette.mode === "light" ? '#e6e6e6' : '#484464',
            color: theme.palette.mode === "light" ? '#848484' : '#a6a6a6'
        },
        attachedItemButtonIcon: {
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.2rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.2rem' : '',
            cursor: 'pointer'
        },
    }
    /*END: Styles */
    const onInitiateAttachment = () => {
        attachButtonRef.current.click();
    }
    const onChangeAttachments = ({ target }) => {
        let file = target.files[0];
        let reader = new FileReader();
        reader.onload = function () {
            if ((file.size / 1024) > constantValues.MAX_FILE_SIZE_IN_KB) {
                dispatch(showFailNotification(
                    `File size can't be more than ${constantValues.MAX_FILE_SIZE_IN_KB} KB`
                ))
                return;
            }
            let newAttach = {
                FileName: file.name,
                ContentType: file.type,
                Content: reader.result.split(',')[1]
            }
            setAttachments(prev => [...prev, newAttach])
        }
        reader.readAsDataURL(file);
    }
    const onRemoveAttachment = (index) => {
        let result = attachments.filter((x, i) => i !== index);
        setAttachments(result);
    }
    const onCreateConversation = () => {
        createConversation(
            id,
            text,
            attachments
        )
    }

    useEffect(() => {
        if(result?.success){
            getTicketById();
            setText('');
            setAttachments([]);
        }
    }, [result])

    return (
        <section style={styles.root}>
            <OutlinedTextField
                value={text}
                onChange={(e) => setText(e.target.value)}
                minRows={10}
                placeholder={'write your comment'}
                style={styles.textfieldRoot}
                multiline
                variant='outlined'
            />
            {/*BEGIN: Attachments */}
            <div style={styles.attachmentsContainer}>
                <Button
                    onClick={onInitiateAttachment}
                    variant='contained'
                    style={styles.addAttachmentButton}
                >
                    <AddIcon style={styles.addAttachButtonIcon} />
                    {TEXTS["attach"]}
                    <input
                        ref={attachButtonRef}
                        hidden
                        type='file'
                        multiple
                        accept={'image/*'}
                        onChange={onChangeAttachments}
                    />
                </Button>
                {
                    attachments?.map((a, index) =>
                        <div variant='contained' style={styles.attachedItemButton}>
                            <CloseIcon
                                onClick={() => onRemoveAttachment(index)}
                                fontSize='small'
                                style={styles.attachedItemButtonIcon}
                            />
                            {a.FileName}
                        </div>
                    )
                }
                <Button
                    onClick={onCreateConversation}
                    disabled={loading}
                    variant='contained'
                    style={styles.sendButton}
                >
                    {TEXTS["send"]}
                    {loading && <CircularProgress />}
                </Button>
            </div>
            {/*END: Attachments */}
        </section>
    )
}

export default Actions