import { createContext } from "react";
import usePriceSocket from "../hooks/Sockets/usePriceSocket";

export const SocketContext = createContext();

function SocketContextProvider({ children }) {
    const { result, isConnected, connectPrices, disconnectPrices } = usePriceSocket();
    //{ result, isConnected, connectPrices, disconnectPrices }
    return (
        <SocketContext.Provider value={{ result, isConnected, connectPrices, disconnectPrices }}>
            {children}
        </SocketContext.Provider>
    )
}

export default SocketContextProvider