import * as React from 'react';
import { useEffect, useMemo } from 'react';
import constantValues from '../../../misc/constantValues';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';
import localStorage from 'redux-persist/es/storage';

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const removeCookies = () => {
    localStorage.removeItem('tvlocalstorage.available');
    localStorage.removeItem('tv.logger.loglevel');
    localStorage.removeItem('tv.logger.logHighRate');
}


const TradingViewChart = ({ symbol, timeframe }) => {
    const query = useQuery();
    const themeQuery = query.get('theme');
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        let defaultProps = {
            fullscreen: true,
            symbol: 'BTC',
            interval: constantValues.timeFrameToInterval(timeframe),
            container: "tv_coin_chart",
            library_path: "/charting_library/",
            locale: "en",
        };
        const widgetOptions = {
            symbol: symbol || defaultProps.symbol,
            // BEWARE: no trailing slash is expected in feed URL
            datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
                constantValues.CHART_DATA_FEED
            ),
            interval: defaultProps.interval,
            container: "tv_coin_chart",
            library_path: '/charting_library/',
            locale: 'en',
            disabled_features: [
                'use_localstorage_for_settings',
                'save_chart_properties_to_local_storage',
                'google_analytics',
                'header_screenshot',
                'header_saveload',
                'timeframes_toolbar',
                'items_favoriting',
                'localstorage',
                'chart_template_storage',
            ],
            chart_storage_url: 'http://saveload.tradingview.com',
            charts_storage_api_version: '1.0',
            load_last_chart: false,
            settings_adapter: {
                initialSettings: {},
                setValue: function (key, value) {
                    //
                },
                removeValue: function (key) {

                },
            },
            save_load_adapter: {

            },
            autosize: true,
            theme: themeQuery || theme.palette.mode
        };

        const tvWidget = new window.TradingView.widget(widgetOptions);
        window.tvWidget = tvWidget;

        setTimeout(() => removeCookies(), 170);
        setTimeout(() => removeCookies(), 200);
        tvWidget.onChartReady(() => {
            removeCookies();
        })


    }, [symbol, timeframe, theme.palette.mode]);


    return (
        <div
            style={{
                height: mobileSize ? 'calc(70vh - 85px)' : 'calc(85vh - 85px)',
                width: mobileSize ? '100%' : '93%'
            }}
            id="tv_coin_chart"
            className={'TVChartContainer'}
        />
    );
}

export default TradingViewChart