/* Action Types */
const SHOW_SUCCESS_NOTIFICATION = "SHOW_SUCCESS_NOTIFICATION";
const SHOW_FAIL_NOTIFICATION = "SHOW_FAIL_NOTIFICATION";
const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

/* Actions */
export function showSuccessNotification(message = "Successful!") {
    return {
        type: SHOW_SUCCESS_NOTIFICATION,
        message
    }
};

export function showFailNotification(message = "Failed") {
    return {
        type: SHOW_FAIL_NOTIFICATION,
        message
    }
}

export function hideNotification() {
    return {
        type: HIDE_NOTIFICATION,
    }
}

/* Reducer */
const initialState = { message: "", show: false, severity: 'success' };
export function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_SUCCESS_NOTIFICATION:
            return { message: action.message, show: true, severity: 'success' };
        case SHOW_FAIL_NOTIFICATION:
            return { message: action.message, show: true, severity: 'error' };
        case HIDE_NOTIFICATION:
            return {...initialState, severity: state.severity};
        default:
            return state;
    }
}