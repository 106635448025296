import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import Localization from "../../../../localization";
import { Link } from 'react-router-dom';
import constantValues from '../../../../misc/constantValues';
import MuiSkeleton from '../../../../material-ui/Skeleton/MuiSkeleton';


function Table({ tickets, loading, didNotLoad, getTickets }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            overflowX: 'auto',
            marginTop: '3rem',
            whiteSpace: 'nowrap'
        },
        tableRoot: {
            width: '100%'
        },
        tableHeader: {
            border: 0,
            paddingBottom: '1.5em',
            fontWeight: 'normal',
            color: theme.palette.mode === "dark" ? '#c2c2c2' : '#7a7a7a'
        },
        tableData: {
            paddingTop: '1em',
            paddingBottom: '1em',
            textAlign: 'center',
        },
        priority: (type) => {
            switch (type) {
                case "Urgent":
                    return { color: 'rgb(240,114,89)' };
                case "Medium":
                    return { color: 'rgb(249,204,36)' };
                case "Low":
                    return { color: 'rgb(192,230,226)' };
                default:
                    return { color: 'gray' }
            }
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            fontWeight: 'bold'
        },
        fontWeight: {
            fontWeight: 'bold'
        }
    }
    /*END: Styles */
    return (
        <section style={styles.root}>
            {
                loading || didNotLoad ?
                    <MuiSkeleton
                        variant='rectangular'
                        width={'100%'}
                        height={300}
                        didNotLoad={didNotLoad}
                        reload={getTickets}
                    /> :
                    tickets.length > 0 ?
                        <table style={styles.tableRoot}>
                            <tr>
                                <th style={styles.tableHeader}></th>
                                <th style={styles.tableHeader}>{TEXTS["title"]}</th>
                                <th style={styles.tableHeader}>{TEXTS["priority"]}</th>
                                <th style={styles.tableHeader}>{TEXTS["category"]}</th>
                                <th style={styles.tableHeader}></th>
                                <th style={styles.tableHeader}>{TEXTS["status"]}</th>
                            </tr>
                            {tickets.map((x, index) =>
                                <tr key={x.title + index}>
                                    <td style={{ ...styles.tableData, ...styles.fontWeight }}>{index + 1}</td>
                                    <td style={styles.tableData}>
                                        <Link
                                            style={styles.link}
                                            to={constantValues.TICKET_DETAIL_ROUTE.replace(':id', x.id)}>
                                            {x.title}
                                        </Link>
                                    </td>
                                    <td style={{ ...styles.tableData, ...styles.priority(x.priority) }}>
                                        {x.priority}
                                    </td>
                                    <td style={styles.tableData}>
                                        {x.category}
                                    </td>
                                    <td style={styles.tableData}>
                                        {x.createdAt && new Date(x.createdAt).toLocaleDateString()}
                                    </td>
                                    <td style={styles.tableData}>
                                        {x.status}
                                    </td>
                                </tr>
                            )}
                        </table> :
                        <div>
                            No tickets have been issued
                        </div>
            }
        </section>
    )
}

export default Table