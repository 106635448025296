import en from "./languages/en.json";
import fa from "./languages/fa.json";

export default function Localization(language) {
    switch(language){
        case "en":
            return en;
        case "fa":
            return fa;  
        default:
            return en;
    }
}
