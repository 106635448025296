import { Grid } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import constantValues from "../../../misc/constantValues";
import { useSelector } from 'react-redux';
import Localization from '../../../localization';
import OutlinedTextField from "../../../material-ui/TextField/OutlinedTextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import useContact from "../../../hooks/contact/useContact";
import CircularProgress from '../../../material-ui/CircularProgress/CircularProgress';

function Form() {
    const { loading, contactCompany } = useContact();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setSubject('');
        setMessage('');
    }

    const onSubmit = () => {
        contactCompany(
            firstName,
            lastName,
            email,
            subject,
            message,
            resetForm
        )
    }

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
            fontSize: '14px',
            color: '#7c7c7c',
            gap: 40
        },
        inputContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        halfInputContainer: {
            width: mobileSize ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column'
        },
        halfContainer: {
            width: mobileSize ? '100%' : '50%',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
            gap: 10,
            color: '#7c7c7c',
            fontSize: '14px',
        },
        halfInput: {
            width: '100%'
        },
        input: {
            width: '100%'
        },
        inputTitle: {
            marginBottom: '0.4rem'
        },
        btn: {
            marginTop: '1rem',
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '8rem',
            borderRadius: '8px'
        }
    }
    return (
        <Grid item xs={12} >
            <div style={styles.container}>

                <div style={styles.inputContainer}>
                    <div style={styles.inputTitle}>{TEXTS['firstName']}</div>
                    <OutlinedTextField
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        style={styles.input}
                        size="small"
                    />
                </div>

                <div style={styles.inputContainer}>
                    <div style={styles.inputTitle}>{TEXTS['lastName']}</div>
                    <OutlinedTextField
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        style={styles.input}
                        size="small"
                    />
                </div>

            </div>

            <div style={styles.container}>
                <div style={styles.inputContainer}>
                    <div style={styles.inputTitle}>{TEXTS['email']}</div>
                    <OutlinedTextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={styles.input}
                        size="small"
                    />
                </div>
            </div>

            <div style={styles.container}>
                <div style={styles.inputContainer}>
                    <div style={styles.inputTitle}>Subject</div>
                    <OutlinedTextField
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        style={styles.input}
                        size="small"
                    />
                </div>
            </div>

            <div style={styles.container}>
                <div style={styles.inputContainer}>
                    <div style={styles.inputTitle}>Message</div>
                    <OutlinedTextField
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        multiline
                        minRows={5}
                        style={styles.input}
                        size="small"
                    />
                </div>
            </div>

            <Button
                onClick={onSubmit}
                disabled={loading}
                variant='contained'
                style={styles.btn}
            >
                {TEXTS['submit']}
                {loading && <CircularProgress />}
            </Button>
        </Grid>
    )
}

export default Form