import MarketOverAll from "../../misc/MarketOverAll";
import Header from "./components/Header";
import Table from "./components/Table";
import { useTheme } from '@mui/material/styles';

function SearchHistory() {
    const theme = useTheme();
    const styles = {
        root: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: '15px'
        },
        content: {
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem'
        }
    }
    return (
        <main style={styles.root}>
            <MarketOverAll />
            <div style={styles.content}>
                <Header />
                <Table />
            </div>
        </main>
    )
}

export default SearchHistory