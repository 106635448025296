import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';
import { showLimitDialog, setLimits } from '../../redux/state/limitReducer';

function useStrategy() {
    const limitsReducer = useSelector(state => state.limitsReducer);
    const signalsReducer = useSelector(state => state.signalsReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [loading, setLoading] = useState(false);
    const [didNotLoad, setDidNotLoad] = useState(false);
    const [strategies, setStrategies] = useState(null);
    const [strategy, setStrategy] = useState(null);
    const [coins, setCoins] = useState({});
    const [result, setResult] = useState(null);
    const dispatch = useDispatch();
    const createRequest = useRequest();

    const resetResult = () => {
        setResult(null);
        setDidNotLoad(false);
    }

    const getCoinPrices = (coins) => {
        coins.forEach(c => {
            const onSuccess = (response) => {
                setCoins(prev => {
                    const newCoins = { ...prev };
                    newCoins[c] = response.data;
                    return newCoins
                })
            }
            const onFail = (error) => {
                console.log("failed to fetch coin: " + c)
            }
            createRequest({
                shouldNotCheckIsSuccess: true,
                method: 'GET',
                baseUrl: constantValues.SECONDARY_BASE_URL,
                url: constantValues.GET_SYMBOL_PRICE(c),
                onSuccess,
                onFail
            })
        })
    }

    const deleteStrategyById = (id) => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            setResult({ success: true, shouldFetchStrategies: true });
            dispatch(setLimits({ ...limitsReducer, strategyLeft: limitsReducer?.strategyLeft + 1 }))
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'DELETE',
            url: constantValues.DELETE_STRATEGY(id),
            onSuccess,
            onFail
        })
    }

    const editStrategy = (
        id,
        name,
        Items,
        TimeFrames,
        sendSocialNotification,
        sendEmailNotification,
        ExecuteNumber,
        Execute,
        placement
    ) => {

        if (Items?.length === 0) {
            dispatch(showFailNotification("No Signals Selected(Changes not saved)"));
            return;
        }

        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            dispatch(showSuccessNotification("Strategy Edited!"));
            setResult({ success: true, shouldFetchStrategies: true, strategy: response.data.data });
        }
        const onFail = (error) => {
            setLoading(false);
            setResult({ success: true, shouldFetchStrategies: true });
            let errMessage = ErrorParser(error);
            if (errMessage === 'OverUse') {
                dispatch(showLimitDialog(constantValues.OVERUSE_ENUMS.OVERUSE_STRATEGY_EDIT));
                return;
            }
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'PUT',
            url: constantValues.EDIT_STRATEGY(id),
            data: {
                name: name === 'Default' && placement === 'Dashboard' ? new Date().toDateString() : name,
                Items: Items,
                TimeFrames,
                sendSocialNotification,
                sendEmailNotification,
                ExecuteNumber,
                Execute,
                placement
            },
            onSuccess,
            onFail
        })
    }

    const getStrategies = (placement) => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoading(false);
            setStrategies(response.data.data);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            // let errMessage = ErrorParser(error);
            // let localizedError = TEXTS[errMessage] || errMessage;
            // dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            url: constantValues.GET_STRATEGIES('CRYPTO', placement),
            onSuccess,
            onFail
        })
    }

    const createStrategy = (
        name,
        Items,
        TimeFrames,
        sendSocialNotification,
        sendEmailNotification,
        ExecuteNumber,
        Execute,
        placement
    ) => {

        if (Items?.length === 0) {
            dispatch(showFailNotification("No Signals Selected(Changes not saved)"));
            return;
        }

        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            dispatch(showSuccessNotification("Strategy Created!"));
            setResult({
                success: true,
                shouldFetchStrategies: true,
            });
            dispatch(setLimits({ ...limitsReducer, strategyLeft: limitsReducer?.strategyLeft - 1 }))
        }
        const onFail = (error) => {
            setLoading(false);
            setResult({ success: false, shouldFetchStrategies: false })
            let errMessage = ErrorParser(error);
            if (errMessage === 'OverUse') {
                dispatch(showLimitDialog(constantValues.OVERUSE_ENUMS.OVERUSE_CREATE_STRATEGY));
                return;
            }
            // let localizedError = TEXTS[errMessage] || errMessage;
            // dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'POST',
            url: constantValues.CREATE_STRATEGY,
            data: {
                name,
                Items: Items,
                TimeFrames,
                sendSocialNotification,
                sendEmailNotification,
                ExecuteNumber,
                Execute,
                placement,
                marketType: 'CRYPTO'
            },
            onSuccess,
            onFail
        })
    }

    const getStrategyById = (id) => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoading(false);
            setStrategy(response.data.data)
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            // let errMessage = ErrorParser(error);
            // let localizedError = TEXTS[errMessage] || errMessage;
            // dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_STRATEGY(id),
            onSuccess,
            onFail
        })
    }

    const searchStrategy = (
        id,
        Execute,
        Days,
        Timeframe,
        QuoteAssets,
        BaseAssets,
        ByTopDays,
        ByDateTime,
        From,
        To,
        Group
    ) => {
        resetResult();
        setLoading(true);
        const onSuccess = (response) => {
            setResult(response.data.data);
            setLoading(false);
        }
        const onFail = (error) => {
            setLoading(false);
            setResult({ failed: true });
            let errMessage = ErrorParser(error);
            if (errMessage === 'OverUse') {
                dispatch(showLimitDialog(constantValues.OVERUSE_ENUMS.OVERUSE_STRATEGY_SEARCH));
                return;
            }
            // let localizedError = TEXTS[errMessage] || errMessage;
            // dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'POST',
            url: constantValues.SEARCH_STRATEGY(id),
            data: {
                Execute,
                Days,
                Timeframe,
                QuoteAssets,
                BaseAssets,
                ByTopDays,
                ByDateTime,
                From,
                To,
                Group
            },
            onSuccess,
            onFail
        })
    }

    const manipulateFavSymbols = (currResult, key) => {
        let symbols = Object.keys(currResult.result);
        let result = {};
        symbols.forEach(x => {
            let r = currResult.result[x]
            if (x === key) {
                result[x] = { ...r, isFavorite: !r.isFavorite };
            } else {
                result[x] = r;
            }
        });
        setResult({ ...currResult, result })
    }

    return {
        getStrategies,
        loading,
        didNotLoad,
        strategies,
        strategy,
        getStrategyById,
        createStrategy,
        editStrategy,
        searchStrategy,
        deleteStrategyById,
        result,

        coins,
        getCoinPrices,

        manipulateFavSymbols,
        resetResult
    }
}

export default useStrategy