import CheckIcon from "@mui/icons-material/Check";
import ShieldIcon from "@mui/icons-material/Shield";
import Button from "@mui/material/Button";
import constantValues from "../../../misc/constantValues";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';

function Products({ plans }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '3rem',
            fontSize: '13px'
        },
        content: {
            width: '100%',
            display: 'flex',
            overflowX: 'auto',
            marginTop: '2rem',
            color: theme.palette.mode === 'dark' ? constantValues.ICON_COLOR_1 : '#401a6c',
            paddingBottom: '2em',
            paddingTop: '0.1em',
            paddingRight: '1em'
        },
        features: {
            display: 'flex',
            flexDirection: 'column',
            width: desktopSize ? '25%' : 'auto',
            fontSize: constantValues.IS_RTL_LANGUAGE(language) ? '14.928px' : ''
        },
        productContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: desktopSize ? '25%' : '',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.4rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.4rem' : '',
            position: 'relative'
        },
        header: {
            paddingBottom: '1em',
            paddingTop: constantValues.IS_RTL_LANGUAGE(language) ? '1.4em' : '1.6em',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px'
        },
        bronzeAndSilverHeader: {
            paddingBottom: '1.2em',
            paddingTop: '2em',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.mode === 'light' ? '#f5f2f2' : 'rgb(39,35,58)',
            borderTopRightRadius: '28px',
            borderTopLeftRadius: '28px',
            minWidth: desktopSize ? '' : mobileSize ? '60vw' : '30vw'
        },
        goldHeader: {
            paddingBottom: '1.2em',
            paddingTop: '2em',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.mode === 'light' ? 'white' : 'rgb(29,26,46)',
            minWidth: desktopSize ? '' : mobileSize ? '60vw' : '30vw'
        },
        lighItemFeatures: {
            backgroundColor: theme.palette.mode === 'light' ? 'white' : 'rgb(37,34,57)',
            width: '100%',
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'center',
            height: '49.9px',
            alignItems: 'center'
        },
        darkItemFeatures: {
            backgroundColor: theme.palette.mode === 'light' ? '#f5f2f2' : 'rgb(29,26,46)',
            width: '100%',
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'center',
            height: '49.9px',
            alignItems: 'center'
        },
        darkItemBronze: {
            backgroundColor: theme.palette.mode === 'light' ? 'rgb(235,229,229)' : 'rgb(39,35,58)',
            width: '100%',
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'center',
            height: '49.9px',
            alignItems: 'center'
        },
        darkItemGold: {
            backgroundColor: theme.palette.mode === 'light' ? 'rgb(235,229,229)' : 'rgb(37,34,57)',
            width: '100%',
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'center',
            height: '49.9px',
            alignItems: 'center'
        },
        lightItemBronze: {
            backgroundColor: theme.palette.mode === 'light' ? 'rgb(245,242,242)' : 'rgb(45,41,66)',
            width: '100%',
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'center',
            height: '49.9px',
            alignItems: 'center'
        },
        lightItemGold: {
            backgroundColor: theme.palette.mode === 'light' ? 'rgb(245,242,242)' : 'rgb(29,26,46)',
            width: '100%',
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'center',
            height: '49.9px',
            alignItems: 'center'
        },
        hiddenSpace: {
            width: '100%',
            height: '43px',
        },
        featuresHiddenSpace: {
            width: '100%',
            height: '62px'
        },
        productButtonContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            top: 20
        },
        bronzeButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            backgroundColor: '#f07259',
            width: '60%',
            borderRadius: '8px'
        },
        bronzeButtonSmall: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            backgroundColor: '#f07259',
            width: '50%',
            borderRadius: '8px',
            fontSize: '13px'
        },
        silverButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            backgroundImage: 'linear-gradient(58deg, #aeaeae 17%, #c6c6c6 87%)',
            width: '60%',
            borderRadius: '8px'
        },
        silverButtonSmall: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            backgroundImage: 'linear-gradient(58deg, #aeaeae 17%, #c6c6c6 87%)',
            width: '50%',
            borderRadius: '8px',
            fontSize: '13px'
        },
        circle: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: 'white',
            marginRight: '0.4rem'
        },
        shieldIcon: {
            width: '12px',
            height: '12px',
            marginRight: '0.4rem'
        },
        startNowContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1em',
            paddingBottom: '1em',
            backgroundColor: theme.palette.mode === 'light' ? 'rgb(245,242,242)' : 'rgb(39,35,58)',
            borderBottomRightRadius: '28px',
            borderBottomLeftRadius: '28px',
        },
        startNowContainerGold: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: 'linear-gradient(225deg, #f9cc24 0%, #ffa000 100%)',
            position: 'absolute',
            bottom: 0,
            height: '90px',
            color: 'white',
            fontSize: '18px',
            fontWeight: 'bold',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            borderCollapse: 'collapse',
            cursor: 'pointer'
        },
        goldTop: {
            backgroundImage: 'linear-gradient(225deg, #f9cc24 0%, #ffa000 100%)',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            height: '60px',
            color: 'white',
            fontWeight: 'bold',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
            fontSize: '18px'
        },
        productContainerGold: {
            display: 'flex',
            flexDirection: 'column',
            width: desktopSize ? '25%' : '',
            marginRight: '0.4rem',
            position: 'relative',
            border: '2px solid #f9cc24',
            borderTop: 0,
            borderBottom: 0,
            borderRadius: '10px',
            boxShadow: '0 3px 20px 0 rgba(150, 110, 0, 0.37)'
        },
        bigHiddenSpace: {
            width: '100%',
            height: '90px',
        },
        featuresInner: {
            marginRight: desktopSize ? '' : '0.4rem',
            marginLeft: desktopSize ? '' : '0.4rem'
        },
        topHeader: {
            marginLeft: !desktopSize ? '0.4rem' : ''
        },
        link: {
            width: '100%',
            textDecoration: 'none',
            color: 'inherit',
            display: 'flex',
            justifyContent: 'center'
        },
    }

    const bronze = plans?.find(x => x.name === 'Bronze');
    const silver = plans?.find(x => x.name === 'Silver');
    const gold = plans?.find(x => x.name === 'Gold');

    const keys = [
        'simultaneousAccess',//Default
        'notification',//CheckIcon or -
        'timeFrames',//timeFrames.length
        '5minTimeframe',//timeFrames.find(x => x === '5m')
        '1minTimeframe',//..
        'strategy',//Default
        'favoriteCoin',//default
        'backTest',//CheckIcon or -
        'threeMonth',//+€
        'sixMonth',//+€
        'twelveMonth'//+€
    ];

    const parsePlans = (p) => {
        if (p) {
            let result = keys.map(k => {
                switch (k) {
                    case 'notification':
                        return p[k] ? <CheckIcon /> : '-';
                    case 'advancedNotification':
                        return p[k] ? <CheckIcon /> : '-';
                    case 'timeFrames':
                        return p[k].length;
                    case '5minTimeframe':
                        return p.timeFrames.find(x => x === '5m') ? <CheckIcon /> : '-';
                    case '1minTimeframe':
                        return p.timeFrames.find(x => x === '1m') ? <CheckIcon /> : '-';
                    case 'backTest':
                        return p[k] ? <CheckIcon /> : '-';
                    case 'threeMonth':
                        return p[k] + '€';
                    case 'sixMonth':
                        return p[k] + '€';
                    case 'twelveMonth':
                        return p[k] + '€';
                    default:
                        return p[k];
                }
            });
            return result
        }

        return null
    }

    return (
        <section style={styles.root}>
            <div style={styles.content}>
                {/*BEGIN: Features */}
                <div style={styles.features}>
                    <div style={styles.featuresHiddenSpace} />
                    <h3 style={styles.header}>
                        {TEXTS['features']}
                    </h3>
                    {
                        [
                            TEXTS['simultaneousAccess'],
                            TEXTS['notifications'],
                            TEXTS['timeframes'],
                            TEXTS['5minTimeframe'],
                            TEXTS['1minTimeframe'],
                            TEXTS['strategy'],
                            TEXTS['favoriteCoins'],
                            TEXTS['backtest'],
                            TEXTS['3Months'],
                            TEXTS['6Months'],
                            TEXTS['1Year']
                        ].map((x, index) =>
                            <div
                                style={index % 2 === 0 ? styles.darkItemFeatures : styles.lighItemFeatures}
                                key={x}
                            >
                                <div style={styles.featuresInner}>
                                    {x}
                                </div>
                            </div>
                        )
                    }
                </div>
                {/*END: Features */}

                {/*BEGIN: Bronze */}
                <div style={styles.productContainer}>
                    <div style={styles.productButtonContainer}>
                        <Button style={styles.bronzeButton} variant='contained'>
                            <div style={styles.circle} />
                            Bronze
                        </Button>
                    </div>
                    <div style={styles.hiddenSpace} />
                    <div style={styles.bronzeAndSilverHeader}>
                        <h1>{bronze?.oneMonth}€</h1>
                        /month
                    </div>
                    {
                        bronze && parsePlans(bronze).map((x, index) =>
                            <div
                                style={index % 2 === 0 ? styles.darkItemBronze : styles.lightItemBronze}
                                key={index}
                            >
                                {x}
                            </div>
                        )
                    }
                    <div style={styles.startNowContainer}>
                        <Link
                            style={styles.link}
                            to={constantValues.REGISTER_ROUTE}
                            //constantValues.PAYMENT_ROUTE.replace(':id', bronze?.id)
                        >
                            <Button style={styles.bronzeButtonSmall} variant='contained'>
                                Start Now
                            </Button>
                        </Link>
                    </div>
                </div>
                {/*END: Bronze */}

                {/*BEGIN: Silver */}
                <div style={styles.productContainer}>
                    <div style={styles.productButtonContainer}>
                        <Button style={styles.silverButton} variant='contained'>
                            <ShieldIcon style={styles.shieldIcon} />
                            Silver
                        </Button>
                    </div>
                    <div style={styles.hiddenSpace} />
                    <div style={styles.bronzeAndSilverHeader}>
                        <h1>{silver?.oneMonth}€</h1>
                        /month
                    </div>
                    {
                        silver && parsePlans(silver).map((x, index) =>
                            <div
                                style={index % 2 === 0 ? styles.darkItemBronze : styles.lightItemBronze}
                                key={index}
                            >
                                {x}
                            </div>
                        )
                    }
                    <div style={styles.startNowContainer}>
                        <Link
                            style={styles.link}
                            to={constantValues.REGISTER_ROUTE}
                        >
                            <Button style={styles.silverButtonSmall} variant='contained'>
                                Start Now
                            </Button>
                        </Link>
                    </div>
                </div>
                {/*END: Silver */}

                {/*BEGIN: Gold */}
                <div style={styles.productContainerGold}>
                    <div style={styles.goldTop}>
                        <EmojiEventsIcon />
                        <div>Gold</div>
                    </div>
                    <div style={styles.hiddenSpace} />
                    <div style={styles.goldHeader}>
                        <h1>{gold?.oneMonth}€</h1>
                        /month
                    </div>
                    {
                        gold && parsePlans(gold)?.map((x, index) =>
                            <div
                                style={index % 2 === 0 ? styles.darkItemGold : styles.lightItemGold}
                                key={index}
                            >
                                {x}
                            </div>
                        )
                    }
                    <div style={styles.bigHiddenSpace} />
                    <Link to={constantValues.REGISTER_ROUTE}>
                        <div style={styles.startNowContainerGold}>
                            Start Now
                        </div>
                    </Link>
                </div>
                {/*END: Gold */}
            </div>
        </section>
    )
}

export default Products