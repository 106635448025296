import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import HelpIcon from '../../../../material-ui/Icons/HelpIcon';
import constantValues from '../../../../misc/constantValues';
import { useSelector } from "react-redux";
import OutlinedTextField from '../../../../material-ui/TextField/OutlinedTextField';
import Localization from "../../../../localization";
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Divider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import AddSignalDialog from './AddSignalDialog';

function MainConfig({
    name,
    setName,
    transaction,
    setTransaction,
    enterSignals,
    setEnterSignals,
    exitSignals,
    setExitSignals,
    executeCandlesEnter,
    setExecuteCandlesEnter,
    executeCandlesExit,
    setExecuteCandlesExit
}) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    const [showEnterSignalsDialog, setShowEnterSignalsDialog] = useState(false);
    const [showExitSignalsDialog, setShowExitSignalsDialog] = useState(false);

    /*BEGIN: Styles */
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            fontSize: mobileSize ? '12px' : '14px'
        },
        helpIcon: {
            marginLeft: '0.4rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
        },
        grid: {

        },
        container: {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            marginTop: '1rem',
            gap: desktopSize ? '' : 10
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            width: mobileSize ? '100%' : '',
            marginBottom: mobileSize ? '1rem' : ''
        },
        topInputContainer: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: mobileSize ? '' : '3rem',
            color: '#7d7d7d',
            gap: 10
        },
        flex: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '2rem'
        },
        transactionInputRoot: {
            width: '85px',
        },
        nameInputRoot: {
            width: mobileSize ? '102px' : ''
        },
        timeAndSignalConfig: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
            marginTop: mobileSize ? '1rem' : '2rem',
            gap: mobileSize ? 10 : 6
        },
        smallBoldText: {
            fontWeight: 'bold',
            fontSize: mobileSize ? '15px' : '',
            width: mobileSize ? '100%' : '',
            marginBottom: mobileSize ? '1rem' : ''
        },
        signalButton: {
            textTransform: 'none',
            paddingLeft: '0.4em',
            paddingRight: '1em',
            fontSize: mobileSize ? '12px' : '14px',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        candleInput: {

        },
        whenToExit: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: desktopSize ? '' : '1rem',
            gap: mobileSize ? 10 : 6
        },
        divider: {
            marginTop: '3rem'
        },
        nameInputRootSX: {
            fontSize: mobileSize ? '12px' : ''
        }
    }
    /*END: Styles */
    return (
        <section style={styles.root}>
            {/*BEGIN: Enter Signals Dialog */}
            <AddSignalDialog
                open={showEnterSignalsDialog}
                onClose={() => setShowEnterSignalsDialog(false)}
                selectedSignals={enterSignals}
                setSelectedSignals={setEnterSignals}
            />
            {/*END: Enter Signals Dialog */}

            {/*BEGIN: Exit Signals Dialog */}
            <AddSignalDialog
                open={showExitSignalsDialog}
                onClose={() => setShowExitSignalsDialog(false)}
                selectedSignals={exitSignals}
                setSelectedSignals={setExitSignals}
            />
            {/*END: Exit Signals Dialog */}

            <Grid style={styles.grid} container justifyContent={'center'}>

                {/*BEGIN: Title and Transaction Type */}
                <Grid item xs={11}>
                    <div style={styles.container}>
                        <div style={styles.title}>
                            <h2>
                                {TEXTS["backtest"]}
                            </h2>
                            <HelpIcon style={styles.helpIcon} />
                        </div>
                        <div style={styles.topInputContainer}>
                            <div>
                                {TEXTS['name']}:
                            </div>
                            <OutlinedTextField
                                style={styles.nameInputRoot}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                size='small'
                                placeholder={TEXTS['backtestName']}
                                sx={{
                                    "& .MuiOutlinedInput-root": styles.nameInputRootSX,
                                }}
                            />
                        </div>
                        <div style={styles.topInputContainer}>
                            <div>
                                {TEXTS["transaction"]}:
                            </div>
                            <OutlinedTextField
                                value={transaction}
                                onChange={(e) => setTransaction(e.target.value)}
                                style={styles.transactionInputRoot}
                                size='small'
                                select
                                sx={{
                                    "& .MuiOutlinedInput-input": {
                                        fontSize: mobileSize ? '12px' : '14px',
                                        color: theme.palette.mode === 'dark' ?
                                            constantValues.ICON_COLOR_1 : ''
                                    }
                                }}
                            >
                                <MenuItem value={'Buy'}>{TEXTS['buy']}</MenuItem>
                                <MenuItem value={'Sell'}>{TEXTS['sell']}</MenuItem>
                            </OutlinedTextField>
                        </div>
                    </div>
                </Grid>
                {/*END: Title and Transaction Type */}

                {/*BEGIN: Signal Configs */}
                <Grid item xs={11}>
                    <div style={styles.timeAndSignalConfig} >
                        {/* */}
                        <div style={styles.smallBoldText}>
                            {TEXTS['whenShouldIEnter']}
                        </div>
                        <div>
                            {TEXTS['enterWhen']}
                        </div>
                        <Button
                            sx={{ boxShadow: 0 }}
                            onClick={() => setShowEnterSignalsDialog(true)}
                            style={styles.signalButton}
                            variant='contained'
                        >
                            <AddIcon />
                            {enterSignals?.length} {TEXTS['signal']}
                        </Button>
                        <div>
                            {TEXTS['happensIn']}
                        </div>
                        <OutlinedTextField
                            value={executeCandlesEnter}
                            onChange={(e) => setExecuteCandlesEnter(e.target.value)}
                            style={styles.candleInput}
                            size='small'
                            select
                            sx={{
                                "& .MuiOutlinedInput-input": {
                                    fontSize: mobileSize ? '12px' : '14px',
                                    color: theme.palette.mode === 'dark' ?
                                        constantValues.ICON_COLOR_1 : ''
                                }
                            }}
                        >
                            {constantValues.BACKTEST_CANDLE_OPTIONS.map(c =>
                                <MenuItem key={c} value={c}>
                                    {c} {TEXTS['candles']}
                                </MenuItem>
                            )}
                        </OutlinedTextField>
                        {/* */}

                        {/* */}
                        <div style={styles.whenToExit}>
                            <div style={styles.smallBoldText}>
                                {TEXTS['whenShouldIExit']}
                            </div>
                            <div>
                                {TEXTS['exitWhen']}
                            </div>
                            <Button
                                sx={{boxShadow: 0}}
                                onClick={() => setShowExitSignalsDialog(true)}
                                style={styles.signalButton}
                                variant='contained'
                            >
                                <AddIcon />
                                {exitSignals?.length} {TEXTS['signal']}
                            </Button>
                            <div>
                                {TEXTS['happensIn']}
                            </div>
                            <OutlinedTextField
                                style={styles.candleInput}
                                size='small'
                                select
                                value={executeCandlesExit}
                                onChange={(e) => setExecuteCandlesExit(e.target.value)}
                                sx={{
                                    "& .MuiOutlinedInput-input": {
                                        fontSize: mobileSize ? '12px' : '14px',
                                        color: theme.palette.mode === 'dark' ?
                                            constantValues.ICON_COLOR_1 : ''
                                    }
                                }}
                            >
                                {constantValues.BACKTEST_CANDLE_OPTIONS.map(c =>
                                    <MenuItem key={c} value={c}>
                                        {c} {TEXTS['candles']}
                                    </MenuItem>
                                )}
                            </OutlinedTextField>
                        </div>
                        {/* */}
                    </div>
                </Grid>
                {/*END: Signal Configs */}

                {/*Divider */}
                <Grid xs={11}>
                    <Divider style={styles.divider} />
                </Grid>
            </Grid>
        </section>
    )
}

export default MainConfig