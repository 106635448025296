import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification } from '../../redux/state/notificationReducer';
import { updateTimeframes } from '../../redux/state/timeframesReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useEffect, useState } from 'react';

function useTimeframe() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const getTimeframes = (retryCount = 1) => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            dispatch(updateTimeframes(response.data.data));
        }
        const onFail = (error) => {
            setLoading(false);
            if (retryCount > 0) {
                setTimeout(() => {
                    getTimeframes(retryCount - 1);
                }, 2000)
            }
            // let errMessage = ErrorParser(error);
            // let localizedError = TEXTS[errMessage] || errMessage;
            // dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_TIMEFRAMES('CRYPTO'),
            onSuccess,
            onFail
        })
    }

    return { getTimeframes, loading }
}

export default useTimeframe