import Grid from '@mui/material/Grid';
import constantValues from '../../../../misc/constantValues';
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import Localization from "../../../../localization";
import HelpIcon from '../../../../material-ui/Icons/HelpIcon';
import useMediaQuery from '@mui/material/useMediaQuery';

const formatPercentNumber = (num) => {
    let result = parseFloat((Math.round(num * 100)).toFixed(2));
    if (result < 0) {
        return `-${Math.abs(result)}` + '%';
    }
    return (result) + '%'
}

function ResultOverAll({ backtest }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            fontSize: mobileSize ? '12px' : '15px',
        },
        smallBoldText: {
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            marginTop: '3rem'
        },
        helpIcon: {
            marginLeft: '0.4rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
        },
        tableRoot: {
            width: '100%',
            marginTop: '1rem'
        },
        tableContainer: {
            width: '100%',
            overflowX: 'auto'
        },
        tableHeader: {
            border: 0,
            padding: '1em',
            color: '#7a7a7a',
            fontWeight: 'normal',
            whiteSpace: mobileSize ? 'nowrap' : ''
        },
        tableData: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            textAlign: 'center'
        },
        exitReason: {
            color: '#7a7a7a',
            fontWeight: 'normal'
        },
        coinLogo: {
            width: '32px',
            height: '32px',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.4rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.4rem' : ''
        },
        coinShortName: {
            color: '#cccccc',
            fontSize: '12px',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.2rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '0.2rem' : '',
            marginTop: '0.2rem'
        },
        numColor: (num) => ({
            color: num > 0 ? '#78c9c0' : '#f07259'
        }),
        td: {
            paddingTop: '1.5em',
            paddingBottom: '1.5em'
        }
    }
    /*END: Styles */
    return (
        <section style={styles.root}>
            <Grid container justifyContent="center">
                <Grid item xs={11}>
                    <div style={styles.smallBoldText}>
                        <div>
                            {TEXTS['fullResults']}:
                        </div>
                        <HelpIcon style={styles.helpIcon} />
                    </div>
                </Grid>
                <Grid item xs={11}>
                    <div style={styles.tableContainer}>
                        <table style={styles.tableRoot}>
                            <tr>
                                {
                                    [
                                        TEXTS['coin'],
                                        TEXTS['enteryDate'],
                                        TEXTS['enteryPrice'],
                                        TEXTS['exitDate'],
                                        TEXTS['exitPrice'],
                                        TEXTS['highestPrice'],
                                        TEXTS['lowestPrice'],
                                        TEXTS['profitAndLoss'],
                                        TEXTS['candles'],
                                        TEXTS['exitReason']
                                    ].map(x =>
                                        <th style={styles.tableHeader} key={x}>
                                            {x}
                                        </th>
                                    )
                                }
                            </tr>
                            {backtest?.deals.map(x =>
                                <tr>
                                    <td style={styles.td}>
                                        <div style={styles.tableData}>
                                            <img
                                                style={styles.coinLogo}
                                                src={`/media/${x.symbol.replace('USDT','').toLowerCase()}.svg`}
                                                alt={x.symbol}
                                            />
                                            <div>
                                                {x.symbol.replace('USDT', '')}
                                            </div>
                                            <div style={styles.coinShortName}>
                                                {'USDT'}
                                            </div>
                                        </div>
                                    </td>

                                    <td style={styles.td}>
                                        <div style={styles.tableData}>
                                            {new Date(x.entryDateTime).toLocaleDateString()}
                                        </div>
                                    </td>

                                    <td style={styles.td}>
                                        <div style={styles.tableData}>
                                            {x.entryPrice}
                                        </div>
                                    </td>

                                    <td style={styles.td}>
                                        <div style={styles.tableData}>
                                            {new Date(x.exitDateTime).toLocaleDateString()}
                                        </div>
                                    </td>

                                    <td style={styles.td}>
                                        <div style={styles.tableData}>
                                            {x.exitPrice}
                                        </div>
                                    </td>

                                    <td style={styles.td}>
                                        <div style={styles.tableData}>
                                            {x.maxPriceWithin}
                                        </div>
                                    </td>

                                    <td style={styles.td}>
                                        <div style={styles.tableData}>
                                            {x.minPriceWithin}
                                        </div>
                                    </td>

                                    <td style={styles.td}>
                                        <div dir='ltr' style={{ ...styles.tableData, ...styles.numColor(x.profit) }}>
                                            {formatPercentNumber(x.profit)}
                                        </div>
                                    </td>

                                    <td style={styles.td}>
                                        <div style={styles.tableData}>
                                            {x.candles}
                                        </div>
                                    </td>

                                    <td style={styles.td}>
                                        <div style={{ ...styles.tableData, ...styles.exitReason }}>
                                            {x.endDealType}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </table>
                    </div>
                </Grid>
            </Grid>
        </section>
    )
}

export default ResultOverAll