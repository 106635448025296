import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from '../localization';
import { useSelector, useDispatch } from 'react-redux';
import constantValues from './constantValues';
import { acceptTerms } from '../redux/state/termsOfServiceReducer';

function TermsAndServicesDialog({ open, onClose }) {
    const dispatch = useDispatch();
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1.5em',
            paddingBottom: '1.5em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            marginTop: '3rem'
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '7rem'
        },
        texts: {
            marginTop: '1rem'
        }
    }
    /*END: Styles */

    const submit = () => {
        dispatch(acceptTerms());
        onClose();
    }

    const termsOfServiceLink = constantValues.TERMS_OF_SERVICE_URL;
    const privacyPolicyLink = 'https://allcan.trade/blog/index.php/privacy-policy/';
    const endUserLicenseAgreementLink = 'https://allcan.trade/blog/index.php/end-user-licance-aggrement/'

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>
                    <h1>Terms of Service</h1>
                    <div style={styles.texts}>
                        By continuing using this website you agree to our
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href={termsOfServiceLink}
                        >
                            {' Terms Of Service'}
                        </a>
                        {/* <a href={endUserLicenseAgreementLink}> End User License agreements </a>, */}
                        {', Cookies and'}
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href={privacyPolicyLink}
                        >
                            {' Privacy Policy'}
                        </a>
                    </div>
                    <div style={styles.actions}>
                        <Button onClick={submit} style={styles.button} variant={'contained'}>
                            I Accept
                        </Button>
                        <Button onClick={onClose} style={styles.button} variant='outlined'>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default TermsAndServicesDialog