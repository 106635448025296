import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function useBrokers() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const [brokers, setBrokers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [didNotLoad, setDidNotLoad] = useState(false);

    const getBrokers = () => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoading(false);
            setBrokers(response.data.data)
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_BROKERS,
            onSuccess,
            onFail
        })
    }

    const updateBrokers = (symbols) => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            dispatch(showSuccessNotification('Exchanges Updated'))
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'POST',
            url: constantValues.UPDATE_BROKERS,
            data: { symbols },
            onSuccess,
            onFail
        })
    }

    return {
        getBrokers,
        loading,
        didNotLoad,
        brokers,
        updateBrokers
    }
}

export default useBrokers