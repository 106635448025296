import Paper from '@mui/material/Paper';
import CircleCheckbox from '../../../material-ui/Checkbox/CircleCheckbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';

function PaymentMethod({ paymentMethod, setPaymentMethod }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            fontWeight: 'bold',
            fontSize: '1rem',
            marginTop: '1.5rem'
        },
        paper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '2em',
            paddingTop: '0.5em',
            marginTop: '1rem',
            boxShadow: mobileSize ? 'none' : ''
        },
        paperContent: {
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '13px'
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem'
        }
    }

    const onChangeMethod = (method) => {
        setPaymentMethod(method);
    }
    return (
        <section style={styles.root}>
            <div style={styles.paper}>
                <div style={styles.paperContent}>
                    <div style={styles.header}>
                        {TEXTS['paymentMethod']}
                    </div>
                    <div style={styles.item}>
                        <CircleCheckbox
                            onChange={() => onChangeMethod('Paypal')}
                            checked={paymentMethod === 'Paypal'}
                        />
                        <div>Paypal</div>
                    </div>
                    {/* <div style={styles.item}>
                        <CircleCheckbox
                            onChange={() => onChangeMethod('CryptoCurrency')}
                            checked={paymentMethod === 'CryptoCurrency'}
                        />
                        <div>Cryptocurrency</div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default PaymentMethod