import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification } from '../../redux/state/notificationReducer';
import { updateSignals } from "../../redux/state/signalsReducer";
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function useSignal() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const getSignals = () => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            //Parse Data
            let parsedData = [];
            let categories = response.data.data.categories;
            let allSignalsBooleans = response.data.data.signals;
            Object.keys(categories).map(x => {
                parsedData.push({
                    section: x,
                    signals: categories[x],
                    showList: false
                })
            });
            //Sort Data
            let order = response.data.data.order;
            let sortedData = [];
            order.map(p => {
                let data = parsedData.find(x => x.section === p);
                sortedData.push(data);
            });
            //
            //Generate result object
            let freeSignals = response.data.data.free;
            let premiumSignals = response.data.data.premium;
            let result = {
                data: sortedData,
                freeSignals,
                premiumSignals,
                allSignalsBooleans
            };

            dispatch(updateSignals(result))
        }
        const onFail = (error) => {
            setLoading(false);
            // let errMessage = ErrorParser(error);
            // let localizedError = TEXTS[errMessage] || errMessage;
            // dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            url: constantValues.GET_SIGNALS('CRYPTO'),
            onSuccess,
            onFail
        })
    }

    return { getSignals, loading }
}

export default useSignal