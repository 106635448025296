import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function useExchange() {
    const User = useSelector(state => state.userReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const [loading, setLoading] = useState(false);
    const [didNotLoad, setDidNotLoad] = useState(false);
    const [signals, setSignals] = useState(null);
    const [favSymbols, setFavSymbols] = useState(null);
    const [coins, setCoins] = useState(null);

    const getFavoriteSymbols = () => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            setFavSymbols(response.data.data);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_FAVORITE_SYMBOLS('CRYPTO'),
            onSuccess,
            onFail
        })
    }

    const getSignalByPairAndTimeframe = (base, quote, timeframe) => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoading(false);
            setSignals(response.data.data);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'GET',
            url: User?.accessToken ?
                constantValues.GET_SIGNAL_BY_PAIR_AND_TIMEFRAME(
                    'CRYPTO',
                    base,
                    quote,
                    constantValues.parseTimeFrameForNewAPI(timeframe)
                ) :
                constantValues.GET_SIGNAL_BY_PAIR_AND_TIMEFRAME_GUEST(
                    'CRYPTO',
                    base,
                    quote,
                    constantValues.parseTimeFrameForNewAPI(timeframe)
                ),
            onSuccess,
            onFail
        })
    };

    const getAllCoins = () => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setCoins(response.data.data);
            setLoading(false);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_ALL_COINS('CRYPTO'),
            onSuccess,
            onFail
        })
    }

    return {
        getSignalByPairAndTimeframe,
        loading,
        didNotLoad,
        signals,

        favSymbols,
        getFavoriteSymbols,

        getAllCoins,
        coins
    }
}

export default useExchange