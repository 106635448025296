import Dialog from '@mui/material/Dialog';
import CancelIcon from '@mui/icons-material/Cancel';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import constantValues from '../../../../misc/constantValues';
import { updateSignals } from '../../../../redux/state/signalsReducer';
import Localization from '../../../../localization';
import OutlinedTextField from '../../../../material-ui/TextField/OutlinedTextField';
import SignalSelector from '../../../../misc/SignalSelector';


function AddSignalDialog({ open, onClose, selectedSignals, setSelectedSignals }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Application State */
    const signalsReducer = useSelector(state => state.signalsReducer);
    const [signals, setSignals] = useState(selectedSignals);
    const [shownSignals, setShownSignals] = useState(signalsReducer?.data);
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();
    /*END: Application State */

    /*BEGIN: UI Functions */
    const calcShownSignals = (sValue) => {
        setSearchValue(sValue);
        let searchString = sValue.toLowerCase();
        let result = signalsReducer
            .data
            .filter(x => {
                let section = TEXTS[`signal-${x.section}`]?.toLowerCase();
                let isSectionTitle = section?.includes(searchString);
                let isInSection = false;
                x.signals.every(s => {
                    let sig = TEXTS[`signal-${s}`]?.toLowerCase()
                    if (sig?.includes(searchString)) {
                        isInSection = true;
                        return false;
                    }
                    return true;
                })
                return isSectionTitle || isInSection;
            });

        setShownSignals(result);
    }

    const toggleColapse = (e, index) => {
        e.preventDefault();
        let result = [];
        shownSignals.map((x, i) => {
            if (i === index) {
                result.push({ ...x, showList: !x.showList });
            } else {
                result.push({ ...x, showList: false });
            }
        });
        setShownSignals(result);
    }

    const toggleSection = (section) => {
        let sectionSignal = signalsReducer.data.find(x => x.section === section).signals;
        let shouldToggleOff = sectionSignal.every(x => signals.includes(x));
        if (shouldToggleOff) {
            let result = signals.filter(x => !sectionSignal.includes(x));
            setSignals(result);
        } else {
            let filterPrevious = signals
                .filter(x => !sectionSignal.includes(x));
            let result = [...filterPrevious, ...sectionSignal];
            setSignals(result);
        }
    }

    const toggleSignal = (id) => {
        if (signals.includes(id)) {
            let result = signals.filter(x => x !== id);
            setSignals(result);
        } else {
            let result = [...signals, id];
            setSignals(result)
        }
    }
    /*END: UI Functions */

    /*BEGIN: Styles */
    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1.5em',
            paddingBottom: '1.5em',
            backgroundColor: theme.palette.background.default,
        },
        container: {
            width: '94%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        searchInputRoot: {
            width: '100%',
            marginTop: '2rem'
        },
        searchInput: {
            paddingRight: constantValues.IS_RTL_LANGUAGE(language) ? 1 : 0,
            paddingLeft: 0,
            borderRadius: '8px',
            color: theme.palette.text.primary,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        startAdornmentRTL: {
            paddingRight: 0,
            paddingLeft: 0,
            backgroundColor: theme.palette.mode === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '',
            borderBottomRightRadius: '',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        endAdornmentLTR: {
            paddingRight: 0,
            backgroundColor: theme.palette.mode === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        adornmentIconColor: {
            color: 'white'
        },
        searchInputContainer: {
            width: '93%',
        },
        selectorsContainer: {
            display: 'flex',
            width: '93%',
            alignItems: 'center',
            marginTop: '1rem',
            gap: 20
        },
        textButton: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            color: theme.palette.primary.main,
            textTransform: 'none',
            fontWeight: 500,
            padding: 0
        },
        signalsContainer: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            width: '100%',
            height: '250px',
        },
        divider: {
            width: '100%',
            borderBottom: '1px solid #e1e1e1',
            marginTop: '0.4rem',
            marginBottom: '0.4rem'
        },
        signal: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        signalHeader: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            cursor: 'pointer'
        },
        dialogActionsContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem'
        },
        saveChangesButton: {
            textTransform: 'none',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        cancelButton: {
            textTransform: 'none',
            backgroundColor: theme.palette.mode === "light" ? '#ebebeb' :
                constantValues.ICON_COLOR_2,
            color: theme.palette.text.primary,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        signalList: {
            display: 'flex',
            flexDirection: 'column',
            width: '94%',
            fontSize: '14px'
        },
        signalListContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        helpIcon: {
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
            marginLeft: '0.4rem',
            width: '0.6em',
            height: '0.6em'
        }
    }
    /*END: Styles */

    const onCloseDialog = () => {
        let result = [];
        signalsReducer.data.map((s) => {
            result.push({ ...s, showList: false });
        });
        dispatch(updateSignals({ ...signalsReducer, data: result }));
        setShownSignals(result);

        onClose();
    }

    const saveChanges = () => {
        setSelectedSignals(signals);
        onCloseDialog();
    }

    useEffect(() => {
        setSignals(selectedSignals);
    }, [open, selectedSignals]);

    return (
        <Dialog
            open={open}
            onClose={onCloseDialog}
            maxWidth={'sm'}
            fullWidth
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>

                    {/*BEGIN: header */}
                    <div style={styles.header}>
                        <h3>{TEXTS["addStrategy"]}</h3>
                        <IconButton onClick={onClose}>
                            <CancelIcon
                                style={
                                    theme.palette.mode === "dark" ?
                                        { color: constantValues.ICON_COLOR_2 } : undefined}
                                color='disabled'
                            />
                        </IconButton>
                    </div>
                    {/*END: header */}

                    {/*BEGIN: Search */}
                    <div style={styles.searchInputContainer}>
                        <OutlinedTextField
                            value={searchValue}
                            onChange={(e) => calcShownSignals(e.target.value)}
                            style={styles.searchInputRoot}
                            label={constantValues.IS_RTL_LANGUAGE(language) ? undefined : TEXTS["search"]}
                            placeholder={constantValues.IS_RTL_LANGUAGE(language) ? TEXTS["search"] : undefined}
                            size='small'
                            variant='outlined'
                            sx={{
                                "& .MuiOutlinedInput-root": styles.searchInput,
                                "& .MuiInputLabel-root": {
                                    color: (theme) => theme.palette.text.disabled,
                                },
                            }}
                            InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                                {
                                    endAdornment: (
                                        <InputAdornment
                                            position={"start"}
                                            sx={styles.startAdornmentRTL}
                                        >
                                            <SearchIcon style={styles.adornmentIconColor} />
                                        </InputAdornment>
                                    )
                                } :
                                {
                                    endAdornment: (
                                        <InputAdornment
                                            position={"end"}
                                            sx={styles.endAdornmentLTR}
                                        >
                                            <SearchIcon style={styles.adornmentIconColor} />
                                        </InputAdornment>
                                    )
                                }
                            }
                        />
                    </div>
                    {/*END: Search */}

                    {/*BEGIN: Select/Deselect All Signals */}
                    <div style={styles.selectorsContainer}>
                        <Button onClick={() => setSignals([])} style={styles.textButton} variant='text'>
                            Deselect All
                        </Button>
                    </div>
                    {/*END: Select/Deselect All Signals */}

                    <div style={styles.divider} />

                    {/*BEGIN: Signals */}
                    <SignalSelector
                        shownSignals={shownSignals}
                        toggleColapse={toggleColapse}
                        toggleSection={toggleSection}
                        toggleSignal={toggleSignal}
                        signals={signals}
                    />
                    {/*END: Signals */}

                    {/*BEGIN: Dialog Actions */}
                    <div style={styles.dialogActionsContainer}>
                        <Button
                            onClick={onClose}
                            sx={{ boxShadow: 0 }}
                            style={styles.cancelButton}
                            variant='contained'
                        >
                            {TEXTS["cancel"]}
                        </Button>
                        <Button onClick={saveChanges} style={styles.saveChangesButton} variant='contained'>
                            {TEXTS["saveChanges"]}
                        </Button>
                    </div>
                    {/*END: Dialog Actions */}
                </div>
            </div>
        </Dialog>
    )
}

export default AddSignalDialog