import * as React from 'react';
import { useEffect, useMemo } from 'react';
import constantValues from './constantValues';
import CryptoJS from 'crypto-js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';


function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const TradingViewChart = ({ hash, symbol, interval, selectedSimilar, height, fullScreen }) => {
    const query = useQuery();
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    let queryTheme = query.get('theme');
    useEffect(() => {
        let defaultProps = {
            // debug: true, // uncomment this line to see Library errors and warnings in the console
            fullscreen: true,
            symbol: 'BTC',
            interval: '1D',
            container: "tv_chart_container",
            library_path: "/charting_library/",
            locale: "en",
        };
        const widgetOptions = {
            symbol: symbol || defaultProps.symbol,
            // BEWARE: no trailing slash is expected in feed URL
            datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
                constantValues.CHART_DATA_FEED,
                300000
            ),
            interval: interval || defaultProps.interval,
            container: "tv_chart_container",
            library_path: '/charting_library/',
            locale: 'en',
            disabled_features: [
                'use_localstorage_for_settings',
                'save_chart_properties_to_local_storage',
                'google_analytics',
                'header_screenshot',
                'header_saveload',
                'timeframes_toolbar',
            ],
            // enabled_features: ['study_templates', 'left_toolbar', 'side_toolbar_in_fullscreen_mode'],
            client_id: 'tradingview.com',
            user_id: 'public_user_id',
            // autosize: props.autosize || defaultProps.autosize,
            autosize: true,
            theme: queryTheme || theme.palette.mode,
        };

        const tvWidget = new window.TradingView.widget(widgetOptions);
        window.tvWidget = tvWidget;

        tvWidget.onChartReady(() => {
            const Url = `${constantValues.BASE_URL}v2/chart/signal?hash=${hash}`;
            fetch(Url)
                .then(response => response.text())
                .then(draw => {
                    let exc = draw;
                    let timeUrl = `${constantValues.BASE_URL}v2/chart/window`;
                    fetch(
                        timeUrl,
                        {
                            method: "POST",
                            body: JSON.stringify({ TimeFrame: constantValues.intervalToTimeFrame(interval) }),
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        }
                    )
                        .then(response => response.json())
                        .then(response => {
                            var cipher = exc
                            var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY);
                            var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_IV);
                            var decrypted = CryptoJS.AES.decrypt(cipher, key,
                                {
                                    keySize: 128 / 8,
                                    iv: iv,
                                    mode: CryptoJS.mode.CBC,
                                    padding: CryptoJS.pad.Pkcs7
                                }).toString(CryptoJS.enc.Utf8);
                            if (tvWidget) {
                                tvWidget
                                    .chart()
                                    .setVisibleRange(response);
                                    console.log(decrypted, 'decrypted')
                                setTimeout(() => eval(decrypted.replaceAll("widget", "window.tvWidget")), 2000)
                            }
                        }).catch(err => {
                            console.log(err, 'inner err')
                        })
                }).catch(err => {
                    console.log(err, 'couldnt fetch outer')
                })
        });
    }, [hash, symbol, selectedSimilar]);

    console.log(queryTheme || theme.palette.mode, 'LLL')
    return (
        <div
            style={{
                height: height || fullScreen ? '90vh' :
                    (mobileSize ? 'calc(70vh - 85px)' : 'calc(85vh - 85px)'),
                zIndex: 2000000003
            }}
            id="tv_chart_container"
            className={'TVChartContainer'}
        />
    );
}

export default TradingViewChart