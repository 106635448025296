import { useEffect, useState, useMemo } from "react";
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from "react-redux";
import Localization from "../../../../localization";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import constantValues from "../../../../misc/constantValues";
import useRegister from "../../../../hooks/User/useRegister";
import CircularProgress from "../../../../material-ui/CircularProgress/CircularProgress";
import useProfile from "../../../../hooks/User/useProfile";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { Checkbox } from "@mui/material";
import TermsAndServicesDialog from '../../../../misc/TermsAndServicesDialog';
import { toggleTerms, resetTerms } from "../../../../redux/state/termsOfServiceReducer";
import { useLocation } from 'react-router-dom';
import SuccessBox from './SuccessBox';
import { showCookieDialog } from "../../../../redux/state/cookieReducer";

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function RegisterationForm() {
    const cookieReducer = useSelector(state => state.cookieReducer);
    const [showSuccessBox, setShowSuccessBox] = useState(false);
    const query = useQuery();
    const invitationCodeFromQuery = query.get('inviationCode');
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const termsOfServiceReducer = useSelector(state => state.termsOfServiceReducer)

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));

    const styles = {
        root: {
            display: 'flex',
            width: '100%',
            alignItems: 'flex-start',
            flexDirection: 'column',
        },
        inputContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        inputHelper: {
            color: theme.palette.text.disabled,
            fontSize: '11px'
        },
        firstNameInput: {
            width: desktopSize ? '40%' : "100%",
        },
        lastNameInput: {
            width: desktopSize ? '60%' : '100%',
            marginLeft: desktopSize && constantValues.IS_RTL_LANGUAGE(language) ? '' : '2rem',
            marginRight: desktopSize && constantValues.IS_RTL_LANGUAGE(language) ? '2rem' : ''
        },
        input: {
            width: '100%',
            backgroundColor: themeReducer === "light" ? 'white' : '#29263a'
        },
        inputRow: {
            width: desktopSize ? '80%' : '100%',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem'
        },
        buttonContainer: {
            width: '100%',
            marginTop: '2rem'
        },
        button: {
            textTransform: 'none',
            width: desktopSize ? '8rem' : '100%',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
        },
        errorContainer: {
            fontSize: '11px',
            height: '12px',
            color: theme.palette.error.main,
            fontWeight: 'bold'
        },
        startAdornmentRTL: {
            marginLeft: '0.6rem'
        },
        icon: {
            cursor: 'pointer'
        },
        termsText: {
            fontSize: '10px',
            color: 'gray',
            gap: 3,
            display: 'flex',
            alignItems: 'center',
        },
        termsButton: {
            background: 'inherit',
            border: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            color: 'blue',
            fontSize: '10px',
            cursor: 'pointer',
            textTransform: 'none',
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 1
        }
    }
    /*END: Styles */

    /*BEGIN: Form State */
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [invitationCode, setInvitationCode] = useState(invitationCodeFromQuery);
    const [errorList, setErrorList] = useState([]);
    const [localErrors, setLocalErrors] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    /*END: Form State */

    /*BEGIN: Hooks */
    const {
        loading,
        register,
        validateForm
    } = useRegister();
    /*END: Hooks */

    const onRegisterSuccess = () => {
        setShowSuccessBox(true);
    }

    /*BEGIN: Functions */
    function registerUser() {
        if (!cookieReducer?.accepted) {
            dispatch(showCookieDialog())
            return;
        }
        let form = {
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            Password: password,
            PasswordConfirmation: passwordConfirm,
            Referral: invitationCode,
            termsOfService: termsOfServiceReducer?.accepted,
            captcha: ''
        }

        let errors = validateForm(form);

        if (errors.length > 0) {
            let eList = errors.map(x => x.field);
            setErrorList(eList);
            setLocalErrors(errors);
            return;
        }

        //Reset Errors if no errors found
        setErrorList([]);

        register(
            firstName,
            lastName,
            email,
            password,
            invitationCode,
            'sss',
            onRegisterSuccess
        )
    }
    /*END: Functions */

    useEffect(() => {
        dispatch(resetTerms());
        setInvitationCode(invitationCodeFromQuery);
        return () => {
            dispatch(resetTerms())
        }
    }, [invitationCodeFromQuery])

    return (
        <form style={styles.root}>
            <TermsAndServicesDialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
            />

            <div style={styles.inputRow}>
                {/*BEGIN: firstName */}
                <div style={Object.assign({}, styles.inputContainer, styles.firstNameInput)}>
                    <div style={styles.inputHelper}>{TEXTS["firstName"]}*</div>
                    <TextField
                        autoComplete="new-password"
                        size='small'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        variant='outlined'
                        style={styles.input}
                        fullWidth
                        error={errorList.includes("firstName")}
                    />
                    <div style={styles.errorContainer}>
                        {localErrors &&
                            localErrors.find(x => x.field === "firstName")?.message
                        }
                    </div>
                </div>
                {/*END: firstName */}

                {/*BEGIN: lastName -- desktopSize */}
                {desktopSize &&
                    <div
                        style={Object.assign({},
                            styles.inputContainer,
                            styles.lastNameInput
                        )}
                    >
                        <div style={styles.inputHelper}>{TEXTS["lastName"]}*</div>
                        <TextField
                            autoComplete="new-password"
                            size='small'
                            style={styles.input}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            variant='outlined'
                            error={errorList.includes("lastName")}
                        />
                        <div style={styles.errorContainer}>
                            {localErrors &&
                                localErrors.find(x => x.field === "lastName")?.message
                            }
                        </div>
                    </div>
                }
                {/*END: lastName -- desktopSize */}
            </div>

            {!desktopSize &&
                <div style={styles.inputRow}>
                    {/*BEGIN: lastName -- !desktopSize */}
                    <div style={styles.inputContainer}>
                        <div style={styles.inputHelper}>{TEXTS["lastName"]}*</div>
                        <TextField
                            autoComplete="new-password"
                            size='small'
                            style={styles.input}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            variant='outlined'
                            error={errorList.includes("lastName")}
                        />
                        <div style={styles.errorContainer}>
                            {localErrors &&
                                localErrors.find(x => x.field === "lastName")?.message
                            }
                        </div>
                    </div>
                    {/*BEGIN: lastName -- !desktopSize */}
                </div>
            }

            <div style={styles.inputRow}>
                {/*BEGIN: email */}
                <div style={styles.inputContainer}>
                    <div style={styles.inputHelper}>{TEXTS["email"]}*</div>
                    <TextField
                        autoComplete="new-password"
                        type='email'
                        size='small'
                        style={styles.input}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant='outlined'
                        error={errorList.includes("email")}
                    />
                    <div style={styles.errorContainer}>
                        {localErrors &&
                            localErrors.find(x => x.field === "email")?.message
                        }
                    </div>
                </div>
                {/*BEGIN: email */}
            </div>

            <div style={styles.inputRow}>
                {/*BEGIN: Password */}
                <div style={styles.inputContainer}>
                    <div style={styles.inputHelper}>{TEXTS["password"]}*</div>
                    <TextField
                        autoComplete="new-password"
                        size='small'
                        style={styles.input}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant='outlined'
                        type={showPassword ? 'text' : 'password'}
                        error={errorList.includes("password")}
                        InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"start"}
                                        sx={styles.startAdornmentRTL}
                                    >
                                        {showPassword ?
                                            <VisibilityIcon style={styles.icon} onClick={() => setShowPassword(false)} /> :
                                            <VisibilityOffIcon style={styles.icon} onClick={() => setShowPassword(true)} />
                                        }
                                    </InputAdornment>
                                )
                            } :
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"end"}
                                    >
                                        {showPassword ?
                                            <VisibilityIcon style={styles.icon} onClick={() => setShowPassword(false)} /> :
                                            <VisibilityOffIcon style={styles.icon} onClick={() => setShowPassword(true)} />
                                        }
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                    <div style={styles.errorContainer}>
                        {localErrors &&
                            localErrors.find(x => x.field === "password")?.message
                        }
                    </div>
                </div>
                {/*BEGIN: Password */}
            </div>

            <div style={styles.inputRow}>
                {/*BEGIN: Password Confirmation */}
                <div style={styles.inputContainer}>
                    <div style={styles.inputHelper}>{TEXTS["confirmPassword"]}*</div>
                    <TextField
                        autoComplete="new-password"
                        size='small'
                        style={styles.input}
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        variant='outlined'
                        type={showConfirm ? 'text' : 'password'}
                        error={errorList.includes("passwordConfirm")}
                        InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"start"}
                                        sx={styles.startAdornmentRTL}
                                    >
                                        {showConfirm ?
                                            <VisibilityIcon style={styles.icon} onClick={() => setShowConfirm(false)} /> :
                                            <VisibilityOffIcon style={styles.icon} onClick={() => setShowConfirm(true)} />
                                        }
                                    </InputAdornment>
                                )
                            } :
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"end"}
                                    >
                                        {showConfirm ?
                                            <VisibilityIcon style={styles.icon} onClick={() => setShowConfirm(false)} /> :
                                            <VisibilityOffIcon style={styles.icon} onClick={() => setShowConfirm(true)} />
                                        }
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                    <div style={styles.errorContainer}>
                        {localErrors &&
                            localErrors.find(x => x.field === "passwordConfirm")?.message
                        }
                    </div>
                </div>
                {/*BEGIN: Password Confirmation */}
            </div>

            {!invitationCodeFromQuery &&
                <div style={styles.inputRow}>
                    {/*BEGIN: Invitation Code */}
                    <div style={styles.inputContainer}>
                        <div style={styles.inputHelper}>
                            {TEXTS["invitationCode"]}
                            ({TEXTS["optional"]})
                        </div>
                        <TextField
                            autoComplete="new-password"
                            size='small'
                            style={styles.input}
                            value={invitationCode}
                            onChange={(e) => setInvitationCode(e.target.value)}
                            variant='outlined'
                            error={errorList.includes("invitationCode")}
                        />
                        <div style={styles.errorContainer}>
                            {localErrors &&
                                localErrors.find(x => x.field === "invitationCode")?.message
                            }
                        </div>
                    </div>
                    {/*END: Invitation Code */}
                </div>
            }
            <div style={{ ...styles.inputRow, marginTop: 0 }}>
                <div style={styles.inputContainer}>
                    <div className="flex-row gap-small width-100">
                        <Checkbox
                            onChange={() => dispatch(toggleTerms())}
                            checked={termsOfServiceReducer?.accepted}
                            size='small'
                            color='primary'
                        />
                        <div style={styles.termsText}>
                            <div>I have read and accept the</div>
                            <Button onClick={() => setShowDialog(true)} style={styles.termsButton}>
                                Terms of Service
                            </Button>
                        </div>
                    </div>
                    <div style={styles.errorContainer}>
                        {localErrors &&
                            localErrors.find(x => x.field === "termsOfService")?.message
                        }
                    </div>
                </div>
            </div>
            <SuccessBox
                route={constantValues.REGISTER_ROUTE}
                showSuccessBox={showSuccessBox}
            />
            <div style={styles.buttonContainer}>
                <Button
                    style={styles.button}
                    variant='contained'
                    onClick={() => registerUser()}
                    disabled={loading}
                >
                    {TEXTS["register"]}
                    {loading && <CircularProgress />}
                </Button>
            </div>
        </form>
    )
}

export default RegisterationForm