import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import Localization from '../../../localization';
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import Button from '@mui/material/Button';
import constantValues from '../../../misc/constantValues';
import TelegramIcon from '@mui/icons-material/Telegram';
import useMessenger from '../../../hooks/User/useMessenger';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';

function ProfileMessenger() {
    const { disconnectPlatform, loading } = useMessenger();
    const User = useSelector(state => state.userReducer);

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '4em'
        },
        content: {
            width: mobileSize ? '93%' : desktopSize ? '80%' : '95%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'wrap',
            wordWrap: 'break-word'
        },
        inputContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: mobileSize ? 'center' : 'space-between',
            width: mobileSize ? '100%' : '80%',
            marginTop: '2rem',
            gap: 8,
            flexWrap: 'wrap'
        },
        subTitle: {
            marginTop: '2.5rem',
            width: '80%',
            fontWeight: 'bold'
        },
        subText: {
            width: '80%',
            marginTop: '1rem'
        },
        inputTitle: {
            color: theme.palette.mode === "dark" ? '#bcbcbc' : '#7c7c7c',
            width: mobileSize ? '' : '100px',
            whiteSpace: 'nowrap'
        },
        inputRoot: {
            width: mobileSize ? '8rem' : desktopSize ? '22rem' : '19rem',
        },
        inputParent: {
            flexGrow: 1,
        },
        pageTitle: {
            marginTop: '3rem',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: mobileSize ? 'center' : ''
        },
        titleMargin: {
            width: '100%',
            marginTop: '2rem'
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
        }
    }
    /*END: Styles */
    return (
        <section style={styles.root}>
            <div style={styles.content}>
                <div style={styles.pageTitle}>Connect your messenger to recieve notifications everywhere</div>
                <div style={styles.titleMargin} />

                <div dir='ltr' style={styles.inputContainer}>
                    <div className="flex-row">
                        <TelegramIcon style={{ color: 'rgb(41,182,246)' }} />
                        <div style={styles.inputTitle}>Telegram:</div>
                    </div>
                    <div style={styles.inputParent}>
                        <OutlinedTextField
                            style={styles.inputRoot}
                            size="small"
                            value={User.telegramLink}
                        />
                    </div>
                    {User?.hasTelegram ?
                        <Button
                            disabled={loading}
                            onClick={() => disconnectPlatform('telegram')}
                            variant='outlined'
                            color='error'
                            style={styles.button}
                        >
                            Disconnect
                        </Button> :
                        <Button onClick={() => window.open(User.telegramLink)} style={styles.button} variant='contained'>
                            Open Link
                        </Button>
                    }
                </div>

                <div style={styles.subTitle}>
                    For a better user experience, please run this section with your mobile phone
                </div>
                <div style={styles.subText}>
                    If you have entered the site with your mobile phone, please click on the link and enter
                    the third wave telegram robot. Then click start to connect your account
                </div>

                {/* <div dir='ltr' style={styles.inputContainer}>
                    <div className="flex-row">
                        <WhatsAppIcon style={{ color: 'rgb(64,195,81)' }} />
                        <div style={styles.inputTitle}>Whatsapp:</div>
                    </div>
                    <div style={styles.inputParent}>
                        <OutlinedTextField
                            style={styles.inputRoot}
                            size="small"
                            value="https://allcan.trade"
                        />
                    </div>
                    <Button style={styles.button} variant='contained'>
                        Open Link
                    </Button>
                </div>

                <div style={styles.subTitle}>
                    For a better user experience, please run this section with your mobile phone
                </div>
                <div style={styles.subText}>
                    If you have entered the site with your mobile phone, please click on the link and enter
                    the third wave telegram robot. Then click start to connect your account
                </div>

                <div dir='ltr' style={styles.inputContainer}>
                    <div className="flex-row">
                        <FacebookIcon style={{ color: 'rgb(3,155,229)' }} />
                        <div style={styles.inputTitle}>Facebook:</div>
                    </div>
                    <div style={styles.inputParent}>
                        <OutlinedTextField
                            style={styles.inputRoot}
                            size="small"
                            value="https://allcan.trade"
                        />
                    </div>
                    <Button style={styles.button} variant='contained'>
                        Open Link
                    </Button>
                </div>

                <div style={styles.subTitle}>
                    For a better user experience, please run this section with your mobile phone
                </div>
                <div style={styles.subText}>
                    If you have entered the site with your mobile phone, please click on the link and enter
                    the third wave telegram robot. Then click start to connect your account
                </div> */}
            </div>
        </section>
    )
}

export default ProfileMessenger