import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';
import useTimeframe from '../Signal/useTimeframe';

function useSession() {
    const { getTimeframes } = useTimeframe();
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [loading, setLoading] = useState(false);
    const [didNotLoad, setDidNotLoad] = useState(false);
    const [data, setData] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const createRequest = useRequest();
    const dispatch = useDispatch();

    const deleteSession = (id, callback) => {
        setLoadingDelete(true);

        const onSuccess = (response) => {
            setLoadingDelete(false);
            dispatch(showSuccessNotification('Deleted'));
            callback();
            getTimeframes();
        }

        const onFail = (error) => {
            setLoadingDelete(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            url: constantValues.DELETE_SESSION(id),
            onSuccess,
            onFail
        })
    }

    const getSessions = () => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoading(false);
            setData(response.data.data);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            url: constantValues.GET_SESSIONS,
            onSuccess,
            onFail
        })
    }
    return {
        getSessions,
        loading,
        didNotLoad,
        data,
        loadingDelete,
        deleteSession
    }
}

export default useSession