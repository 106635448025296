import { useTheme } from '@mui/material/styles';
import MarketOverAll from '../../misc/MarketOverAll';
import Header from './components/Header';
import Table from './components/Table';
import useExchange from '../../hooks/Exchange/useExchange';
import { useEffect, useState } from 'react';

function FavoriteCoin() {
    const [searchValue, setSearchValue] = useState('');
    const { getFavoriteSymbols, favSymbols, loading, didNotLoad } = useExchange();
    /*BEGIN: Styles */
    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: '15px'
        }
    }
    /*END: Styles */

    useEffect(() => {
        getFavoriteSymbols();
    }, [])
    return (
        <main style={styles.root}>
            {/*Overall market data*/}
            <MarketOverAll />
            {/*Header */}
            <Header
                favSymbols={favSymbols}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                getFavoriteSymbols={getFavoriteSymbols}
            />
            {/*Table */}
            <Table
                searchValue={searchValue}
                loading={loading}
                didNotLoad={didNotLoad}
                favSymbols={favSymbols}
                getFavoriteSymbols={getFavoriteSymbols}
            />
        </main>
    )
}

export default FavoriteCoin