import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MarketOverAll from "../../misc/MarketOverAll";
import SignalTable from "./components/SignalTable";
import DashboardOptions from "./components/DashboardOptions";
import useStrategy from "../../hooks/Strategy/useStrategy";
import useMediaQuery from '@mui/material/useMediaQuery';

function Dashboard() {
    const {
        getStrategies,
        strategies,
        loading,
        editStrategy,
        result,
        searchStrategy,
        manipulateFavSymbols,
        resetResult,
        coins,
        getCoinPrices
    } = useStrategy();

    const [strategy, setStrategy] = React.useState(null);
    const [matchAll, setMatchAll] = React.useState(false);
    const [timeframe, setTimeframe] = React.useState('1h');
    const [showDialog, setShowDialog] = React.useState(false);
    const [xPage, setXPage] = React.useState(0);
    const [yPage, setYPage] = React.useState(0);

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: '15px',
        }
    }
    /*END: Styles */


    //Get Strategies on mount
    React.useEffect(() => {
        getStrategies('Dashboard');
    }, []);

    React.useEffect(() => {
        if (strategies && !loading) {
            setStrategy(strategies[0]);

            //Searching with default params
            let strat = strategies[0];
            resetResult();
            searchStrategy(
                strat.id,
                matchAll ? 'All' : 'Any',//Execute
                40,//Days
                '1h',//Timeframe
                [],//QuoteAssets
                [],//BaseAssets
                true,//ByTopDays
                false,//ByDateTime
                "",//from
                "",//to
                "Any"//group
            )
        }
    }, [strategies])

    return (
        <main style={styles.root}>
            {/*Overall market data*/}
            <MarketOverAll />

            {/*Dashboard options */}
            <DashboardOptions
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                matchAll={matchAll}
                setMatchAll={setMatchAll}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
                editStrategy={editStrategy}
                searchStrategy={searchStrategy}
                loading={loading}
                result={result}
                strategy={strategy}
                setStrategy={setStrategy}
                setXPage={setXPage}
                setYPage={setYPage}
            />

            {/*Signal Table */}
            <SignalTable
                editStrategy={editStrategy}
                matchAll={matchAll}
                timeframe={timeframe}

                strategy={strategy}
                setStrategy={setStrategy}
                searchStrategy={searchStrategy}
                loading={loading}
                result={result}
                manipulateFavSymbols={manipulateFavSymbols}
                resetResult={resetResult}
                coins={coins}
                getCoinPrices={getCoinPrices}

                setShowDialog={setShowDialog}

                xPage={xPage}
                setXPage={setXPage}
                yPage={yPage}
                setYPage={setYPage}
            />
        </main>
    )
}

export default Dashboard