import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function useTicket() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [loading, setLoading] = useState(false);
    const [didNotLoad, setDidNotLoad] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [result, setResult] = useState(null);
    const [ticket, setTicket] = useState(null);
    const createRequest = useRequest();
    const dispatch = useDispatch();

    const resetResult = () => {
        setResult(null);
    }

    const getTickets = (page) => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoading(false);
            setTickets(response.data.data);
            setResult(null);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            url: constantValues.GET_TICKETS,
            params: { page },
            onSuccess,
            onFail
        })
    }

    const createTicket = (
        Category,
        Priority,
        Title,
        Text,
        Attachments //[{FileName,ContentType,Content}]
    ) => {
        setLoading(true);

        const onSuccess = (response) => {
            setLoading(false);
            dispatch(showSuccessNotification('Ticket Created!'));
            setResult({ success: true })
        }
        const onFail = (error) => {
            setLoading(false);
            setResult({ success: false })
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            data: { Category, Priority, Title, Text, Attachments },
            url: constantValues.CREATE_TICKET,
            method: 'POST',
            onSuccess,
            onFail
        })
    }

    const createConversation = (id, Text, Attachments) => {
        setLoading(true);
        const onSuccess = (response) => {
            dispatch(showSuccessNotification('Message Sent!'));
            setResult({ success: true });
            setLoading(false);
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'POST',
            url: constantValues.CREATE_TICKET_CONVERSATION(id),
            data: { Text, Attachments },
            onSuccess,
            onFail
        })
    }

    const closeTicket = (id) => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'POST',
            url: constantValues.CLOSE_TICKET(id),
            onSuccess,
            onFail
        })
    }

    const getTicketById = (id) => {
        setLoading(true);
        const onSuccess = (response) => {
            setTicket(response.data.data);
            setLoading(false);
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_TICKET_BY_ID(id),
            onSuccess,
            onFail
        })
    }

    return {
        createTicket,
        createConversation,
        getTickets,
        getTicketById,
        closeTicket,
        resetResult,
        tickets,
        ticket,
        result,
        loading,
        didNotLoad
    }
}

export default useTicket