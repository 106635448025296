/* Action Types */
const UPDATE_SIGNALS = "UPDATE_SIGNALS";

/* Actions */
export function updateSignals(payload) {
    return {
        type: UPDATE_SIGNALS,
        payload
    }
};

/* Reducer */
const initialState = null;
export function signalsReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_SIGNALS:
            return action.payload;
        default:
            return state;
    }
}