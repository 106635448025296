import { useTheme } from '@mui/material/styles';
import MarketOverAll from "../../misc/MarketOverAll";
import Header from "./components/Header";
import Table from "./components/Table";
import { useEffect, useState } from 'react';
import useStrategy from '../../hooks/Strategy/useStrategy';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

function RunStrategy() {
    const [timeframe, setTimeframe] = useState('1h');
    const [signalDate, setSignalDate] = useState(40);
    const [matchAll, setMatchAll] = useState(false);
    const [exchange, setExchange] = useState('Binance');
    const [tradeType, setTradeType] = useState('Any');
    const [xPage, setXPage] = useState(0);
    const [yPage, setYPage] = useState(0);
    const { id } = useParams();
    const {
        loading,
        result,
        strategy,
        getStrategyById,
        searchStrategy,
        manipulateFavSymbols,
        resetResult,
        coins,
        getCoinPrices
    } = useStrategy();

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: '15px',
        }
    }
    /*END: Styles */


    useEffect(() => {
        //Get Strategy by Id 
        getStrategyById(id);
    }, []);

    useEffect(() => {
        if (strategy) {
            setXPage(0);
            setYPage(0);
            searchStrategy(
                strategy.id,
                matchAll ? 'All' : 'Any',
                signalDate,
                timeframe,
                [],
                [],
                true,
                false,
                null,
                null,
                tradeType
            )
        }
    }, [strategy, matchAll, signalDate, timeframe, tradeType])

    return (
        <main style={styles.root}>
            <MarketOverAll />
            <Header
                exchange={exchange}
                setExchange={setExchange}
                signalDate={signalDate}
                setSignalDate={setSignalDate}
                matchAll={matchAll}
                setMatchAll={setMatchAll}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
                tradeType={tradeType}
                setTradeType={setTradeType}
            />
            <Table
                strategy={strategy}
                loading={loading}
                result={result}
                manipulateFavSymbols={manipulateFavSymbols}
                resetResult={resetResult}
                coins={coins}
                getCoinPrices={getCoinPrices}
                xPage={xPage}
                yPage={yPage}
                setXPage={setXPage}
                setYPage={setYPage}
            />
        </main>
    )
}

export default RunStrategy