import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from "../../../../localization";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@mui/material/styles';
import constantValues from '../../../../misc/constantValues';
import Button from '@mui/material/Button';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import { Link } from 'react-router-dom';
import MuiSkeleton from '../../../../material-ui/Skeleton/MuiSkeleton';
import { showLimitDialog } from '../../../../redux/state/limitReducer';

function Table({ loading, didNotLoad, getBacktestHistory, backtestHistory }) {
    const dispatch = useDispatch();
    const limitReducer = useSelector(state => state.limitReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            overflowX: 'auto',
            marginTop: '4rem'
        },
        tableHeader: {
            border: 0,
            padding: 0,
            paddingBottom: '1em',
            color: theme.palette.mode === 'dark' ? '#d2d2d2' : '#7a7a7a',
            fontWeight: 'normal'
        },
        tableData: {
            paddingTop: mobileSize ? '1em' : '2em',
            paddingBottom: mobileSize ? '1em' : '2em'
        },
        dateData: {
            width: '100%', display: 'flex', justifyContent: 'center'
        },
        buttonData: {
            display: 'flex',
            justifyContent: 'center'
        },
        button: {
            borderRadius: '8px'
        }
    }
    /*END: Styles */

    if (loading || didNotLoad) {
        return (
            <div style={styles.root}>
                <MuiSkeleton
                    variant='rectangular'
                    width={'100%'}
                    height='300px'
                    didNotLoad={didNotLoad}
                    reload={getBacktestHistory}
                />
            </div>
        )
    }

    return (
        <section style={styles.root}>
            {backtestHistory?.length > 0 ?
                <table width="100%">
                    <tr >
                        <th style={styles.tableHeader}>{TEXTS['backtestName']}</th>
                        <th style={styles.tableHeader}>{TEXTS['date']}</th>
                        <th style={styles.tableHeader}>{TEXTS['result']}</th>
                    </tr>
                    {backtestHistory?.map((m, i) =>
                        <tr>
                            <td style={styles.tableData}>{m.name}</td>
                            <td>
                                <div style={styles.dateData}>
                                    {m.from.toLocaleDateString().replaceAll('/', '-')}
                                </div>
                            </td>
                            <td style={styles.tableData}>
                                <div style={styles.buttonData}>
                                    {
                                        !limitReducer?.backtest ?
                                            <Button
                                                onClick={() => dispatch(
                                                    showLimitDialog(
                                                        constantValues
                                                            .ACCOUNT_LIMIT_ENUMS.INTERACT_BACKTEST
                                                    )
                                                )}
                                                sx={{ boxShadow: 0 }}
                                                variant='contained'
                                                style={styles.button}
                                            >
                                                <DataSaverOnIcon />
                                            </Button> :
                                            <Link
                                                to={
                                                    constantValues
                                                        .BACKTEST_RESULT_ROUTE
                                                        .replace(':id', m.original)
                                                }
                                            >
                                                <Button
                                                    sx={{ boxShadow: 0 }}
                                                    variant='contained'
                                                    style={styles.button}
                                                >
                                                    <DataSaverOnIcon />
                                                </Button>
                                            </Link>
                                    }
                                </div>
                            </td>
                        </tr>
                    )}
                </table> :
                <div>
                    {limitReducer?.backtest ?
                        'No backtest added yet. If you have already issued one refresh your page in a few minutes or if not add a new one' :
                        'Please upgrade your plan in order to use this feature'
                    }
                </div>
            }
        </section>
    )
}

export default Table