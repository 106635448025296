/* Action Types */
const SHOW_COOKIE_DIALOG = "SHOW_COOKIE_DIALOG";
const ACCEPT_COOKIE = "ACCEPT_COOKIE";
const RESET_COOKIE = "RESET_COOKIE";
const HIDE_COOKIE_DIALOG = "HIDE_COOKIE_DIALOG";

/* Actions */
export function showCookieDialog() {
    return {
        type: SHOW_COOKIE_DIALOG,
    };
}

export function hideCookieDialog() {
    return {
        type: HIDE_COOKIE_DIALOG
    }
}

export function acceptCookies(options) {
    return {
        type: ACCEPT_COOKIE,
        options
    }
}

export function resetCookies() {
    return {
        type: RESET_COOKIE
    }
}

const initialState = {
    showDialog: false,
    accepted: false,
    optionals: ['tradingview', 'google-analytics']
};

export function cookieReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_COOKIE_DIALOG:
            return { ...state, showDialog: true };
        case HIDE_COOKIE_DIALOG:
            return { ...state, showDialog: false };
        case ACCEPT_COOKIE:
            return { showDialog: false, accepted: true, optionals: action.options };
        case RESET_COOKIE:
            return initialState;
        default:
            return state;
    }
}
