import useMediaQuery from '@mui/material/useMediaQuery';
import Localization from "../../../../localization";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@mui/material/styles';
import constantValues from '../../../../misc/constantValues';
import HelpIcon from '../../../../material-ui/Icons/HelpIcon';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';
import { showLimitDialog } from '../../../../redux/state/limitReducer';

function Header() {
    const dispatch = useDispatch();
    const limitReducer = useSelector(state => state.limitReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '2rem'
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        subText: {
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '2rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '2rem' : '',
            color: theme.palette.mode === 'dark' ? '#b8b7ba' : '#606060'
        },
        helpIcon: {
            marginLeft: '0.4rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
        },
        button: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            textTransform: 'none',
            fontSize: mobileSize ? '12px' : ''
        },
        link: {
            textDecoration: 'none',
        }
    }
    /*END: Styles */
    return (
        <section style={styles.root}>
            <div style={styles.titleContainer}>
                <h2>{TEXTS['backtest']}</h2>
                <HelpIcon style={styles.helpIcon} />
                {!mobileSize &&
                    <div style={styles.subText}>
                        {TEXTS['backtestHistory']}
                    </div>
                }
            </div>
            {
                !limitReducer?.backtest ?
                    <Button
                        onClick={
                            () => dispatch(showLimitDialog(
                                constantValues.ACCOUNT_LIMIT_ENUMS.INTERACT_BACKTEST
                            ))
                        }
                        sx={{ boxShadow: 0 }}
                        variant='contained'
                        style={styles.button}
                    >
                        {TEXTS['addBacktest']}
                    </Button> :
                    <Link style={styles.link} to={constantValues.BACKTEST_SEARCH_ROUTE}>
                        <Button
                            sx={{ boxShadow: 0 }}
                            variant='contained'
                            style={styles.button}
                        >
                            {TEXTS['addBacktest']}
                        </Button>
                    </Link>
            }

        </section>
    )
}

export default Header