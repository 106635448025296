import Paper from '@mui/material/Paper';
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import constantValues from '../../../misc/constantValues';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import usePayment from '../../../hooks/Payment/usePayment';
import { useDispatch } from 'react-redux';
import { showSuccessNotification, showFailNotification } from '../../../redux/state/notificationReducer';
import CircularProgress from '../../../material-ui/CircularProgress/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';

function OtherDetails({ plan, paymentMethod, setStep }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const {
        preCheckoutPayment,
        checkoutPayment,
        loading
    } = usePayment();
    const [currPlan, setCurrPlan] = useState('oneMonth');
    const plan_types = ['oneMonth', 'threeMonth', 'sixMonth', 'twelveMonth'];
    const [discountCode, setDiscountCode] = useState('');
    const [hasDiscount, setHasDiscount] = useState(false);
    const [afterDiscountAmount, setAfterDiscountAmount] = useState(null);
    const typeToName = (i) => {
        switch (i) {
            case 0:
                return TEXTS['1Month' + plan?.name + 'Plan'];
            case 1:
                return TEXTS['3Months' + plan?.name + 'Plan'];
            case 2:
                return TEXTS['6Months' + plan?.name + 'Plan'];
            case 3:
                return TEXTS['1Year' + plan?.name + 'Plan'];
            default:
                return TEXTS['1Month' + plan?.name + 'Plan'];
        }
    }
    const parseName = (p) => {
        return typeToName(plan_types.indexOf(p))
    }
    const calculateTaxes = () => {
        if (plan) {
            return ((plan[currPlan] * plan?.taxPercent) / 100)
        }
        return null
    }

    const initiateCheckout = () => {
        preCheckoutPayment(
            plan?.id,
            paymentMethod,
            currPlan.charAt(0).toUpperCase() + currPlan.slice(1),
            discountCode || undefined,
            () => {
                checkoutPayment(
                    plan?.id,
                    paymentMethod,
                    currPlan.charAt(0).toUpperCase() + currPlan.slice(1),
                    discountCode || undefined
                );
            }
        )
    }

    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            fontWeight: 'bold',
            marginLeft: '0.8rem'
        },
        paper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '1.5em',
            paddingTop: '1.5em',
            marginTop: '1rem',
            boxShadow: mobileSize ? 'none' : ''
        },
        paperContent: {
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '13px'
        },
        inputContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        inputTitle: {
            color: '#7c7c7c',
            marginBottom: '0.4rem'
        },
        inputRoot: {
            width: '100%'
        },
        smallMargin: {
            width: '100%',
            marginTop: '1rem'
        },
        bigMargin: {
            width: '100%',
            marginTop: '2rem'
        },
        flex: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: 10
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2
        },
        applyButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            height: '100%',
            minWidth: '6rem',
            fontSize: '13px',
        },
        planInfo: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '15px',
            marginTop: '1rem'
        },
        planInfoTotal: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '15px',
            marginTop: '1rem',
            fontWeight: 'bold'
        },
        divider: {
            marginTop: '1rem',
            marginBottom: '0.5rem',
            width: '100%'
        },
        confirmButton: {
            marginTop: '1rem',
            width: '60%',
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        backButton: {
            marginTop: '1rem',
            width: '40%',
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        menuItem: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        }
    }

    const checkDiscountCode = () => {
        if (discountCode.length < 3) {
            dispatch(showFailNotification('Invalid Discount Code'))
        } else {
            preCheckoutPayment(
                plan?.id,
                paymentMethod,
                currPlan.charAt(0).toUpperCase() + currPlan.slice(1),
                discountCode,
                (data) => {
                    setHasDiscount(true);
                    setAfterDiscountAmount(data?.total);
                    dispatch(showSuccessNotification('Discount Applied!'))
                }
            )
        }
    }

    const onChangePlan = (np) => {
        setCurrPlan(np);
        setDiscountCode("");
        setHasDiscount(false);
        setAfterDiscountAmount(null);
    }

    return (
        <section style={styles.root}>
            <div style={styles.header}>
                {TEXTS['orderDetails']}
            </div>
            <Paper style={styles.paper}>
                <div style={styles.paperContent}>

                    <div style={styles.inputContainer}>
                        <div style={styles.inputTitle}>
                            {TEXTS['changePlan']}
                        </div>
                        <OutlinedTextField
                            value={currPlan}
                            onChange={(e) => onChangePlan(e.target.value)}
                            style={styles.inputRoot}
                            select
                            size='small'
                            variant='outlined'
                        >
                            {plan_types.map(t =>
                                <MenuItem style={styles.menuItem} value={t} key={t}>
                                    {parseName(t)}
                                </MenuItem>
                            )}
                        </OutlinedTextField>
                    </div>

                    <div style={styles.smallMargin} />

                    <div style={styles.inputContainer}>
                        <div style={styles.inputTitle}>
                            {TEXTS['promoCode']}
                        </div>
                        <div style={styles.flex}>
                            <OutlinedTextField
                                disabled={hasDiscount}
                                value={discountCode}
                                onChange={(e) => setDiscountCode(e.target.value)}
                                style={styles.inputRoot}
                                placeholder={TEXTS['enterPromoCode']}
                                size='small'
                                variant='outlined'
                            />
                            <Button
                                disabled={loading || hasDiscount}
                                onClick={checkDiscountCode}
                                style={styles.applyButton}
                                variant='contained'
                            >
                                {TEXTS['apply']}
                                {loading && <CircularProgress />}
                            </Button>
                        </div>
                    </div>

                    <div style={styles.bigMargin} />

                    <div style={styles.planInfo}>
                        <div>{parseName(currPlan)}</div>
                        <div>{plan && plan[currPlan]} EUR</div>
                    </div>

                    {/* <div style={styles.planInfo}>
                        <div>{TEXTS['taxes']}(20%)</div>
                        <div>{calculateTaxes()} EUR</div>
                    </div> */}
                </div>

                <Divider style={styles.divider} />

                <div style={styles.paperContent}>
                    <div style={styles.planInfoTotal}>
                        <div>{TEXTS['total']}</div>
                        <div style={styles.flexColumn}>
                            {
                                hasDiscount &&
                                <div >
                                    <span style={{ color: 'rgb(120, 201, 192)' }}>
                                        {afterDiscountAmount && afterDiscountAmount}</span> EUR
                                </div>
                            }
                            <div
                                style={hasDiscount ?
                                    {
                                        textDecoration: 'line-through',
                                        textDecorationColor: 'rgb(240, 114, 89)',
                                        fontSize: '0.7rem'
                                    } : {}
                                }>
                                <span
                                    style={hasDiscount ? { color: 'rgb(240, 114, 89)' } : {}}
                                >{plan && (plan[currPlan] + calculateTaxes())}</span> EUR
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
            <div className="flex-row gap-1">
                <Button onClick={() => setStep(0)} variant='outlined' style={styles.backButton}>
                    Back
                </Button>
                <Button
                    disabled={loading}
                    onClick={initiateCheckout}
                    variant='contained'
                    style={styles.confirmButton}
                >
                    {TEXTS['confirmAndPay']}
                    {loading && <CircularProgress />}
                </Button>
            </div>
        </section >
    )
}

export default OtherDetails