import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useBacktest from '../../../hooks/Backtest/useBacktest';
import { useEffect } from 'react';

import MarketOverAll from '../../../misc/MarketOverAll';
import Header from './components/Header';
import Table from './components/Table';

function BacktestHistory() {
    const { getBacktestHistory, backtestHistory, loading, didNotLoad } = useBacktest();
    /*BEGIN: Styles */
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: mobileSize ? '12px' : '15px',
            paddingBottom: '1em'
        },
        content: {
            width: mobileSize ? '97%' : '80%',
            display: 'flex',
            flexDirection: 'column'
        }
    }
    /*BEGIN: Styles */

    useEffect(() => {
        getBacktestHistory();
    }, [])

    return (
        <main style={styles.root}>
            {/* Market Overall */}
            <MarketOverAll />
            <div style={styles.content}>
                {/*Header */}
                <Header />
                {/*Table */}
                <Table
                    loading={loading}
                    didNotLoad={didNotLoad}
                    getBacktestHistory={getBacktestHistory}
                    backtestHistory={backtestHistory}
                />
            </div>
        </main>
    )
}

export default BacktestHistory