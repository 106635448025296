import StarBorderIcon from '@mui/icons-material/StarBorder';
import LinearProgress from '../../../material-ui/LinearProgress/LinearProgress';
import binance_button from '../../../media/binance-button.png';
import tradingview_button from '../../../media/tradingview-button.png';
import coin_marketcap_button from '../../../media/coin-marketcap-button.png';
import useSymbol from '../../../hooks/Exchange/useSymbol';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SocketContext } from '../../../misc/SocketContextProvider';
import MuiSkeleton from '../../../material-ui/Skeleton/MuiSkeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import constantValues from '../../../misc/constantValues';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';
import StarIcon from '../../../material-ui/Icons/StarIcon';

function CoinDetails({ SocketFuncs }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    let { name } = useParams();
    const {
        loadingInfo,
        didNotLoadInfo,
        getSymbolInfo,
        symbolInfo,

        loadingPrice,
        didNotLoadPrice,
        getSymbolPrice,
        symbolPrice,

        toggleFavSymbol,
        loadingToggleFavSymbol
    } = useSymbol();

    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem'
        },
        container: {
            width: desktopSize ? '93%' : '96%',
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'wrap'
        },
        symbolDetails: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2.5rem',
            flexWrap: 'wrap'
        },
        flex: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
        },
        symbolLogo: {
            width: '64px',
            height: '64px'
        },
        symbolName: (length) => ({
            marginLeft: '1rem',
            width: `${length * 19}px`,
            maxWidth: desktopSize ? '200px' : '180px',
            fontSize: desktopSize ? '19px' : '16px',
            fontWeight: 'bold'
        }),
        subText: {
            color: '#c2c2c2',
            marginLeft: '0.5rem',
            marginTop: '0.3rem',
            fontSize: '18px'
        },
        starIcon: {
            width: '1.4em',
            height: '1.4em',
            marginLeft: '0.6rem',
            cursor: 'pointer'
        },
        currPrice: {
            marginLeft: '2rem',
            marginTop: desktopSize ? '' : '1rem',
            display: 'flex',
            alignItems: 'center',
            width: desktopSize ? '160px' : '',
            fontSize: desktopSize ? '24px' : '26px',
        },
        subSmallTextGray: {
            color: '#aeaeae'
        },
        subSmallText: {
            marginLeft: '0.2rem',
            fontWeight: 'bold'
        },
        priceRange: {
            display: 'flex',
            flexDirection: desktopSize ? 'row' : 'column',
            alignItems: 'center',
            marginTop: desktopSize ? '1rem' : '3rem',
            marginLeft: desktopSize ? '5rem' : ''
        },
        progressBar: {
            width: desktopSize ? '215px' : '100%',
            marginLeft: '0.6rem',
            marginRight: '0.6rem',
            marginTop: desktopSize ? '' : '1rem',
        },
        statisticHistory: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: desktopSize ? '' : 'space-between',
            width: desktopSize ? '' : '100%',
            marginLeft: desktopSize ? '1rem' : '',
            marginTop: '3.1rem'
        },
        statisticContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: desktopSize ? '3rem' : '',
            alignItems: 'center',
        },
        statisticTitle: {
            color: '#aeaeae'
        },
        statisticValue: (v) => {
            if (v > 0) {
                return {
                    color: 'green',
                    fontWeight: 'bold'
                }
            }
            return {
                color: '#f07259',
                fontWeight: 'bold'
            }
        },
        exchangeButtonContainer: {
            display: 'flex',
            width: desktopSize ? '' : '100%',
            flexDirection: desktopSize ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: desktopSize ? '' : 'space-between',
            marginLeft: desktopSize ? '3rem' : '',
            marginTop: desktopSize ? '3rem' : '1rem',
            gap: 10
        },
        exchangeButton: {
            width: desktopSize ? '8rem' : '7rem',
            height: '2rem',
            cursor: 'pointer'
        },
        skeletonContainer: {
            marginTop: '2rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem'
        },
        lowAndHighSmall: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    }

    const getData = () => {
        getSymbolInfo(name.replace('USDT', ''));
        getSymbolPrice(name);
    }

    const onToggleFavSymbol = () => {
        toggleFavSymbol(name.replace('USDT', ''), 'USDT');
    }

    useEffect(() => {
        getData();
        SocketFuncs.connectPrices([name]);
        return () => {
            SocketFuncs.disconnectPrices([name]);
        }
    }, [name]);

    const weekPrice = (((Number(symbolPrice?.Close) - (symbolPrice?.Week)) / symbolPrice?.Week) * 100).toFixed(2);
    const monthPrice = (((Number(symbolPrice?.Close) - (symbolPrice?.Month)) / symbolPrice?.Month) * 100).toFixed(2);
    const threeMonthsPrice = (((Number(symbolPrice?.Close) - (symbolPrice?.ThreeMonth)) / symbolPrice?.ThreeMonth) * 100).toFixed(2);
    const linearDetermine = ((Number(symbolPrice?.Close) - symbolPrice?.Low) * 100) / (Number(symbolPrice?.High) - symbolPrice?.Low);

    if (!symbolInfo || !symbolPrice) {
        return (
            <div style={styles.skeletonContainer}>
                <MuiSkeleton
                    variant='rectangular'
                    width={'93%'}
                    height={150}
                    didNotLoad={didNotLoadPrice || didNotLoadInfo}
                    reload={getData}
                />
            </div>
        )
    }

    console.log({ ...symbolInfo, ...symbolPrice }, 'symbolInfo')

    return (
        <section dir='ltr' style={styles.root}>
            <div style={styles.container}>
                {/*BEGIN: Symbol Details */}
                <div style={styles.symbolDetails}>
                    <div style={styles.flex}>
                        <img
                            style={styles.symbolLogo}
                            src={`/media/${name.replace("USDT", "").toLocaleLowerCase()}.svg`}
                            alt={'symbol logo'}
                        />
                        <h3 style={styles.symbolName(symbolInfo?.name)}>
                            {symbolInfo?.name}
                        </h3>
                        <div style={styles.subText}>
                            {symbolInfo?.currency}
                        </div>

                        <StarIcon
                            isFavorite={symbolInfo?.pairs?.find(x => x.quote === 'USDT')?.isFavorite}
                            onClick={onToggleFavSymbol}
                            style={styles.starIcon}
                        />
                        <div style={styles.currPrice}>
                            <h3>$
                                {
                                    SocketFuncs?.result[name]?.c &&
                                    parseFloat(SocketFuncs?.result[name]?.c)
                                }
                                {
                                    !SocketFuncs?.result[name]?.c &&
                                    parseFloat(Number(symbolPrice?.Close))
                                }
                            </h3>
                            <div style={styles.subText}>USD</div>
                        </div>
                    </div>
                    {desktopSize &&
                        <div style={styles.priceRange}>
                            <div style={styles.subSmallTextGray}>
                                Low:
                            </div>
                            <div style={styles.subSmallText}>
                                $
                                {
                                    SocketFuncs?.result[name]?.l &&
                                    parseFloat(SocketFuncs?.result[name]?.l)
                                }
                                {
                                    !SocketFuncs?.result[name]?.l &&
                                    parseFloat(Number(symbolPrice?.Low))
                                }
                            </div>
                            <LinearProgress
                                style={styles.progressBar}
                                variant="determinate"
                                value={linearDetermine}
                            />
                            <div style={styles.subSmallTextGray}>
                                High:
                            </div>
                            <div style={styles.subSmallText}>
                                $
                                {
                                    SocketFuncs?.result[name]?.h &&
                                    parseFloat(SocketFuncs?.result[name]?.h)
                                }
                                {
                                    !SocketFuncs?.result[name]?.h &&
                                    parseFloat(Number(symbolPrice?.High))
                                }
                            </div>
                        </div>
                    }
                    {!desktopSize &&
                        <div style={styles.priceRange}>
                            <div style={styles.lowAndHighSmall}>
                                <div style={styles.flex}>
                                    <div style={styles.subSmallTextGray}>
                                        Low:
                                    </div>
                                    <div style={styles.subSmallText}>
                                        $
                                        {
                                            SocketFuncs?.result[name]?.l &&
                                            parseFloat(SocketFuncs?.result[name]?.l)
                                        }
                                        {
                                            !SocketFuncs?.result[name]?.l &&
                                            parseFloat(Number(symbolPrice?.Low))
                                        }
                                    </div>
                                </div>

                                <div style={styles.flex}>
                                    <div style={styles.subSmallTextGray}>
                                        High:
                                    </div>
                                    <div style={styles.subSmallText}>
                                        $
                                        {
                                            SocketFuncs?.result[name]?.h &&
                                            parseFloat(SocketFuncs?.result[name]?.h)
                                        }
                                        {
                                            !SocketFuncs?.result[name]?.h &&
                                            parseFloat(Number(symbolPrice?.High))
                                        }
                                    </div>
                                </div>
                            </div>
                            <LinearProgress
                                style={styles.progressBar}
                                variant="determinate"
                                value={linearDetermine}

                            />
                        </div>
                    }
                </div>
                {/*END: Symbol Details */}

                {/*BEGIN: Statistics History */}
                <div style={styles.statisticHistory}>
                    <div style={styles.statisticContainer}>
                        <div style={styles.statisticTitle}>Last week:</div>
                        <div style={styles.statisticValue(weekPrice)}>
                            {weekPrice}%
                        </div>
                    </div>
                    <div style={styles.statisticContainer}>
                        <div style={styles.statisticTitle}>Last month:</div>
                        <div style={styles.statisticValue(monthPrice)}>
                            {monthPrice}%
                        </div>
                    </div>
                    <div style={styles.statisticContainer}>
                        <div style={styles.statisticTitle}>Last 3 months:</div>
                        <div style={styles.statisticValue(threeMonthsPrice)}>
                            {threeMonthsPrice}%
                        </div>
                    </div>
                </div>
                {/*END: Statistics History */}

                {/*BEGIN: Exchange Buttons */}
                <div style={styles.exchangeButtonContainer}>
                    <a
                        href={constantValues.generateBinanceLink(
                            symbolInfo.currency, 'USDT'
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={binance_button}
                            alt={'binance'}
                            style={styles.exchangeButton}
                        />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={constantValues.generateTradingViewLink(symbolInfo.currency + 'USDT')}
                    >
                        <img
                            src={tradingview_button}
                            alt={'tradingview'}
                            style={styles.exchangeButton}
                        />
                    </a>

                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={constantValues.generateCoinMarketcapLink(symbolInfo.name)}
                    >
                        <img
                            src={coin_marketcap_button}
                            alt={'coin marketcap'}
                            style={styles.exchangeButton}
                        />
                    </a>
                </div>
                {/*END: Exchange Buttons */}
            </div>
        </section>
    )
}

function WithContext() {
    return (
        <SocketContext.Consumer>
            {value =>
                <CoinDetails SocketFuncs={value} />
            }
        </SocketContext.Consumer>
    )
}

export default WithContext