import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import constantValues from "./constantValues";
import { showCookieDialog } from "../redux/state/cookieReducer";
import { SocketContext } from "./SocketContextProvider";

function MarketOverAll({ SocketFuncs }) {
    //TODO: symbols are hardcoded for now
    let SYMBOLS = ['BTCUSDT', 'ETHUSDT', 'ADAUSDT', 'BNBUSDT', 'XRPUSDT'];
    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const cookieReducer = useSelector(state => state.cookieReducer);
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            backgroundColor: themeReducer === 'light' ?
                constantValues.BACKGROUND_COLOR_1 : constantValues.BACKGROUND_COLOR_2
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: '2em',
            paddingBottom: '2em',
            overflowX: desktopSize ? 'hidden' : 'auto',
            overflowY: 'hidden',
            width: mobileSize ? '100%' : desktopSize ? '84%' : '100%',
            justifyContent: desktopSize ? 'space-between' : '',
            paddingRight: desktopSize ? '' : '2em',
            paddingLeft: desktopSize ? '' : '2em'
        },
        symbol: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: desktopSize ? '' : '2rem'
        },
        logo: {
            width: '24px',
            height: '24px',
            marginRight: '0.4rem'
        },
        name: {
            marginRight: '0.2rem'
        },
        link: {
            textDecoration: 'none', color: 'inherit'
        }
    }
    /*END: Styles */
    const showCookiePop = () => {
        dispatch(showCookieDialog());
    }
    //This component should always remain LTR
    return (
        <section dir='ltr' style={styles.root}>
            <div style={styles.container}>
                {SYMBOLS.map((o, i) =>
                    <div
                        style={
                            i === 0 ? Object.assign({}, styles.symbol, { marginLeft: '' }) :
                                styles.symbol
                        }
                        key={o}
                    >
                        {
                            cookieReducer?.accepted ?
                                <a
                                    style={styles.link}
                                    href={constantValues.SINGLE_SYMBOL_ROUTE.replace(':name', o)}
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    <div className="flex-row">
                                        <img style={styles.logo} src={`/media/${o.replace('USDT', '').toLocaleLowerCase()}.svg`} alt={o} />
                                        <div style={styles.name}>{o}:</div>
                                    </div>
                                </a> :
                                <div onClick={showCookiePop} className="flex-row">
                                    <img style={styles.logo} src={`/media/${o.replace('USDT', '').toLocaleLowerCase()}.svg`} alt={o} />
                                    <div style={styles.name}>{o}:</div>
                                </div>
                        }
                        <div style={{ width: `${SocketFuncs?.result[o]?.c.length * 7}px` }}>
                            {
                                parseFloat(SocketFuncs?.result[o]?.c)
                            }
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

function WithContext() {
    return (
        <SocketContext.Consumer>
            {value =>
                <MarketOverAll SocketFuncs={value} />
            }
        </SocketContext.Consumer>
    )
}

export default WithContext