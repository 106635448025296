import { Grid } from "@mui/material"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import constantValues from "../../../misc/constantValues";

function Footer() {
    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem'
        },
        item: {
            display: 'flex',
            width: '100%',
            marginTop: '1.5rem'
        },
        sub: {
            color: '#7c7c7c',
            width: '250px',
        },
        main: {
            width: '250px',
        },
        icons: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '2rem',
            gap: 20
        }
    }
    return (
        <Grid item xs={12}>
            <div style={styles.container}>

                <div style={styles.item}>
                    <div style={styles.main}>
                        Sales Department:
                    </div>
                    <div style={styles.sub}>
                        sales@allcan.trade
                    </div>
                </div>

                <div style={styles.item}>
                    <div style={styles.main}>
                        Support Department:
                    </div>
                    <div style={styles.sub}>
                        support@allcan.trade
                    </div>
                </div>

                {/* <div style={styles.item}>
                    <div style={styles.main}>
                        Tell(Working Days):
                    </div>
                    <div style={styles.sub}>
                        +58 425 555 1212
                    </div>
                </div> */}

                {/* <div style={styles.item}>
                    <div style={styles.main}>
                        Address:
                    </div>
                    <div style={styles.sub}>
                        Robert Robertson, 1234 NW Bobcat Lane, St. Robert, MO 65584-5678
                    </div>
                </div> */}

                <div style={styles.icons}>
                    <a target="_blank" rel="noreferrer" href={constantValues.TELEGRAM_LINK}>
                        <TelegramIcon color='primary' />
                    </a>
                    <a target="_blank" rel="noreferrer" href={constantValues.LINKEDIN_LINK}>
                        <LinkedInIcon color='primary' />
                    </a>
                    <a target="_blank" rel="noreferrer" href={constantValues.YOUTUBE_LINK}>
                        <YouTubeIcon color='primary' />
                    </a>
                </div>
            </div>
        </Grid>
    )
}

export default Footer