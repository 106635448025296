import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import constantValues from "../../misc/constantValues";
import MarketOverAll from "../../misc/MarketOverAll";
import Introduction from "./components/Introduction";
import GraphicSummary from "./components/GraphicSummary";
import TabSummary from "./components/TabSummary";
import HowACTWorks from "./components/HowACTWorks";
import EasyToUse from "./components/EasyToUse";
import ACTJobAtGlance from "./components/ACTJobAtGlance";
import Products from "./components/Products";
import News from "./components/News";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";

const MOCK_PLANS = [{ "id": "421cd8b8-010d-4702-a2e0-08daa0b1c284", "name": "Bronze", "marketType": "CRYPTO", "simultaneousAccess": 1, "notification": true, "advancedNotification": false, "timeFrames": ["30m", "1h", "4h", "6h", "12h", "1D", "1W"], "strategy": 5, "favoriteCoin": 20, "backTest": false, "oneMinute": false, "oneMonth": 30, "threeMonth": 85, "sixMonth": 160, "twelveMonth": 300 }, { "id": "e633871b-12a0-43ae-a2e2-08daa0b1c284", "name": "Gold", "marketType": "CRYPTO", "simultaneousAccess": 2, "notification": true, "advancedNotification": true, "timeFrames": ["5m", "15m", "30m", "1h", "4h", "6h", "12h", "1D", "1W"], "strategy": 15, "favoriteCoin": 50, "backTest": true, "oneMinute": true, "oneMonth": 90, "threeMonth": 260, "sixMonth": 500, "twelveMonth": 900 }, { "id": "351786a2-c968-428d-a2e4-08daa0b1c284", "name": "Silver", "marketType": "CRYPTO", "simultaneousAccess": 1, "notification": true, "advancedNotification": true, "timeFrames": ["15m", "30m", "1h", "4h", "6h", "12h", "1D", "1W"], "strategy": 10, "favoriteCoin": 35, "backTest": false, "oneMinute": false, "oneMonth": 45, "threeMonth": 125, "sixMonth": 240, "twelveMonth": 450 }];

function Home() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const User = useSelector(state => state.userReducer);
    const isAuthenticated = User?.accessToken && User?.emailConfirmed;
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.palette.text.primary,
            fontSize: mobileSize ? '13px' : '14px',
            overflowX: 'hidden'
        },
        smallPadding: {
            width: '100%',
            paddingTop: '3em'
        },
        largePadding: {
            width: '100%',
            paddingTop: '5em'
        },
        content: {
            width: desktopSize ? '80%' : '90%',
            display: 'flex',
            flexDirection: 'column'
        },
        fullWidthContent: {
            width: '100%',
            display: 'flelx',
            flexDirection: 'column'
        },
        plansHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }
    }

    if (isAuthenticated) {
        return (
            <Navigate to={constantValues.DASHBOARD_ROUTE} replace={true} />
        )
    }

    return (
        <main style={styles.root}>
            <Helmet>
                <title>Smart Trading Assistant | allcan.trade</title>
                <meta name="keywords" content="HTML, CSS, JavaScriptCrypto Trading, Trading strategy, Auto Trader, Technical Analysis, Signal, Auto Chart Analysis, Trading Assistant, Trading Bot, Crypto Monitoring, Crypto Tracker" />
            </Helmet>
            <MarketOverAll />

            <div style={styles.smallPadding} />

            <div style={styles.content}>
                <Introduction />
                <div style={styles.largePadding} />
                <GraphicSummary />
                <div id={'features'} style={styles.largePadding} />
                <TabSummary />
            </div>

            <div style={styles.fullWidthContent}>
                <div style={styles.largePadding} />
                <HowACTWorks />
            </div>

            <div style={styles.content}>
                <div style={styles.largePadding} />
                <EasyToUse />
            </div>

            <div style={styles.fullWidthContent}>
                <div style={styles.largePadding} />
                <ACTJobAtGlance />
            </div>

            <div style={styles.content}>
                <div style={styles.largePadding} />
                <h1 id={'pricing'} style={styles.plansHeader}>
                    ACT Plans
                </h1>
                <div style={styles.largePadding} />
                <Products plans={MOCK_PLANS} />
                <div style={styles.largePadding} />
                <News />
            </div>

            <div style={styles.fullWidthContent}>
                <div style={styles.largePadding} />
                <Footer />
            </div>
        </main>
    )
}

export default Home