import { Grid, Paper } from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MuiSkeleton from "../../../material-ui/Skeleton/MuiSkeleton";
import useBlog from '../../../hooks/Blog/useBlog';
import { useEffect } from "react";


function News() {
    const {
        loading,
        didNotLoad,
        posts,
        getPosts
    } = useBlog();
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        paper: {
            width: '100%',
            height: '519px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '30px',
            boxShadow: '0 22px 56px 0 rgba(48, 12, 92, 0.12)',
            cursor: 'pointer'
        },
        img: {
            objectFit: 'cover',
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            width: '100%',
            height: '300px'
        },
        title: {
            width: '90%',
            fontWeight: 'bold',
            marginTop: '1rem',
            textTransform: 'none',
            textDecoration: 'none',
            cursor: 'pointer'
        },
        text: {
            width: '90%',
            marginTop: '1rem',
            color: '#949494',
            textTransform: 'none',
            textDecoration: 'none',
            cursor: 'pointer'
        },
        link: {
            textDecoration: 'none',
        }
    }

    useEffect(() => {
        getPosts();
    }, [])

    const MAX_LENGTH = mobileSize ? 146 : desktopSize ? 200 : 120;

    if (didNotLoad || loading) {
        return (
            <MuiSkeleton
                variant={'rectangle'}
                width='100%'
                height='519px'
                didNotLoad={didNotLoad}
                reload={getPosts}
            />
        )
    }

    return (
        <Grid id={'learning'} container justifyContent="center" spacing={4}>
            {
                posts?.map(x =>
                    <Grid key={x.text} item xs={12} lg={4} sm={4}>
                        <a style={styles.link} href={x.url} target={'_blank'} rel={'noreferrer'}>
                            <Paper style={styles.paper}>
                                <img style={styles.img} src={x.img} alt={'img-paper'} />
                                <div style={styles.title}>
                                    {x.title}
                                </div>
                                <div style={styles.text}>
                                    {
                                        x.text.length > MAX_LENGTH ?
                                            x.text.substring(0, MAX_LENGTH) + '...' :
                                            x.text
                                    }
                                </div>
                            </Paper>
                        </a>
                    </Grid>
                )
            }
        </Grid>
    )
}

export default News