/* Action Types */
const TOGGLE_TERMS = "TOGGLE_TERMS";
const ACCEPT_TERMS = "ACCEPT_TERMS";
const RESET_TERMS = "RESET_TERMS";

/* Actions */
export function toggleTerms() {
    return {
        type: TOGGLE_TERMS,
    }
}

export function acceptTerms() {
    return {
        type: ACCEPT_TERMS
    }
}

export function resetTerms() {
    return {
        type: RESET_TERMS
    }
}

const initialState = { accepted: false };

export function termsOfServiceReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_TERMS:
            return { accepted: !state.accepted };
        case ACCEPT_TERMS:
            return { accepted: true }
        case RESET_TERMS:
            return initialState
        default:
            return state
    }
}