import { Paper } from "@mui/material";
import Fade from '@mui/material/Fade';
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import useVerifyEmail from "../../../../hooks/User/useVerifyEmail";
import CountdownTimer from "../../../../misc/CountdownTimer";
import { useNavigate, useLocation } from "react-router-dom";
import constantValues from "../../../../misc/constantValues";
import { useSelector, useDispatch } from "react-redux";
import Localization from "../../../../localization/Localization";
import Dialog from '@mui/material/Dialog';
import useToken from "../../../../hooks/User/useToken";

function SuccessBox({ showSuccessBox, route }) {
    const { revokeToken, loading: loadingRevoke } = useToken();
    const dispatch = useDispatch();
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    const location = useLocation();
    const User = useSelector(state => state.userReducer);
    const navigate = useNavigate();
    const [countdownDate, setCountdownDate] = useState(0);
    const [isCountdown, setIsCountdown] = useState(false);
    const [show, setShow] = useState(true);

    const {
        loadingResend,
        resendVerify,
    } = useVerifyEmail();

    const onResendVerify = () => {
        resendVerify();
        let seconds = 1000 * 62;
        let newCountdown = new Date().getTime() + seconds;
        setCountdownDate(newCountdown);
        setIsCountdown(true);
        setTimeout(() => setIsCountdown(false), seconds)
    }
    const styles = {
        root: {
            width: '100%',
            paddingTop: '1em',
            paddingBottom: '1em',
            backgroundColor: 'rgb(29, 26, 46)',
            display: 'flex',
            justifyContent: 'center',
        },
        content: {
            display: 'flex',
            width: '90%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize: '14px'
        },
        header: {
            color: route === constantValues.LOGIN_ROUTE ? '#f07259' : '#78c9c0',
            fontWeight: 'bold'
        },
        text: {
            color: 'white',
            marginTop: '1rem',
            textAlign: 'center'
        },
        actions: {
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            gap: 10
        },
        primaryAction: {
            textTransform: 'none'
        },
        secondaryAction: {
            textTransform: 'none'
        }
    }

    const onOkay = () => {
        revokeToken(true);
        setShow(false);
    }

    useEffect(() => {
        if (User?.accessToken && User?.emailConfirmed) {
            navigate('/');
        }
        if (User?.accessToken) {
            setShow(true);
        }
    }, [User?.accessToken, User?.emailConfirmed])

    if (!showSuccessBox) {
        return null
    }

    return (
        <Dialog
            open={showSuccessBox && show}
            dir={
                constantValues.IS_RTL_LANGUAGE(language) ?
                    'rtl' : 'ltr'
            }
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: 'blur(5px)'
                    }
                }
            }}
        >
            <Fade unmountOnExit in={showSuccessBox && show}>
                <div style={styles.root}>
                    <div style={styles.content}>
                        <div style={styles.header}>
                            {
                                route === constantValues.REGISTER_ROUTE &&
                                'Registeration Successful!'
                            }
                            {
                                route === constantValues.LOGIN_ROUTE &&
                                'Email not verified'
                            }
                        </div>
                        <div style={styles.text}>
                            You need to <b>Confirm Your Email</b> in order to continue forward.
                            If you can't find the confirmation link in your inbox please check your <b>Spam Folder</b> and then try Resending the link
                        </div>
                        <div style={styles.actions}>
                            <Button
                                onClick={onResendVerify}
                                disabled={isCountdown || loadingResend}
                                variant={'outlined'}
                                style={styles.primaryAction}
                            >
                                {
                                    isCountdown ? <CountdownTimer countdownDate={countdownDate} /> :
                                        'Resend'
                                }
                            </Button>
                            <Button
                                onClick={onOkay}
                                variant={'contained'}
                                style={styles.secondaryAction}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Dialog>
    )
}

export default SuccessBox