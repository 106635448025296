/* Action Types */
const INITIATE_TRIAL = "INITIATE_TRIAL";
const SHOW_INITIATE = "SHOW_INITIATE";
const END_TRIAL = "END_TRIAL";
const FIX_STATE = "FIX_STATE";

/* Actions */
export function initiateTrial(email) {
    return {
        type: INITIATE_TRIAL,
        email
    }
}

export function showInitiate(email) {
    return {
        type: SHOW_INITIATE,
        email
    }
}

export function endTrial(email) {
    return {
        type: END_TRIAL,
        email
    }
}

export function fixState(){
    return {
        type: FIX_STATE
    }
}

/* Reducer */
const initialState = [
    // { isFinished: true, date: null, isShownInitiate: true, email }
];
export function trialReducer(state = initialState, action) {
    switch (action.type) {
        case INITIATE_TRIAL:

            const exists = state.find(x => x.email === action.email);

            if (!exists) {
                const initiateTrial = [
                    ...state,
                    { email: action.email, isFinished: false, date: new Date(), isShownInitiate: false }
                ]
                return initiateTrial
            }

            return state;

        case SHOW_INITIATE:

            const showInitiate = state.map(x => {
                if (action.email === x.email) {
                    return { ...x, isShownInitiate: true }
                }
                return x;
            });

            return showInitiate;

        case END_TRIAL:

            const endTrial = state.map(x => {
                if (x.email === action.email) {
                    return { ...state, isFinished: true };
                }
                return x;
            });

            return endTrial;

        case FIX_STATE:
            if(!Array.isArray(state)){
                return initialState;
            }
            return state;    
        default:
            return state;
    }
}