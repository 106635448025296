import { useEffect } from "react";
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser } from "../../../redux/state/userReducer";
import constantValues from "../../../misc/constantValues";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function LoginByQuery() {
    const query = useQuery();
    const accessToken = query.get("accessToken");
    const refreshToken = query.get("refreshToken");
    const redirectURL = query.get("redirectURL");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (accessToken && refreshToken) {
            dispatch(loginUser({ accessToken, refreshToken, emailConfirmed: true }))
            setTimeout(() => {
                let url = constantValues.PLAN_ROUTE;
                if(redirectURL){
                    url = redirectURL;
                }
                navigate(url)
            }, 2000)
        }
    }, [])
    return (
        <div>Redirecting...</div>
    )
}

export default LoginByQuery