import constantValues from '../../../misc/constantValues';
import HelpIcon from '../../../material-ui/Icons/HelpIcon';
import { useTheme } from '@mui/material/styles';
import useExchange from '../../../hooks/Exchange/useExchange';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';
import MuiSkeleton from '../../../material-ui/Skeleton/MuiSkeleton';
import Pagination from '../../../material-ui/Pagination/Pagination';
import ChartDialog from '../../../misc/TradingViewChartDialog';
import chart_icon from "../../../media/chart-line-icon.png";
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import MenuItem from '@mui/material/MenuItem';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';


const ROWS_PER_PAGE = 5;

function CoinTable({ timeframe, setTimeframe }) {
    const limitReducer = useSelector(state => state.limitReducer);
    const User = useSelector(state => state.userReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    let { name } = useParams();
    const timeframes = useSelector(state => state.timeframesReducer);
    const { getSignalByPairAndTimeframe, loading, didNotLoad, signals } = useExchange();
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [showChart, setShowChart] = useState(false);
    const [shownTimeframe, setShownTimeframe] = useState(timeframe);
    const [selected, setSelected] = useState(null);
    const styles = {
        root: {
            width: '93%',
            marginTop: '2rem',
            paddingBottom: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowX: 'auto'
        },
        titleRoot: {
            display: 'flex',
            justifyContent: 'center'
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        subText: {
            fontSize: '11px',
            width: '100%',
            textAlign: 'left',
            color: constantValues.ICON_COLOR_1,
        },
        titleText: {
            textAlign: 'left',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        },
        tableRoot: {
            marginBottom: '0.4rem',
            overflowX: 'auto',
            width: '100%'
        },
        tableHeader: {
            border: 0,
            paddingBottom: '1.5em',
        },
        tableData: {
            textAlign: 'center',
        },
        helpIcon: {
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.4rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '0.4rem' : ''
        },
        firstTableHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        warning: {
            color: theme.palette.warning.light
        },
        buyType: {
            color: constantValues.FONT_COLOR_1
        },
        tableSignalContainer: {
            textAlign: 'center',
            color: constantValues.FONT_COLOR_1,
            fontWeight: 'bold',
            fontSize: '24px',
            minWidth: '155px',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer'
        },
        signalMatch: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        skeletonContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem'
        },
        primaryColor: {
            color: theme.palette.mode === 'light' ?
                theme.palette.primary.main : constantValues.ICON_COLOR_1
        },
        outlinedTextFieldRoot: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            minWidth: '150px',
            marginTop: '1rem'
        },
        selectTextFieldRoot: {
            paddingRight: 0,
            borderRadius: '8px',
            paddingTop: '0.3em',
            color: theme.palette.text.primary,
            borderColor: constantValues.BORDER_COLOR_1,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        inputLabelRoot: {
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            fontWeight: 'normal',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        selectInputIcon: {
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
        },
    }

    const calcTableContent = () => {
        let result = {};
        let items = [];
        signals?.map(signal => {
            let similarSignals = `${signal?.dateTime}|${signal?.hash}`;
            result = {
                ...result,
                [signal?.insCode]: {
                    ...result[signal?.insCode],
                    [signal?.signalCode]: similarSignals,
                    Currency: signal?.currency
                }
            };
            items.push(signal?.signalCode);
        });
        return { result, items };
    }

    const openChart = (signal) => {
        setShowChart(true);
        let similarSignals = [`${signal?.dateTime}|${signal?.hash}`];
        setSelected({
            similarSignals: similarSignals,
            insCode: signal?.insCode,
            symbol: signal?.currency,
            parentSignalCode: signal?.signalCode[0] + signal?.signalCode[1],
            signalCode: signal?.signalCode,
            interval: constantValues.timeFrameToInterval(signal?.timeFrame),
            timeframe: signal?.timeFrame,
            tableContent: calcTableContent()
        })
    }

    useEffect(() => {
        getSignalByPairAndTimeframe(name.replace('USDT', ''), 'USDT', timeframe);
        setPage(0);
    }, [timeframe, name]);

    if (!signals) {
        return (
            <div style={styles.skeletonContainer}>
                <MuiSkeleton
                    variant='rectangular'
                    width={'93%'}
                    height={400}
                    didNotLoad={didNotLoad}
                    reload={() => getSignalByPairAndTimeframe(name.replace('USDT', ''), 'USDT', timeframe)}
                />
            </div>
        )
    }

    return (
        <section style={styles.root}>

            {/*BEGIN: Chart Dialog */}
            {showChart && selected &&
                <ChartDialog
                    open={showChart}
                    onClose={() => setShowChart(false)}
                    setSelected={setSelected}

                    symbol={selected?.symbol}
                    similarSignals={selected?.similarSignals}
                    parentSignalCode={selected?.parentSignalCode}
                    signalCode={selected?.signalCode}
                    insCode={selected?.insCode}

                    interval={selected?.interval}

                    timeframe={selected?.timeframe}
                    tableContent={selected?.tableContent}

                />
            }
            {/*END: Chart Dialog */}

            {/*BEGIN: Timeframe */}
            <div className="width-100">
                <OutlinedTextField
                    value={timeframe}
                    defaultValue={constantValues.DEFAULT_CRYPTO_TIMEFRAME}
                    onChange={(e) => setTimeframe(e.target.value)}
                    style={styles.outlinedTextFieldRoot}
                    label={TEXTS["timeframe"]}
                    select
                    size='small'
                    variant='outlined'
                    sx={{
                        "& .MuiOutlinedInput-root": styles.selectTextFieldRoot,
                        "& .MuiInputLabel-root": styles.inputLabelRoot,
                        "& .MuiSelect-icon": styles.selectInputIcon
                    }}
                >
                    <MenuItem style={styles.menuItem} disabled value={null}>
                        {TEXTS["timeframe"]}
                    </MenuItem>
                    {
                        User?.accessToken &&
                        timeframes?.map(t =>
                            !limitReducer?.timeFrames?.includes(t) ?
                                <Tooltip
                                    title={
                                        'You must upgrade your plan in order to use this timeframe. Click to view plans'
                                    }
                                    arrow
                                >
                                    <Link
                                        to={constantValues.PLAN_ROUTE}
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <span>
                                            <MenuItem
                                                disabled
                                                value={t}
                                                key={t}
                                            >
                                                <div className="flex-row">
                                                    <LockOutlinedIcon fontSize='small' />
                                                    {t}
                                                </div>
                                            </MenuItem>
                                        </span>
                                    </Link>
                                </Tooltip> :
                                <MenuItem
                                    value={t}
                                    key={t}
                                >
                                    {t}
                                </MenuItem>
                        )
                    }
                    {
                        !User?.accessToken &&
                        ['5m', '15m', '30m', '1h', '4h', '6h', '12h', '1D', '1W'].map(t =>
                            !['1W'].includes(t) ?
                                <Tooltip
                                    placement='right'
                                    title={
                                        'You must register in order to view this timeframe'
                                    }
                                    arrow
                                >
                                    <span>
                                        <MenuItem
                                            disabled
                                            value={t}
                                            key={t}
                                        >
                                            <div className="flex-row">
                                                <LockOutlinedIcon fontSize='small' />
                                                {t}
                                            </div>
                                        </MenuItem>
                                    </span>
                                </Tooltip> :
                                <MenuItem
                                    value={t}
                                    key={t}
                                >
                                    {t}
                                </MenuItem>
                        )
                    }
                </OutlinedTextField>
            </div>
            {/*END: Timeframe */}

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <table width="100%" style={styles.tableRoot}>
                    <tr>
                        <th style={styles.tableHeader}>
                            <div style={styles.firstTableHeader}>
                                <h3>{TEXTS['lastSignals']}</h3>
                                <HelpIcon style={styles.helpIcon} />
                            </div>
                        </th>
                        <th style={styles.tableHeader}>{TEXTS['timeframe']}</th>
                        <th style={styles.tableHeader}>{TEXTS['date']}</th>
                        <th style={styles.tableHeader}>{TEXTS['signal']}</th>
                    </tr>
                    {signals?.slice(page * ROWS_PER_PAGE, (page * ROWS_PER_PAGE) + ROWS_PER_PAGE).map((s, i) =>
                        <tr key={s.hash}>
                            <td style={styles.tableData}>
                                <div style={styles.titleRoot}>
                                    <div style={styles.titleContainer}>
                                        <div style={styles.titleText}>{TEXTS[`signal-${s.signalCode}`] || `Unknown(${s.signalCode})`}</div>
                                        <div style={styles.subText}>
                                            {TEXTS[`signal-${s.signalCode.slice(0, 2)}`] || `Unknown(${s.signalCode.slice(0, 2)})`}/<span
                                                style={
                                                    constantValues.isSellOrBuySignal(s.signalCode) === 'Buy' ?
                                                        styles.buyType : styles.warning
                                                }
                                            >
                                                {constantValues.isSellOrBuySignal(s.signalCode)}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <HelpIcon signalCode={s.signalCode} style={styles.helpIcon} />
                                    </div>
                                </div>
                            </td>
                            <td style={{ ...styles.tableData, ...styles.primaryColor }}>
                                {timeframe}
                            </td>
                            <td style={{ ...styles.tableData, ...styles.primaryColor }}>
                                {
                                    constantValues.convertDateToTableFormat(
                                        new Date(s.dateTime)
                                    )
                                }
                            </td>
                            <td style={styles.tableData}>
                                <div onClick={() => openChart(s)} style={styles.tableSignalContainer}>
                                    <div style={styles.signalMatch}>
                                        <img src={chart_icon} alt='chart' />
                                        <div>
                                            {'FIT'}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                </table>
            </div>
            <Pagination
                current={page + 1}
                max={Math.ceil(signals.length / ROWS_PER_PAGE)}
                onNext={() => setPage(prev => prev + 1)}
                onPrevious={() => setPage(prev => prev - 1)}
            />
        </section>
    )
}

export default CoinTable