import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useTimeframe from '../hooks/Signal/useTimeframe';
import useSignal from '../hooks/Signal/useSignal';
import useProfile from '../hooks/User/useProfile';
import useLimit from '../hooks/Limit/useLimit';

function EssentialDataManager() {
    const User = useSelector(state => state.userReducer);
    const { getTimeframes } = useTimeframe();
    const { getSignals } = useSignal();
    const { getProfile } = useProfile();
    const { getLimits } = useLimit();

    useEffect(() => {
        if (User?.accessToken && User?.emailConfirmed) {
            getTimeframes();
            getProfile();
            getSignals();
            getLimits();
        }
    }, [User?.accessToken, User?.emailConfirmed])

    return (
        <div style={{ display: 'none' }} />
    )
}

export default EssentialDataManager