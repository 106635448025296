/* Action Types */
const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

/* Actions */
export function changeLanguage(data){
    return {
        type: CHANGE_LANGUAGE,
        payload: data
    }
}

/* Reducer */
const initialState = "en";
export function localizationReducer(state = initialState, action){
    switch(action.type){
        case CHANGE_LANGUAGE:
            return action.payload;
        default:
            return state;    
    }
}