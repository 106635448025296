import Dialog from "@mui/material/Dialog";
import constantValues from "./constantValues";
import Localization from "../localization";
import { useSelector, useDispatch } from "react-redux";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Button, Checkbox } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import { useEffect, useState } from "react";
import LabelIcon from '@mui/icons-material/Label';
import { acceptCookies, hideCookieDialog, showCookieDialog } from "../redux/state/cookieReducer";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import CustomCheckbox from "../material-ui/Checkbox/CustomCheckbox";

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const LIST_1 = [
    {
        icon: <AccountCircleIcon color="primary" />,
        text: 'Personalised ads and content, ad and content measurement, audience insights and product development'
    },
    {
        icon: <ArchiveIcon color="primary" />,
        text: 'Store and/or access information on a device'
    }
];

const LIST_2 = [
    {
        icon: 'A',
        text: 'Google analytics and Tag Manager : Used to differentiate between users and analayze how our users visit our website, the number of times and their last date and first date of visit also to throttle request rate of analytics and check how users use the website'
    },
    {
        icon: 'A',
        text: "Tradingview: Collects statistics on the user's interaction with the realtime price-and stock bar on the website and also to control the looks of the chart"
    },
    {
        icon: 'A',
        text: 'Keep track of user profile'
    },
    {
        icon: 'A',
        text: 'Managing your preferred theme'
    },
    {
        icon: 'A',
        text: 'Localization'
    },
    {
        icon: 'A',
        text: 'Notification Manager'
    },
    {
        icon: 'A',
        text: 'Available Timeframes'
    },
    {
        icon: 'A',
        text: 'Available Signals'
    },
    {
        icon: 'A',
        text: 'Managing your account limits'
    },
    {
        icon: 'A',
        text: 'Managing your free trial popup'
    },
    {
        icon: 'A',
        text: 'Managing your cookies permissions'
    },
]

const LIST = [
    {
        key: 'essentials',
        open: false,
        active: true,
        title: 'Essential Cookies(persist:root)',
        description: 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies',
        items: [
            "Keep track of user profile",
            "Managing your preferred theme",
            "Localization",
            "Notification Manager",
            "Available Timeframes",
            "Available Signals",
            "Managing your account limits",
            "Keeping track of your free trial",
            "Keeping track of your accounts limits",
            "Managing your cookies permissions",
            "Tradingview Interractions"
        ]
    },
    {
        key: 'google-analytics',
        open: false,
        active: true,
        title: "Statistics",
        description: 'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously',
        items: [
            "Google analytics/Tag manager(_ga,_ga_#,_gat,_gid)"
        ]
    },
    {
        key: 'tradingview',
        open: false,
        active: true,
        title: 'Marketing',
        description: "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers",
        items: [
            "Tradingview: Collects statistics on the user's interaction with the realtime price-and stock bar on the website and also to control the looks of the chart"
        ]
    }
]

function CookieDialog() {
    const [cookieList, setCookieList] = useState(LIST);
    const query = useQuery();
    const location = useLocation();
    const dispatch = useDispatch();
    const [showMore, setShowMore] = useState(false);
    const cookieReducer = useSelector(state => state.cookieReducer);
    /*BEGIN: Localization */
    const language = useSelector((state) => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    const forceCloseCookie = (query.get('acceptCookie'));

    const showDialog =
        !cookieReducer?.accepted &&
        cookieReducer?.showDialog &&
        location.pathname !== constantValues.CHART_ROUTE + '/' &&
        !forceCloseCookie;


    const onChangeCookie = (key, value, index) => {
        let result = cookieList?.map((x, i) => {
            if (i === index) {
                return { ...x, [key]: value }
            }
            return x;
        })
        setCookieList(result)
    }
    const onSubmit = () => {
        let optionalCookies = cookieList
            .filter(c => c.active)
            .map(c => c.key);
        dispatch(acceptCookies(optionalCookies.filter(c => c !== 'essentials')));
    }

    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1.5em',
            paddingBottom: '1.5em'
        },
        container: {
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        subHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            color: '#8b8b8b'
        },
        header: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
            fontSize: '1.3rem',
            fontWeight: 'bold',
            textAlign: 'center'
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
        },
        readMoreList: {
            display: 'flex',
            flexDirection: 'column',
            width: '92%',
            gap: 15,
            marginTop: '0.4rem'
        },
        listItem: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '13px',
            gap: 10
        },
        listHeader: {
            fontSize: '14px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center'
        },
        listDescription: {
            fontSize: '13px',
            fontWeight: 'normal',
            marginLeft: '1rem'
        },
        readMore: {
            width: '90%',
            marginTop: '0.4rem',
            marginLeft: '0.4rem',
            display: 'flex',
            alignItems: 'center',
            fontSize: '13px',
        },
        readMoreIcon: {
            color: '#dcdcdc'
        },
        readMoreButton: {
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            cursor: 'pointer',
            backgroundColor: 'inherit',
            border: 'none',
            paddingLeft: 0
        },
        bottomText: {
            marginTop: '1rem',
            width: '95%',
            fontSize: '13px'
        },
        actions: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1.5rem',
            gap: '10px'
        },
        acceptButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        readMoreItem: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            fontSize: '13px',
            color: '#8b8b8b',
            marginLeft: '1rem'
        }
    }
    const onClose = () => {
        dispatch(hideCookieDialog())
    }

    useEffect(() => {
        if (!cookieReducer?.accepted) {
            setTimeout(() => {
                dispatch(showCookieDialog())
            }, 3000)
        }
    }, [])
    return (
        <Dialog
            open={showDialog}
            maxWidth={"sm"}
            fullWidth
            dir={constantValues.IS_RTL_LANGUAGE(language) ? "rtl" : "ltr"}
            componentsProps={{
                backdrop: {
                    style: {
                        backdropFilter: "blur(5px)",
                    },
                },
            }}
        >
            <div style={styles.root}>
                <div style={styles.container}>
                    <div style={styles.subHeader}>
                        Welcome To Allcan.trade
                    </div>
                    <div style={styles.header}>
                        AllCan asks for your consent in order to use your personal data to:
                    </div>
                    {
                        cookieList.map((x, i) =>
                            <div key={x.key} style={styles.list}>
                                <div style={styles.listHeader}>
                                    <Checkbox
                                        size='small'
                                        disabled={i === 0}
                                        checked={x.active}
                                        onChange={() => onChangeCookie(
                                            'active',
                                            !x.active,
                                            i
                                        )}
                                    />
                                    <div>
                                        {x.title}
                                    </div>
                                </div>
                                <div style={styles.listDescription}>
                                    {x.description}
                                </div>
                                <div style={styles.readMore}>
                                    <button onClick={() => onChangeCookie('open', !x.open, i)} style={styles.readMoreButton}>
                                        <ExpandCircleDownOutlinedIcon style={styles.readMoreIcon} />
                                        <div>
                                            Read More
                                        </div>
                                    </button>
                                </div>
                                <div style={styles.readMoreList}>
                                    <Collapse in={x.open}>
                                        {
                                            x.items.map(item =>
                                                <div key={item} style={styles.readMoreItem}>
                                                    <LabelIcon size='small' color='primary' />
                                                    <div>
                                                        {item}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Collapse>
                                </div>
                            </div>
                        )
                    }
                    <div style={styles.bottomText}>
                        We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised ads and content, ad and content measurement, and audience insights, as well as to develop and improve products.
                    </div>
                    <div style={styles.bottomText}>
                        Alternatively you may access more detailed information and change your preferences before consenting or to refuse consenting. Please note that some processing of your personal data may not require your consent
                    </div>
                    <div style={styles.actions}>
                        <Button onClick={onClose} style={styles.acceptButton} variant='outlined'>
                            Refuse
                        </Button>
                        <Button onClick={onSubmit} style={styles.acceptButton} variant={'contained'}>
                            Accept Cookies
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default CookieDialog
