import { Grid, Button } from "@mui/material";
import introduction_header from "../../../media/introduction-header.svg";
import { Link } from 'react-router-dom';
import constantValues from "../../../misc/constantValues";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Introduction() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        imgContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: desktopSize ? 'center' : ''
        },
        img: {
            width: mobileSize ? '100%' : '70%',
            height: 'auto'
        },
        textContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            marginTop: mobileSize ? '1rem' : ''
        },
        subTextContainer: {
            color: '#8b8b8b',
        },
        padding: {
            width: '100%',
            paddingTop: '1.5em'
        },
        btn: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            width: mobileSize ? '90%' : '60%',
            fontSize: '1.25rem',
            boxShadow: '0 10px 29px 0 rgba(255, 121, 0, 0.34)',
            backgroundColor: '#ff7900',
            borderRadius: '16px'
        }
    }

    if (desktopSize) {
        return (
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <div style={styles.textContainer}>
                        <h1>
                            Empower your Trading Intuition
                        </h1>
                        <div style={styles.padding} />
                        <div style={styles.subTextContainer}>
                            <div>
                                A Smart Aid for your Trading.
                            </div>
                            <div>
                                For Cryptocurrencies and Forex(soon)
                            </div>
                        </div>
                        <div style={styles.padding} />
                        <Button
                            to={constantValues.REGISTER_ROUTE}
                            LinkComponent={Link}
                            variant='contained'
                            style={styles.btn}
                        >
                            Start for FREE 7-Days
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div style={styles.imgContainer}>
                        <img style={styles.img} src={introduction_header} alt={'intro Header'} />
                    </div>
                </Grid>
            </Grid>
        )
    }

    //Mobile & tablet
    return (
        <Grid container>
            <Grid item xs={12} lg={6}>
                <div style={styles.imgContainer}>
                    <img style={styles.img} src={introduction_header} alt={'intro Header'} />
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div style={styles.textContainer}>
                    <h1>
                        Empower your Trading Intuition
                    </h1>
                    <div style={styles.padding} />
                    <div style={styles.subTextContainer}>
                        <div>
                            A Smart Aid for your Trading.
                        </div>
                        <div>
                            For Forex and Cryptocurrencies
                        </div>
                    </div>
                    <div style={styles.padding} />
                    <Button
                        to={constantValues.REGISTER_ROUTE}
                        LinkComponent={Link}
                        variant='contained'
                        style={styles.btn}
                    >
                        Start for FREE 7-Days
                    </Button>
                </div>
            </Grid>
        </Grid>
    )

}

export default Introduction