import { useState, useEffect } from 'react';
import { io } from 'socket.io-client';

const URL = "api.allcan.trade";//api.allcan.trade || api.xxxx.trade
const socket = io(URL, {
    autoConnect: false,
    extraHeaders: {
        token: 'LWpiEYAMKNWadRTux6hDU6rZdHmupTfzauNp3GyweHHp4hNpXHrWhcbkfUuZFKhZ8wSEwn9HgZpJydmyAfvWvapwfWk3gW6nGtqH5utu3UHBZVzGVKXc58iTeGaeLigNtVyQV4EDNXG8VL6kzu8k2V'
    }
});

let SYMBOLS = ['BTCUSDT', 'ETHUSDT', 'ADAUSDT', 'BNBUSDT', 'XRPUSDT'];

function usePriceSocket() {
    const [isConnected, setIsConnected] = useState(false);
    const [result, setResult] = useState({});
    const [connectedSymbols, setConnectedSymbols] = useState([]);

    const connectPrices = (symbols) => {
        if (symbols) {
            let newSymbols = symbols.filter(x => !connectedSymbols.includes(x));
            setConnectedSymbols(prevState => [...prevState, ...newSymbols]);
            //Subscribe to Symbols
            newSymbols.forEach(s => {
                socket.on(s, (event) => {
                    setResult((prevResult) => {
                        const newResult = { ...prevResult };
                        newResult[event.s] = event;
                        return newResult
                    });
                })
            })
        }
    }

    const disconnectPrices = (symbols) => {
        if (symbols) {
            //Filtering out the new symbols but excluding SYMBOLS from filter
            let newSymbols = connectedSymbols
                .filter(x => symbols.includes(x) || SYMBOLS.includes(x))
            setConnectedSymbols(newSymbols);

            symbols
                .filter(x => SYMBOLS.includes(x))
                .forEach(s => {
                    socket.off(s);
                })
        }
    }

    useEffect(() => {
        //Initiate Socket Connection
        socket.connect();
        //Subscribe To Connect/Disconnect
        socket.on('connect', () => {
            setIsConnected(true);
            //Connecting prices that will be used in OverAllMarket
            //TODO: get symbols from servere

            connectPrices(SYMBOLS);
        });
        socket.on('disconnect', () => {
            setIsConnected(false);
        });
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            connectedSymbols.forEach(s => {
                socket.off(s);
            });
            socket.disconnect();
        }
    }, []);

    return { result, isConnected, connectPrices, disconnectPrices }
}

export default usePriceSocket