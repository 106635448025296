import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import Header from './components/Header';
import MessageBox from './components/MessageBox';
import Actions from './components/Actions';

import useTicket from '../../../hooks/Ticket/useTicket';


function TicketDetail() {
    const { id } = useParams();
    const { loading, ticket, getTicketById, createConversation, result } = useTicket();
    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.mode === "dark" ? '#14121d' : '#f7f7f7',
            color: theme.palette.text.primary,
            fontSize: '15px',
            minHeight: '83vh'
        },
        paper: {
            width: desktopSize ? '80%' : '100%',
            marginTop: '2rem',
            paddingTop: '2em',
            paddingBottom: '2em',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem'
        },
        content: {
            width: desktopSize ? '70%' : '95%',
            display: 'flex',
            flexDirection: 'column',
        }
    }
    /*END: Styles */

    useEffect(() => {
        getTicketById(id)
    }, [])
    return (
        <main dir='ltr' style={styles.root}>
            <Paper style={styles.paper}>
                <div style={styles.content}>
                    <Header
                        loading={loading}
                        ticket={ticket}
                        getTicketById={() => getTicketById(id)}
                    />
                    <MessageBox ticket={ticket} />
                    <Actions
                        getTicketById={() => getTicketById(id)}
                        createConversation={createConversation}
                        loading={loading}
                        result={result}
                    />
                </div>
            </Paper>
        </main>
    )
}

export default TicketDetail