import { useSelector, useDispatch } from 'react-redux';
import Localization from '../../localization';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';
import { loginUser } from '../../redux/state/userReducer';

function useSubscription() {
    const dispatch = useDispatch();
    const User = useSelector(state => state.userReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const [loadingSubscription, setLoadingSubscription] = useState(false);
    const [loadingHistory, setLoadingHistory] = useState(false);

    const [didNotLoadSubscription, setDidNotLoadSubscription] = useState(false);
    const [didNotLoadHistory, setDidNotLoadHistory] = useState(false);

    const [subscription, setSubscription] = useState(null);
    const [subscriptionHistory, setSubscriptionHistory] = useState(null);

    const createRequest = useRequest();

    const getCurrentSubscription = () => {
        setLoadingSubscription(true);
        setDidNotLoadSubscription(false);
        const onSuccess = (response) => {
            setLoadingSubscription(false);
            setSubscription(response?.data?.data);
            dispatch(loginUser({ ...User, plan: response.data.data.plan }))
        }
        const onFail = (error) => {
            setLoadingSubscription(false);
            setDidNotLoadSubscription(true);
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_CURRENT_SUBSCRIPTION('CRYPTO'),
            onSuccess,
            onFail
        })
    }

    const getSubscriptionHistory = () => {
        setLoadingHistory(true);
        setDidNotLoadHistory(false);
        const onSuccess = (response) => {
            setLoadingHistory(false);
            setSubscriptionHistory(response?.data?.data)
        }
        const onFail = (error) => {
            setLoadingHistory(false);
            setDidNotLoadHistory(true);
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_SUBSCRIPTION_HISTORY('CRYPTO'),
            onSuccess,
            onFail
        })
    }

    return {
        loadingHistory,
        loadingSubscription,
        didNotLoadHistory,
        didNotLoadSubscription,
        getCurrentSubscription,
        getSubscriptionHistory,
        subscription,
        subscriptionHistory
    }
}

export default useSubscription