import * as React from 'react';
import { useSelector } from "react-redux";
import Localization from "../../../localization";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpIcon from '@mui/icons-material/Help';
import AddIcon from '@mui/icons-material/Add';
import constantValues from '../../../misc/constantValues';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import AntSwitch from '../../../material-ui/Switch/AntSwitch';
import AddStrategyDialog from "./AddStrategyDialog";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';


function DashboardOptions({
    strategy,
    setStrategy,
    editStrategy,
    loading,
    result,
    searchStrategy,
    timeframe,
    setTimeframe,
    matchAll,
    setMatchAll,
    showDialog,
    setShowDialog,
    setXPage,
    setYPage
}) {
    const limitReducer = useSelector(state => state.limitReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Application state */
    const [exchange, setExchange] = React.useState('Binance');
    const [signalDate, setSignalDate] = React.useState(40);
    const [tradeType, setTradeType] = React.useState("Any");
    const timeframes = useSelector(state => state.timeframesReducer);

    /*END: Application state */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));

    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2rem',
            marginBottom: mobileSize ? '1rem' : ''
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '98%',
            flexWrap: 'wrap',
            gap: desktopSize ? 5 : mobileSize ? 5 : 20
        },
        helpIcon: {
            marginLeft: '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
        },
        button: {
            borderRadius: '8px',
            marginLeft: '1.5rem',
            textTransform: 'none',
            padding: '0.6em 1em 0.6em 1em',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        selectTextFieldRoot: {
            paddingRight: 0,
            borderRadius: '8px',
            paddingTop: '0.3em',
            color: theme.palette.text.primary,
            borderColor: constantValues.BORDER_COLOR_1,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        outlinedTextFieldRoot: {
            minWidth: mobileSize ? '140px' : '150px',
            marginLeft: '1.5rem',
            marginTop: mobileSize ? '1rem' : '',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
        },
        matchContainer: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            marginLeft: desktopSize ? '1rem' : '1.5rem',
            marginTop: desktopSize ? '' : '1rem'
        },
        switchText: {
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1.5rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '1.5rem' : ''
        },
        pageHeader: {
            marginLeft: mobileSize ? '1rem' : '',
            marginRight: mobileSize && constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : ''
        },
        searchContainer: {
            marginTop: '2rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        signalDateInput: {
            minWidth: mobileSize ? '140px' : '150px',
            marginLeft: '1.5rem',
            marginTop: mobileSize ? '1rem' : ''
        },
        tradeTypeInput: {
            minWidth: mobileSize ? '130px' : '150px',
            marginLeft: '1.5rem',
            marginTop: mobileSize ? '1rem' : ''
        },
        headerContainer: {
            display: 'flex',
            alignItems: 'center',
            minWidth: mobileSize ? '100%' : '',
            flexWrap: 'wrap'
        },
        inputLabelRoot: {
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            fontWeight: 'normal',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        selectInputIcon: {
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
        },
        inputAdornment: {
            paddingRight: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : '',
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        searchInputNonDeskRoot: {
            minWidth: '180px',
            width: '95%'
        },
        searchInputNonDesk: {
            paddingRight: 0,
            borderRadius: '8px',
            color: theme.palette.text.primary,
        },
        menuItem: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        }
    }
    /*END: Styles */

    const resetPages = () => {
        setXPage(0);
        setYPage(0);
    }

    React.useEffect(() => {
        if (strategy) {
            resetPages();
            searchStrategy(
                strategy.id,
                matchAll ? 'All' : 'Any',
                signalDate,
                timeframe,
                [],
                [],
                true,
                false,
                "",
                "",
                tradeType
            )
        }
    }, [timeframe, signalDate, matchAll, exchange, tradeType])

    return (
        <div style={styles.root}>

            {/*BEGIN: Add Strategy Dialog */}
            <AddStrategyDialog
                resetPages={resetPages}
                searchStrategy={searchStrategy}
                editStrategy={editStrategy}
                loading={loading}
                result={result}
                timeframe={timeframe}
                signalDate={signalDate}
                matchAll={matchAll}
                strategy={strategy}
                setStrategy={setStrategy}
                open={showDialog}
                onClose={() => setShowDialog(false)}
            />
            {/*END: Add Strategy Dialog */}

            <div style={styles.container}>
                <div style={styles.headerContainer}>
                    <h2 style={styles.pageHeader}>{TEXTS["signal"]}</h2>
                    <HelpIcon style={styles.helpIcon} />
                    <Button
                        onClick={() => setShowDialog(true)}
                        sx={{ borderRadius: '8px', boxShadow: 0 }}
                        style={styles.button}
                        variant='contained'
                    >
                        <AddIcon style={{ marginRight: '0.4rem' }} />
                        {TEXTS["addStrategy"]}
                    </Button>
                </div>
                {/*BEGIN: exchange */}
                <OutlinedTextField
                    value={exchange}
                    onChange={(e) => setExchange(e.target.value)}
                    style={styles.outlinedTextFieldRoot}
                    label={TEXTS["exchange"]}
                    select
                    size='small'
                    variant='outlined'
                    sx={{
                        "& .MuiOutlinedInput-root": styles.selectTextFieldRoot,
                        "& .MuiInputLabel-root": styles.inputLabelRoot,
                        "& .MuiSelect-icon": styles.selectInputIcon
                    }}
                >
                    <MenuItem style={styles.menuItem} disabled value={null}>
                        {TEXTS["exchange"]}
                    </MenuItem>
                    <MenuItem value={"Binance"}>
                        Binance
                    </MenuItem>
                    {/* <MenuItem value={"Coinbase"}>
                        Coinbase
                    </MenuItem> */}
                </OutlinedTextField>
                {/*END: exchange */}

                {/*BEGIN: Timeframe */}
                <OutlinedTextField
                    value={timeframe}
                    defaultValue={strategy?.timeFrames[0]}
                    onChange={(e) => setTimeframe(e.target.value)}
                    style={styles.outlinedTextFieldRoot}
                    label={TEXTS["timeframe"]}
                    select
                    size='small'
                    variant='outlined'
                    sx={{
                        "& .MuiOutlinedInput-root": styles.selectTextFieldRoot,
                        "& .MuiInputLabel-root": styles.inputLabelRoot,
                        "& .MuiSelect-icon": styles.selectInputIcon
                    }}
                >
                    <MenuItem style={styles.menuItem} disabled value={null}>
                        {TEXTS["timeframe"]}
                    </MenuItem>
                    {timeframes?.map(t =>
                        !limitReducer?.timeFrames?.includes(t) ?
                            <Tooltip
                                title={
                                    'You must upgrade your plan in order to use this timeframe. Click to view plans'
                                }
                                arrow
                            >
                                <Link
                                    style={{ color: 'inherit', textDecoration: 'none' }}
                                    to={constantValues.PLAN_ROUTE}
                                >
                                    <span>
                                        <MenuItem
                                            disabled
                                            value={t}
                                            key={t}
                                        >
                                            <div className="flex-row">
                                                <LockOutlinedIcon fontSize='small' />
                                                {t}
                                            </div>
                                        </MenuItem>
                                    </span>
                                </Link>
                            </Tooltip> :
                            <MenuItem
                                value={t}
                                key={t}
                            >
                                {t}
                            </MenuItem>
                    )}
                </OutlinedTextField>
                {/*END: Timeframe */}

                {/*BEGIN: Signal Date */}
                <OutlinedTextField
                    value={signalDate}
                    onChange={(e) => setSignalDate(e.target.value)}
                    style={styles.signalDateInput}
                    label={TEXTS["candles"]}
                    select
                    size='small'
                    variant='outlined'
                    sx={{
                        "& .MuiOutlinedInput-root": styles.selectTextFieldRoot,
                        "& .MuiInputLabel-root": styles.inputLabelRoot,
                        "& .MuiSelect-icon": styles.selectInputIcon
                    }}
                >
                    <MenuItem style={styles.menuItem} disabled value={null}>
                        {TEXTS["candles"]}
                    </MenuItem>
                    {constantValues.CANDLE_OPTIONS.map(d =>
                        <MenuItem value={d} key={d}>
                            {`${d} ${d === 1 ? 'Candle' : 'Candles'} ago`}
                        </MenuItem>
                    )}
                </OutlinedTextField>
                {/*END: Signal Date */}

                {/*BEGIN: Trade Type */}
                <OutlinedTextField
                    value={tradeType}
                    onChange={(e) => setTradeType(e.target.value)}
                    style={styles.tradeTypeInput}
                    select
                    label={TEXTS["spot"]}
                    size='small'
                    variant='outlined'
                    sx={{
                        "& .MuiOutlinedInput-root": styles.selectTextFieldRoot,
                        "& .MuiInputLabel-root": styles.inputLabelRoot,
                        "& .MuiSelect-icon": styles.selectInputIcon
                    }}
                >
                    {["Any", "Spot", "Future"].map(x =>
                        <MenuItem style={styles.menuItem} value={x}>
                            {x}
                        </MenuItem>
                    )}
                </OutlinedTextField>
                {/*END: Trade Type */}

                {/*BEGIN: Match */}
                <div style={styles.matchContainer}>
                    <div style={styles.switchText}>{TEXTS["matchAll"]}</div>
                    <AntSwitch checked={matchAll} onChange={(e) => setMatchAll(prev => !prev)} />
                </div>
                {/*END: Match */}
            </div>
        </div>
    )
}

export default DashboardOptions