import { useSelector, useDispatch } from "react-redux";
import Localization from "../../../../localization";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, useEffect, useMemo } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import constantValues from "../../../../misc/constantValues";
import useRecovery from "../../../../hooks/User/useRecovery";
import CircularProgress from "../../../../material-ui/CircularProgress/CircularProgress";
import { showFailNotification } from '../../../../redux/state/notificationReducer';
import { useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

function Form() {
    const query = useQuery();
    const dispatch = useDispatch();
    const { recoverPassword, loading } = useRecovery();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            display: 'flex',
            width: '100%',
            alignItems: 'flex-start',
            flexDirection: 'column',
        },
        inputContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        inputHelper: {
            color: theme.palette.text.disabled,
            fontSize: '11px'
        },
        input: {
            width: '100%',
            backgroundColor: theme.palette.mode === "light" ? 'white' : '#29263a'
        },
        inputRow: {
            width: desktopSize ? '80%' : '100%',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem'
        },
        buttonContainer: {
            width: '100%',
            marginTop: '2rem'
        },
        button: {
            textTransform: 'none',
            width: desktopSize ? '8rem' : '100%',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            borderRadius: '8px'
        },
        errorContainer: {
            fontSize: '11px',
            height: '12px',
            color: theme.palette.error.main
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.warning.light
        },
        icon: {
            cursor: 'pointer'
        }
    }
    /*END: Styles */

    /*BEGIN: Form State */
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    /*BEGIN: Form State */

    const submit = () => {
        if (newPassword !== confirmPassword) {
            dispatch(showFailNotification('Invalid password confirmation'));
            return;
        }
        let token = query.get('q');
        const email = query.get('e');
        recoverPassword(
            email,
            token,
            newPassword,
            'sss' //Captcha
        )
    }

    return (
        <main style={styles.root}>
            <div style={styles.inputRow}>
                <div style={styles.inputContainer}>
                    <div style={styles.inputHelper}>{TEXTS["newPassword"]}</div>
                    <TextField
                        type={showPassword ? 'text' : 'password'}
                        size='small'
                        style={styles.input}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        variant='outlined'
                        InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"start"}
                                        sx={styles.startAdornmentRTL}
                                    >
                                        {showPassword ?
                                            <VisibilityIcon style={styles.icon} onClick={() => setShowPassword(false)} /> :
                                            <VisibilityOffIcon style={styles.icon} onClick={() => setShowPassword(true)} />
                                        }
                                    </InputAdornment>
                                )
                            } :
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"end"}
                                    >
                                        {showPassword ?
                                            <VisibilityIcon style={styles.icon} onClick={() => setShowPassword(false)} /> :
                                            <VisibilityOffIcon style={styles.icon} onClick={() => setShowPassword(true)} />
                                        }
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                    <div style={styles.errorContainer}>

                    </div>
                </div>
            </div>

            <div style={styles.inputRow}>
                <div style={styles.inputContainer}>
                    <div style={styles.inputHelper}>{TEXTS["confirm"]}</div>
                    <TextField
                        type={showConfirm ? 'text' : 'password'}
                        size='small'
                        style={styles.input}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        variant='outlined'
                        InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"start"}
                                        sx={styles.startAdornmentRTL}
                                    >
                                        {showConfirm ?
                                            <VisibilityIcon style={styles.icon} onClick={() => setShowConfirm(false)} /> :
                                            <VisibilityOffIcon style={styles.icon} onClick={() => setShowConfirm(true)} />
                                        }
                                    </InputAdornment>
                                )
                            } :
                            {
                                endAdornment: (
                                    <InputAdornment
                                        position={"end"}
                                    >
                                        {showConfirm ?
                                            <VisibilityIcon style={styles.icon} onClick={() => setShowConfirm(false)} /> :
                                            <VisibilityOffIcon style={styles.icon} onClick={() => setShowConfirm(true)} />
                                        }
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                    <div style={styles.errorContainer}>

                    </div>
                </div>
            </div>

            <div style={styles.buttonContainer}>
                <Button
                    onClick={submit}
                    disabled={loading}
                    style={styles.button}
                    variant='contained'
                    sx={{ boxShadow: 0 }}
                >
                    {TEXTS["submit"]}
                    {loading && <CircularProgress />}
                </Button>
            </div>
        </main>
    )
}

export default Form