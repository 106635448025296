import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import constantValues from '../../misc/constantValues';

function MuiSkeleton({ variant, width, height, didNotLoad, reload }) {
    const styles = {
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        },
    }
    return (
        <Skeleton
            onClick={didNotLoad ? () => reload() : null}
            style={styles.root}
            variant={variant}
            width={width}
            height={height}
        >
            {didNotLoad ? 'Click to retry' : ''}
        </Skeleton>
    )
}

export default MuiSkeleton