import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import Localization from '../../../localization';
import AntSwitch from '../../../material-ui/Switch/AntSwitch';
import Checkbox from '../../../material-ui/Checkbox/CustomCheckbox';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import constantValues from "../../../misc/constantValues";
import useBrokers from '../../../hooks/Brokers/useBrokers';
import MuiSkeleton from '../../../material-ui/Skeleton/MuiSkeleton';
import CircularProgress from '../../../material-ui/CircularProgress/CircularProgress';

function ProfileExchange() {
    const { getBrokers, brokers, loading, didNotLoad, updateBrokers } = useBrokers();
    const [exchanges, setExchanges] = useState(null);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '4em'
        },
        content: {
            width: mobileSize ? '93%' : desktopSize ? '80%' : '95%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'wrap',
            wordWrap: 'break-word'
        },
        pageTitle: {
            marginTop: '3rem',
            fontWeight: 'bold',
            fontSize: '20px',
            width: '80%'
        },
        subText: {
            width: '80%',
            marginTop: '1rem'
        },
        switchContainer: {
            width: '80%',
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'center',
        },
        checkboxsContainer: {
            width: '80%',
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        checkboxParent: {
            width: '200px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem'
        },
        switchText: {
            marginRight: '1rem'
        },
        exchangeName: {
            whiteSpace: 'nowrap'
        },
        buttonContainer: {
            width: '80%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '2rem'
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        }
    }
    /*END: Styles */

    const allBrokersSelected = () => {
        let isSelected = true;
        exchanges.every(e => {
            if (!e.isSelected) {
                isSelected = false;
                return false
            }
            return true
        });
        return isSelected
    }

    const noBrokerSelected = () => {
        let isSelected = true;
        exchanges.every(e => {
            if (e.isSelected) {
                isSelected = false
                return false;
            }
            return true;
        });
        return isSelected;
    }

    const selectAllBrokers = () => {
        let result = exchanges.map(e => ({ ...e, isSelected: true }));
        setExchanges(result);
    }

    const deselectAllBrokers = () => {
        let result = exchanges.map(e => ({ ...e, isSelected: false }))
        setExchanges(result)
    }

    const toggleBroker = (index) => {
        let result = [];
        exchanges.map((e, i) => {
            if (i === index) {
                result.push({ ...e, isSelected: !e.isSelected })
            } else {
                result.push(e);
            }
        })
        setExchanges(result);
    }

    const onToggleAll = () => {
        let isAllChecked = allBrokersSelected();
        if (isAllChecked) {
            deselectAllBrokers();
        } else {
            selectAllBrokers();
        }
    }

    const onUpdateBrokers = () => {
        let symbols = [];
        exchanges.map(x => {
            if (x.isSelected) {
                symbols.push(x.name);
            }
        });
        updateBrokers(symbols);
    }

    useEffect(() => {
        getBrokers();
    }, []);

    useEffect(() => {
        if (brokers) {
            let result = [];
            let keys = Object.keys(brokers);
            keys.map(k => {
                result.push({ name: k, isSelected: brokers[k] });
            });
            setExchanges(result);
        }
    }, [brokers]);

    return (
        <section style={styles.root}>
            <div style={styles.content}>
                <div style={styles.pageTitle}>{TEXTS["availableExchangeServices"]}</div>
                <div style={styles.subText}>
                    {TEXTS["exchangeServicesText"]}
                </div>
                <div style={styles.switchContainer}>
                    <div style={styles.switchText}>{TEXTS["selectAll"]}</div>
                    {exchanges && <AntSwitch
                        checked={allBrokersSelected()}
                        onChange={onToggleAll}
                    />
                    }
                </div>
                {(loading || didNotLoad || !brokers) ?
                    <MuiSkeleton
                        width={'80%'}
                        height={'110px'}
                        reload={getBrokers}
                        variant='rectangular'
                        didNotLoad={didNotLoad}
                    /> :
                    <div style={styles.checkboxsContainer}>
                        {
                            exchanges?.map((e, index) =>
                                <div key={e.name} style={styles.checkboxParent}>
                                    <Checkbox
                                        checked={e.isSelected}
                                        onChange={() => toggleBroker(index)}
                                    />
                                    <div style={styles.exchangeName}>{e.name}</div>
                                </div>
                            )
                        }
                    </div>
                }
                <div style={styles.buttonContainer}>
                    <Button
                        onClick={onUpdateBrokers}
                        disabled={loading || didNotLoad}
                        style={styles.button}
                        variant="contained"
                    >
                        {TEXTS["saveChanges"]}
                        {loading && <CircularProgress />}
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default ProfileExchange