import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import Localization from '../../../localization';
import Grid from "@mui/material/Grid";
import StarIcon from '@mui/icons-material/Star';
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import constantValues from '../../../misc/constantValues';
import { useState, useRef, useEffect } from 'react';
import Countries from '../../../localization/Countries';
import InputAdornment from '@mui/material/InputAdornment';
import useProfile from '../../../hooks/User/useProfile';
import CircularProgress from '../../../material-ui/CircularProgress/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import ChangePwDialog from './components/ChangePWDialog';
import profile_example from "../../../media/profile-example.jpg";
import useSubscription from '../../../hooks/Plan/useSubscription';

function ProfileOverview() {
    /*BEGIN: Application State */
    const User = useSelector(state => state.userReducer);
    const { subscription, getCurrentSubscription } = useSubscription();
    const [firstName, setFirstName] = useState(User?.firstName);
    const [lastName, setLastName] = useState(User?.lastName);
    const [email, setEmail] = useState(User?.email);
    const [phoneNumber, setPhoneNumber] = useState(
        User?.phone?.replace(
            Countries.find(x => x.name === User?.location).dialCode,
            ''
        )
    );
    const [gender, setGender] = useState(User.gender || '');
    const [birthdate, setBirthdate] = useState(User?.birthdate);
    const [location, setLocation] = useState(
        Countries.find(c => c.name === User?.location) || ''
    );
    const [locationValue, setLocationValue] = useState(
        Countries.find(c => c.name === User?.location) || null
    );
    const [avatar, setAvatar] = useState(null);
    const [showChangePWDialog, setShowChangePWDialog] = useState(false);
    const [password, setPassword] = useState(undefined);
    const [newPassword, setNewPassword] = useState(undefined);

    const { loading, updateProfile, getProfile } = useProfile();
    const attachButtonRef = useRef(null);
    /*END: Application State */

    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        profileHeaderRoot: constantValues.IS_RTL_LANGUAGE(language) ?
            {
                display: 'flex',
                alignItems: 'center',
                marginTop: '2rem',
                marginRight: desktopSize ? '2rem' : '',
                width: '100%',
                justifyContent: !mobileSize && !desktopSize ? 'center' : ''
            } : {
                display: 'flex',
                alignItems: 'center',
                marginTop: '2rem',
                marginLeft: desktopSize ? '2rem' : '',
                width: '100%',
                justifyContent: !mobileSize && !desktopSize ? 'center' : ''
            },
        profPic: (pic) => ({
            width: '130px',
            height: '130px',
            borderRadius: '50%',
            backgroundImage: `url(${pic})`,
            // backgroundPosition: 'center center',
            // backgroundRepeat: 'no-repeat',
            // backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            backgroundColor: '#464646',
            marginLeft: '0.6rem'
        }),
        profileHeader: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '1rem'
        },
        accType: {
            fontWeight: 'bold',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.4rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '0.4rem' : ''
        },
        profHeaderDetails: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '0.5rem',
            flexWrap: 'wrap'
        },
        accJoinDate: constantValues.IS_RTL_LANGUAGE(language) ?
            {
                marginRight: mobileSize ? '0.4rem' : '1rem',
                fontSize: '11px'
            } :
            {
                marginLeft: mobileSize ? '0.4rem' : '1rem',
                fontSize: '11px'
            },
        inputTitle: {
            color: '#7c7c7c',
            whiteSpace: 'nowrap',
            fontSize: desktopSize ? '' : '13px'
        },
        inputTitleSecond: constantValues.IS_RTL_LANGUAGE(language) ?
            {
                color: '#7c7c7c',
                whiteSpace: 'nowrap',
                marginRight: desktopSize ? '2rem' : '',
                fontSize: desktopSize ? '' : '13px'
            } :
            {
                color: '#7c7c7c',
                whiteSpace: 'nowrap',
                marginLeft: desktopSize ? '2rem' : '',
                fontSize: desktopSize ? '' : '13px'
            },
        gridContainer: {
            width: desktopSize ? '50%' : '93%',
        },
        contents: {
            width: mobileSize ? '100%' : desktopSize ? '90%' : '80%',
            display: 'flex',
            paddingBottom: '4em',
            flexDirection: desktopSize ? '' : 'column',
            alignItems: mobileSize ? 'center' : ''
        },
        gridMargin: {
            marginTop: '2rem'
        },
        inputContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        outlinedInput: {
            width: '100%',
            maxWidth: '70%',
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        avatarContainer: {
            display: 'flex',
            width: '100%',
            maxWidth: '70%',
            alignItems: 'center',
            border: theme.palette.mode === "light" ? 'solid 1px #ebebeb' : 'solid 1px #44405a',
            borderRadius: '4px'
        },
        avatarPic: (pic) => ({
            width: '60px',
            height: '60px',
            backgroundImage: `url(${pic})`,
            // backgroundPosition: 'center center',
            // backgroundRepeat: 'no-repeat',
            // backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            backgroundColor: '#464646',
        }),
        avatarOptions: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.4rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '0.4rem' : '',
            fontSize: '11px'
        },
        avatarOp: {
            color: avatar ? 'gray' :
                (theme.palette.mode === "dark" ?
                    constantValues.ICON_COLOR_1 : theme.palette.primary.main),
            fontWeight: 'bold',
            marginTop: '0.5rem',
            cursor: avatar ? '' : 'pointer'
        },
        avatarOp2: {
            cursor: 'pointer',
            color: theme.palette.mode === "dark" ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            fontWeight: 'bold',
            marginTop: '0.5rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : ''
        },
        saveChangesContainer: {
            display: 'flex',
            width: desktopSize ? '100%' : '99%',
            justifyContent: 'flex-end',
            marginTop: desktopSize ? '4rem' : '1rem',
            gap: '10px'
        },
        birthDateGridMargin: {
            marginTop: desktopSize ? '' : '2rem'
        },
        changePasswordContainer: {
            width: '100%',
            maxWidth: '70%'
        }
    }
    /*END: Styles */

    const onUpdateProfile = () => {
        updateProfile(
            firstName,
            lastName,
            phoneNumber ? Countries.find(x => x.name === location)?.dialCode + phoneNumber : undefined,
            gender,
            birthdate,
            locationValue?.name,
            avatar,
            (password !== undefined && newPassword !== undefined),//needPasswordChange,
            password,
            newPassword
        )
    }

    const onChangeAttachments = ({ target }) => {
        let file = target.files[0];
        let reader = new FileReader();
        reader.onload = function () {
            let newAttach = {
                FileName: file.name,
                ContentType: file.type,
                Content: reader.result.split(',')[1],
                File: reader.result
            }
            setAvatar(newAttach);
        }
        reader.readAsDataURL(file);
    }

    const onInitiateAttachment = () => {
        if (!avatar) {
            attachButtonRef.current.click();
        }
    }

    useEffect(() => {
        getProfile();
        getCurrentSubscription();
    }, [])

    const calcUserAge = () => {
        let startDate = new Date(User?.registerDateTime);
        let endDate = new Date();
        let diff = endDate.getTime() - startDate.getTime();
        let daysBetween = diff / (1000 * 3600 * 24);
        let roundDaysBetween = Math.round(daysBetween);
        if (roundDaysBetween <= 1) {
            return 'Joined today';
        }
        return `Joined ${roundDaysBetween} days ago`;
    }

    return (
        <section style={styles.root}>
            <ChangePwDialog
                open={showChangePWDialog}
                onClose={() => setShowChangePWDialog(false)}
                currentPassword={password}
                setCurrentPassword={setPassword}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
            />
            {/*BEGIN: Header */}
            <div style={styles.profileHeaderRoot}>
                <img
                    alt=''
                    src={User?.profile}
                    style={styles.profPic(User?.profile || profile_example)}
                />
                <div style={styles.profileHeader}>
                    <h1>{User.firstName + ' ' + User.lastName}</h1>
                    <div style={styles.profHeaderDetails}>
                        <div className="flex-row">
                            <StarIcon style={{ color: '#ffa000' }} />
                            <div style={styles.accType}>{subscription?.plan?.name} member</div>
                        </div>
                        <div style={styles.accJoinDate}>
                            {calcUserAge()}
                        </div>
                    </div>
                </div>
            </div>
            {/*END: Header */}

            <div style={{ marginTop: '2rem' }}></div>

            <div style={styles.contents}>

                {/*BEGIN: Left half of page */}
                <div style={styles.gridContainer}>
                    <Grid container>
                        {/*BEGIN: firstName */}
                        <Grid item xs={12}>
                            <div style={styles.inputContainer}>
                                <div style={styles.inputTitle}>{TEXTS["firstName"]}:</div>
                                <OutlinedTextField
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    style={styles.outlinedInput}
                                    size="small"
                                    placeholder={TEXTS["firstName"]}
                                />
                            </div>
                        </Grid>
                        {/*END: firstName */}

                        {/*BEGIN: lastName */}
                        <Grid item xs={12} style={styles.gridMargin}>
                            <div style={styles.inputContainer}>
                                <div style={styles.inputTitle}>{TEXTS["lastName"]}:</div>
                                <OutlinedTextField
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    style={styles.outlinedInput}
                                    size="small"
                                    placeholder={TEXTS["lastName"]}
                                />
                            </div>
                        </Grid>
                        {/*END: lastName */}

                        {/*BEGIN: email */}
                        <Grid item xs={12} style={styles.gridMargin}>
                            <div style={styles.inputContainer}>
                                <div style={styles.inputTitle}>{TEXTS["email"]}:</div>
                                <OutlinedTextField
                                    disabled={User?.emailConfirmed}
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={styles.outlinedInput}
                                    size="small"
                                    placeholder={TEXTS["email"]}
                                />
                            </div>
                        </Grid>
                        {/*END: email */}

                        {/*BEGIN: phone number */}
                        <Grid item xs={12} style={styles.gridMargin}>
                            <div style={styles.inputContainer}>
                                <div style={styles.inputTitle}>{TEXTS["phoneNumber"]}:</div>
                                <OutlinedTextField
                                    type='tel'
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    style={styles.outlinedInput}
                                    size="small"
                                    placeholder={TEXTS["phoneNumber"]}
                                    InputProps={
                                        !constantValues.IS_RTL_LANGUAGE(language) ?
                                            {
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        sx={styles.inputAdornment}
                                                    >
                                                        <div style={{ marginRight: '0.4rem' }}>
                                                            {
                                                                Countries.find(x => x.name === location)?.dialCode
                                                            }
                                                        </div>
                                                    </InputAdornment>
                                                )
                                            } : {
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        sx={styles.inputAdornment}
                                                    >
                                                        <div style={{ marginLeft: '0.4rem' }}>
                                                            {
                                                                Countries.find(x => x.name === location)?.dialCode
                                                            }
                                                        </div>
                                                    </InputAdornment>
                                                )
                                            }
                                    }
                                />
                            </div>
                        </Grid>
                        {/*END: phone number */}

                        {/*BEGIN: Gender */}
                        <Grid item xs={12} style={styles.gridMargin}>
                            <div style={styles.inputContainer}>
                                <div style={styles.inputTitle}>{TEXTS["gender"]}:</div>
                                <OutlinedTextField
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    style={styles.outlinedInput}
                                    size="small"
                                    placeholder={TEXTS["gender"]}
                                    select
                                >
                                    <MenuItem value={TEXTS["male"]}>{TEXTS["male"]}</MenuItem>
                                    <MenuItem value={TEXTS["female"]}>{TEXTS["female"]}</MenuItem>
                                    <MenuItem value={'Other'}>Other</MenuItem>
                                </OutlinedTextField>
                            </div>
                        </Grid>
                        {/*END: Gender */}
                    </Grid>
                </div>
                {/*END: Left half of page */}

                {/*BEGIN: Right half of page */}
                <div style={styles.gridContainer}>
                    <Grid container>
                        {/*BEGIN: birthDate */}
                        <Grid item xs={12} style={styles.birthDateGridMargin}>
                            <div style={styles.inputContainer}>
                                <div style={styles.inputTitleSecond}>{TEXTS["birthdate"]}:</div>
                                <OutlinedTextField
                                    type='date'
                                    value={birthdate}
                                    onChange={(e) => setBirthdate(e.target.value)}
                                    style={styles.outlinedInput}
                                    size="small"
                                    placeholder={TEXTS["birthdate"]}
                                />
                            </div>
                        </Grid>
                        {/*END: birthDate */}

                        {/*BEGIN: location */}
                        <Grid item xs={12} style={styles.gridMargin}>
                            <div style={styles.inputContainer}>
                                <div style={styles.inputTitleSecond}>{TEXTS["location"]}:</div>
                                {/* <OutlinedTextField
                                    select
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    style={styles.outlinedInput}
                                    size="small"
                                    placeholder={TEXTS["location"]}
                                >
                                    {Countries.map(c =>
                                        <MenuItem key={c.name} value={c.name}>
                                            {c.name}
                                        </MenuItem>
                                    )}
                                </OutlinedTextField> */}
                                <Autocomplete
                                    style={{
                                        width: '100%',
                                        maxWidth: '70%'
                                    }}
                                    value={locationValue}
                                    onChange={(e, newValue) => setLocationValue(newValue)}
                                    inputValue={location}
                                    onInputChange={(e, newValue) => {
                                        setLocation(newValue);

                                    }}
                                    options={Countries}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <div {...props}>
                                            {option.name}
                                        </div>
                                    )}
                                    renderInput={(params) => (
                                        <OutlinedTextField
                                            {...params}
                                            placeholder={TEXTS["location"]}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password'
                                            }}
                                            style={{ ...styles.outlinedInput, width: '100%', maxWidth: '100%' }}
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        {/*END: location */}

                        {/*BEGIN: password */}
                        <Grid item xs={12} style={styles.gridMargin}>
                            <div style={styles.inputContainer}>
                                <div style={styles.inputTitleSecond}>{TEXTS["password"]}:</div>
                                <div style={styles.changePasswordContainer}>
                                    <Button
                                        onClick={() => setShowChangePWDialog(true)}
                                        style={styles.button}
                                        variant='contained'
                                    >
                                        {TEXTS["changePassword"]}
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                        {/*END: password */}

                        {/*BEGIN: Avatar */}
                        <Grid item xs={12} style={styles.gridMargin}>
                            <div style={styles.inputContainer}>
                                <div style={styles.inputTitleSecond}>{TEXTS["avatar"]}:</div>
                                <div style={styles.avatarContainer}>
                                    <div
                                        style={
                                            styles.avatarPic(
                                                User.avatar ||
                                                avatar?.File ||
                                                profile_example
                                            )
                                        }
                                    />
                                    <div style={styles.avatarOptions}>
                                        <div>JPG, GIF or PNG, Max size of 700kb</div>
                                        <div className="flex-row">
                                            <input
                                                ref={attachButtonRef}
                                                hidden
                                                type='file'
                                                multiple
                                                accept={'image/*'}
                                                onChange={onChangeAttachments}
                                            />
                                            <div onClick={onInitiateAttachment} style={styles.avatarOp}>
                                                {TEXTS["uploadPhoto"]}
                                            </div>
                                            <div style={styles.avatarOp2} onClick={() => setAvatar(undefined)}>
                                                {TEXTS["deletePhoto"]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        {/*END: Avatar */}

                        {/*BEGIN: Save Changes */}
                        <Grid item xs={12} style={styles.gridMargin}>
                            <div style={styles.saveChangesContainer}>
                                <Button
                                    variant={'outlined'}
                                    color='error'
                                    LinkComponent={'a'}
                                    refererr={'noreferrer noopener'}
                                    target="_blank"
                                    href="https://allcan.trade/account/delete"
                                >
                                    Delete Account
                                </Button>
                                <Button
                                    disabled={loading}
                                    onClick={onUpdateProfile}
                                    style={styles.button}
                                    variant='contained'
                                >
                                    {TEXTS["saveChanges"]}
                                    {loading && <CircularProgress />}
                                </Button>
                            </div>
                        </Grid>
                        {/*END: Save Changes */}
                    </Grid>
                </div>
                {/*END: Right half of page */}
            </div>
        </section>
    )
}

export default ProfileOverview