import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import constantValues from "../../../../misc/constantValues";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Localization from "../../../../localization";
import { useSelector } from "react-redux";

function Action({ submit, loading }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            display: 'flex',
            width: '100%',
            marginTop: '2rem',
            paddingBottom: '4em'
        },
        button: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            width: mobileSize ? '92%' : '10rem',
            position: mobileSize ? 'fixed' : '',
            bottom: 10,
        }
    }
    return (
        <section style={styles.root}>
            <Grid container justifyContent={'center'}>
                <Grid item xs={11}>
                    <Button
                        sx={{boxShadow: 0}}
                        disabled={loading}
                        style={styles.button}
                        variant='contained'
                        onClick={submit}
                    >
                        {TEXTS['runBacktest']}
                    </Button>
                </Grid>
            </Grid>
        </section>
    )
}

export default Action