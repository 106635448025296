import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function useCooperation() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const [loading, setLoading] = useState(false);

    const validateForm = (
        firstName,
        lastName,
        email,
        phoneNumber,
        city,
        business,
        profession,
        description,
        country
    ) => {
        if (firstName?.length < 2) {
            dispatch(showFailNotification('Please enter your first name correctly'));
            return false;
        }
        if (lastName?.length < 2) {
            dispatch(showFailNotification('Please enter your last name correctly'));
            return false;
        }
        if (!email?.match('^(?=.*[0-9])')) {
            dispatch(showFailNotification('Please enter a proper email'));
            return false;
        }
        if (phoneNumber?.length < 5) {
            dispatch(showFailNotification('Please enter a proper phone number'));
            return false;
        }
        if (city?.length < 3) {
            dispatch(showFailNotification('Please enter a proper city name'));
            return false;
        }
        if (business?.length < 2) {
            dispatch(showFailNotification('Please enter your business name correctly'));
            return false;
        }
        if (profession?.length < 2) {
            dispatch(showFailNotification('Please enter your profession properly'));
            return false;
        }
        if (description?.length < 5) {
            dispatch(showFailNotification('Please be more descriptive about how you want to work with us'));
            return false;
        }

        if (country?.length < 3) {
            dispatch(showFailNotification('Please enter a proper country name'));
            return false;
        }

        return true;
    }

    const requestCooperation = (
        firstName,
        lastName,
        email,
        phoneNumber,
        city,
        business,
        profession,
        description,
        country,
        resetForm
    ) => {

        const isValidForm = validateForm(
            firstName,
            lastName,
            email,
            phoneNumber,
            city,
            business,
            profession,
            description,
            country
        );

        if (!isValidForm) {
            return;
        }

        setLoading(true);
        const onSuccess = () => {
            resetForm();
            setLoading(false);
            dispatch(showSuccessNotification('Your message was submited! We will get back at you as soon as possible'));
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'POST',
            url: constantValues.COOPERATION_WITH_US,
            onFail,
            onSuccess,
            data: {
                firstName,
                lastName,
                email,
                phoneNumber,
                city,
                business,
                profession,
                description,
                country
            }
        })
    }
    return {
        loading,
        requestCooperation
    }
}

export default useCooperation