import { useTheme } from '@mui/material/styles';
import OutlinedTextField from '../../../../material-ui/TextField/OutlinedTextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from "react-redux";
import Localization from "../../../../localization";
import useMediaQuery from '@mui/material/useMediaQuery';
import constantValues from '../../../../misc/constantValues';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import NewTicketDialog from './NewTicketDialog';
import { useState } from 'react';

function Header({ loading, createTicket, result, resetResult, showDialog, setShowDialog }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: desktopSize ? '' : 'space-between',
            flexWrap: 'wrap'
        },
        searchInputRoot: {
            minWidth: mobileSize ? '200px' : '400px',
            marginLeft: desktopSize && !constantValues.IS_RTL_LANGUAGE(language) ? '2rem' : '',
            marginRight: desktopSize && constantValues.IS_RTL_LANGUAGE(language) ? '2rem' : '',
            width: desktopSize ? '' : '100%',
            marginTop: desktopSize ? '' : '1.5rem'
        },
        muiOutlinedInputRoot: {
            paddingRight: 0,
            paddingLeft: 0,
            borderRadius: '8px',
            color: theme.palette.text.primary,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        startAdornmentRTL: {
            paddingRight: 0,
            paddingLeft: 0,
            backgroundColor: theme.palette.mode === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            borderTopLeftRadius: '',
            borderBottomLeftRadius: '',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            marginRight: 0
        },
        endAdornmentLTR: {
            paddingRight: 0,
            backgroundColor: theme.palette.mode === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        adornmentIconColor: {
            color: 'white'
        },
        button: {
            textTransform: 'none',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '2rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '2rem' : '',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        },
        addIcon: {
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.4rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.4rem' : ''
        }
    }
    /*END: Styles */

    return (
        <section style={styles.root}>
            <h2>{TEXTS["tickets"]}</h2>
            {
                !desktopSize &&
                <Button onClick={() => setShowDialog(true)} style={styles.button} variant="contained">
                    <AddIcon style={styles.addIcon} />
                    {TEXTS["newTicket"]}
                </Button>
            }
            <OutlinedTextField
                style={styles.searchInputRoot}
                label={constantValues.IS_RTL_LANGUAGE(language) ? undefined : TEXTS["search"]}
                placeholder={constantValues.IS_RTL_LANGUAGE(language) ? TEXTS["search"] : undefined}
                size='small'
                variant='outlined'
                sx={{
                    "& .MuiOutlinedInput-root": styles.muiOutlinedInputRoot,
                    "& .MuiInputLabel-root": {
                        color: '#BEBEBE',
                    },
                }}
                InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                    {
                        startAdornment: (
                            <InputAdornment
                                position={"start"}
                                sx={styles.startAdornmentRTL}
                            >
                                <SearchIcon style={styles.adornmentIconColor} />
                            </InputAdornment>
                        )
                    } :
                    {
                        endAdornment: (
                            <InputAdornment
                                position={"end"}
                                sx={styles.endAdornmentLTR}
                            >
                                <SearchIcon style={styles.adornmentIconColor} />
                            </InputAdornment>
                        )
                    }
                }
            />
            <NewTicketDialog
                loading={loading}
                createTicket={createTicket}
                resetResult={resetResult}
                open={showDialog}
                onClose={() => setShowDialog(false)}
                result={result}
            />
            {
                desktopSize &&
                <Button onClick={() => setShowDialog(true)} style={styles.button} variant="contained">
                    <AddIcon style={styles.addIcon} />
                    {TEXTS["newTicket"]}
                </Button>
            }
        </section>
    )
}

export default Header