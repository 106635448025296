import HelpIcon from '@mui/icons-material/Help';
import { useTheme } from '@mui/material/styles';
import constantValues from '../../../misc/constantValues';

function Header() {
    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center'
        },
        helpIcon: {
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
            marginLeft: '2rem'
        },
        subText: {
            color: '#606060',
            marginLeft: '2rem'
        }
    }
    return (
        <section style={styles.root}>
            <h1>History</h1>
            <HelpIcon style={styles.helpIcon} />
            <div style={styles.subText}>
                Search history in last 24 hours
            </div>
        </section>
    )
}

export default Header