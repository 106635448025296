import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Grid
} from '@mui/material';
import { useEffect } from 'react';

import Texts from './components/Texts';
import Image from './components/Image';
import Form from './components/Form';

function Cooperation() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.palette.text.primary,
            fontSize: mobileSize ? '13px' : '14px',
            overflowX: 'hidden',
            paddingBottom: '3em'
        },
        padding: {
            width: '100%',
            paddingTop: '2em'
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
    return (
        <main style={styles.root}>
            <div style={styles.padding} />
            <Grid container justifyContent={'center'}>
                {mobileSize ? <Image /> : <Texts />}
                {mobileSize ? <Texts /> : <Image />}
                <Form />
            </Grid>
        </main>
    )
}

export default Cooperation