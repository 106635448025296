import { useSelector } from "react-redux";
import Localization from "../../../localization";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import intro_pic from "../../../media/moj3-international-auth.png";
import intro_pic_darkmode from "../../../media/moj3-international-auth-darkmode.png";
import { Link } from "react-router-dom";
import constantValues from "../../../misc/constantValues";
import LoginForm from "./components/LoginForm";
import React from "react";

function Login(props) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            paddingTop: '1em',
            paddingBottom: '1em'
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: desktopSize ? '4rem' : '2rem',
            width: desktopSize ?
                '40%' : mobileSize ?
                    constantValues.LAYOUT_WIDTH_SMALL :
                    "70%"
        },
        introPic: {
            width: '43%',
            height: '100%',
            display: desktopSize ? 'block' : 'none'
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.warning.light
        },
        h1: {
            fontSize: '3rem',
            fontWeight: 'bold'
        }
    }
    /*END: Styles */

    return (
        <main style={styles.root}>
            <div style={styles.formContainer}>
                <h1 style={styles.h1}>{TEXTS["login"]}</h1>
                <p>
                    {TEXTS["newToCompanyName"]} <span>
                        <Link
                            style={styles.link}
                            to={constantValues.REGISTER_ROUTE}
                        >
                            {TEXTS["createAnAccount"]}
                        </Link>
                    </span>
                </p>
                <LoginForm />
            </div>
            <img
                src={themeReducer === "light" ? intro_pic : intro_pic_darkmode}
                style={styles.introPic}
                alt={'intro pic'}
            />
        </main>
    )
}


export default Login