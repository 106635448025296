import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';
import { showFailNotification } from '../../redux/state/notificationReducer';
import { useDispatch } from 'react-redux';

function useBlog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [didNotLoad, setDidNotLoaod] = useState(false);
    const [posts, setPosts] = useState(null);
    const createRequest = useRequest();

    const getPosts = () => {
        setLoading(true);
        setDidNotLoaod(false);

        const onSuccess = (response) => {
            setLoading(false);
            let parsedData = response?.data?.items?.slice(0,3).map(i =>
            ({
                title: i.title,
                url: i.url,
                img: i.image,
                text: i.content_text
            }));
            setPosts(parsedData);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoaod(true);
        }

        createRequest({
            method: 'GET',
            url: constantValues.GET_BLOG_POSTS,
            baseUrl: constantValues.BASE_BLOG_URL,
            shouldNotCheckIsSuccess: true,
            onSuccess,
            onFail,
        })
    }

    return {
        posts,
        loading,
        didNotLoad,
        getPosts,
    }
}

export default useBlog