import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis,
    Tooltip as ChartTooltip, CartesianGrid,
} from 'recharts';
import Grid from '@mui/material/Grid';

const formatChartNumber = (num) => {
    let result = parseFloat((Math.round(num * 100)).toFixed(2));
    return result
}

const calculateMonthsChart = (months) => {
    let result = [];
    for (var key in months) {
        result.push({ date: key, price: formatChartNumber(months[key]) })
    }
    result.sort(
        (a, b) => {
            let calcYear = (date) => Number(date.split('-')[0]);
            let calcMonth = (date) => Number(date.split('-')[1]);

            if (calcYear(a.date) > calcYear(b.date)) {
                return 1;
            }
            if (calcYear(a.date) === calcYear(b.date)) {
                if (calcMonth(a.date) > calcMonth(b.date)) {
                    return 1;
                }
            }
            return -1
        }
    )
    return result;
}

function ResultChart({ backtest }) {
    const styles = {
        root: {
            width: '100%',
            marginTop: '3rem',
            overflow: 'hidden',
        }
    }
    return (
        <section style={styles.root} dir='ltr'>
            <Grid container justifyContent='center'>
                <Grid item xs={11}>
                    <ResponsiveContainer dir='ltr' width={'95%'} height={400}>
                        <LineChart
                            data={calculateMonthsChart(backtest?.months)}
                        >
                            <CartesianGrid strokeDasharray="5 5" />
                            <XAxis dataKey={"date"} />
                            <YAxis dataKey={"price"} />
                            <ChartTooltip
                                wrapperStyle={{
                                    borderColor: 'white',
                                    boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
                                }}
                                contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                                formatter={(value, name) => { return [value + '%', 'x'] }}
                                labelStyle={{ fontWeight: 'bold', color: '#666666' }}
                            />
                            <Line type={'monotone'} dataKey="price" stroke="#72499d" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </section>
    )
}

export default ResultChart