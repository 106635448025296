import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showLimitDialog } from '../../redux/state/limitReducer';

function useBacktest() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const navigate = useNavigate();
    const createRequest = useRequest();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [didNotLoad, setDidNotLoad] = useState();
    const [backtestHistory, setBacktestHistory] = useState(null);
    const [backtest, setBacktest] = useState(null);

    const getBacktestHistory = () => {
        setLoading(true);
        setDidNotLoad(false);

        const onSuccess = (response) => {
            setLoading(false);
            let parsedList = [];
            response?.data?.data?.map(x => {
                let data = x.split(',');
                parsedList.push({
                    id: data[0],
                    name: data[1],
                    from: constantValues.convertFileTimeToDateTime(data[2]),
                    original: x
                })
            });
            let sortedList = parsedList.sort((a, b) => {
                let aDate = a.original.split(',')[2];
                let bDate = b.original.split(',')[2];
                return bDate - aDate
            })
            setBacktestHistory(sortedList);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            url: constantValues.GET_BACKTEST_HISTORY,
            method: 'GET',
            onSuccess,
            onFail
        })
    }
    const createBacktest = (
        name,
        inCodes,
        outCodes,
        maxCandleBetweenIn,
        stopLoss,
        profitLimit,
        maxCandle,
        from,
        to,
        timeFrame,
        tradeType
    ) => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            dispatch(showSuccessNotification('Backtest result will be shown as soon as its calculated'))
            navigate(constantValues.BACKTEST_HISTORY_ROUTE);
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            if (errMessage === 'OverUse') {
                dispatch(showLimitDialog(constantValues.OVERUSE_ENUMS.OVERUSE_CREATE_BACKTEST));
                return;
            }
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            url: constantValues.CREATE_BACKTEST,
            data: {
                name,
                inCodes,
                outCodes,
                maxCandleBetweenIn,
                stopLoss: 1 - Number(stopLoss / 100),
                takeProfit: 1 + Number(profitLimit / 100),
                maxCandle,
                from: new Date(from),
                to: new Date(to),
                timeFrame,
                marketType: 'CRYPTO',
                dealType: tradeType,
                openFeeRate: 0,
                closeFeeRate: 0
            },
            method: 'POST',
            onSuccess,
            onFail
        })
    }
    const getBacktestById = (id) => {
        setLoading(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoading(false);
            setBacktest(response?.data?.data);
        }
        const onFail = (error) => {
            setLoading(false);
            setDidNotLoad(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            url: constantValues.GET_BACKTEST_BY_ID(id),
            method: 'GET',
            onSuccess,
            onFail
        })
    }
    return {
        loading,
        didNotLoad,
        backtest,
        backtestHistory,
        getBacktestById,
        getBacktestHistory,
        createBacktest
    }
}

export default useBacktest