import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useMemo, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import constantValues from '../../misc/constantValues';
import { useSelector } from "react-redux";
import Localization from '../../localization';
import { useLocation } from 'react-router-dom';

import ProfileOverview from './Overview';
import ProfileExchange from './Exchange';
import ProfileInvitation from './Invitation';
import ProfileSession from './Session';
import ProfileMessenger from './Messenger';

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

function Profile() {
    const query = useQuery();
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.mode === "dark" ? '#14121d' : '#f7f7f7',
            color: theme.palette.text.primary,
            fontSize: '15px',
            paddingTop: mobileSize ? '' : '1rem',
            paddingBottom: desktopSize ? '1rem' : '',
        },
        tab: (isActive) => isActive ?
            {
                textTransform: 'none',
                color: theme.palette.mode === "dark" ?
                    constantValues.ICON_COLOR_1 : theme.palette.primary.main,
                fontWeight: 'bolder',
                fontFamily: constantValues.PRIMARY_FONT_FAMILY
            }
            : {
                textTransform: 'none',
                color: theme.palette.mode === "dark" ? '#827e9b' : '#9f9f9f',
                fontWeight: 'bolder',
                fontFamily: constantValues.PRIMARY_FONT_FAMILY
            },
        tabContainer: {
            width: desktopSize ? '75vw' : '100%',
        },
        tabContent: {
            width: desktopSize ? '75vw' : '100%',
            marginTop: '1rem',
            minHeight: !desktopSize && !mobileSize ? '87vh' : '89vh',
        },
        tabContentMargin: {
            width: '100%'
        },
        tabPaperSX: mobileSize ? {
            boxShadow: 'none',
            borderTop: '1px solid #ebebeb',
            borderRadius: 0,
        } : {}
    }
    /*END: Styles */

    /*BEGIN: Application State */
    const [tab, setTab] = useState(0);
    /*END: Application State */

    let tabFromQuery = query.get('tab');
    useEffect(() => {
        setTab(Number(tabFromQuery))
    }, [tabFromQuery])

    return (
        <main style={styles.root}>
            {/*BEGIN: Tabs */}
            <Paper sx={styles.tabPaperSX} style={styles.tabContainer}>
                <Tabs
                    dir={constantValues.IS_RTL_LANGUAGE(language) ? 'rtl' : 'ltr'}
                    allowScrollButtonsMobile
                    scrollButtons="auto"
                    variant={mobileSize ? 'scrollable' : 'fullWidth'}
                    centered
                    value={tab}
                    onChange={(e, v) => setTab(v)}
                    TabIndicatorProps={{
                        style: theme.palette.mode === "dark" ?
                            {
                                backgroundColor: constantValues.ICON_COLOR_1,
                                borderBottom: `2px solid ${constantValues.ICON_COLOR_1}`
                            } :
                            {
                                borderBottom: `2px solid ${theme.palette.primary.main}`
                            }
                    }}
                >
                    <Tab style={styles.tab(tab === 0)} label={TEXTS["profile"]} />
                    <Tab style={styles.tab(tab === 1)} label={TEXTS["inviteCode"]} />
                    <Tab style={styles.tab(tab === 2)} label={TEXTS["messenger"]} />
                    <Tab style={styles.tab(tab === 3)} label={TEXTS["myExchangeServices"]} />
                    <Tab style={styles.tab(tab === 4)} label={'Sessions'} />
                </Tabs>
            </Paper>
            {/*END: Tabs */}

            {/*BEGIN: Profile Contents */}
            <Paper style={styles.tabContent}>
                <div style={styles.tabContentMargin}>
                    {tab === 0 && <ProfileOverview />}
                    {tab === 1 && <ProfileInvitation />}
                    {tab === 2 && <ProfileMessenger />}
                    {tab === 3 && <ProfileExchange />}
                    {tab === 4 && <ProfileSession />}
                </div>
            </Paper>
            {/*END: Profile Contents */}
        </main>
    )
}

export default Profile