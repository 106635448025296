import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { hideNotification } from '../redux/state/notificationReducer';
import constantValues from './constantValues';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function NotificationManager() {
    const notification = useSelector(state => state.notificationReducer);
    const dispatch = useDispatch();

    const closeNotification = () => {
        dispatch(hideNotification())
    }

    const styles = {
        alert: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY
        }
    }

    return (
        <Snackbar onClose={closeNotification} autoHideDuration={6000} open={notification.show}>
            <Alert style={styles.alert} onClose={closeNotification} severity={notification.severity}>
                {notification.message}
            </Alert>
        </Snackbar>
    )
}

export default NotificationManager