import Grid from '@mui/material/Grid';
import HelpIcon from '../../../../material-ui/Icons/HelpIcon';
import { useTheme } from '@mui/material/styles';
import constantValues from '../../../../misc/constantValues';
import { useSelector } from "react-redux";
import Localization from "../../../../localization";
import useMediaQuery from '@mui/material/useMediaQuery';

const formatPercentNumber = (num) => {
    let result = parseFloat((Math.round(num * 100)).toFixed(2));
    if (result < 0) {
        return `-${Math.abs(result)}` + '%';
    }
    return (result) + '%'
}

const formatLongNumber = (num) => {
    let result = (Math.round(num * 100) / 100).toFixed(2);
    if (result < 0) {
        return `-${Math.abs(result)}`;
    }
    return (result)
}

function ResultDetails({ backtest, backtestName }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: Styles */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            marginTop: '1rem',
            fontSize: mobileSize ? '12px' : '15px',
            overflowX: 'hidden'
        },
        helpIcon: {
            marginLeft: '0.4rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            color: theme.palette.mode === "dark" ? theme.palette.primary.main : constantValues.ICON_COLOR_1,
        },
        smallBoldText: {
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            marginTop: '2rem'
        },
        paramsContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: 25,
            flexWrap: 'wrap',
            marginTop: '1rem',
        },
        paramTitle: {
            color: '#8f8f8f'
        },
        resultContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            marginTop: '1rem',
            gap: mobileSize ? '' : '10px 50px',
        },
        resultItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: mobileSize ? '100%' : '30%',
            marginTop: mobileSize ? '0.4rem' : ''
        },
        resultItemValue: {
            marginLeft: mobileSize ? '' : '3rem'
        },
        numColor: (num) => ({
            color: num > 0 ? '#78c9c0' : '#f07259'
        }),
        flex: {
            display: 'flex',
            alignItems: 'center',
            gap: mobileSize ? 5 : ''
        }
    }
    /*END: Styles */

    return (
        <section style={styles.root}>
            <Grid container justifyContent="center" >

                <Grid item xs={11}>
                    <div className="flex-row">
                        <h1>{TEXTS['backtest']}</h1>
                        <HelpIcon style={styles.helpIcon} />
                    </div>
                </Grid>

                <Grid item xs={11}>
                    <div style={styles.smallBoldText}>
                        <div>
                            {TEXTS['parameters']}:
                        </div>
                        <HelpIcon style={styles.helpIcon} />
                    </div>
                </Grid>

                <Grid item xs={11}>
                    <div style={styles.paramsContainer}>
                        <div style={styles.flex}>
                            <div style={styles.paramTitle}>
                                {TEXTS['backtestName']}:
                            </div>
                            <div>
                                {backtestName}
                            </div>
                        </div>
                        <div style={styles.flex}>
                            <div style={styles.paramTitle}>
                                {TEXTS['priceBasis']}:
                            </div>
                            <div>
                                {backtest?.priceBasis}
                            </div>
                        </div>
                        <div style={styles.flex}>
                            <div style={styles.paramTitle}>
                                {TEXTS['duration']}:
                            </div>
                            <div>
                                from {new Date(backtest?.from).toLocaleDateString()} until {new Date(backtest?.to).toLocaleDateString()} during last {backtest?.maxCandle} candles
                            </div>
                        </div>
                        <div style={styles.flex}>
                            <div style={styles.paramTitle}>
                                {TEXTS['profitLimit']}:
                            </div>
                            <div>
                                {formatPercentNumber(backtest?.takeProfit - 1)}
                            </div>
                        </div>
                        <div style={styles.flex}>
                            <div style={styles.paramTitle}>
                                {TEXTS['lossLimit']}:
                            </div>
                            <div>
                                {formatPercentNumber(1 - backtest?.stopLoss)}
                            </div>
                        </div>
                        <div style={styles.flex}>
                            <div style={styles.paramTitle}>
                                {TEXTS['timeframe']}:
                            </div>
                            <div>
                                {backtest?.timeFrame}
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={11}>
                    <div style={styles.smallBoldText}>
                        <div>{TEXTS['result']}</div>
                        <HelpIcon style={styles.helpIcon} />
                    </div>
                </Grid>

                <Grid item xs={11}>
                    <div style={styles.resultContainer}>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['totalNumberOfTransactions']}:
                            </div>
                            <div style={styles.resultItemValue}>
                                {backtest?.totalDeals}
                            </div>
                        </div>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['grossProfit']}:
                            </div>
                            <div dir='ltr' style={{ ...styles.resultItemValue, ...styles.numColor(backtest?.grossGain) }}>
                                {formatPercentNumber(backtest?.grossGain)}
                            </div>
                        </div>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['averageProfitOnNegativeTrades']}:
                            </div>
                            <div dir='ltr' style={{ ...styles.resultItemValue, ...styles.numColor(backtest?.avgDealsWithLoss) }}>
                                {formatPercentNumber(backtest?.avgDealsWithLoss)}
                            </div>
                        </div>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['negativeTransactions']}:
                            </div>
                            <div style={{ ...styles.resultItemValue, ...styles.numColor(backtest?.dealsWithLoss) }}>
                                {backtest?.dealsWithLoss}
                            </div>
                        </div>


                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['grossLoss']}:
                            </div>
                            <div dir='ltr' style={{ ...styles.resultItemValue, ...styles.numColor(backtest?.grossLoss) }}>
                                {formatPercentNumber(backtest?.grossLoss)}
                            </div>
                        </div>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['averageOfTradingBeingOpen']}:
                            </div>
                            <div style={styles.resultItemValue}>
                                {formatLongNumber(backtest?.avgCandle)}
                            </div>
                        </div>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['positiveTransactions']}:
                            </div>
                            <div style={{ ...styles.resultItemValue, ...styles.numColor(backtest?.dealsWithGain) }}>
                                {backtest?.dealsWithGain}
                            </div>
                        </div>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['averageProfitInAllTrades']}:
                            </div>
                            <div dir='ltr' style={{ ...styles.resultItemValue, ...styles.numColor(backtest?.avgProfit) }}>
                                {formatPercentNumber(backtest?.avgProfit)}
                            </div>
                        </div>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['successRate']}:
                            </div>
                            <div style={{ ...styles.resultItemValue, ...styles.numColor(backtest?.successRate) }}>
                                {formatPercentNumber(backtest?.successRate)}
                            </div>
                        </div>


                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['totalProfitAndLoss']}:
                            </div>
                            <div dir='ltr' style={{ ...styles.resultItemValue, ...styles.numColor(backtest?.totalGainLoss) }}>
                                {formatPercentNumber(backtest?.totalGainLoss)}
                            </div>
                        </div>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['averageProfitOnPositiveTrades']}:
                            </div>
                            <div style={{ ...styles.resultItemValue, ...styles.numColor(backtest?.avgDealsWithGain) }}>
                                {formatPercentNumber(backtest?.avgDealsWithGain)}
                            </div>
                        </div>

                        <div style={styles.resultItem}>
                            <div style={styles.paramTitle}>
                                {TEXTS['feePaid']}:
                            </div>
                            <div style={styles.resultItemValue}>
                                {backtest?.allFeePaid}
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={11}>
                    <div style={styles.smallBoldText}>
                        <div>{TEXTS['monthlyProfitAndLossChart']}</div>
                        <HelpIcon style={styles.helpIcon} />
                    </div>
                </Grid>
            </Grid>
        </section>
    )
}

export default ResultDetails