import * as React from 'react';
import { useSelector } from "react-redux";
import Localization from "../../../localization";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import constantValues from '../../../misc/constantValues';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '../../../material-ui/Icons/HelpIcon';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '../../../material-ui/Icons/StarIcon';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import ChartDialog from '../../../misc/TradingViewChartDialog';
import { Link } from 'react-router-dom';
import { SocketContext } from "../../../misc/SocketContextProvider";
import useSymbol from "../../../hooks/Exchange/useSymbol";
import MuiSkeleton from "../../../material-ui/Skeleton/MuiSkeleton";
import Pagination from "../../../material-ui/Pagination/Pagination";
import chart_icon from "../../../media/chart-line-icon.png";

const OutlinedTextFieldSelect = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: constantValues.BORDER_COLOR_1,
        },
        '&:hover fieldset': {
            borderColor: constantValues.ICON_COLOR_1,
        },
        '&.Mui-focused fieldset': {
            borderColor: constantValues.ICON_COLOR_1,
        },
    },
});
function Table({
    SocketFuncs,
    strategy,
    loading,
    result,
    manipulateFavSymbols,
    resetResult,
    coins,
    getCoinPrices,
    xPage,
    yPage,
    setXPage,
    setYPage
}) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    /*BEGIN: App State */
    const [symbol, setSymbol] = React.useState(null);
    const [symbolList, setSymbolList] = React.useState([]);
    const [showChart, setShowChart] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState('');
    const { toggleFavSymbol, loadingToggleFavSymbol } = useSymbol();
    const [blackListedSignals, setBlackListedSignals] = React.useState([]);
    /*BEGIN: App State */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            marginTop: '1rem',
            marginBottom: '1em',
            fontSize: '13px'
        },
        tableSymbolContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        tableSignalContainer: {
            textAlign: 'center',
            color: constantValues.FONT_COLOR_1,
            fontWeight: 'bold',
            fontSize: '24px',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer'
        },
        logo: {
            width: '32px',
            height: '32px',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : (mobileSize ? '0.4rem' : '1rem'),
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? (mobileSize ? '0.4rem' : '1rem') : '',
        },
        firstTableHeader: {
            borderTop: 0,
            borderLeft: 0,
            verticalAlign: 'top',
            paddingBottom: mobileSize ? '0.1em' : '1.5em',
            background: theme.palette.mode === 'light' ? 'white' : 'rgb(29, 26, 46)',
            zIndex: 2,
        },
        lastInRow: {
            borderRight: 0,
            fontWeight: 'normal',
            paddingBottom: mobileSize ? '0.1em' : ''
        },
        firstDataCell: {
            borderLeft: constantValues.IS_RTL_LANGUAGE(language) ? undefined : 0,
            borderRight: constantValues.IS_RTL_LANGUAGE(language) ? 0 : undefined,
            paddingRight: mobileSize ? '0.5em' : '1.5em',
            paddingTop: mobileSize ? '' : '1em',
            paddingBottom: mobileSize ? '' : '1em',
        },
        tableHeader: {
            fontWeight: 'normal',
            verticalAlign: 'top',
            paddingBottom: '1.5em',
            background: theme.palette.mode === 'light' ? 'white' : 'rgb(29, 26, 46)',
            zIndex: 2,
        },
        warning: {
            color: theme.palette.warning.light
        },
        buyType: {
            color: constantValues.FONT_COLOR_1
        },
        subText: {
            fontSize: '11px',
            width: '100%',
            textAlign: 'left',
            color: constantValues.ICON_COLOR_1,
        },
        titleText: {
            textAlign: 'left',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            width: mobileSize ? '100px' : '150px'
        },
        searchContainer: {
            display: 'flex',
            flexDirection: mobileSize ? undefined : 'column',
            justifyContent: 'center'
        },
        allSymbolsRoot: {
            minWidth: !desktopSize ? '150px' : '',
            marginTop: mobileSize ? '' : desktopSize ? '1rem' : ''
        },
        firstDataCellLogoContainer: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: 'flex-end',
            height: '100%',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : (mobileSize ? '0.4rem' : ''),
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? (mobileSize ? '0.4rem' : '') : '',
        },
        helpIcon: {
            marginTop: '1rem',
            width: '17px',
            height: '17px'
        },
        muiOutlinedInputRoot: {
            paddingRight: 0,
            paddingLeft: 0,
            borderRadius: '8px',
            color: theme.palette.text.primary,
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            fontSize: '13px',
            maxWidth: '200px'
        },
        symbolSelectorLabel: {
            color: themeReducer === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            fontWeight: 'normal',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            fontSize: '13px'
        },
        startAdornmentRTL: {
            paddingRight: 0,
            paddingLeft: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '',
            borderBottomRightRadius: '',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        endAdornmentLTR: {
            paddingRight: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        adornmentIconColor: {
            color: 'white'
        },
        titleRoot: {
            display: 'flex'
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        cancelIconContainer: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'flex-end'
        },
        binanceButton: {
            fontSize: '10px',
            width: '50px',
            textTransform: 'none',
            marginTop: '0.4rem',
            borderRadius: '5px',
            backgroundColor: themeReducer === "dark" ? constantValues.ICON_COLOR_2 : '#a680ba',
            paddingTop: '0.4em',
            paddingBottom: '0.4em',
        },
        symbolPriceContainer: {
            fontSize: '13px',
            color: '#a680ba',
            width: '120px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        dateContainer: {
            fontSize: '9px',
            color: 'gray',
            fontWeight: 'normal'
        },
        signalMatch: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        tableContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            width: '120px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        searchInputNonDeskRoot: {
            minWidth: '180px',
            width: '95%',
            marginBottom: '1rem',
            marginTop: '1rem'
        },
        searchInputNonDesk: {
            paddingRight: 0,
            borderRadius: '8px',
            color: theme.palette.text.primary,
        },
        inputAdornment: {
            paddingRight: 0,
            backgroundColor: themeReducer === 'light' ?
                constantValues.ICON_COLOR_1 : '',
            height: '39px',
            maxHeight: '10em',
            width: '50px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        paginationContainer: {
            width: '98%',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        bottomPaginationContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        firstDataCellContainer: {
            display: 'flex',
        },
        tableData: {
            padding: 0,
            paddingBottom: '2.5em',
            paddingTop: '2.5em'
        },
        addStrategyBtn: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            fontSize: '13px',
            minWidth: !mobileSize ? '170px' : ''
        }
    }
    /*END: Styles */

    const onBlackListSignal = (c) => {
        let newList = [...blackListedSignals, c];
        setBlackListedSignals(newList);
    }

    const onChangeFavSymbol = (base, quote = 'USDT') => {
        manipulateFavSymbols(result, base);
        toggleFavSymbol(base, quote);
    }

    const callStrategyAPI = () => {
        resetResult();
    }

    const openChart = (currency, signal) => {
        setShowChart(true);
        let splitedSignal = result?.result[currency][signal].split(',')
        setSelected({
            similarSignals: splitedSignal,
            insCode: currency,
            symbol: result?.result[currency]['Currency'],
            parentSignalCode: signal[0] + signal[1],
            signalCode: signal
        })
    }

    React.useEffect(() => {
        if (strategy && !loading) {
            callStrategyAPI()
        }
    }, [strategy]);

    React.useEffect(() => {
        if (result?.result && symbolList.length === 0) {
            setBlackListedSignals([]);
            let symbols = Object.keys(result?.result);
            let sList = symbols.map(x => result?.result[x].Currency);
            setSymbolList(sList);
            SocketFuncs.connectPrices(symbols);
            getCoinPrices(symbols);
            return () => {
                let symbols = Object.keys(result?.result);
                SocketFuncs.disconnectPrices(symbols)
            }
        }
    }, [result]);

    // eslint-disable-next-line no-restricted-globals
    let screenWidth = screen?.width
    let xFieldPerPage = Math.trunc(((screenWidth - 270) / 200));

    const X_FIELD_PER_PAGE = mobileSize ? 1 : xFieldPerPage;
    const Y_FIELD_PER_PAGE = mobileSize ? 4 : 8;

    const headers = result?.items?.filter(x => !blackListedSignals.includes(x))
        .slice(xPage * X_FIELD_PER_PAGE, xPage * X_FIELD_PER_PAGE + X_FIELD_PER_PAGE);

    const data = result?.result &&
        Object.keys(result?.result)
            .filter(d => {
                //Filtering By Symbol
                if (symbol) {
                    return result?.result[d].Currency.toLowerCase() === symbol.toLowerCase()
                } else {
                    return result?.result[d].Currency.toLowerCase().includes(searchValue.toLowerCase()) ||
                        d.toLowerCase().includes(searchValue.toLowerCase())
                }
            });

    React.useEffect(() => {
        setYPage(0);
    }, [symbol, searchValue]);

    return (
        <div style={styles.tableContainer}>

            {/*BEGIN: Chart Dialog */}
            {showChart &&
                <ChartDialog
                    open={showChart}
                    onClose={() => setShowChart(false)}
                    setSelected={setSelected}

                    symbol={selected?.symbol}
                    similarSignals={selected?.similarSignals}
                    parentSignalCode={selected?.parentSignalCode}
                    signalCode={selected?.signalCode}
                    insCode={selected?.insCode}

                    interval={constantValues.timeFrameToInterval(result?.timeFrame)}

                    timeframe={result?.timeFrame}
                    tableContent={result}
                />
            }
            {/*END: Chart Dialog */}

            {/*BEGIN: Search !desktopSize */}
            {!desktopSize && !loading &&
                <div style={styles.searchContainer}>
                    <OutlinedTextField
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={styles.searchInputNonDeskRoot}
                        placeholder={TEXTS["searchCoin"]}
                        size='small'
                        variant='outlined'
                        sx={{
                            "& .MuiOutlinedInput-root": styles.searchInputNonDesk,
                            "& .MuiInputLabel-root": {
                                color: (theme) => theme.palette.text.disabled
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={styles.inputAdornment}
                                >
                                    <SearchIcon style={{ color: 'white' }} />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            }

            {result?.result &&
                <div style={styles.paginationContainer}>
                    <Pagination
                        current={xPage + 1}
                        max={Math.ceil(result?.items?.length / X_FIELD_PER_PAGE)}
                        onNext={() => setXPage(prev => prev + 1)}
                        onPrevious={() => setXPage(prev => prev - 1)}
                    />
                </div>
            }

            {loading || !result?.result ?
                <div style={{ width: '100%', marginTop: '1rem' }}>
                    <MuiSkeleton
                        variant='rectangular'
                        width={'100%'}
                        height={'400px'}
                        didNotLoad={result?.failed}
                        reload={callStrategyAPI}
                    />
                </div> :
                <div
                    id={'table-container'}
                    style={{
                        width: '100%',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                    }}
                >
                    <table width="100%" style={styles.root}>
                        <tr>
                            <th dir='ltr' style={styles.firstTableHeader}>
                                <div style={styles.searchContainer}>
                                    {/*BEGIN: Search */}
                                    {desktopSize &&
                                        <OutlinedTextField
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            style={{ minWidth: '180px' }}
                                            label={constantValues.IS_RTL_LANGUAGE(language) ? undefined : TEXTS["search"]}
                                            placeholder={constantValues.IS_RTL_LANGUAGE(language) ? TEXTS["search"] : undefined}
                                            size='small'
                                            variant='outlined'
                                            sx={{
                                                "& .MuiOutlinedInput-root": styles.muiOutlinedInputRoot,
                                                "& .MuiInputLabel-root": {
                                                    color: (theme) => theme.palette.text.disabled,
                                                    fontSize: '13px'
                                                },
                                            }}
                                            InputProps={constantValues.IS_RTL_LANGUAGE(language) ?
                                                {
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position={"start"}
                                                            sx={styles.startAdornmentRTL}
                                                        >
                                                            <SearchIcon style={styles.adornmentIconColor} />
                                                        </InputAdornment>
                                                    )
                                                } :
                                                {
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position={"end"}
                                                            sx={styles.endAdornmentLTR}
                                                        >
                                                            <SearchIcon style={styles.adornmentIconColor} />
                                                        </InputAdornment>
                                                    )
                                                }
                                            }
                                        />
                                    }
                                    {/*END: Search */}

                                    {/*BEGIN: Symbol Selector */}
                                    <OutlinedTextFieldSelect
                                        value={symbol}
                                        onChange={(e) => setSymbol(e.target.value)}
                                        style={styles.allSymbolsRoot}
                                        label={TEXTS["allSymbols"]}
                                        select
                                        size='small'
                                        variant='outlined'
                                        sx={{
                                            "& .MuiOutlinedInput-root": styles.muiOutlinedInputRoot,
                                            "& .MuiInputLabel-root": styles.symbolSelectorLabel,
                                            "& .MuiSelect-icon": {
                                                color: (theme) => theme.palette.mode === "dark" ?
                                                    constantValues.ICON_COLOR_1 : theme.palette.primary.main,
                                            }
                                        }}
                                    >
                                        <MenuItem value={null}>
                                            All Symbols
                                        </MenuItem>
                                        {symbolList.map(s =>
                                            <MenuItem value={s}>
                                                {s}
                                            </MenuItem>
                                        )}
                                    </OutlinedTextFieldSelect>
                                    {/*END: Symbol Selector */}
                                </div>
                            </th>
                            {
                                headers
                                    .map((h, i) =>
                                        <th
                                            id={i === 0 && 'firstTableHeaderSignal'}
                                            dir='ltr'
                                            style={styles.tableHeader}
                                        >
                                            <div style={styles.titleRoot}>
                                                <div style={styles.titleContainer}>
                                                    <div style={styles.titleText}>
                                                        {TEXTS[`signal-${h}`] || `Unknown(${h})`}
                                                    </div>
                                                    <div style={styles.subText}>
                                                        {TEXTS[`signal-${h.slice(0, 2)}`] || `Unknown(${h.slice(0, 2)})`}/<span
                                                            style={Number(h) % 2 === 0 ? styles.warning : styles.buyType}
                                                        >
                                                            {constantValues.isSellOrBuySignal(h)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div style={styles.cancelIconContainer}>
                                                    <CancelIcon
                                                        onClick={() => onBlackListSignal(h)}
                                                        fontSize='small'
                                                        color='disabled'
                                                        style={
                                                            themeReducer === "dark" ?
                                                                { color: constantValues.ICON_COLOR_2 } : undefined}
                                                    />
                                                    <HelpIcon
                                                        signalCode={h}
                                                        style={styles.helpIcon}
                                                    />
                                                </div>
                                            </div>
                                        </th>
                                    )}

                            {headers.length < X_FIELD_PER_PAGE &&
                                Array.apply(null, Array(X_FIELD_PER_PAGE - headers.length)).map(k =>
                                    <th
                                        key={k}
                                        dir='ltr'
                                        style={{ ...styles.tableHeader, paddingRight: 0, paddingLeft: 0 }}
                                    >
                                        <div style={styles.addStrategyBtn}>
                                            {TEXTS['addStrategy']}
                                        </div>
                                    </th>
                                )
                            }
                        </tr>
                        {
                            data
                                .slice(yPage * Y_FIELD_PER_PAGE, (yPage * Y_FIELD_PER_PAGE) + Y_FIELD_PER_PAGE)
                                .map((d, i) =>
                                    <tr>
                                        <td style={styles.firstDataCell}>
                                            <div style={styles.firstDataCellContainer}>
                                                <img src={`/media/${d.replace("USDT", "").toLocaleLowerCase()}.svg`} alt={''} style={styles.logo} />
                                                <div style={styles.tableSymbolContainer}>
                                                    <Link
                                                        style={styles.link}
                                                        to={
                                                            constantValues.SINGLE_SYMBOL_ROUTE.replace(':name', d)
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {result?.result[d].Currency}
                                                    </Link>
                                                    <div style={styles.symbolPriceContainer}>
                                                        {d} <span style={{ color: 'gray' }}>
                                                            {
                                                                SocketFuncs?.result[d]?.c &&
                                                                parseFloat(SocketFuncs?.result[d]?.c)
                                                            }
                                                            {
                                                                !SocketFuncs?.result[d]?.c &&
                                                                parseFloat(coins[d]?.Close)
                                                            }
                                                        </span>
                                                    </div>
                                                    <Button
                                                        sx={{ boxShadow: 0 }}
                                                        style={styles.binanceButton}
                                                        variant='contained'
                                                        href={constantValues.generateBinanceLink(d.replace("USDT", ""), 'USDT')}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Binance
                                                    </Button>
                                                </div>
                                                <div
                                                    style={styles.firstDataCellLogoContainer}
                                                >
                                                    {/* <MoreVertIcon style={{ color: constantValues.ICON_COLOR_1 }} /> */}
                                                    <StarIcon
                                                        isFavorite={result?.result[d]?.isFavorite}
                                                        onClick={() => onChangeFavSymbol(d)}
                                                        style={{
                                                            color: '#f9cc24',
                                                            marginTop: '1.3rem'
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </td>

                                        {
                                            headers
                                                .map(item =>
                                                    <td style={styles.tableData}>
                                                        {
                                                            result?.result[d][item] ?
                                                                <div onClick={() => openChart(d, item)} style={styles.tableSignalContainer}>
                                                                    <div style={styles.signalMatch}>
                                                                        <img src={chart_icon} alt='chart' />
                                                                        <div>
                                                                            {'FIT'}
                                                                        </div>
                                                                    </div>
                                                                    <div style={styles.dateContainer}>
                                                                        {constantValues.convertDateToTableFormat(
                                                                            new Date(result?.result[d][item].split('|')[0])
                                                                        )}
                                                                    </div>
                                                                </div> :
                                                                <div style={styles.tableSignalContainer}>

                                                                </div>
                                                        }
                                                    </td>
                                                )
                                        }

                                        {headers.length < X_FIELD_PER_PAGE &&
                                            Array.apply(null, Array(X_FIELD_PER_PAGE - headers.length)).map(k =>
                                                <td>

                                                </td>
                                            )
                                        }
                                    </tr>
                                )}
                    </table>
                </div>
            }

            {result?.result &&
                <div style={styles.bottomPaginationContainer}>
                    <Pagination
                        current={yPage + 1}
                        max={Math.ceil(data?.length / Y_FIELD_PER_PAGE)}
                        onNext={() => setYPage(prev => prev + 1)}
                        onPrevious={() => setYPage(prev => prev - 1)}
                    />
                </div>
            }
        </div>
    )
}

function WithContext({
    strategy,
    loading,
    result,
    manipulateFavSymbols,
    resetResult,
    coins,
    getCoinPrices,
    xPage,
    yPage,
    setXPage,
    setYPage
}) {
    return (
        <SocketContext.Consumer>
            {value => <Table
                strategy={strategy}
                SocketFuncs={value}
                loading={loading}
                result={result}
                manipulateFavSymbols={manipulateFavSymbols}
                resetResult={resetResult}
                coins={coins}
                getCoinPrices={getCoinPrices}
                xPage={xPage}
                yPage={yPage}
                setXPage={setXPage}
                setYPage={setYPage}
            />
            }
        </SocketContext.Consumer>
    )
}

export default WithContext