import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import constantValues from '../../misc/constantValues';

function CustomCircularProgress({ size, color }) {
    const language = useSelector(state => state.localizationReducer);

    const styles = {
        root: {
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.4rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '0.4rem' : ''
        }
    }
    return (
        <CircularProgress
            style={styles.root}
            size={size || 20}
            color={color || 'secondary'}
        />
    )
}

export default CustomCircularProgress