import prof_example from "../../../../media/profile-example.jpg";
import TextField from "@mui/material/TextField";
import DownloadIcon from '@mui/icons-material/Download';
import constantValues from '../../../../misc/constantValues';

const MOCK_DATA = [
    {
        sender: "Costumer",
        message: "Message...............................................................................................................................................................................................AA",
        name: "Karen Dashti",
        date: "Today 17:28",
        type: "Text",
    },
    {
        sender: "Costumer",
        message: "",
        name: "Karen Dashti",
        date: "Today 17:28",
        type: "Media",
    },
    {
        sender: "Support",
        message: "Your Problem ...",
        profPic: "",
        name: "Karen Dashti",
        date: "Today 17:28",
        type: "Media",
    },
    {
        sender: "Support",
        message: "Does this fix your problem?",
        profPic: "",
        name: "Karen Dashti",
        date: "Today 17:28",
        type: "Text"
    },
    {
        sender: "Costumer",
        message: "Yes. Thank you",
        profPic: "",
        name: "Karen Dashti",
        date: "Today 17:28",
        type: "Text"
    },
    {
        sender: "Support",
        message: "You're very welcome sir",
        profPic: "",
        name: "Karen Dashti",
        date: "Today 17:28",
        type: "Text"
    }
];

function MessagaBox({ ticket }) {
    const styles = {
        root: {
            border: '1px solid #d4d4d4',
            paddingTop: '1em',
            paddingBottom: '2em',
            paddingRight: '1em',
            paddingLeft: '1em',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem',
            maxHeight: '60vh',
            overflowY: 'auto'
        },
        messageRoot: (sender, type) => {
            return {
                padding: type === "Text" ? '1em' : '0.8em',
                paddingTop: type === "Text" ? '1em' : 0,
                paddingBottom: type === "Text" ? '1em' : 0,
                paddingLeft: type === "Text" ? '1em' : 0,
                paddingRight: (sender === "Costumer" && type !== "Text") ? 0 : '1em',

                marginLeft: '0.5rem',
                marginRight: '0.5rem',
                marginTop: '1rem',

                display: 'flex',
                justifyContent: type === "Text" ? 'flex-start' : 'flex-end',
                flexWrap: 'wrap',
                maxWidth: '80%',
                minWidth: type === "Text" ? '' : '160px',
                width: type === 'Text' ? '' : '160px',

                borderRadius: '10px',
                borderTopLeftRadius: sender === "Support" ? '10px' : 0,
                borderTopRightRadius: sender === "Support" ? 0 : '10px',

                backgroundColor: sender === "Support" ? '#f1f1f1' : '#5b2e91',
                color: sender === "Support" ? 'black' : 'white',

                fontSize: type === "Text" ? '' : '11px'
            }
        },
        messageContainer: (sender) => {
            return {
                width: '100%',
                flexDirection: 'column',
                alignItems: sender === "Support" ? 'flex-end' : 'flex-start',
                marginTop: '1rem',
                display: 'flex',
            }
        },
        profPic: {
            width: '32px',
            height: '32px',
            backgroundImage: `url(${prof_example})`,
            borderRadius: '50%',
            backgroundSize: 'cover',
            backgroundColor: '#464646',
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%'
        },
        mediaRoot: (sender) => ({
            display: 'flex',
            alignItems: 'center',
        }),
        mediaName: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100px'
        },
        downloadIconContainer: (sender) => ({
            marginRight: sender === "Costumer" ? '' : '0.4rem',
            marginLeft: sender === "Costumer" ? '0.4rem' : '',
            backgroundColor: sender === "Costumer" ? 'black' : constantValues.ICON_COLOR_1,
            width: '42px',
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '11px'
        }),
        downloadIcon: {
            color: 'white'
        },
        messageDetailContainer: (type, sender) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: (type === "Text" && sender === "Support") ? '3rem' : '0.8rem',
            marginTop: '0.4rem',
            fontSize: '10px'
        }),
        messageBox: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            maxWidth: '80%',
        },
        profPicContainer: {
            height: '100%', display: 'flex'
        },
        sender: {
            fontWeight: 'bold'
        },
        sendDate: {
            marginLeft: '1rem',
        }
    }
    return (
        <section style={styles.root}>
            {ticket?.conversations?.filter(x => x.attachments.length > 0 || x.text.length > 0).map((x, i) =>
                <div style={styles.messageContainer(x.isFromAdmin ? 'Support' : 'Costumer')} key={i}>
                    <div dir={!x.isFromAdmin ? 'ltr' : 'rtl'} style={styles.flexRow}>
                        <div style={styles.profPicContainer}>
                            <img src={x?.user?.profile} alt='' style={styles.profPic} />
                        </div>
                        <div style={styles.messageBox}>
                            {x.text?.length > 0 &&
                                <div style={styles.messageRoot(x.isFromAdmin ? 'Support' : 'Costumer', 'Text')}>
                                    <TextField
                                        dir='ltr'
                                        style={{ width: (x?.text?.length * 14) + 'px' }}
                                        disabled
                                        sx={{
                                            '& .MuiInputBase-input.Mui-disabled': {
                                                'WebkitTextFillColor': x.isFromAdmin ?
                                                    "black" : "white",
                                            },
                                        }}
                                        variant='standard'
                                        multiline
                                        value={x.text}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                    />
                                </div>
                            }
                            {x?.attachments?.length > 0 &&
                                x?.attachments?.map(m =>
                                    <div style={styles.messageRoot(x.isFromAdmin ? 'Support' : 'Costumer', 'Media')}>
                                        <div style={styles.mediaRoot(x.isFromAdmin ? 'Support' : 'Costumer')}>
                                            <div style={styles.mediaName}>
                                                {m.fileName}
                                            </div>
                                            <div style={styles.downloadIconContainer(x.isFromAdmin ? 'Support' : 'Costumer')}>
                                                <DownloadIcon
                                                    onClick={() => window.open(m.path)}
                                                    style={styles.downloadIcon}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div dir='ltr' style={styles.messageDetailContainer('Text', x.isFromAdmin ? 'Support' : 'Costumer')}>
                                <div style={styles.sender}>
                                    {x?.user?.fullName}
                                </div>
                                <div style={styles.sendDate}>
                                    {new Date(x.createdAt).toLocaleDateString()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

export default MessagaBox