
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Grid
} from '@mui/material';
import { useEffect } from 'react';
import Texts from "./components/Texts";
import Image from "./components/Image";

function About() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.palette.text.primary,
            fontSize: mobileSize ? '13px' : '14px',
            overflowX: 'hidden',
            paddingBottom: '3em',
            marginTop: '1rem'
        },
        padding: {
            width: '100%',
            paddingTop: '2em'
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <main style={styles.root}>
            <Grid container justifyContent={'center'}>
                {!desktopSize ? <Image /> : <Texts />}
                {!desktopSize ? <Texts /> : <Image />}
            </Grid>
        </main>
    )
}

export default About