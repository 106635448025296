import MarketOverAll from "../../misc/MarketOverAll";
import YourPlans from "./components/YourPlans";
import Products from "./components/Products";
import Cooperation from "./components/Cooperation";
import PlanHistory from "./components/PlanHistory";
import usePlan from "../../hooks/Plan/usePlan";
import { useEffect, useMemo } from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiSkeleton from '../../material-ui/Skeleton/MuiSkeleton';
import useSubscripton from '../../hooks/Plan/useSubscription';
import Localization from '../../localization';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showSuccessNotification, showFailNotification } from '../../redux/state/notificationReducer';

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

function Plan() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const query = useQuery();
    const { loading, plans, getAllPlans, didNotLoad } = usePlan();
    const {
        loadingHistory,
        loadingSubscription,
        didNotLoadHistory,
        didNotLoadSubscription,
        getCurrentSubscription,
        getSubscriptionHistory,
        subscription,
        subscriptionHistory
    } = useSubscripton();

    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary
        },
        content: {
            width: desktopSize ? '70%' : '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem'
        },
        skeletonContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem'
        }
    }

    const getData = () => {
        getAllPlans();
        getCurrentSubscription();
        getSubscriptionHistory();
    }

    let isPaymentSuccess = query.get('success');
    let msg = query.get('msg');
    useEffect(() => {
        if (msg) {
            if (isPaymentSuccess === 'False') {
                dispatch(showFailNotification(TEXTS[msg]));
            } else {
                dispatch(showSuccessNotification(TEXTS[msg]))
            }
        }
        getData();
    }, []);

    return (
        <main style={styles.root}>
            <MarketOverAll />
            <div style={styles.content}>

                {loadingSubscription || didNotLoadSubscription ?
                    <div style={styles.skeletonContainer}>
                        <MuiSkeleton
                            variant='rectangular'
                            reload={getCurrentSubscription}
                            didNotLoad={didNotLoadSubscription}
                            width={'100%'}
                            height={'110px'}
                        />
                    </div> :
                    <YourPlans subscription={subscription} />
                }

                {loading || didNotLoad ?
                    <div style={styles.skeletonContainer}>
                        <MuiSkeleton
                            variant='rectangular'
                            reload={getAllPlans}
                            didNotLoad={didNotLoad}
                            width={'100%'}
                            height={'300px'}
                        />
                    </div> :
                    <Products plans={plans} />
                }

                <Cooperation />

                {loadingHistory || didNotLoadHistory ?
                    <MuiSkeleton
                        variant='rectangular'
                        width={'100%'}
                        height={'300px'}
                        reload={getSubscriptionHistory}
                    /> :
                    <PlanHistory subscriptionHistory={subscriptionHistory} />
                }
            </div>
        </main>
    )
}

export default Plan