import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function usePayment() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const [loading, setLoading] = useState(false);

    const preCheckoutPayment = (
        PlanId,
        PaymentProvider,
        Duration,
        DiscountCode,
        callback
    ) => {
        setLoading(true);
        const onSuccess = (response) => {
            setLoading(false);
            callback(response?.data?.data);
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'POST',
            url: constantValues.PRE_CHECKOUT_PAYMENT,
            onSuccess,
            onFail,
            data: { PlanId, PaymentProvider, Duration, DiscountCode }
        })
    }

    const checkoutPayment = (
        PlanId,
        PaymentProvider,
        Duration,
        DiscountCode
    ) => {
        setLoading(false);
        const onSuccess = (response) => {
            setLoading(false);
            setTimeout(() => window.open(response?.data?.data?.ipgUrl, '_blank'), 500);
        }
        const onFail = (error) => {
            setLoading(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'POST',
            url: constantValues.CHECKOUT_PAYMENT,
            onSuccess,
            onFail,
            data: { PlanId, PaymentProvider, Duration, DiscountCode }
        })
    }
    return {
        loading,
        preCheckoutPayment,
        checkoutPayment,
    }
}

export default usePayment