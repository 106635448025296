import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

function GoogleAnalytics() {
    const cookieReducer = useSelector(state => state.cookieReducer);

    useEffect(() => {
        if (cookieReducer?.accepted && cookieReducer?.optionals?.includes('google-analytics')) {
            // Load Google Analytics script only if the user has accepted cookies
            loadGoogleAnalytics();
        }
    }, [cookieReducer?.accepted]);

    function loadGoogleAnalytics() {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'G-W69J87TH46');
    }

    return null;
}

export default GoogleAnalytics;