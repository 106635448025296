import Button from "@mui/material/Button";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';

import constantValues from "../../../misc/constantValues";
import tab_summary_landing from "../../../media/tab-summary-landing.svg";

function ListItem({ texts, types }) {
    const styles = {
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            marginTop: '1rem',
            flexWrap: 'wrap'
        },
        normal: {
        },
        bold: {
            fontWeight: 'bold'
        },
        yellowCircle: {
            borderRadius: '50%',
            width: '10px',
            height: '10px',
            backgroundColor: '#ffde00',
            marginRight: '1rem'
        }
    }

    return (
        <div style={styles.root}>
            <div style={styles.yellowCircle} />
            {
                texts?.map((s, i) =>
                    <div
                        style={types[i] === 'normal' ? styles.normal : styles.bold}
                        key={s}
                        className="flex-row"
                    >
                        {s}
                    </div>
                )
            }
        </div>
    )
}

function TabSummary() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const [tab, setTab] = useState(0);
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        tabButtons: {
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            width: '100%',
            justifyContent: 'center'
        },
        btn: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            width: desktopSize ? '10rem' : '45%',
            fontWeight: 'bold'
        },
        gray: {
            color: '#aeaeae'
        },
        largePadding: {
            width: '100%',
            paddingTop: mobileSize ? '3em' : '5em'
        },
        imgContainer: {
            width: '100%',
            display: 'flex',
        },
        img: {
            width: '70%',
            height: 'auto',
            marginTop: mobileSize ? '1rem' : ''
        },
        container: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        topMargin: {
            width: '100%',
            marginTop: '1rem'
        },
        smallMargin: {
            width: '100%',
            marginTop: mobileSize ? '1rem' : ''
        }
    }

    const SECTIONS = tab === 1 ? [
        {
            texts: ['Saving time', 'for Technical analysis'],
            types: ['bold', 'normal']
        },
        {
            texts: ['Reliability for', 'Decision Making'],
            types: ['normal', 'bold']
        },
        {
            texts: ['Covering', 'Various Strategies', 'simultaneously'],
            types: ['normal', 'bold', 'normal']
        },
        {
            texts: ['Observing', 'all the market'],
            types: ['bold', 'normal']
        },
        {
            texts: ['Maximizing Trading', 'Productivity'],
            types: ['normal', 'bold']
        }
    ] :
        [
            {
                texts: ['Smart', 'analaysing and suggesting'],
                types: ['bold', 'normal']
            },
            {
                texts: ['Helping you use', 'Technical Analaysis', 'perfectly'],
                types: ['normal', 'bold', 'normal']
            },
            {
                texts: ['Increasing', 'trading skills'],
                types: ['normal', 'bold']
            },
            {
                texts: ['Preventing', 'trading faults'],
                types: ['bold', 'normal']
            },
            {
                texts: ['Maximizing', 'Trading Profit'],
                types: ['normal', 'bold']
            }
        ]

    return (
        <section style={styles.root}>
            <div style={styles.tabButtons}>
                <Button
                    onClick={() => setTab(0)}
                    style={tab === 1 ? { ...styles.btn, ...styles.gray } : styles.btn}
                    variant={tab === 0 ? 'contained' : 'text'}
                >
                    for Beginners
                </Button>
                <Button
                    onClick={() => setTab(1)}
                    style={tab === 0 ? { ...styles.btn, ...styles.gray } : styles.btn}
                    variant={tab === 1 ? 'contained' : 'text'}
                >
                    for Experts
                </Button>
            </div>
            <div style={styles.largePadding} />
            <Grid container>
                {tab === 1 &&
                    <Slide direction={'right'} in={tab === 1} mountOnEnter unmountOnExit>
                        <Grid item xs={12} lg={6} sm={6}>
                            <div style={styles.container}>
                                <div style={styles.smallMargin} />
                                <div>
                                    For Expert Traders, ACT brings:
                                </div>
                                <div style={styles.topMargin}>
                                    {
                                        SECTIONS.map((s, i) =>
                                            <ListItem
                                                key={i}
                                                texts={s.texts}
                                                types={s.types}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Slide>
                }
                {tab === 1 &&
                    <Slide direction={'right'} in={tab === 1} >
                        <Grid item xs={12} lg={6} sm={6}>
                            <div style={styles.imgContainer}>
                                <img
                                    style={styles.img}
                                    src={tab_summary_landing}
                                    alt={'tab_summary_landing'}
                                />
                            </div>
                        </Grid>
                    </Slide>
                }

                {tab === 0 &&
                    <Slide direction={'right'} in={tab === 0} >
                        <Grid item xs={12} lg={6} sm={6}>
                            <div style={styles.imgContainer}>
                                <img
                                    style={styles.img}
                                    src={tab_summary_landing}
                                    alt={'tab_summary_landing'}
                                />
                            </div>
                        </Grid>
                    </Slide>
                }

                {tab === 0 &&
                    <Slide direction={'right'} in={tab === 0} mountOnEnter unmountOnExit>
                        <Grid item xs={12} lg={6} sm={6}>
                            <div style={styles.container}>
                                <div style={styles.smallMargin} />
                                <div>
                                    For Beginner Traders, ACT brings:
                                </div>
                                <div style={styles.topMargin}>
                                    {
                                        SECTIONS.map((s, i) =>
                                            <ListItem
                                                key={i}
                                                texts={s.texts}
                                                types={s.types}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Slide>
                }

            </Grid>
        </section>
    )
}

export default TabSummary