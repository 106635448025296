/* Action Types */
const SET_LIMITS = "SET_LIMITS";
const SHOW_LIMIT_DIALOG = "SHOW_LIMIT_DIALOG";
const HIDE_LIMIT_DIALOG = "HIDE_LIMIT_DIALOG";

/* Actions */
export function setLimits(data) {
    return {
        type: SET_LIMITS,
        payload: data
    }
}

export function showLimitDialog(message) {
    return {
        type: SHOW_LIMIT_DIALOG,
        message
    }
}

export function hideLimitDialog() {
    return {
        type: HIDE_LIMIT_DIALOG
    }
}

/* Reducer */

const initialState = { showDialog: false, message: '' }

export function limitReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LIMITS:
            return { ...state, ...action.payload };
        case SHOW_LIMIT_DIALOG:
            return { ...state, showDialog: true, message: action.message }
        case HIDE_LIMIT_DIALOG:
            return { ...state, showDialog: false }
        default:
            return state;
    }
}