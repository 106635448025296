import dashboard_blurred from "../../../media/dashboard-blurred.webp";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import intro_poster from '../../../media/intro_poster.JPG';

function ACTJobAtGlance() {
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        container: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
            //Should add purple background
        },
        videoBackground: {
            width: desktopSize ? '80%' : '98%',
            height: 'auto',
            position: 'absolute',
        },
        startButton: {
            zIndex: 1000,
            color: '#ff7900',
            width: '4rem',
            height: '4rem'
        },
        startButtonContainer: {
            width: '100%',
            height: mobileSize ? '250px' : desktopSize ? '570px' : '540px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        header: {
            width: desktopSize ? '80%' : '90%',
            display: 'flex',
            justifyContent: 'center'
        }
    }

    return (
        <section style={styles.root}>
            <h1 id={'atAGlance'} style={styles.header}>
                ACT Job at a Glance
            </h1>
            <div style={styles.container}>
                {/* <img
                    id='at_glance_img'
                    src={dashboard_blurred}
                    alt={'glance'}
                    style={styles.videoBackground}
                />
                <div style={{ ...styles.startButtonContainer }}>
                    <IconButton>
                        <PlayCircleFilledWhiteIcon style={styles.startButton} />
                    </IconButton>
                </div> */}
                <video
                    style={{ width: '100%', height: 'auto' }}
                    controls
                    poster={intro_poster}
                >
                    <source src={'/media/intro_video.mp4'} type={'video/mp4'} />
                </video>
            </div>
        </section>
    )
}

export default ACTJobAtGlance