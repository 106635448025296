import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';

function useBillingInformation() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const [loadingGet, setLoadingGet] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [billingInformation, setBillingInformation] = useState(null);
    const [didNotLoad, setDidNotLoad] = useState(false);
    const [result, setResult] = useState(null);

    const getCurrentBillingInfo = () => {
        setLoadingGet(true);
        setDidNotLoad(false);
        const onSuccess = (response) => {
            setLoadingGet(false);
            setBillingInformation(response.data.data);
        }
        const onFail = (error) => {
            setLoadingGet(false);
            if (error?.message === 'BillingNotFound') {
                setBillingInformation(null);
            } else {
                setDidNotLoad(true);
                let errMessage = ErrorParser(error);
                let localizedError = TEXTS[errMessage] || errMessage;
                dispatch(showFailNotification(localizedError));
            }
        }
        createRequest({
            method: 'GET',
            url: constantValues.GET_CURRENT_BILLING_INFORMATION,
            onSuccess,
            onFail
        })
    }

    const updateBillingInformation = (
        Country,
        ZipCode,
        State,
        City,
        BillingAddress,
        FirstName,
        LastName,
        Email
    ) => {
        setLoadingUpdate(true);
        const onSuccess = (response) => {
            setLoadingUpdate(false);
            // dispatch(showSuccessNotification('Billing Information Confirmed'));
            setResult({ success: true })
        }
        const onFail = (error) => {
            setLoadingUpdate(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }
        createRequest({
            method: 'POST',
            url: constantValues.UPDATE_BILLING_INFORMATION,
            onSuccess,
            onFail,
            data: {
                Country,
                ZipCode,
                State,
                City,
                BillingAddress,
                FirstName,
                LastName,
                Email
            }
        })
    }
    return {
        loadingUpdate,
        loadingGet,
        billingInformation,
        didNotLoad,
        getCurrentBillingInfo,
        updateBillingInformation,
        setBillingInformation,
        result
    }
}

export default useBillingInformation