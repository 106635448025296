import BillingInformation from "./BillingInformation";
import PaymentMethod from "./PaymentMethod";
import { Paper, Grid, Button } from "@mui/material";
import { useState, useEffect } from 'react';
import useBillingInformation from "../../../hooks/BillingInformation/useBillingInformation";
import constantValues from "../../../misc/constantValues";
import Countries from "../../../localization/Countries";
import { useSelector } from 'react-redux';
import CircularProgress from '../../../material-ui/CircularProgress/CircularProgress';

function Step1({ paymentMethod, setPaymentMethod, setStep }) {
    const User = useSelector(state => state.userReducer);
    const [country, setCountry] = useState(
        Countries.find(c => c.name === User?.location) || ''
    );
    const [countryValue, setCountryValue] = useState(
        Countries.find(c => c.name === User?.location) || null
    );
    const [zipCode, setZipCode] = useState('');
    const [estate, setEstate] = useState('');
    const [city, setCity] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const {
        getCurrentBillingInfo,
        loadingGet,
        loadingUpdate,
        didNotLoad,
        billingInformation,
        updateBillingInformation,
        setBillingInformation,
        result
    } = useBillingInformation();
    const styles = {
        root: {
            width: '100%',
            display: 'flex'
        },
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '2em'
        },
        btn: {
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            textTransform: 'none',
            width: '60%'
        }
    }

    const onNextStep = () => {
        updateBillingInformation(
            Countries.find(x => x.name === country)?.isoCode,
            zipCode,
            estate,
            city,
            billingAddress,
            firstName,
            lastName,
            email,
        )
    }

    useEffect(() => {
        //On Update Success
        if (result?.success) {
            setBillingInformation({
                country,
                zipCode,
                state: estate,
                city,
                billingAddress,
                firstName,
                lastName,
                email
            });

            setStep(1);
        }
    }, [result]);

    return (
        <Paper style={styles.root}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <BillingInformation
                        getCurrentBillingInfo={getCurrentBillingInfo}
                        loadingGet={loadingGet}
                        loadingUpdate={loadingUpdate}
                        didNotLoad={didNotLoad}
                        billingInformation={billingInformation}
                        setBillingInformation={setBillingInformation}
                        result={result}
                        zipCode={zipCode}
                        setZipCode={setZipCode}
                        estate={estate}
                        setEstate={setEstate}
                        city={city}
                        setCity={setCity}
                        billingAddress={billingAddress}
                        setBillingAddress={setBillingAddress}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        email={email}
                        setEmail={setEmail}
                        country={country}
                        setCountry={setCountry}
                        countryValue={countryValue}
                        setCountryValue={setCountryValue}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div style={styles.container}>
                        <PaymentMethod
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                        />
                        <Button
                            disabled={loadingUpdate}
                            onClick={onNextStep}
                            style={styles.btn}
                            variant='contained'
                        >
                            {'Next Step'}
                            {loadingUpdate && <CircularProgress />}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Step1