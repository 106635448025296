import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import constantValues from '../../misc/constantValues';
import { useSelector } from 'react-redux';

function CircleCheckbox({ checked, onChange }) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    /*END: Localization */

    /*BEGIN: Styles */
    const themeReducer = useSelector(state => state.themeReducer);
    const theme = useTheme();
    const styles = {
        root: {
            width: '14px',
            height: '14px',
            borderRadius: '50%',
            backgroundColor: 'rgb(119, 119, 119)',
            border: 'none',
            cursor: 'pointer',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '0.8rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '0.8rem' : '',
            display: 'inline-block',
            textDecoration: 'none',
            opacity: 0.2
        },
        active: {
            backgroundColor: themeReducer === "light" ?
                theme.palette.primary.main : constantValues.ICON_COLOR_1,
            border: 'none',
            opacity: 1
        }
    }
    const compStyle = checked ? Object.assign({}, styles.root, styles.active) : styles.root;
    /*END: Styles */

    return (
        <button
            onClick={onChange}
            style={compStyle}
        />
    )
}

export default CircleCheckbox