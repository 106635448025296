import help_icon_light from './help-light.png';
import help_icon_dark from './help-dark.png';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import Popover from '@mui/material/Popover';
import useMediaQuery from '@mui/material/useMediaQuery';

function HelpIcon({ style, signalCode }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            {/*This popover is  */}
            <Popover
                disablePortal={false}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                sx={{
                    pointerEvents: 'none',
                    zIndex: 1000,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                container={open ? anchorEl.parentNode : undefined}
                //open && !signalCode ? anchorEl.parentNode : undefined
            >
                {signalCode &&
                    <img
                        src={`/media/signal/signal-${signalCode}.png`}
                        alt={signalCode}
                        width={mobileSize ? '300px' : '600px'}
                        height={mobileSize ? '150px' : '300px'}
                    />
                }
            </Popover>
            {
                theme.palette.mode === 'dark' ?
                    <img
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        style={style}
                        src={help_icon_dark}
                        alt='help_dark'
                    /> :
                    <img
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        style={style}
                        src={help_icon_light}
                        alt='help_light'
                    />
            }
        </React.Fragment>
    )
}

export default HelpIcon