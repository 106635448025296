import { useDispatch, useSelector } from 'react-redux';
import Localization from '../../localization';
import ErrorParser from '../../misc/ErrorParser';
import { showFailNotification, showSuccessNotification } from '../../redux/state/notificationReducer';
import useRequest from '../../axios/useRequest';
import constantValues from '../../misc/constantValues';
import { useState } from 'react';
import { setLimits } from '../../redux/state/limitReducer';

function useSymbol() {
    const limitReducer = useSelector(state => state.limitReducer);
    const User = useSelector(state => state.userReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const dispatch = useDispatch();
    const createRequest = useRequest();
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [loadingChart, setLoadingChart] = useState(false);
    const [loadingPrice, setLoadingPrice] = useState(false);
    const [loadingToggleFavSymbol, setLoadingToggleFavSymbol] = useState(false);
    const [didNotLoadToggleFavSymbol, setDidNotLoadToggleFavSymbol] = useState(false);
    const [didNotLoadInfo, setDidNotLoadInfo] = useState(false);
    const [didNotLoadChart, setDidNotLoadChart] = useState(false);
    const [didNotLoadPrice, setDidNotLoadPrice] = useState(false);
    const [symbolInfo, setSymbolInfo] = useState(null);
    const [symbolChart, setSymbolChart] = useState(null);
    const [symbolPrice, setSymbolPrice] = useState(null);

    const toggleFavSymbol = (base, quote) => {
        setLoadingToggleFavSymbol(true);
        setDidNotLoadToggleFavSymbol(false);
        const onSuccess = (response) => {
            setLoadingToggleFavSymbol(false);
            getSymbolInfo(base);

            let favoriteCoinLeft = response?.data?.message !== 'PairRemoved' ?
                limitReducer?.favoriteCoinLeft - 1 :
                limitReducer?.favoriteCoinLeft + 1;

            dispatch(setLimits({
                ...limitReducer,
                favoriteCoinLeft
            }))
        }
        const onFail = (error) => {
            setDidNotLoadToggleFavSymbol(true);
            setLoadingToggleFavSymbol(false);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'POST',
            url: constantValues.TOGGLE_FAVORITE_SYMBOL,
            data: { base, quote, marketType: 'CRYPTO' },
            onSuccess,
            onFail
        })
    }

    const getSymbolInfo = (name) => {
        setLoadingInfo(true);
        setDidNotLoadInfo(false);
        const onSuccess = (response) => {
            setLoadingInfo(false);
            setSymbolInfo(response.data.data)
        }
        const onFail = (error) => {
            setLoadingInfo(false);
            setDidNotLoadInfo(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            url: User?.accessToken ?
                constantValues.GET_SYMBOL_INFO('CRYPTO', name) :
                constantValues.GET_SYMBOL_INFO_GUEST('CRYPTO', name),
            onSuccess,
            onFail
        })
    }

    const getSymbolChart = (name, limit) => {
        setLoadingChart(true);
        setDidNotLoadChart(false);
        const onSuccess = (response) => {
            setLoadingChart(false);
            let parsedResult = response.data.map(x => ({ time: x.dateTime, value: x.price }))
            setSymbolChart(parsedResult)
        }
        const onFail = (error) => {
            setLoadingChart(false);
            setDidNotLoadChart(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            baseUrl: constantValues.SECONDARY_BASE_URL,
            url: constantValues.GET_SYMBOL_CHART('CRYPTO', name, limit),
            onSuccess,
            onFail,
            shouldNotCheckIsSuccess: true
        })
    }

    const getSymbolPrice = (name) => {
        setLoadingPrice(true);
        setDidNotLoadPrice(false);
        const onSuccess = (response) => {
            setLoadingPrice(false);
            setSymbolPrice(response.data)
        }
        const onFail = (error) => {
            setLoadingPrice(false);
            setDidNotLoadPrice(true);
            let errMessage = ErrorParser(error);
            let localizedError = TEXTS[errMessage] || errMessage;
            dispatch(showFailNotification(localizedError));
        }

        createRequest({
            method: 'GET',
            baseUrl: constantValues.SECONDARY_BASE_URL,
            url: constantValues.GET_SYMBOL_PRICE(name),
            onSuccess,
            onFail,
            shouldNotCheckIsSuccess: true
        })
    }
    return {
        loadingInfo,
        loadingPrice,
        loadingChart,
        loadingToggleFavSymbol,

        didNotLoadInfo,
        didNotLoadPrice,
        didNotLoadChart,
        didNotLoadToggleFavSymbol,

        symbolInfo,
        symbolChart,
        symbolPrice,

        getSymbolInfo,
        getSymbolChart,
        getSymbolPrice,
        toggleFavSymbol
    }
}

export default useSymbol