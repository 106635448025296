
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Button,
    Grid, Paper, TextField
} from '@mui/material';
import { useState } from 'react';
import useDeleteAccount from '../../hooks/User/useDeleteAccount';
import { Link } from 'react-router-dom';

const DeleteAccount = () => {
    const { deleteAccount, loading } = useDeleteAccount();
    const [password, setPassword] = useState("");
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.palette.text.primary,
            fontSize: mobileSize ? '13px' : '14px',
            overflowX: 'hidden',
            paddingBottom: '3em',
            marginTop: '1rem'
        },
        paper: {
            width: mobileSize ? '97%' : !desktopSize ? '60%' : '40%',
            paddingTop: '1em',
            paddingBottom: '2em',
            border: '2px solid red',
            marginTop: '1rem'
        },
        title: {
            fontWeight: 'bold',
            fontSize: '1.4rem'
        },
        description: {
            fontSize: '1rem',
            color: 'gray',
            marginTop: '1rem',
            fontWeight: 'bold'
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginTop: '3rem'
        },
        input: {
            minWidth: '70%',
            marginTop: '1rem'
        }
    }
    const submit = () => {
        deleteAccount(password)
    }
    return (
        <main style={styles.root}>
            <Paper style={styles.paper}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={11}>
                        <div style={styles.title}>
                            Delete Account
                        </div>
                    </Grid>
                    <Grid item xs={11}>
                        <div style={styles.description}>
                            If you continue with this procedure your account will be deleted and your data will be lost forever
                        </div>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            size='small'
                            disabled={loading}
                            style={styles.input}
                            value={password}
                            label={'Password'}
                            onChange={(e) => setPassword(e.target.value)}
                            variant='outlined'
                            type={'password'}
                            name={'deleteConfirmation'}
                            autoComplete={'new-password'}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <div style={styles.actions}>
                            <Button
                                disabled={loading}
                                LinkComponent={Link}
                                to="https://allcan.trade"
                                variant={'contained'}
                            >
                                Cancel
                            </Button>
                            <Button disabled={loading} onClick={submit} color='error' variant={'outlined'}>
                                I Understand, Delete My Account
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </main>
    )
}


export default DeleteAccount