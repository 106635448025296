import Button from "@mui/material/Button";
import constantValues from "../../../misc/constantValues";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';
import { Link } from "react-router-dom";

function Cooperation() {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '2em',
            marginTop: '1rem'
        },
        container: {
            width: desktopSize || mobileSize ? '96%' : '80%',
            padding: desktopSize ? '2em' : '',
            paddingRight: desktopSize ? '3em' : '',
            paddingLeft: desktopSize ? '3em' : '',
            marginTop: '2rem',
            display: 'flex',
            flexDirection: mobileSize ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.mode === 'dark' ? '#484464' : '#f5f2f2',
            borderRadius: '13px',
            alignSelf: 'center'
        },
        text: {
            maxWidth: mobileSize ? '93%' : '60%',
            paddingTop: mobileSize ? '1em' : desktopSize ? '' : '2em',
            paddingBottom: mobileSize ? '1em' : desktopSize ? '' : '2em',
            marginLeft: !desktopSize && !mobileSize ? '1rem' : '0.6rem'
        },
        dangerText: {
            color: theme.palette.error.light
        },
        button: {
            backgroundColor: '#f07258',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            borderRadius: '8px',
            boxShadow: '0 5px 28px 0 rgba(240, 114, 89, 0.25)',
            fontSize: !desktopSize ? '10px' : '',
            marginRight: mobileSize ? '0.4rem' : desktopSize ? '' : '1rem',
            marginBottom: mobileSize ? '1rem' : ''
        },
        header: {
            marginLeft: !desktopSize ? '0.4rem' : ''
        },
        link: {
            color: 'white',
            textDecoration: 'none'
        }
    }
    return (
        <section style={styles.root}>
            <h2 style={styles.header}>{TEXTS['cooperationWithUs']}</h2>
            <div style={styles.container}>
                <div style={styles.text}>
                    {TEXTS['cooperationWithUsText1']}
                </div>
                <Link style={styles.link} to={constantValues.COOPERATION_ROUTE}>
                    <Button variant='contained' style={styles.button}>
                        {TEXTS['beOurBusinessPartner']}
                    </Button>
                </Link>
            </div>
        </section>
    )
}

export default Cooperation