import * as React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import NotFound from "./components/404";
import SecureRoute from "./components/SecureRoute";
import TokenController from "../misc/TokenController";

import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import Register from "../pages/Auth/Register";
import Login from "../pages/Auth/Login";
import LoginByQuery from '../pages/Auth/LoginByQuery';
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ChangePassword from "../pages/Auth/ChangePassword";
import Coin from "../pages/Coin";
import Strategy from "../pages/Strategy";
import FavoriteCoin from "../pages/FavoriteCoin";
import Profile from "../pages/Profile";
import TicketsOverview from "../pages/Tickets/TicketsOverview";
import TicketDetail from "../pages/Tickets/TicketDetail";
import BacktestSearch from "../pages/Backtest/Search";
import BacktestResult from "../pages/Backtest/Result";
import BacktestHistory from "../pages/Backtest/History";
import RunStrategy from "../pages/RunStrategy";
import SearchHistory from "../pages/SearchHistory";
import Plan from "../pages/Plan";
import Payment from "../pages/Payment";
import Chart from "../pages/Chart";
import Cooperation from "../pages/Cooperation";
import Contact from "../pages/Contact";
import About from "../pages/About";
import DeleteAccount from '../pages/DeleteAccount';

import Header from "../misc/Header";
import NotificationManager from "../misc/NotificationManager";
import ThemeDirectionSetter from "../localization/ThemeDirectionSetter";
import constantValues from "../misc/constantValues";
import SocketContextProvider from "../misc/SocketContextProvider";
import EssentialDataManager from '../misc/EssentialDataManager';
import AppLimitsDialog from '../misc/AppLimitsDialog';
import TrialDialog from '../misc/TrialDialog';
import CookieDialog from '../misc/CookieDialog';
// import AdminPanel from '../misc/AdminPanel';
import VerifyEmail from '../pages/VerifyEmail/VerifyEmail';
import GoogleAnalytics from '../misc/GoogleAnalytics';

function ApplicationRouter() {
    return (
        <SocketContextProvider>
            <BrowserRouter>
                <div>
                    {/*Analytics */}
                    <GoogleAnalytics />

                    {/*Token Manager */}
                    <TokenController />

                    {/*Set Theme Direction */}
                    <ThemeDirectionSetter />

                    {/*Notification Manager */}
                    <NotificationManager />

                    {/*Essential Data Manager(signals,timeframes,profile,limit..) */}
                    <EssentialDataManager />

                    {/*Account Limitations Info */}
                    <AppLimitsDialog />

                    <CookieDialog />

                    {/*Trial Dialog */}
                    <TrialDialog />

                    {/*Remove on Production */}
                    {/* <AdminPanel /> */}
                    {/*Remove on Production */}

                    {/*Header */}
                    <Header />

                    {/*BEGIN: Routes */}
                    <Routes>
                        <Route exact path={'/'} element={<Home />} />
                        <Route exact path={constantValues.REGISTER_ROUTE} element={<Register />} />
                        <Route exact path={constantValues.LOGIN_ROUTE} element={<Login />} />
                        <Route exact path={constantValues.LOGIN_BY_QUERY_ROUTE} element={<LoginByQuery />} />
                        <Route exact path={constantValues.CHART_ROUTE} element={<Chart />} />
                        <Route exact path={constantValues.SINGLE_SYMBOL_ROUTE} element={<Coin />} />
                        <Route exact path={constantValues.COOPERATION_ROUTE} element={<Cooperation />} />
                        <Route exact path={constantValues.CONTACT_ROUTE} element={<Contact />} />
                        <Route exact path={constantValues.ABOUT_ROUTE} element={<About />} />
                        <Route
                            exact
                            path={constantValues.FORGOT_PASSWORD_ROUTE}
                            element={<ForgotPassword />}
                        />
                        <Route
                            exact
                            path={constantValues.CHANGE_PASSWORD_ROUTE}
                            element={<ChangePassword />}
                        />
                        <Route
                            exact
                            path={constantValues.VERIFY_EMAIL_ROUTE}
                            element={<VerifyEmail />}
                        />
                        <Route
                            exact
                            path={constantValues.DASHBOARD_ROUTE}
                            element={<SecureRoute><Dashboard /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.STRATEGY_ROUTE}
                            element={<SecureRoute><Strategy /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.FAVORITE_COIN_ROUTE}
                            element={<SecureRoute><FavoriteCoin /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.PROFILE_ROUTE}
                            element={<SecureRoute><Profile /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.TICKETS_OVERVIEW_ROUTE}
                            element={<SecureRoute><TicketsOverview /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.TICKET_DETAIL_ROUTE}
                            element={<SecureRoute><TicketDetail /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.BACKTEST_HISTORY_ROUTE}
                            element={<SecureRoute><BacktestHistory /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.BACKTEST_SEARCH_ROUTE}
                            element={<SecureRoute><BacktestSearch /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.BACKTEST_RESULT_ROUTE}
                            element={<SecureRoute><BacktestResult /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.RUN_STRATEGY_ROUTE}
                            element={<SecureRoute><RunStrategy /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.SEARCH_HISTORY_ROUTE}
                            element={<SecureRoute><SearchHistory /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.PLAN_ROUTE}
                            element={<SecureRoute><Plan /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.PAYMENT_ROUTE}
                            element={<SecureRoute><Payment /></SecureRoute>}
                        />
                        <Route
                            exact
                            path={constantValues.DELETE_ACCOUNT_ROUTE}
                            element={<SecureRoute><DeleteAccount /></SecureRoute>}
                        />

                        <Route
                            path="*"
                            element={<NotFound />}
                        />
                    </Routes>
                    {/*END: Routes */}
                </div>
            </BrowserRouter>
        </SocketContextProvider>
    )
}

export default ApplicationRouter