import OutlinedTextField from "../../../material-ui/TextField/OutlinedTextField";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import constantValues from '../../../misc/constantValues';
import Localization from '../../../localization';
import AntSwitch from '../../../material-ui/Switch/AntSwitch';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Link } from "react-router-dom";

function Header({
    timeframe,
    setTimeframe,
    exchange,
    setExchange,
    signalDate,
    setSignalDate,
    matchAll,
    setMatchAll,
    tradeType,
    setTradeType
}) {
    const limitReducer = useSelector(state => state.limitReducer);
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */
    const timeframesReducer = useSelector(state => state.timeframesReducer);
    const theme = useTheme();
    const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginTop: '2rem',
            flexWrap: 'wrap',
            gap: 20
        },
        title: {
            marginLeft: '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : '',
            width: !desktopSize ? '100%' : ''
        },
        inputTitle: {
            color: '#585858',
            marginLeft: desktopSize ? '3rem' : '1rem',
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '1rem' : ''
        },
        timeframeInput: {
            width: '100px',
            marginLeft: '1rem'
        },
        exchangeInput: {
            width: '150px',
            marginLeft: '1rem'
        },
        signalDateInput: {
            minWidth: '150px',
            marginLeft: '1rem'
        },
        matchContainer: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.mode === "dark" ? constantValues.ICON_COLOR_1 : theme.palette.primary.main,
            marginLeft: desktopSize ? '1rem' : '1.5rem',
            marginTop: desktopSize ? '' : '1rem'
        },
        switchText: {
            marginRight: constantValues.IS_RTL_LANGUAGE(language) ? '' : '1.5rem',
            marginLeft: constantValues.IS_RTL_LANGUAGE(language) ? '1.5rem' : ''
        },
    }

    return (
        <section style={styles.root}>
            <h2 style={styles.title}>{TEXTS['strategyResult']}</h2>

            {/*BEGIN: exchange */}
            <OutlinedTextField
                value={exchange}
                onChange={(e) => setExchange(e.target.value)}
                style={styles.exchangeInput}
                label={TEXTS["exchange"]}
                select
                size='small'
                variant='outlined'
            >
                <MenuItem disabled value={null}>
                    {TEXTS["exchange"]}
                </MenuItem>
                <MenuItem value={"Binance"}>
                    Binance
                </MenuItem>
            </OutlinedTextField>
            {/*END: exchange */}

            {/*BEGIN: Timeframe */}
            <OutlinedTextField
                value={timeframe}
                defaultValue={timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
                label={TEXTS["timeframe"]}
                size='small'
                select
                style={styles.timeframeInput}
            >
                {timeframesReducer?.map(t =>
                    !limitReducer?.timeFrames?.includes(t) ?
                        <Tooltip
                            title={
                                'You must upgrade your plan in order to use this timeframe. Click to view plans'
                            }
                            arrow
                        >
                            <Link
                                to={constantValues.PLAN_ROUTE}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <span>
                                    <MenuItem
                                        disabled
                                        value={t}
                                        key={t}
                                    >
                                        <div className="flex-row">
                                            <LockOutlinedIcon fontSize='small' />
                                            {t}
                                        </div>
                                    </MenuItem>
                                </span>
                            </Link>
                        </Tooltip> :
                        <MenuItem
                            value={t}
                            key={t}
                        >
                            {t}
                        </MenuItem>
                )}
            </OutlinedTextField>
            {/*END: timeframe */}

            {/*BEGIN: Signal Date */}
            <OutlinedTextField
                value={signalDate}
                onChange={(e) => setSignalDate(e.target.value)}
                style={styles.signalDateInput}
                label={TEXTS["candles"]}
                select
                size='small'
                variant='outlined'
            >
                <MenuItem disabled value={null}>
                    {TEXTS["candles"]}
                </MenuItem>
                {constantValues.CANDLE_OPTIONS.map(d =>
                    <MenuItem value={d} key={d}>
                        {`${d} ${d === 1 ? 'Candle' : 'Candles'} ago`}
                    </MenuItem>
                )}
            </OutlinedTextField>
            {/*END: Signal Date */}

            {/*BEGIN: Trade Type */}
            <OutlinedTextField
                value={tradeType}
                onChange={(e) => setTradeType(e.target.value)}
                style={styles.tradeTypeInput}
                select
                label={TEXTS["spot"]}
                size='small'
                variant='outlined'
                sx={{
                    "& .MuiOutlinedInput-root": styles.selectTextFieldRoot,
                    "& .MuiInputLabel-root": styles.inputLabelRoot,
                    "& .MuiSelect-icon": styles.selectInputIcon
                }}
            >
                {["Any", "Spot", "Future"].map(x =>
                    <MenuItem style={styles.menuItem} value={x}>
                        {x}
                    </MenuItem>
                )}
            </OutlinedTextField>
            {/*END: Trade Type */}

            {/*BEGIN: Match */}
            <div style={styles.matchContainer}>
                <div style={styles.switchText}>{TEXTS["matchAll"]}</div>
                <AntSwitch checked={matchAll} onChange={(e) => setMatchAll(prev => !prev)} />
            </div>
            {/*END: Match */}
        </section>
    )
}

export default Header