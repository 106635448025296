import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import OutlinedTextField from '../../../material-ui/TextField/OutlinedTextField';
import MenuItem from '@mui/material/MenuItem';
import useBillingInformation from "../../../hooks/BillingInformation/useBillingInformation";
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Countries from "../../../localization/Countries";
import Button from '@mui/material/Button';
import constantValues from '../../../misc/constantValues';
import WarningIcon from '@mui/icons-material/Warning';
import MuiSkeleton from '../../../material-ui/Skeleton/MuiSkeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import Localization from '../../../localization';
import Autocomplete from '@mui/material/Autocomplete';

function BillingInformation({
    getCurrentBillingInfo,
    loadingGet,
    didNotLoad,
    billingInformation,
    setBillingInformation,
    result,
    zipCode,
    setZipCode,
    estate,
    setEstate,
    city,
    setCity,
    billingAddress,
    setBillingAddress,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    country,
    setCountry,
    countryValue,
    setCountryValue
}) {
    /*BEGIN: Localization */
    const language = useSelector(state => state.localizationReducer);
    const TEXTS = Localization(language);
    /*END: Localization */

    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = {
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
            color: theme.palette.text.primary,
            fontSize: '1rem',
            gap: 10
        },
        paper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '2em',
            paddingTop: '1.5em',
            marginTop: '1rem',
            color: theme.palette.mode === 'light' ? '#777777' : '#e2e2e2',
            boxShadow: mobileSize ? 'none' : ''
        },
        paperContent: {
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '13px'
        },
        gridContainer: {
            marginTop: '1rem'
        },
        inputContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        inputRoot: {
            width: '100%',
            marginTop: '0.4rem'
        },
        confirmedBox: {
            borderRadius: '6px',
            gap: 2,
            width: '110px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '9px',
            color: theme.palette.success.main,
            border: `1px solid ${theme.palette.success.main}`
        },
        notConfirmedBox: {
            borderRadius: '6px',
            gap: 2,
            width: '110px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '9px',
            color: theme.palette.warning.main,
            border: `1px solid ${theme.palette.warning.main}`
        },
        applyButton: {
            textTransform: 'none',
            fontFamily: constantValues.PRIMARY_FONT_FAMILY,
            width: '100%',
            marginTop: '1rem',
        }
    }
    useEffect(() => {
        getCurrentBillingInfo()
    }, []);

    useEffect(() => {
        if (billingInformation) {
            let b = billingInformation;
            setCountry(Countries.find(c => c.isoCode === b.country)?.name);
            setCountryValue(Countries.find(c => c.isoCode === b.country))
            setZipCode(b.zipCode);
            setEstate(b.state);
            setCity(b.city);
            setBillingAddress(b.billingAddress);
            setFirstName(b.firstName);
            setLastName(b.lastName);
            setEmail(b.email);
        }
    }, [billingInformation]);


    if (loadingGet || didNotLoad) {
        return (
            <div style={styles.root}>
                <MuiSkeleton
                    variant='rectangular'
                    width={'100%'}
                    height={'80vh'}
                    reload={getCurrentBillingInfo}
                    didNotLoad={didNotLoad}
                    loading={loadingGet}
                />
            </div>
        )
    }

    return (
        <section style={styles.root}>

            <div style={styles.paper}>
                <div style={styles.paperContent}>
                    <div style={styles.header}>
                        <div>
                            {TEXTS['billingInformation']}
                        </div>
                        {/* {!billingInformation &&
                            <div style={styles.notConfirmedBox}>
                                <WarningIcon />
                                <div>{TEXTS['notConfirmed']}</div>
                            </div>
                        } */}
                    </div>
                    <div>
                        {TEXTS['billingInformationText']}
                    </div>
                    <Grid style={styles.gridContainer} container spacing={2}>
                        <Grid item lg={12} xs={12}>
                            <div style={styles.inputContainer}>
                                <div>
                                    {TEXTS['country']}*
                                </div>
                                {/* <OutlinedTextField
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    style={styles.inputRoot}
                                    select
                                    size='small'
                                    variant='outlined'
                                >
                                    {Countries?.map(c =>
                                        <MenuItem value={c.name} key={c.name}>
                                            {c.name}
                                        </MenuItem>
                                    )}
                                </OutlinedTextField> */}
                                <Autocomplete
                                    style={styles.inputRoot}
                                    value={countryValue}
                                    onChange={(e, newValue) => setCountryValue(newValue)}
                                    inputValue={country}
                                    onInputChange={(e, newValue) => {
                                        setCountry(newValue);
                                    }}
                                    options={Countries}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <div {...props}>
                                            {option.name}
                                        </div>
                                    )}
                                    renderInput={(params) => (
                                        <OutlinedTextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password'
                                            }}
                                            style={{ width: '100%' }}
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <div style={styles.inputContainer}>
                                <div>
                                    {TEXTS['zipCode']}*
                                </div>
                                <OutlinedTextField
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}
                                    style={styles.inputRoot}
                                    size='small'
                                    variant='outlined'
                                />
                            </div>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <div style={styles.inputContainer}>
                                <div>
                                    {TEXTS['stateOrRegion']}*
                                </div>
                                <OutlinedTextField
                                    value={estate}
                                    onChange={(e) => setEstate(e.target.value)}
                                    style={styles.inputRoot}
                                    size='small'
                                    variant='outlined'
                                />
                            </div>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <div style={styles.inputContainer}>
                                <div>
                                    {TEXTS['city']}*
                                </div>
                                <OutlinedTextField
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    style={styles.inputRoot}
                                    size='small'
                                    variant='outlined'
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={styles.inputContainer}>
                                <div>
                                    {TEXTS['billingAddress']}*
                                </div>
                                <OutlinedTextField
                                    value={billingAddress}
                                    onChange={(e) => setBillingAddress(e.target.value)}
                                    style={styles.inputRoot}
                                    size='small'
                                    variant='outlined'
                                />
                            </div>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <div style={styles.inputContainer}>
                                <div>
                                    {TEXTS['firstName']}*
                                </div>
                                <OutlinedTextField
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    style={styles.inputRoot}
                                    size='small'
                                    variant='outlined'
                                />
                            </div>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <div style={styles.inputContainer}>
                                <div>
                                    {TEXTS['lastName']}*
                                </div>
                                <OutlinedTextField
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    style={styles.inputRoot}
                                    size='small'
                                    variant='outlined'
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={styles.inputContainer}>
                                <div>
                                    {TEXTS['email']}*
                                </div>
                                <OutlinedTextField
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={styles.inputRoot}
                                    size='small'
                                    variant='outlined'
                                />
                            </div>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <div>
                                <Button
                                    disabled={loadingUpdate}
                                    style={styles.applyButton}
                                    variant='contained'
                                    onClick={onUpdateBillingInformation}
                                >
                                    {billingInformation ?
                                        TEXTS['update'] : TEXTS['confirm']
                                    }
                                </Button>
                            </div>
                        </Grid> */}
                    </Grid>
                </div>
            </div>
        </section>
    )
}

export default BillingInformation