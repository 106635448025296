import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from "react-redux";

const lightTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 800,
            lg: 1280,
            xl: 1536,
        }
    },
    palette: {
        mode: "light",
        primary: {
            main: "#72499d",
        },
        secondary: {
            main: "#cdaed3",
        },
        background: {
            paper: "#fff",
            default: "#fff"
        },
        text: {
            primary: "#000000",
            secondary: "#72499d",
            disabled: "#7c7c7c"
        }
    }
});

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#72499d",
        },
        secondary: {
            main: "#cdaed3",
        },
        background: {
            paper: "rgb(29, 26, 46)",
            default: "rgb(29, 26, 46)"
        },
        text: {
            primary: "#fff",
            secondary: "#c9acec",
            disabled: "#7c7c7c",
        },
        warning: {
            main: '#f07259'
        }
    }
});

export default function Theme({ children }) {
    const theme = useSelector((state) => state.themeReducer);
    return (
        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
            {children}
        </ThemeProvider>
    )
}